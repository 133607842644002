import React, { useState, useEffect } from 'react';

import { ITrainingSchedule } from 'model';
import { useStores } from 'state';

export const useTrainingSchedules = () => {
  const { sitterStore } = useStores();
  const [data, setData] = useState<ITrainingSchedule[]>([]);

  useEffect(() => {
    (async () => {
      setData(await sitterStore.getTrainningSchedules());
    })();
  }, []);

  return data;
};

export const useMyTrainingSchedule = ({ userId }) => {
  const { sitterStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    fee: 0,
    host_start_time: null,
    host_end_time: null,
    supplies: '신원 확인을 위한 신분증, 필기구, 마스크 필수착용',
    note:
      '교육 당일에 프로필 사진촬영이 있어요.\n자기소개서에 들어갈 사진이니 모자 착용은 하지말아주세요!',
    center_name: '',
    center_address: '',
    center_guide: '',
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { result } = await sitterStore.getMyTrainingSchedule(userId);
        setData(result);
      } catch (error) {
        alert(`교육 신청정보 조회에 실패하였습니다.${error.message} `);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return { data, loading };
};
