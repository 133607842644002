import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

import { Labeled } from 'atom/input';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep4 } from './RequestStep4';

// 안쓰임
export const RequestStep3 = observer(({
  Bottom,
  onNext,
}) => {
  const { customerStore } = useStores();

  return (
    <>
      <Title>
        이름과 이메일주소를<br />
        작성해주세요.
      </Title>
      <Space height={74} />

      <Labeled
        label="이름"
      >
        <TextField
          variant="outlined"
          placeholder="입력란"
          style={{ width: '100%' }}
          value={customerStore.form.name}
          onChange={e => customerStore.form.name = e.target.value}
        />
      </Labeled>
      <Space height={20} />

      <Labeled
        label="이메일 주소"
      >
        <TextField
          variant="outlined"
          placeholder="입력란"
          style={{ width: '100%' }}
          value={customerStore.form.email}
          onChange={e => customerStore.form.email = e.target.value}
        />
      </Labeled>
      <Space height={60} />
      <Bottom
        progress={1 / 11 * 3}
        onClickNext={() => onNext(RequestStep4)}
      />
    </>
  );
});
