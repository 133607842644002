import React from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';

import { Space, HorizontalLayout } from 'atom/layout';
import { Color } from 'constant';
import { useIsMobile } from 'util/index';

interface WeekdayPickerProps {
  value: number[];
  onChange: (x: number[]) => void;
}
export const WeekdayPicker = ({ value, onChange }: WeekdayPickerProps) => {
  const isMobile = useIsMobile();

  const onClickItem = (item: number) => {
    if (value.includes(item)) onChange(value.filter(x => x !== item));
    else onChange(sortBy([...value, item]));
  };

  return (
    <HorizontalLayout>
      {['월', '화', '수', '목', '금', '토', '일'].map((x, idx) => (
        <WeekItem
          key={idx}
          isMobile={isMobile}
          active={value.includes(idx)}
          onClick={() => {
            onClickItem(idx);
          }}
        >
          {x}
        </WeekItem>
      ))}
    </HorizontalLayout>
  );
};
const WeekItem = styled.div<any>`
  display: flex;

  width: 45px;
  height: 45px;

  font-size: 14px;
  font-weight: bold;

  border-radius: 25px;

  align-items: center;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;

  cursor: pointer;
  transition: all 0.14s linear;

  ${({ isMobile }) =>
    isMobile
      ? `
    width: 35px;
    height: 35px;

    font-size: 13px;
  `
      : `
  `}
  ${({ active, isMobile }) =>
    active
      ? `
    background-color: ${Color.Primary};
    color: white;
  `
      : `
    border: solid 2px #e5e5e5;
    color: #b7b7b7;
  `}

  ${({ disabled }) =>
    disabled
      ? `
      border: solid 2px white;
      color: white;
      cursor: default;
`
      : `

`}
`;
