import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, LightGray20, Primary34 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Content2InfoBox = ({ info }) => {
  const { imagePath, title, subTitle, description } = info;
  return (
    <Container center>
      <ImgBox src={imagePath} />
      <Space height={64} />
      <Primary34 center bold>
        {title}
      </Primary34>
      <Space height={12} />
      <DarkGray24 center bold>
        {subTitle}
      </DarkGray24>
      <Space height={32} />
      <LightGray20 semiBold>{description}</LightGray20>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  margin: 0 90px;
`;
const ImgBox = styled.img`
  width: 360px;
  height: 240px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export default Content2InfoBox;
