import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { HorizontalLayout } from 'atom/layout';
import { DatePickerPopup, DateRangePickerPopup } from 'component/popup';
import { useIsMobile, useTopmost } from 'util/index';
import { DatePicker } from './DatePicker';
import { DateRangePicker } from './DateRangePicker';
import { count2DaysLater } from 'page/customer/request';

interface DateRangeInputProps {
  hasIcon?: boolean;
  style?: CSSProperties;
  isStart: boolean;
  startDate: DateTime;
  endDate: DateTime;
  onChange: (startDate: DateTime, endDate?: DateTime) => void;
}
export const DateRangeInput = ({
  hasIcon = false,
  isStart,
  startDate,
  endDate,
  onChange,
  ...props
}: DateRangeInputProps) => {
  const isMobile = useIsMobile();
  const topmost = useTopmost();
  const [show, setShow] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: e => {
      // @ts-ignore
      if (e.target.tagName !== 'ABBR') {
        setShow(false);
      }
    },
  });

  const onClickOpen = () => {
    if (isMobile) {
      if (isStart) {
        const id = topmost.show(
          <DatePickerPopup
            value={startDate}
            onChange={start => onChange(start, null)}
            onClose={() => topmost.dismiss(id)}
          />,
        );
      } else {
        const id = topmost.show(
          <DateRangePickerPopup
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => onChange(start, end)}
            onClose={() => topmost.dismiss(id)}
          />,
        );
      }
    } else {
      if (!isStart) onChange(startDate, null);
      setShow(true);
    }
  };

  return (
    <>
      <Container ref={ref} {...props}>
        <HorizontalLayout>
          <TextField
            placeholder="YYYY-MM-DD"
            variant="outlined"
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            value={
              isStart
                ? startDate?.toFormat('yyyy-MM-dd')
                : endDate?.toFormat('yyyy-MM-dd')
            }
            onClick={onClickOpen}
          />
          {hasIcon && <CalendarIcon onClick={onClickOpen} />}
        </HorizontalLayout>

        {isStart &&
          !!startDate &&
          startDate.diffNow('days').days < count2DaysLater() && (
            <ErrorText>
              시작일은 영업일 기준, 최소 2일 이후여야 합니다.
            </ErrorText>
          )}

        {isStart && !!startDate && startDate.diffNow('days').days > 61 && (
          <ErrorText>시작일은 최대 2개월 이내부터 선택이 가능해요.</ErrorText>
        )}
        {!isStart && !!endDate && startDate.diff(endDate, 'days').days >= 1 && (
          <ErrorText>종료일은 시작일 이후여야 합니다.</ErrorText>
        )}
        {!isStart && !!endDate && endDate.diff(startDate, 'days').days < 28 && (
          <ErrorText>정기돌봄은 4주 이상 이용하셔야 합니다.</ErrorText>
        )}

        {show && !isStart && (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={(start, end) => {
              onChange(start, end);
              setShow(false);
            }}
          />
        )}
        {show && isStart && (
          <DatePicker
            value={startDate}
            onChange={start => {
              onChange(start, null);
              setShow(false);
            }}
          />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  input {
    caret-color: transparent !important;
  }
`;
const ErrorText = styled.div`
  margin-top: 5px;
  color: red;
`;
const CalendarIcon = styled.img.attrs({
  src: require('asset/icon/calendar.svg').default,
})`
  width: 20px;
  height: 20p;

  margin-left: 10px;

  cursor: pointer;
`;
