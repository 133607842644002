import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray32, DarkGray40, DarkGray44, White44 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const RewardDetail1 = props => {
  const playSteps = [
    {
      title: '책을 읽으며 다양한 질문과 이야기를 만들어요.',
      content: `책을 읽으며 다양한 스토리를 통해 아이들의 흥미를 이끌어내고
            아이의 생각을 확장시켜줘요.`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_5-1.png')
        .default,
    },
    {
      title: '오늘의 놀이 주제를 선정해요.',
      content: `책에 있는 스토리를 활용하거나, 아이가 흥미를 가질만한 상황을 기반으로
            오늘 진행할 놀이 주제를 선정해요.`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_5-2.png')
        .default,
    },
    {
      title: '다양한 놀이 재료들을 탐색하며, 놀이를 진행해요.',
      content: `히어로에서 제공한 다양한 재료들을 탐색하며, 
            놀이주제와 연관된 놀이를 진행해요.`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_5-3.png')
        .default,
    },
    {
      title: '놀이를 마무리해요.',
      content: `질문을 통해 아이가 무엇을 느꼈는지 공감해주며,
            아이가 놀이과정을 통해 생각이 자라날 수 있도록 격려해줘요.`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_5-4.png')
        .default,
    },
  ];
  return (
    <Container center>
      <Space height={120} />
      <RoundTextBox
        style={{
          width: '278px',
        }}
      >
        혜택1
      </RoundTextBox>
      <Space height={78} />
      <DarkGray44 style={{ fontSize: '48px' }}>
        이벤트 혜택을 통해{' '}
        <span style={{ fontWeight: 'bold' }}>기본 돌봄 비용으로</span>
      </DarkGray44>
      <DarkGray44 style={{ fontSize: '48px' }}>
        <span style={{ fontWeight: 'bold' }}>창의놀이</span>까지 덤으로
        경험해보세요!
      </DarkGray44>
      <Space height={78} />
      <div
        style={{ backgroundColor: '#ED584E', width: '100%', height: '1064px' }}
      >
        <Space height={78} />
        <White44 style={{ fontSize: '48px' }} bold center>
          🎉 돌봄히어로 BEST 추천 돌봄 구성 🎉
        </White44>
        <Space height={76} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageCard
            src={
              require('asset/event/event_5years_for_parents/reward1_detail_1.png')
                .default
            }
            title={'1:1 맞춤 돌봄'}
            content={`히어로 선생님이 안전하게
등하원을 함께 해주시고
아이의 기질과 성향에 맞는
의사소통하며 돌봄을 진행해요.`}
          />
          <TextIcon>+</TextIcon>
          <ImageCard
            src={
              require('asset/event/event_5years_for_parents/reward1_detail_2.png')
                .default
            }
            title={'창의놀이'}
            content={`아이 주도놀이를 통해 
          아이의 주체성, 창의성이
          자라날 수 있도록 돕는
          다양한 놀이를 진행해요.`}
          />
        </div>
      </div>
      <Space height={120} />
      <img
        style={{
          width: '734px',
          margin: '0 auto',
        }}
        src={
          require('asset/event/event_5years_for_parents/reward1_detail_3.png')
            .default
        }
      />
      <Space height={120} />
      <DarkGray44
        style={{
          fontSize: '48px',
          color: 'black',
        }}
        bold
        center
      >
        ①{' '}
        <span
          style={{
            background:
              'linear-gradient(to top, #FFD202 30%, transparent 30%) ',
          }}
        >
          돌봄히어로의 창의놀이는 어떻게 다른가요?
        </span>
      </DarkGray44>
      <Space height={76} />
      <WhiteTextBox>
        <DarkGray32 center style={{ fontSize: '34px', whiteSpace: 'pre-line' }}>
          <BoldText>창의놀이</BoldText>는 선생님과{' '}
          <BoldText>아이와의 상호작용 질을 높이기 위해</BoldText>
          <br />
          <BoldText>
            전문 양성 교육 및 보수교육을 수료한 선생님들만 활동
          </BoldText>
          하고 있어요.
          <br />
          <br />
          <BoldText>창의놀이</BoldText>는 아이와 함께 책을 읽는 과정으로부터
          시작되며,
          <br />
          스토리와 연관된 다양한 질문을 만들어보기도 하고 <br />
          상황에 맞는 주제를 설정하며{' '}
          <BoldText>아이주도를 기반으로 자유롭게 진행되는 놀이입니다.</BoldText>
        </DarkGray32>
      </WhiteTextBox>
      <Space height={36} />
      <img
        style={{ width: '1346px' }}
        src={
          require('asset/event/event_5years_for_parents/reward1_detail_4.png')
            .default
        }
      />
      <Space height={240} />
      <DarkGray44
        style={{
          fontSize: '48px',
          color: 'black',
        }}
        bold
        center
      >
        ②{' '}
        <span
          style={{
            background:
              'linear-gradient(to top, #FFD202 30%, transparent 30%) ',
          }}
        >
          돌봄히어로의 창의놀이는 어떻게 진행되나요?
        </span>
      </DarkGray44>
      <Space height={78} />
      <FlexBox
        style={{
          justifyContent: 'left',
          alignItems: 'start',
        }}
      >
        <img
          style={{ margin: '1rem 10rem 0 0' }}
          src={
            require('asset/event/event_5years_for_parents/reward1_detail_5-left.svg')
              .default
          }
        />
        <div>
          {playSteps.map(item => (
            <>
              <StepBox
                key={item.title}
                title={item.title}
                content={item.content}
                src={item.src}
              />
              <Space height={120} />
            </>
          ))}
        </div>
      </FlexBox>
      <Space height={240} />
      <DarkGray44
        style={{
          fontSize: '48px',
          color: 'black',
        }}
        bold
        center
      >
        ③{' '}
        <span
          style={{
            background:
              'linear-gradient(to top, #FFD202 30%, transparent 30%) ',
          }}
        >
          준비물은 어떻게 구성 되어 있나요?
        </span>
      </DarkGray44>
      <Space height={120} />
      <div
        style={{
          position: 'relative',
        }}
      >
        <img
          style={{ width: '1200px' }}
          src={
            require('asset/event/event_5years_for_parents/reward1_detail_6.png')
              .default
          }
        />
        <DarkGray32
          style={{
            fontSize: '34px',
            color: '#696969',
            position: 'absolute',
            right: '-170px',
            bottom: '-5px',
          }}
          bold
        >
          * 기본 준비물 이미지는 실제와 다를 수 있습니다.
        </DarkGray32>
      </div>
      <Space height={100} />

      <WhiteTextBox>
        <DarkGray32
          style={{
            fontSize: '34px',
          }}
          center
        >
          {`놀이 진행에 필요한 기본 준비물(도화지, 클레이, 색종이, 펀치, 가위 등)과 
아이의 창의력 증진에 필요한 50여가지의 다양한 재료들로 구성되어 있습니다. `}
        </DarkGray32>
      </WhiteTextBox>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  width: 100%;
  height: 9600px;
  background: #fff8eb;
`;
const TextIcon = styled.div`
  font-weight: 700;
  font-size: 100px;
  line-height: 70px;

  text-align: center;
  margin: 0 77px;

  color: #ffffff;
`;
const ImageCardFrame = styled(VerticalLayout)`
  width: 520px;
  height: 760px;

  background: #ffffff;
  border: 10px solid #f69f30;
  border-radius: 24px;
`;

const ImageCard = ({ src, title, content }) => {
  return (
    <ImageCardFrame>
      <img
        src={src}
        style={{ width: '100%', height: '386', borderRadius: '1rem 1rem 0 0' }}
      />
      <Space height={34} />
      <DarkGray40 center bold style={{ fontSize: '42px' }}>
        {title}
      </DarkGray40>
      <Space height={38} />
      <DarkGray32 style={{ fontSize: '34px', whiteSpace: 'pre-line' }} center>
        {content}
      </DarkGray32>
    </ImageCardFrame>
  );
};
const RoundTextBox = styled.div`
  background: #000000;
  border-radius: 100px;
  padding: 15px 55px;
  text-align: center;
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
`;

const WhiteTextBox = styled.div`
  width: 1524px;
  padding: 24px 24px 24px 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
`;
const BoldText = styled.span`
  font-weight: bold;
`;
const FlexBox = styled.div`
  display: flex;
`;

const StepBoxFrame = styled(VerticalLayout)`
  width: 950px;
`;
const StepBox = ({ title, content, src }) => {
  return (
    <StepBoxFrame>
      <DarkGray40 bold>{title}</DarkGray40>
      <DarkGray32
        style={{ fontSize: '34px', whiteSpace: 'pre-line', margin: '1rem 0' }}
      >
        {content}
      </DarkGray32>
      <img
        style={{
          width: '900px',
          height: '600px',
        }}
        src={src}
      />
    </StepBoxFrame>
  );
};
export default RewardDetail1;
