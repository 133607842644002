import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { HorizontalLayout, Space, Push } from 'atom/layout';
import { useStores } from 'state';
import { useIsMobile } from 'util/index';
import { DarkGray14, DarkGray18 } from 'atom/text';
import { Button, LinearProgress } from '@material-ui/core';

export const HeaderMyPageEdit = ({
  title = '내 정보',
  handleSave = () => {
    console.log('저장해야함');
  },
}) => {
  const isMobile = useIsMobile(true);
  const history = useHistory();

  // PC에서 모바일 페이지 보여주는 경우
  if (!isMobile) return <></>;

  return (
    <>
      <Holder>
        <Container>
          <img
            style={{ marginLeft: '10px', marginRight: '34px' }}
            src={require('asset/icon/backArrow.svg').default}
            onClick={() => history.goBack()}
          />

          <DarkGray18 bold>{title}</DarkGray18>
          <Button onClick={handleSave}>
            <DarkGray14>저장</DarkGray14>
          </Button>
        </Container>
      </Holder>
    </>
  );
};

const Holder = styled.header`
  position: relative;

  width: 100%;
  height: 56px;
`;
const Container = styled(HorizontalLayout)`
  position: fixed;
  justify-content: space-between;
  padding: 0 13px;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 56px;

  background: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

  align-items: center;

  z-index: 10;
`;
