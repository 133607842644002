import { observable } from 'mobx';

import { isMobile } from 'util/index';

const pcBanners = [
  {
    title: 'PC 광고 배너1',
    imgPath: '/home/image/load/22/04/20/132113_Frame 8 (1).jpg',
    target: 'url',
    content: 'https://bit.ly/3vm3lhr',
    created: '2020-12-06T08:07:59.000Z',
    updated: '2022-07-26T07:31:38.000Z',
  },
  {
    title: 'PC 광고 배너2',
    imgPath: '/home/image/load/22/04/13/161928_Frame 5.jpg',
    target: 'url',
    content: 'http://localhost:3000/service/apply/q1',
    created: '2020-12-06T08:10:12.000Z',
    updated: '2022-04-13T07:24:12.000Z',
  },
  {
    title: 'PC 광고 배너3',
    imgPath: '/home/image/load/22/04/13/161918_Frame 3.jpg',
    target: 'url',
    content: 'https://woorihero.com/event_sd',
    created: '2020-12-06T08:10:14.000Z',
    updated: '2022-04-13T07:19:18.000Z',
  },
];
const mobileBanners = [
  {
    title: '모바일 광고 배너1',
    imgPath: '/home/image/load/22/09/28/101612_202210_event.png',
    target: 'url',
    content: 'https://bit.ly/3JIxaOE',
    created: '2020-12-06T08:10:24.000Z',
    updated: '2022-06-17T05:06:13.000Z',
  },
  {
    title: '모바일 광고 배너2',
    imgPath: '/home/image/load/22/04/13/160504_Frame 9.png',
    target: 'empty',
    content: null,
    created: '2020-12-06T08:10:26.000Z',
    updated: '2022-04-13T07:05:05.000Z',
  },
  {
    title: '모바일 광고 배너3',
    imgPath: '/home/image/load/22/04/13/160522_Frame 10.png',
    target: 'empty',
    content: null,
    created: '2020-12-06T08:10:27.000Z',
    updated: '2022-04-13T07:05:22.000Z',
  },
];
export interface IBannerStore {
  getBanners();
}
const bannerStore = observable<IBannerStore>({
  async getBanners() {
    const { result } = await apiNew.get('/home/banner/', {
      position: isMobile() ? 2 : 1,
    });
    return result;
    //return isMobile() ? mobileBanners : pcBanners;
    // return await api.get(`/monitor/banner`, {
    //   platform: isMobile() ? 'mobile' : 'pc',
    // });
  },
});
export default bannerStore;
