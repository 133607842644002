import { TextField } from '@material-ui/core';
import { RoundButton } from 'atom/button';
import { Labeled } from 'atom/input';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, Primary12 } from 'atom/text';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'state';
import { DateTime } from 'luxon';
import { HeaderCustomerSignUp } from 'component-mobile/header';

const getAge = (birthday: string) => {
  let date = DateTime.fromFormat(birthday, 'yyMMdd');
  if (date.year >= 2025) date = date.minus({ years: 100 });
  return Math.ceil(-date.diffNow('years').years);
};

const SitterSignUpPageStep2 = observer(() => {
  const history = useHistory();
  const { sitterStore } = useStores();
  const [birthWarningKey, setBirthWarningKey] = useState(null);
  const birthWarning = {
    1: '생일을 입력해주세요',
    2: '30세 이상 67세 이하 여성만 가입이 가능합니다.',
  };

  const checkForm = () => {
    const birthday = sitterStore.signUpForm.birth;
    const age = getAge(birthday);
    if (birthday.length !== 6) {
      setBirthWarningKey(1);
      return false;
    }
    if (age + 1 > 67 || age + 1 <= 30) {
      setBirthWarningKey(2);
      return false;
    }

    setBirthWarningKey(null);
    return true;
  };
  const registerHero = () => {
    if (checkForm()) {
      history.push('/sign-up/hero/info3');
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.push('/sign-up/hero/phone');
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <HeaderCustomerSignUp progress={(2 / 7) * 100} />
      <VerticalLayout style={{ padding: '25px' }}>
        <DarkGray24 bold>
          생년월일을 <br />
          입력해주세요.
        </DarkGray24>
        <Space height={64} />
        <Labeled
          label="생년월일 6자리"
          labelStyle={{ fontSize: '18px' }}
          gap={10}
        >
          <TextField
            placeholder="예)641021"
            variant="outlined"
            fullWidth
            value={sitterStore.signUpForm.birth}
            onChange={e =>
              (sitterStore.signUpForm.birth = e.target.value.replace(/\D/g, ''))
            }
          />
        </Labeled>
        {birthWarningKey ? (
          <Primary12 style={{ margin: '4px 0 2px 4px' }}>
            {birthWarning[birthWarningKey]}
          </Primary12>
        ) : (
          <Space height={24} />
        )}
        <Space height={220} />
      </VerticalLayout>
      <RoundButton
        style={{
          width: '320px',
          height: '56px',
          padding: 0,
          fontSize: '16px',
          margin: 'auto',
        }}
        onClick={registerHero}
      >
        다음
      </RoundButton>
    </>
  );
});

export default SitterSignUpPageStep2;
