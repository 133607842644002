import { TextField } from '@material-ui/core';
import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, LightGray12 } from 'atom/text';
import CountDown from 'react-countdown';
import { VerificationStep } from 'model';
import React, { useEffect, useState } from 'react';
import { useStores } from 'state';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Header } from 'component-mobile/header';
import { Footer } from 'component-mobile/footer';
import { Labeled } from 'atom/input';
import { RoundButton } from 'atom/button';
import Timer, { threeMinutes } from 'component-mobile/login/Timer';
import { okLogin } from 'util/token';
import { useAuthDispatch } from '../../context/AuthContext';
import sitterStore from 'state/sitter';

const CustomerPhoneCheckPage = observer(() => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { customerStore } = useStores();
  const [step, setStep] = useState(VerificationStep.InputNumber);
  const [timerDate, setTimerDate] = useState(Date.now());

  const onSendCode = async () => {
    const phone = customerStore.signUpForm.phone;
    if (phone.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    try {
      setStep(VerificationStep.Sent);
      setTimerDate(Date.now());
      await customerStore.sendVerificationCode(phone);
    } catch (e) {
      alert(e.message || '인증번호 전송이 실패했습니다.');
    }
  };

  const onVerifyCode = async () => {
    const phone = customerStore.signUpForm.phone;
    const accessNumber = customerStore.signUpForm.accessNumber;
    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }
    setStep(VerificationStep.Sent);
    try {
      const {
        ok,
        result,
        message,
        token,
        refreshToken,
      } = await customerStore.confirmVerificationCode(
        phone,
        Number(accessNumber),
      );
      if (ok) {
        setStep(VerificationStep.Verified);
        if (token) {
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          if (type === 'HERO') {
            history.push('/hero/done');
          } else {
            history.push('/user/done');
          }
        } else {
          //message === '인증되었습니다.'
          history.push('/sign-up/parents/info');
        }
      } else {
        alert(message || '번호인증에 오류가 발생했습니다.');
      }
    } catch (e) {
      alert(e.message || '번호인증에 실패했습니다.');
    }
  };

  useEffect(() => {
    if (customerStore.signUpForm.phone.length > 0) {
      history.replace('/sign-up/parents/info');
    }
  }, []);
  useEffect(() => {
    setStep(VerificationStep.InputNumber);
  }, [customerStore.signUpForm.phone]);
  return (
    <>
      <Header />
      <Space height={16} />
      <VerticalLayout style={{ padding: '20px' }}>
        <DarkGray24 bold>
          가입하기 위해
          <br />
          휴대폰 번호를 인증해 주세요.
        </DarkGray24>
        <Space height={32} />

        <Labeled label="휴대폰 번호">
          <HorizontalLayout style={{ alignItems: 'end' }}>
            <TextField
              style={{ flex: 1 }}
              disabled={step !== VerificationStep.InputNumber}
              value={customerStore.signUpForm.phone}
              onChange={e =>
                (customerStore.signUpForm.phone = e.target.value.replace(
                  /\D/g,
                  '',
                ))
              }
            />
            <Space width={12} />
            <RoundButton
              variant="primary"
              disabled={step !== VerificationStep.InputNumber}
              style={{
                width: '112px',
                height: '42px',
                padding: 0,
                fontSize: '15px',
              }}
              onClick={onSendCode}
            >
              인증번호 전송
            </RoundButton>
          </HorizontalLayout>
        </Labeled>
        <Space height={40} />
        {step === VerificationStep.InputNumber && <Space height={77} />}
        {step !== VerificationStep.InputNumber && (
          <Labeled label="인증번호를 입력해주세요">
            <HorizontalLayout
              style={{ alignItems: 'end', position: 'relative' }}
            >
              <TextField
                style={{ flex: 1 }}
                value={customerStore.signUpForm.accessNumber}
                onChange={e =>
                  (customerStore.signUpForm.accessNumber = e.target.value.replace(
                    /\D/g,
                    '',
                  ))
                }
              />
              <Space width={12} />

              <div
                style={{ position: 'absolute', right: '130px', top: '10px' }}
              >
                <CountDown date={timerDate + threeMinutes} renderer={Timer} />
              </div>

              <RoundButton
                variant="secondary"
                style={{
                  width: '112px',
                  height: '42px',
                  padding: 0,
                  fontSize: '15px',
                }}
                onClick={onVerifyCode}
              >
                인증하기
              </RoundButton>
            </HorizontalLayout>
          </Labeled>
        )}
        <Space height={217} />
        <LightGray12 style={{ textAlign: 'center' }}>
          * 핸드폰 번호 변경시에는 02-6232-0220 센터로 연락바랍니다.
        </LightGray12>
      </VerticalLayout>

      <Footer />
    </>
  );
});

export default CustomerPhoneCheckPage;
