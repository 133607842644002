export interface IFaqContent {
  question: string;
  answer: string;
  image?: string;
}

// `/hero/about`
export const SitterIntroFaqContents = [
  {
    question: '[돌봄관련] 활동 시간은 어떻게 되나요?',
    answer:
      '돌봄 시간은 최소 2시간부터로 최소활동시간을 보장해드리고 있으며 활동시간은 가정마다 상이할 수 있습니다.',
  },
  {
    question: '[돌봄관련] 정부에서 하는 돌봄인가요?',
    answer:
      '우리동네 돌봄히어로는 사설 돌봄업체로 정부에서 운영하고 있는 아이돌봄사업이 아닙니다. 다만, 서울시와 각 구 여성인력개발센터 등과 선생님 양성교육을 협력해서 진행하고 있습니다.',
  },
  {
    question: '[돌봄관련] 4대 보험 가입이 되나요?',
    answer:
      '우리동네 돌봄히어로는 직업소개업 및 교육업으로 4대 보험 적용은 해당되지 않습니다. 같은 이유로 퇴직금 또한 지급이 불가합니다.',
  },
  {
    question: '[돌봄관련] 공휴일에도 일을 하나요?',
    answer:
      '주말 및 법적공휴일에는 보통 일을 하지 않지만 간혹 서비스를 필요로 하는 가정이 있을 수 있습니다. 가능하실 경우, 지원해주시면 되며 활동비 또한 다르게 책정됩니다. ',
  },
  {
    question: '[활동료] 시급은 어떻게 되나요?',
    answer:
      '아래 표를 확인해주세요!\n평일 주간의 경우 07시~22시이며, 평일 야간은 22시~익일 07시까지 해당됩니다.',
    image: require('asset/sitter/pay.png').default,
  },
  {
    question: '[활동료] 급여는 어떤 방식으로 받나요?',
    answer:
      '활동비는 우리동네 돌봄히어로에서 정산 후 선생님께 지급해 드리고 있습니다. 정기의 경우, 4주 간격으로 활동비를 책정합니다. 4주가 되는 당일에 상세내역서를 안내드리고 있으며 안내드린 그 주 금요일에 지급됩니다. 비정기의 경우, 돌봄이 종료된 다음 날(공휴일 제외) 상세내역서를 안내드립니다.',
  },
  {
    question: '[매칭] 매칭은 어떤 방식으로 되나요?',
    answer:
      '선생님께서 활동 가능하신 지역을 선택하고 선택하신 지역을 기준으로 수요가 들어오면 문자로 안내를 드립니다. 가정의 정보와 관련내용, 돌봄 일자 및 시간을 꼼꼼하게 확인하신 뒤 가능하면 매니저님을 통해 지원하시면 됩니다.',
  },
  {
    question: '[매칭] 교육 수료 후 바로 일을 할 수 있나요?',
    answer:
      '돌봄 활동을 위해서는 서류제출이 필수입니다. 필수서류 4가지를 제출해주셔야만 가정에 지원이 가능하니 기한내에 꼭 제출해주세요!',
    image: require('asset/sitter/documents.png').default,
  },
  {
    question: '[자격증] 자격증은 어떻게 취득할 수 있나요?',
    answer:
      '우리동네 돌봄히어로는 교육부에서 인증한 아동놀이코치 양성기관입니다. 아동놀이코치 2급 자격증은 한 가정에서 6개월 이상 활동 및 보수교육을 3회 이상 참석하셔야만 취득이 가능합니다. 별도의 시험은 없습니다.',
  },
  {
    question: '[히어로센터] 우리동네 돌봄히어로 역할은 무엇인가요?',
    answer:
      '매칭부터 활동비 정산까지 전담매니저가 꼼꼼하게 관리해드리며, 히어로 상담센터 운영을 통해 돌봄 중에 발생된 이슈, 고민사항 등을 함께 든든히 해결해드리는 역할을 하고 있어요. 더불어, 선생님들의 전문성과 역량을 지속적으로 높이기 위해 메달 보수교육을 진행하며 1000가지 놀이법, 놀이키트 등을 지급해드리고 있습니다.',
  },
];

export const RequestFaqContents = [
  {
    question: '선생님은 언제 매칭이 되나요?',
    answer: `신청서가 접수되면 근거리를 기준으로 선생님을 서칭 하고 1-2일(공휴일제외) 내로 담당매니저가 연락을 드리고 있습니다. 가능하신 선생님이 계시면 소개소를 보내드리며 보시고 면접여부를 결정해주시면 됩니다.`,
  },
  {
    question: '히어로 센터 돌봄료는 어떻게 지불하나요?',
    answer: `모든 돌봄료는 후불제이며 선생님 급여는 히어로 센터에서 지급하고 있습니다. 정기돌봄의 경우 4주 기준으로 돌봄료가 책정되며 4주가 되는 일자에 상세내역서와 계좌를 안내드리고 있습니다.
    <서울형 아이돌봄>, <소상공인 민간아이돌봄서비스> 지원 가정의 경우 1개월 기준으로 정산되며 절차는 개별 안내 드립니다.
    `,
  },
  {
    question: '서비스 취소 수수료가 있나요?',
    answer: `선생님과의 면접 일자가 잡힌 후 또는 선생님께서 활동예정이실때 부모님의 사정으로 서비스 를 취소하시는 경우, 시간과 관계없이 수수료 1만원이 발생합니다.서비스 당일에 취소할 시 1일 돌봄료 중 100%의 수수료가 발생되니 신중한 결정 부탁드립니다.`,
  },
  {
    question: '아이 식사와 목욕을 챙겨주시나요?',
    answer: `아이 식사와 목욕은 기본으로 포함되는 서비스입니다.
    (단, 12개월 미만의 영아의 경우 목욕은 제외됩니다.)
    식사의 경우에는 부모님이 미리 준비해주신 식사를 따뜻하게 차려 지도합니다.
    (간단한 고기 및 생선굽기, 계란 후라이 가능)
    `,
  },
  // {
  //   question: '가사일이 제공되나요?',
  //   answer: `히어로 선생님은 아이의 안전 및 상호작용과 정서적 교감에 보다 집중하셔야 하기에 아이와 관련된 업무 외(청소, 빨래, 조리 등)의 가사일은 요구할 수 없는 점 양해 부탁드립니다.`,
  // },
];

/*
export const SitterIntroFaqContents = [
  {
    question: `우리동네 돌봄히어로란?`,
    answer: `우리동네 돌봄히어로란 0~10세 영·유아 및 아동에게 돌봄과 놀이, 등·하원 도움을 제공하는 서비스입니다. 서울시와 협력하여 운영하고 있습니다.`,
  },
  {
    question: `누가 할 수 있나요?`,
    answer: `60세 미만의 아이를 사랑하는 분이라면 누구든 지원이 가능합니다. 아이 등·하원 시간에 활동 가능하신 분, 아이를 사랑하는 분, 아이 양육 경험이 있으신 분들 모두 우리동네 돌봄히어로의 놀이돌봄 선생님이 되실 수 있습니다.`,
  },
  {
    question: '무슨 일을 하나요?',
    answer: `놀이돌봄 선생님은 보육에 대한 전문적 지식과 실무적 능력을 가지고 도움이 필요한 가정에 방문하여 아이를 안정적으로 돌봐주고 놀이를 통해 아이를 즐겁고 건강하게 성장시킵니다.
    놀이돌봄 선생님은 가사지원을 하지 않습니다. 단, 아이에게 차가운 음식을 내어줄 수 없기 때문에 밥과 국, 1차 조리식품(돈까스, 생선, 계란프라이 등)을 데우는 일은 해주셔야 합니다. 또 서비스 중에 발생하는 설거지, 정리정돈도 해주셔야 합니다.`,
  },
  {
    question: '놀이돌봄 선생님은 베이비시터와 어떤 차이가 있나요?',
    answer: `놀이돌봄 선생님은 단순한 도우미가 아닙니다. 아이들에게는 놀이가 곧 학습니다. 놀이돌봄 선생님은 아이의 타고난 특성에 맞는 상호작용과 아이주도 놀이를 통하여 아이의 다양한 능력을 향상시키고 잠재력을 이끌어냅니다.`,
  },
  {
    question: '어떤 과정을 통해 선생님이 될 수 있나요?',
    answer: `체계화된 교육과 실습을 통해 놀이돌봄 전문가로 거듭나실 수 있습니다.`,
  },
  {
    question: '어떤 교육을 받나요?',
    answer: `우리동네 돌봄히어로의 선생님 양성 교육은 이론과 워크숍, 그리고 실습의 병행을 통해 전문적 지식과 실무감각을 균형있게 갖출 수 잇는 교육입니다. 특히 아이주도 놀이 교육법을 배움으로써 아이의 자존감, 자기표현력, 사회성을 집중 발달시키는 놀이돌봄 선생님이 되실 수 있습니다.`,
  },
  {
    question: '교육 커리큘럼은 어떻게 되나요?',
    answer: '',
    image: 'http://woorihero.com/_nuxt/img/901f1fa.png',
  },
  {
    question: '놀이돌봄은 어떠한 방식으로 진행 하나요?',
    answer: `매 주 선생님이 활용할 수 있는 활동계획안과 교육자료가 밴드를 통해 제공됩니다. 또한, 본사에서 소유 중인 책과 커리큘럼도 제공해드리고 있습니다. 매번 책을 바꾸러오기 어려우실 경우 각 아이의 가정에 있는 책 또는 다양한 놀이자료를 활용하여 놀이를 진행하셔도 무방합니다.`,
  },
  {
    question: '활동 시간은 어떻게 되나요?',
    answer: `일반적으로 등원 2시간, 하원 3시간을 기준으로 활동을 진행합니다. 이러한 기준을 바탕으로 선생님께서 원하시는 일정에 따라 탄력적인 시간 활용이 가능합니다. 예를 들어, 선생님이 일정상 오후에만 활동이 가능하시다면, 하원 3시간만 돌봄을 원하는 가정을 선택하여 매칭 지원을 해주시면 됩니다.
    단, 히어로센터에서 선생님의 일정과 관련된 내용을 미리 알고 있어야 원하시는 가정과의 연결이 수월하기 때문에 미리 히어로센터 운영진에게 활동가능 시간을 알려주시기 바랍니다.`,
  },
  {
    question: '시급은 어떻게 되나요?',
    answer: `최소 1만 원, 최대 1만 5천 원의 시급을 받으실 수 있습니다. 활동기간이 6개월이 되었을 경우 활동장려금 10만원을 지급해드리고 아동놀이코치 2급 자격증을 취득할 수 있는 자격이(시험응시가능) 부여됩니다.`,
  },
  {
    question: '급여는 어떤 방식으로 받나요?',
    answer: `우리동네 돌봄히어로는 선생님께서 일하신 시간만큼 시급을 계산하여 급여를 정산, 지급합니다. 4주 기준으로 활동비를 정산하며, 우리동네 돌봄히어로 카카오톡 플러스친구와 히어로 어플리케이션을 통해 상세내역을 발송해드립니다. 4주 정기 돌봄이 끝난 가장 빠른 금요일에 4주 단위로 정산이 이루어집니다.
    급여를 받으시는 통장은 본인 명의 통장뿐 아니라 가족 명의의 통장도 가능합니다.`,
  },
  {
    question: '활동을 통해 받을 수 있는 자격증이 있나요?',
    answer: `우리동네 돌봄히어로의 놀이돌봄 선생님으로 가정과 매칭되어 6개월간 활동하시면 아동놀이코치 2급 자격증을 취득할 수 있는 자격이 부여됩니다. 동놀이코치 2급은 교육부에서 인정하는 민간자격증입니다.
    자격증 취득 과정: [6개월 활동]→[응시자격 획득]→[자격증시험 응시 및 통과]→[자격증 취득]`,
  },
  {
    question: '활동과정 비용은 무엇이고, 얼마인가요?',
    answer: `활동과정 비용이란 선생님이 활동에 필요한 비용으로, 교육 전에 입금 해주셔야 하는 교육비와, 재료비, 교육 후 돌봄 서비스를 시작하실 때 입금해주셔야하는 보험비가 있습니다.
    교육비는 현재 전액 지원됩니다. 재료비는 5만원(교재 및 선생님 유니폼)이 필요하며, 보험비는 연 1회 납부(2018년 기준 2만원)해주셔야 합니다.`,
  },
  {
    question: '어떤 보험에 가입하나요?',
    answer: `배상책임보험은 1인당 연간의 보험료가 발생합니다. (* 2018년 비용 기준으로 20, 000원. 갱신일에 따라 보험비가 상이해질 수 있습니다.)
    보험명: 전문직업(산모 / 베이비도우미) 배상책임보험
    담보내용: 도우미(베이비시터, 산모도우미) 업무를 수행하던 중 피보험자의 과실 및 부주의에 기인하여 제 3자에게 직간접적으로 발생한 법률상 배상책임
    연간 갱신일: 매년 3월 3일
    가입 유의사항: 타 기관에서 가입을 하였어도 “우리동네 돌봄히어로”로 활동시에는 “우리동네 돌봄히어로”를 통해 가입을 하셔야 보험이 적용됩니다.`,
  },
  {
    question: '보장받지 못하는 경우는 어떤 경우인가요?',
    answer: `정식활동이 아닌 부모님과 선생님이 임의로 서비스를 진행하다 발생한 경우는 보장이 되지 않습니다. (운영진을 통하지 않은 계약으로 인해 발생하는 문제에 대해서 운영진은 책임을 지지 않습니다.) 돌봄 중 부모님이 순수 놀이돌봄 업무 이외의 가사일을 요구하여 발생한 사고는 보장되지 않습니다.`,
  },
  {
    question: '4대 보험은 가입이 되나요?',
    answer: `우리동네 돌봄히어로의 업종은 직업소개업 및 교육업이므로 4대보험 가입이 불가능합니다. 같은 이유로 교통비, 퇴직금도 지급이 불가능합니다.`,
  },
  {
    question: '매칭은 어떤 방법으로 되나요?',
    answer: `수요가 들어오면 관련 내용을 선생님들께 문자로 보내드립니다. 문자로 전송된 돌봄 가정의 위치와 시간을 확인하시고 센터로 연락을 주셔서 지원해 주시면 됩니다. 지원을 통해 돌봄 가정과의 연결이 성사되면 부모님과의 면접 일정을 조율합니다.`,
  },
  {
    question: '한 번 매칭된 가정에는 계속 나가나요?',
    answer: `부모님이 돌봄 신청 시 돌봄종료일을 말씀하시지 않은 경우에 부모님과 선생님이 서비스 취소를 특별히 말씀하지 않는다면 서비스 기간이 자동으로 연장됩니다. (일반적인 가정은 1년 이상의 장기돌봄을 원합니다.)
    부모님이 사전에 돌봄 종료일을 설정하였다면, 설정한 종료일을 선생님들께 안내해드리고 해당종료일에 서비스가 종료됩니다.`,
  },
  {
    question: '우리동네 돌봄히어로(히어로센터)의 역할은 무엇인가요?',
    answer: `돌봄 신청 접수
    선생님 선발 & 양성
    선생님 전문성 지원 - 보수교육, 워크숍, 놀이 교안 제공 등
    서비스 홍보
    매칭 관리
    급여 정산
    히어로 상담센터 운영`,
  },
  {
    question: '우리동네 돌봄히어로는 다른 업체와 어떤 차이가 있나요?',
    answer: `우리동네 돌봄히어로는 선생님께서 최대한 불편함 없이 서비스를 제공하실 수 있도록 상담센터를 운영하고 있습니다. 선생님께서는 상담 센터를 통해 매칭 가정, 아이, 돌봄과 관련하여 궁금한 점이나 문제사항을 원활히 해결할 수 있습니다.
    또한 우리동네 돌봄히어로는 선생님들의 전문성 향상, 서로 간의 친목 형성 및 그를 통한 소통의 장 마련을 도모하기 위해 보수교육, 송년회, 워크숍과 같은 프로그램을 매년 진행하고 있습니다. 또 네이버 밴드를 통해 아이들 놀이와 관련한 정보, 팁, 놀이도안을 제공하며 동화책도 대여해드리고 있습니다.
    우리동네 돌봄히어로는 선생님 복지가 우수합니다. 선생님 유니폼, 가방 및 자격증, 명함, 활동 장려금이 지원됩니다.`,
  },
  {
    question: '부모님과의 면접은 어떻게 이루어지나요?',
    answer: `면접은 대면면접과 전화면접이 있습니다. 대면면접의 경우 면접비 1만원을 부모님으로부터 지급받으시게 됩니다.`,
  },
  {
    question: '합격 후 추가로 제출해야 되는 서류가 있나요?',
    answer: `신원 및 건강 상태를 검증하기 위한 4가지 서류가 필요합니다.
    주민등록등본, 주민등록증, 통장사본, 건강진단서(B형간염포함)`,
  },
  {
    question: '예정된 돌봄일에 가지 못하게 되면 어떻게 되나요?',
    answer: `놀이돌봄 선생님은 홀로 남겨진 아이를 돌봐주는 직업입니다. 놀이돌봄 선생님이 예정된 돌봄일을 빠지게 되면 아이는 홀로 남겨지게 되고 아이와 가정은 큰 위험과 곤란에 빠지게 됩니다. 그렇기에 예정된 돌봄일은 천재지변과 같은 정말 피치못하는 일이 발생하지 않는 한 꼭 지켜주시기 바랍니다. 혹시 피치못하는 일이 발생했을 때에는 필히 아이의 부모님과 사전에 협의를 해주셔야 합니다.`,
  },
  {
    question: '정부기관인가요?',
    answer: `우리동네 돌봄히어로는 정부 기관이 아닌 민간업체입니다. 다만 정부와의 협업을 통해 지원을 받아 서비스를 운영하고 있습니다.`,
  },
  {
    question: '교육을 받고 난 후 바로 일을 할 수 있나요?',
    answer: `지역마다 들어오는 수요가 다르기 때문에 바로 하실 수 있다고 확답을 드릴 순 없습니다. 하지만 매칭이 가능한 지역을 확장시킨다면 그만큼 더 빨리 매칭되실 수 있으니 빠르게, 많은 일을 처리하고 싶으시다면 참고하셔서 매칭 가능 지역을 말씀해주시기 바랍니다.`,
  },
  {
    question: '공휴일에도 일을 하나요?',
    answer: `국가 지정 공휴일의 경우에는 서비스가 진행되지 않는 것이 원칙입니다. 부모님이 국가 지정 공휴일에 돌봄을 필요로 하실 경우, 주말돌봄 비용으로 책정되며 정기돌봄의 선생님이 아닌 다른 선생님이 매칭 될 수 있습니다.`,
  },
  {
    question: '면접과 교육 장소, 일정은 어떻게 되나요?',
    answer: `매달 3번째 주 수요일과 목요일에 선생님 면접이 진행됩니다. 그리고 그 다음주인 매달 4번째 주 금요일에 교육이 진행됩니다. 면접과 교육 장소는 성동구 성수역 2번 출구에서 10분 거리에 위치한 성동안심상가입니다.`,
  },
] as IFaqContent[];
*/

export const FaqContents = [
  {
    question: '[돌봄서비스] 서비스 대상이 어떻게 되나요?',
    answer: `[연령 및 인원] 우리동네 돌봄히어로는 영아(12개월 이상)부터 10살 아이까지 서비스 대상이며 최대 두 명의 아이까지 돌봄이 가능합니다.
  [지역] 현재 서울시 전지역에서 서비스를 이용하실 수 있습니다.`,
  },
  {
    question: '[돌봄서비스] 최소 이용시간이 있나요?',
    answer: `등/하원/놀이 돌봄은 최소 2시간부터 신청가능합니다.
단, 하루에 등하원을 모두 이용하시는 경우 하루 4시간 이상부터 신청가능합니다.
    창의놀이의 경우, 기본 돌봄 시간에 포함되어 진행합니다. 또한, 창의놀이는 비정기 돌봄 신청시에는 이용이 불가능합니다.
    `,
  },
  {
    question: '[돌봄서비스] 선생님은 언제 매칭되나요?',
    answer: `신청서가 접수되면 근거리를 기준으로 선생님을 서칭하고 2~3일(공휴일 제외)내로 담당매니저가 연락을 드리고 있습니다. 가능하신 선생님이 계시면 소개서를 보내드리며 보시고 면접 여부를 결정해주시면 됩니다.`,
  },
  {
    question: '[돌봄서비스] 선생님과 면접을 볼 수 있나요?',
    answer: `면접은 필수로 진행됩니다. 면접 방법은 대면면접과 전화면접으로 나뉘며 편하신 방법을 선택해주시면 됩니다. 대면면접의 경우, 면접비 1만원이 발생하며 전화면접은 별도의 면접비가 없습니다.`,
  },
  {
    question: '[돌봄서비스] 아이 식사를 챙겨주시나요?',
    answer: `아이 식사는 기본 서비스입니다.
    단, 부모님이 미리 준비해주신 식사를 따뜻하게 차려 지도합니다.
    (간단한 고기 및 생선굽기, 계란 후라이 가능)
    `,
  },
  {
    question: '[돌봄서비스] 아이 목욕도 해주시나요?',
    answer: `아이 목욕은 기본 서비스입니다. 단, 12개월 미만의 영아의 경우 목욕은 제외됩니다.`,
  },
  {
    question: '[돌봄서비스] 서비스를 취소하고 싶어요. 위약금이 발생하나요?',
    answer: `서비스 취소시기에 따라 위약금이 발생할 수 있으니 아래 내용을 참고해주세요!
  1) 선생님과의 면접 일정이 확정된 후, 서비스를 취소하시는 경우
  2) 선생님과의 돌봄이 확정된 후, 서비스를 취소하시는 경우`,
  },
  {
    question: '[히어로 선생님] 히어로 선생님은 어떤 기준으로 선발되나요?',
    answer: `7단계 인증과정을 꼼꼼하게 거쳐 히어로 선생님을 양성하고 있습니다. 육아 경험이 있는 분들을 대상으로 지원을 받으며 서류에서 통과하신 분들만 교육담당자와 면접을 진행합니다.\n면접에서 합격 시전문교육을 필수로 수료해야하며 교육수료 후에는 신원검증 및 건강진단서 등의 서류가 검토 되어야만 최종적으로 히어로 선생님이 됩니다. 보다 안전한 돌봄을 위해 배상책임보험 또한 가입하고 있습니다. `,
  },
  {
    question: '[히어로 선생님] 히어로 선생님 전부 돌봄 경력이 있나요?',
    answer: `히어로 선생님은 소중한 자녀를 멋지게 키우신 육아 경험자이십니다.
    자녀 외에 아이돌봄 경력이 있는 분들도 계시고, 처음이신 분들도 계십니다. 
    서비스 신청시 원하시는 경력의 선생님을 선택하실 수 있으며, 그에 따라 서비스 가격이 달라질 수 있습니다.
    `,
  },
  {
    question: '[돌봄료] 돌봄료는 어떻게 결제하나요?',
    answer: `모든 돌봄료는 후불제이며 선생님 급여는 히어로 센터에서 지급하고 있습니다.
    정기돌봄의 경우, 4주 기준으로 돌봄료가 책정되며 4주가 되는 일자에 상세내역서와 계좌를 안내드리고 있습니다.
    
    <서울형 아이돌봄>, <소상공인 민간아이돌봄서비스> 지원 가정의 경우 1개월 기준으로 정산되며 절차는 개별 안내 드립니다.`,
  },
] as IFaqContent[];
