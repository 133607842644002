import { Button, TextField } from '@material-ui/core';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, LightGray12 } from 'atom/text';
import { Header } from 'component/header';
import { VerificationStep } from 'model';
import { Color } from 'constant';
import React, { useEffect, useState } from 'react';
import { useStores } from 'state';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Footer } from 'component/footer';
import { okLogin } from 'util/token';
import { useAuthDispatch } from '../../context/AuthContext';

const CustomerPhoneCheckPage = observer(() => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { customerStore } = useStores();
  const [verificationStep, setVerificationStep] = useState(
    VerificationStep.InputNumber,
  );

  const onClickCheckAccessNumber = async () => {
    const phone = customerStore.signUpForm.phone;
    const accessNumber = customerStore.signUpForm.accessNumber;
    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }

    try {
      const {
        ok,
        result,
        message,
        token,
        refreshToken,
      } = await customerStore.confirmVerificationCode(
        phone,
        Number(accessNumber),
      );
      if (ok) {
        if (token) {
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          showConfirm('이미 회원입니다.');
          history.push('/login');
        } else {
          //message === '인증되었습니다.'
          history.push('/sign-up/parents/info');
        }
      } else {
        alert(message || '번호인증에 오류가 발생했습니다.');
      }
    } catch (e) {
      alert(e.message || '번호인증에 실패했습니다.');
    }
  };
  const onSendCode = async () => {
    const phone = customerStore.signUpForm.phone;
    if (phone.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    try {
      await customerStore.sendVerificationCode(phone);
      setVerificationStep(VerificationStep.Sent);
    } catch (e) {
      alert(e.message || '인증번호 전송이 실패했습니다.');
    }
  };

  useEffect(() => {
    if (customerStore.signUpForm.phone.length > 0) {
      history.replace('/sign-up/parents/info');
    }
  }, []);
  return (
    <>
      <Header />
      <VerticalLayout center>
        <Space height={120} />
        <DarkGray24 bold>회원가입을 위해</DarkGray24>
        <DarkGray24 bold>휴대폰 번호를 인증해주세요.</DarkGray24>
        <Space height={32} />

        <TextField
          placeholder="휴대폰 번호를 입력해주세요"
          variant="outlined"
          disabled={verificationStep !== VerificationStep.InputNumber}
          InputProps={{
            style: {
              height: '55px',
              width: '347px',
            },
          }}
          value={customerStore.signUpForm.phone}
          onChange={e =>
            (customerStore.signUpForm.phone = e.target.value.replace(/\D/g, ''))
          }
        />
        <Space height={12} />
        <Container>
          <TextField
            placeholder="인증 번호를 입력해주세요"
            variant="outlined"
            InputProps={{
              style: {
                height: '55px',
                width: '347px',
              },
            }}
            inputProps={{
              maxLength: 4,
            }}
            value={customerStore.signUpForm.accessNumber}
            onChange={e =>
              (customerStore.signUpForm.accessNumber = e.target.value.replace(
                /\D/g,
                '',
              ))
            }
          />
          <SendNumberBtn
            disabled={verificationStep !== VerificationStep.InputNumber}
            onClick={onSendCode}
          >
            인증번호 전송
          </SendNumberBtn>
        </Container>
        <Space height={24} />
        <Button
          style={{
            width: '347px',
            height: '55px',
            backgroundColor: Color.Primary,
            fontSize: '16px',
            color: Color.white,
          }}
          onClick={onClickCheckAccessNumber}
        >
          인증번호 확인
        </Button>
        <Space height={68} />
        <LightGray12>
          휴대폰 번호 변경시에는 02-6232-0220 센터로 연락해주세요.
        </LightGray12>
      </VerticalLayout>
      <Space height={120} />
      <Footer />
    </>
  );
});

export default CustomerPhoneCheckPage;

const Container = styled.div`
  position: relative;
`;
const SendNumberBtn = styled.button`
  width: 120px;
  height: 55px;

  background: #ffffff;
  border: 1px solid #f69f30;
  border-radius: 5px;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #f69f30;

  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    filter: contrast(1.15);
  }
  &:active {
    filter: contrast(0.8);
  }
  position: absolute;
  top: 0;
  left: 370px;

  ${({ disabled }) =>
    disabled
      ? `
  filter: grayscale(1);
  pointer-events: none;
`
      : `
`}
`;
