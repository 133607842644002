import { TextareaAutosize, TextField } from '@material-ui/core';
import { DarkGray10 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const ApplyInputRow = ({ input }) => {
  const {
    label,
    value,
    onChange,
    children,
    spaceHeight,
    placeholder,
    isImportant = false,
    isTextArea = false,
    type = 'text',
  } = input;

  return (
    <FlexBox style={{ margin: `${spaceHeight}px 0`, alignItems: 'start' }}>
      <div style={{ flex: '1 1 25%' }}>
        <DarkGray10 style={{ fontSize: '11px', marginTop: '5px' }} bold>
          <span style={{ color: '#ED584E' }}>
            {isImportant ? '* ' : <span>&nbsp;&nbsp;&nbsp;</span>}
          </span>
          {label}
        </DarkGray10>
      </div>
      <div style={{ flex: '1 1 50%', width: '100%' }}>
        {isTextArea ? (
          <textarea
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            rows={5}
            style={{
              fontSize: '11px',
            }}
          />
        ) : (
          <TextField
            type={type}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            value={value}
            onChange={onChange}
            InputProps={{
              style: {
                fontSize: '11px',
                height: '32px',
                backgroundColor: 'white',
                borderRadius: '12px',
              },
            }}
          />
        )}
      </div>
      <div style={{ flex: '1 1 25%', width: '100%' }}>{children}</div>
    </FlexBox>
  );
};

const FlexBox = styled.div`
  width: 340px;
  display: flex;
`;

export default ApplyInputRow;
