import { observable } from 'mobx';
import { DateTime } from 'luxon';
import { omit } from 'lodash';

import { IChild, IUser } from 'model';
import {
  ICustomer5yearEventApplyForm,
  ICustomerApplyForm,
  ICustomerCompanyApplyForm,
  ICustomerRequestForm,
  ICustomerSignUpForm,
} from './model';
import versionStore from './version';
import { getUserId } from 'util/token';

const DefaultApplyEventFrom = {
  phone: '',
  name: '',
  address: '',
  isServiceAgree: false,
  isPrivacyAgree: false,
} as ICustomer5yearEventApplyForm;

const DefaultCompanyApplyFrom = {
  phone: '',
  companyName: '',
  name: '',
  content: '',
  inflowRoute: '',
  isPrivacyAgree: false,
} as ICustomerCompanyApplyForm;

const DefaultRequestForm = {
  careKind: '정기',
  careService: '',
  careRequest: '',

  schedules: [],
  careTypes: [],
  pickupMethods: [],
  children: [],
  pets: [],
  family: [],

  premium: false,
  cleaning: false,
  cleaningDetail: '',
  heroTypes: [],
} as ICustomerRequestForm;

const DefaultSignUpForm = {
  email: '',
  name: '',
  phone: '',
  isMarketingAgree: false,
  isServiceAgree: false,
  isPrivacyAgree: false,
} as ICustomerSignUpForm;

const DefaultChildForm = {
  name: '',
  birthdate: '',
  sex: 1,
  seq: null,
  memo: '',
} as IChild;

const DefaultApplyForm = {
  type: 1, // 정규 =1 비정규 =0
  childIds: '',
  careStartAt: '',
  careEndAt: '',
  careerRequest: '',
  address: '',
  addressDetail: '',
  pickupMethod: '',
  companionInfo: '',
  pet: '',
  userRequest: '',
  package: 0,
  couponCode: '',
  irregularCareInfo: [], //정기 비정기 형태 다름
  regularPlan: '',
  careType: '',
  careTypes: [], //for client
  schedules: [],
  heroTypes: [],
  pickup: [],
  companion: [],
  petCompanion: [],
} as ICustomerApplyForm;

export interface ICustomerStore {
  user: IUser;
  form: ICustomerRequestForm; //기존 돌봄신청 폼. 사용안할 것임
  applyForm: ICustomerApplyForm;
  signUpForm: ICustomerSignUpForm;
  childForm: IChild;
  applyEventForm: ICustomer5yearEventApplyForm;
  applyCompanyForm: ICustomerCompanyApplyForm;

  // getters
  isLoggedIn: boolean;

  resetForm();
  resetApplyForm();
  resetSignUpForm();
  resetChildForm();

  //삭제 예정
  submitRequest(form: ICustomerRequestForm): Promise<void>;

  submitApply(applyForm: ICustomerApplyForm);
  setApplyBody(applyForm: ICustomerApplyForm): ICustomerApplyForm;

  cancelAppy(orderId: number);

  sendVerificationCode(phone: string);
  confirmVerificationCode(phone: string, accessNumber: number);

  //제거 되야함 signIn
  signIn(phone: string, code: string);

  signUp(form: ICustomerSignUpForm);
  update(data: Partial<IUser>): Promise<void>;
  refreshMe(): Promise<any>;

  getCares();
  getChildren();
  getLastCare();
  removeChild(childId: number);
  addChild(child: IChild);
  updateChild(child: IChild);

  // terms
  getTerms(type: string);
  //myinfo
  getMyInfo(id: string, type: string);
  updateMyInfo(body);

  //5yearEvent
  applyEvent(body);
  resetEventFroms();

  //B2B
  applyCompany(body);
  resetApplyCompanyForms();
}
const customerStore = observable<ICustomerStore>({
  user: null,
  form: DefaultRequestForm,
  applyForm: DefaultApplyForm,
  signUpForm: DefaultSignUpForm,
  childForm: DefaultChildForm,
  applyEventForm: DefaultApplyEventFrom,
  applyCompanyForm: DefaultCompanyApplyFrom,

  get isLoggedIn() {
    return !!this.user;
  },

  resetForm() {
    this.form = JSON.parse(JSON.stringify(DefaultRequestForm));
  },
  resetApplyForm() {
    this.applyForm = DefaultApplyForm;
  },
  resetSignUpForm() {
    this.signUpForm = DefaultSignUpForm;
  },
  resetChildForm() {
    this.childForm = DefaultChildForm;
  },
  async submitRequest(form: ICustomerRequestForm) {
    return await api.post(`/care`, {
      ...form,
    });
  },

  async sendVerificationCode(phone: string) {
    // return await api.post(`/account/accessNumber`, {
    //   phone,
    // });
    return await apiNew.post(`/home/user/accessNumber`, { phone });
  },

  async confirmVerificationCode(phone: string, accessNumber: number) {
    return await apiNew.post(`/home/user/accessNumber/check`, {
      phone,
      accessNumber,
    });
  },
  async signIn(phone: string, code: string) {
    api.token = null;
    // status1: "인증번호가 일치하지않습니다."
    // status0: "입력하신 전화번호는 없는 전화번호입니다."
    const { message, _token_: token } = await api.post(`/account/signin`, {
      accessNumber: code,
      fcmToken: '',
      phone,
    });

    if (!!token && token !== '') {
      // sessionStorage.setItem('token', token);
      api.token = token;
    }

    return {
      message,
    };
  },
  async signUp(form: ICustomerSignUpForm) {
    return await apiNew.post('/home/user/signup/user', {
      ...form,
    });

    // const { message, _token_: token } = await api.post(`/account/signup`, {
    //   ...form,
    // });

    // if (!!token && token !== '') {
    //   // sessionStorage.setItem('token', token);
    //   api.token = token;
    // }

    // return {
    //   message,
    // };
  },

  async update(data: Partial<IUser>) {
    await api.patch(`/account/${this.user.id}`, {
      ...data,
    });
  },
  async refreshMe() {
    this.user = await api.get('/account');
  },
  async getCares() {
    return await apiNew.get('/home/match/check', {
      userId: getUserId(),
    });
    // const { items } = await api.get(`/care?page=1&size=100`);
    // return items.filter(x => x.status !== 'end' && x.status !== 'cancel');
  },
  async getLastCare() {
    return await apiNew.get('/home/mypage/user/register', {
      id: getUserId(),
    });
  },
  async getChildren() {
    const { result } = await apiNew.get(`/home/match/childList/${getUserId()}`);
    return result;
  },
  async removeChild(childId: number) {
    await apiNew.delete(`/home/match/child`, {
      childId,
    });
    // this.resetChildForm();
    versionStore.increaseVersion('child');
  },
  async addChild(child: IChild) {
    const ret = await apiNew.post(`/home/match/child`, {
      userId: getUserId(),
      ...omit(child, 'id'),
      birthDate: DateTime.fromFormat(child.birthdate, 'yyyyMMdd').toFormat(
        'yyyy-MM-dd',
      ),
    });
    this.resetChildForm();
    return ret;
  },
  async updateChild(child: IChild) {
    const res = await apiNew.put(`/home/match/child`, {
      userId: getUserId(),
      ...child,
      birthDate: DateTime.fromFormat(child.birthdate, 'yyyyMMdd').toFormat(
        'yyyy-MM-dd',
      ),
    });
    this.resetChildForm();
    return res;
  },
  setApplyBody(form: ICustomerApplyForm) {
    let body = { ...form } as ICustomerApplyForm;
    const getRegularPlan = () => {
      const plan = form.schedules.map(x => ({
        dayOfWeek: x.weekday,
        serviceType: x.type,
        startAt: DateTime.fromFormat(x.startTime, 'HH:mm').toISO(),
        endAt: DateTime.fromFormat(x.endTime, 'HH:mm').toISO(),
      }));
      return plan;
    };

    const getIrregularPlan = () => {
      const plan = form.schedules.map(x => ({
        serviceType: x.type,
        startAt: DateTime.fromFormat(
          `${x.date} ${x.startTime}`,
          'yyyy-MM-dd HH:mm',
        ).toISO(),
        endAt: DateTime.fromFormat(
          `${x.date} ${x.endTime}`,
          'yyyy-MM-dd HH:mm',
        ).toISO(),
      }));
      return plan;
    };

    if (form.type === 1) {
      //정기 돌봄
      body = {
        ...form,
        careType: form.careTypes.join(),
        regularPlan: JSON.stringify(getRegularPlan()),
      };
    } else {
      //비정기 돌봄
      body = {
        ...form,
        package: 0,
        careType: form.careTypes.join(),
        irregularCareInfo: getIrregularPlan(),
      };
    }
    return body;
  },
  async submitApply(form: ICustomerApplyForm) {
    const body = this.setApplyBody(form);
    const result = await apiNew.post('/home/match', {
      ...body,
    });
    console.log(result);
    return result;
  },

  async cancelAppy(orderId) {
    return await apiNew.put('/home/mypage/user/cancel', { orderId });
  },
  // terms
  async getTerms(type: string) {
    return await api.get(`/static/terms/${type}`);
  },

  async getMyInfo(id: string, type: string) {
    return await apiNew.get(`/home/mypage/`, {
      id,
      type,
    });
  },

  async updateMyInfo(body) {
    return await apiNew.put('/home/mypage/user', {
      ...body,
    });
  },

  async applyEvent(body) {
    return await apiNew.post('/home/event/5yearParents', {
      ...body,
    });
  },
  async resetEventFroms() {
    this.applyEventForm = DefaultApplyEventFrom;
  },

  //B2B 제휴 문의
  async applyCompany(body) {
    return await apiNew.post('/home/b2b/apply', {
      ...body,
    });
  },
  async resetApplyCompanyForms() {
    this.applyCompanyForm = DefaultCompanyApplyFrom;
  },
});

export default customerStore;
