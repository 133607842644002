import moment from 'moment';
import { DateTime } from 'luxon';

export const price = (x: number) => {
  if (!x) return '';
  return x.toLocaleString();
};

export const getDate = (x: string) => {
  if (!x) {
    return '';
  }
  return moment(x).format('YYYY-MM-DD');
};
export const getTime = (x: string) => {
  if (!x) {
    return '';
  }
  return moment(x).format('HH:mm');
};

export const getDayOfWeek = (x: string) => {
  if (!x) {
    return '';
  }
  const englishDay = moment(x).format('dddd');
  const days = {
    Monday: '(월)',
    Tuesday: '(화)',
    Wednesday: '(수)',
    Thursday: '(목)',
    Friday: '(금)',
    Saturday: '(토)',
    Sunday: '(일)',
  };
  return days[englishDay];
};
export const getDateKor = (x: string) => {
  if (!x) {
    return '';
  }
  return moment(x).format('MM월DD일');
};

export const getAge = (birthday: string) => {
  //히어로 나이계산기 ex)640301

  let date = DateTime.fromFormat(birthday, 'yyMMdd');
  let currentYear = DateTime.now().year;

  if (date.year >= currentYear) date = date.minus({ years: 100 });
  //현재년도 - 태어난 년도
  return Math.ceil(-date.diffNow('years').years);
};
