import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import _Sheet from 'react-modal-sheet';

import { Space, Push, VerticalLayout, HorizontalLayout } from 'atom/layout';
import { AdditionalServiceCard, DefaultServiceCard } from 'atom/care';
import { Checkbox } from 'atom/input';
import { RoundButton } from 'atom/button';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep4 } from './RequestStep4';
import { RequestStep5Irregular } from './RequestStep5Irregular';
import { AdditionalServicePopup } from 'component-mobile/care';
import { CareType, isAddonCareType } from 'model';

const Sheet = _Sheet as any;

export const RequestStepAdditionalServices = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [showServices, setShowServices] = useState(false);
  const [showPremiumAge, setShowPremiumAge] = useState(false);

  const [sheetType, setSheetType] = useState<string | null>();

  const onClickNext = () => {
    if (customerStore.form.careKind === '정기') {
      onNext(RequestStep4);
    } else {
      onNext(RequestStep5Irregular);
    }
  };
  const flush = () => {
    customerStore.form.careTypes = customerStore.form.careTypes.filter(
      x => !isAddonCareType(x),
    );
  };
  const onChangePremium = (x: boolean) => {
    customerStore.form.premium = x;
    customerStore.form.cleaning = false;
    customerStore.form.cleaningDetail = null;
    flush();

    if (x) {
      setShowPremiumAge(true);
      customerStore.form.careTypes = [
        ...customerStore.form.careTypes,
        CareType.Premium,
      ];
    } else {
      customerStore.form.careTypes = customerStore.form.careTypes.filter(
        x => x !== CareType.Premium,
      );
      //창의놀이 나이선택 제거
      customerStore.form.premiumDetail = null;
    }
  };
  const onChangeCleaning = (x: boolean) => {
    customerStore.form.cleaning = x;
    customerStore.form.premium = false;
    //창의놀이 나이선택 제거
    customerStore.form.premiumDetail = null;
    flush();

    if (x) setShowServices(true);
    else {
      customerStore.form.careTypes = customerStore.form.careTypes.filter(
        x => x !== CareType.Cleaning && x !== CareType.Cooking,
      );
      customerStore.form.cleaningDetail = null;
    }
  };
  const onCloseServiceSelect = () => {
    setShowServices(false);
  };
  const onClosePremiumAgeSelect = () => {
    setShowPremiumAge(false);
  };

  useEffect(() => {
    if (!customerStore.form.cleaningDetail) return;

    flush();

    if (customerStore.form.cleaningDetail === 'cooking') {
      customerStore.form.careTypes = [
        ...customerStore.form.careTypes,
        CareType.Cooking,
      ];
    } else {
      customerStore.form.careTypes = [
        ...customerStore.form.careTypes,
        CareType.Cleaning,
      ];
    }
  }, [customerStore.form.cleaningDetail]);

  return (
    <>
      <Title>
        원하시는 서비스가 있다면
        <br />
        추가해주세요
      </Title>
      <Space height={8} />
      <Subtitle>
        *추가 서비스는 선택 사항입니다.
        <br />
        *추가할 서비스가 없다면 다음 버튼을 눌러주세요.
      </Subtitle>
      <Space height={35} />
      <DefaultServiceCard
        title="기본 놀이 돌봄"
        price="시간당 13,000원~"
        body="등하원, 실내/야외 놀이, 책 읽기, 목욕, 식사 등"
        comment="등원/하원/놀이는 각각 최소 2시간 이상부터 가능."
      />
      <Space height={8} />
      <img
        src={require('asset/icon/plus.svg').default}
        style={{ width: '17px', alignSelf: 'center' }}
      />
      <Space height={8} />
      <AdditionalServiceCard
        serviceType="premium"
        value={customerStore.form.premium}
        onChange={onChangePremium}
        onDetail={() => {
          setSheetType('premium');
        }}
      />
      <Space height={16} />
      <AdditionalServiceCard
        serviceType="cleaning"
        value={customerStore.form.cleaning}
        onChange={onChangeCleaning}
        onDetail={() => {
          setSheetType('cleaning');
        }}
      />
      <Space height={30} />
      <Bottom progress={(1 / 11) * 1} onClickNext={onClickNext} />
      <AdditionalServicePopup
        show={!!sheetType}
        type={sheetType as any}
        onClose={() => setSheetType(null)}
      />
      <Sheet isOpen={showServices} onClose={onCloseServiceSelect}>
        <Sheet.Container
          style={{
            height: 'auto !important',
            paddingBottom: '40px',
            borderRadius: '32px 32px 0px 0px',
          }}
        >
          <Sheet.Header />
          <Sheet.Content>
            <VerticalLayout center>
              <Space height={48} />
              <HorizontalLayout
                center
                style={{ width: '270px' }}
                onClick={() => (customerStore.form.cleaningDetail = 'cleaning')}
              >
                <Checkbox
                  value={customerStore.form.cleaningDetail === 'cleaning'}
                  onChange={x => {
                    if (x) {
                      customerStore.form.cleaningDetail = 'cleaning';
                    }
                  }}
                />
                <Space width={16} />
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color:
                      customerStore.form.cleaningDetail === 'cleaning'
                        ? '#f69f30'
                        : '#c0c0c0',
                  }}
                >
                  청소
                </span>
              </HorizontalLayout>

              <Space height={8} />
              <div
                style={{ width: '300px', height: '1px', background: '#ececec' }}
              />
              <Space height={8} />

              <HorizontalLayout
                center
                style={{ width: '270px' }}
                onClick={() => (customerStore.form.cleaningDetail = 'cooking')}
              >
                <Checkbox
                  value={customerStore.form.cleaningDetail === 'cooking'}
                  onChange={x => {
                    if (x) {
                      customerStore.form.cleaningDetail = 'cooking';
                    }
                  }}
                />
                <Space width={16} />
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color:
                      customerStore.form.cleaningDetail === 'cooking'
                        ? '#f69f30'
                        : '#c0c0c0',
                  }}
                >
                  요리
                </span>
              </HorizontalLayout>
              <Space height={24} />

              <span style={{ color: '#ff4d00', fontSize: '12px' }}>
                * 청소와 요리 둘중 하나만 서비스 선택이 가능합니다.
              </span>
              <Space height={24} />
              <RoundButton
                style={{ width: '300px' }}
                onClick={onCloseServiceSelect}
              >
                완료
              </RoundButton>
            </VerticalLayout>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>

      <Sheet isOpen={showPremiumAge} onClose={onClosePremiumAgeSelect}>
        <Sheet.Container
          style={{
            height: 'auto !important',
            paddingBottom: '40px',
            borderRadius: '32px 32px 0px 0px',
          }}
        >
          <Sheet.Header />
          <Sheet.Content>
            <VerticalLayout center>
              <Space height={48} />
              <HorizontalLayout
                center
                style={{ width: '270px' }}
                onClick={() => (customerStore.form.premiumDetail = 'A2')}
              >
                <Checkbox
                  value={customerStore.form.premiumDetail === 'A2'}
                  onChange={x => {
                    if (x) {
                      customerStore.form.premiumDetail = 'A2';
                    }
                  }}
                />
                <Space width={16} />
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color:
                      customerStore.form.premiumDetail === 'A2'
                        ? '#f69f30'
                        : '#c0c0c0',
                  }}
                >
                  2-4세
                </span>
              </HorizontalLayout>

              <Space height={8} />
              <div
                style={{ width: '300px', height: '1px', background: '#ececec' }}
              />
              <Space height={8} />

              <HorizontalLayout
                center
                style={{ width: '270px' }}
                onClick={() => (customerStore.form.premiumDetail = 'A5')}
              >
                <Checkbox
                  value={customerStore.form.premiumDetail === 'A5'}
                  onChange={x => {
                    if (x) {
                      customerStore.form.premiumDetail = 'A5';
                    }
                  }}
                />
                <Space width={16} />
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color:
                      customerStore.form.premiumDetail === 'A5'
                        ? '#f69f30'
                        : '#c0c0c0',
                  }}
                >
                  5-7세
                </span>
              </HorizontalLayout>
              <Space height={24} />

              {/* <span style={{ color: '#ff4d00', fontSize: '12px' }}>
                * 청소와 요리 둘중 하나만 서비스 선택이 가능합니다.
              </span> */}
              <Space height={24} />
              <RoundButton
                style={{ width: '300px' }}
                onClick={onClosePremiumAgeSelect}
              >
                완료
              </RoundButton>
            </VerticalLayout>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
});
