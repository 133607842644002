import React from 'react';
import { usePrices } from 'state/hook/value';
import { price } from 'util/index';
import { Table } from './Table';

export const ServiceCategoryTable = ({ style = {} }) => {
  return (
    <Table style={style}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>정기 돌봄</th>
          <th style={{ width: '50%' }}>비정기 돌봄</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>최소 한 달, 주 2회 이상</td>
          <td>1회 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
// export const ServiceCategoryTable = () => {
//   return (
//     <Table style={{ width: '100%', maxWidth: '400px' }}>
//       <thead>
//         <tr>
//           <th>정기 돌봄</th>
//           <th>비정기 돌봄</th>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <td>
//             한 달 이상
//             <br />
//             (4주)
//           </td>
//           <td>1회 이상</td>
//         </tr>
//       </tbody>
//     </Table>
//   );
// };

export const HourlyPricingTable = ({ style = {} }) => {
  const prices = usePrices();

  return (
    <Table style={style}>
      <thead>
        <tr>
          <th style={{ width: '33%' }}>서비스 시간</th>
          <th style={{ width: '33%' }}>아이 1명</th>
          <th style={{ width: '33%' }}>아이 2명</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>정기</td>
          <td>{price(prices['정기_신입_1:1_day'])}원</td>
          <td>{price(prices['정기_신입_1:2_day'])}원</td>
        </tr>
        <tr>
          <td>비정기</td>
          <td>{price(prices['비정기_신입_1:1_day'])}원</td>
          <td>{price(prices['비정기_신입_1:2_day'])}원</td>
        </tr>
      </tbody>
    </Table>
  );
};

// export const HourlyPricingTable = () => {
//   return (
//     <Table style={{ width: '100%', maxWidth: '400px' }}>
//       <thead>
//         <tr>
//           <th>인원</th>
//           <th>가격</th>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <td>아이 1명</td>
//           <td>13,000원</td>
//         </tr>
//         <tr>
//           <td>아이 2명</td>
//           <td>16,000원</td>
//         </tr>
//       </tbody>
//     </Table>
//   );
// };
export const ServiceMinTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>서비스 종류</th>
          <th style={{ width: '50%' }}>최소시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>등원 + 놀이돌봄</td>
          <td>2시간 이상</td>
        </tr>
        <tr>
          <td>하원 + 놀이돌봄</td>
          <td>2시간 이상</td>
        </tr>
        <tr>
          <td>등하원 + 놀이돌봄</td>
          <td>4시간 이상</td>
        </tr>
        <tr>
          <td>놀이돌봄만</td>
          <td>2시간 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
