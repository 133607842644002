import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { HorizontalLayout, VerticalLayout, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { Paper } from 'atom/deco';
import { Progress } from 'atom/progress';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { useStores } from 'state';
import { ApplyStep0, ApplyStep1 } from './apply';
import { useAuthContext } from '../../context/AuthContext';
import { getUserType } from 'util/token';

export const SitterApplyPage = ({}) => {
  const history = useHistory();
  const { isLogin } = useAuthContext();
  const { sitterStore } = useStores();
  const [FormComponent, setFormComponent] = useState<React.FC<any>>(
    isLogin ? ApplyStep1 : ApplyStep0,
  );
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isLogin) {
      const type = getUserType();
      if (type !== 'HERO') {
        showConfirm('부모님은 히어로 교육 신청이 불가합니다.');
        history.goBack();
      }
    }
  }, []);
  useEffect(() => {
    sitterStore.resetForm();
  }, []);
  useEffect(() => {
    setCount(count => count + 1);

    window.history.replaceState(
      {},
      '히어로선생님 지원하기',
      `/hero/apply/q${count}`,
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [FormComponent]);

  return (
    <>
      <Header />
      <Container>
        <Paper style={{ position: 'relative', paddingTop: '30px' }}>
          <HorizontalLayout>
            <Logo />
            <Space width={15} />
            <Title>히어로 선생님 지원하기</Title>
          </HorizontalLayout>
          <Space height={31} />
          <hr />

          <FormContainer>
            <FormComponent
              Bottom={props => <FormBottom {...props} />}
              onNext={setFormComponent}
            />
          </FormContainer>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

interface FormBottomProps {
  disabled: boolean;
  label?: string;
  onClickNext: () => void;
}
const FormBottom = ({
  disabled = false,
  label,
  onClickNext,
}: FormBottomProps) => {
  return (
    <>
      <RoundButton
        style={{ width: '100%' }}
        disabled={disabled}
        onClick={onClickNext}
      >
        {label || '다음'}
      </RoundButton>
    </>
  );
};

const Container = styled.div`
  display: flex;

  background-color: #f7f7f7;

  justify-content: center;

  padding-top: 30px;
  padding-bottom: 200px;
`;
const Logo = styled.img.attrs({
  src: require('asset/logo-small.svg').default,
})`
  width: 42px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 500;

  letter-spacing: -1.2px;
`;
const FormContainer = styled.div`
  padding: 50px 80px 10px 80px;
`;
