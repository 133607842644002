import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray12, DarkGray16, White12 } from 'atom/text';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useStores } from 'state/index';
import styled from 'styled-components';

const LimitedPlayKitCount = props => {
  const { valueStore } = useStores();
  const [value, setValue] = useState(99);

  useEffect(() => {
    (async () => {
      setValue(Number(await valueStore.getValue('event_playkit_count')));
    })();
  }, []);
  return (
    <Container center>
      <Space height={25} />
      <FlexBox>
        <DarkGray12 bold>남은 선착순 인원</DarkGray12>
        <NumberBox number={Math.floor(value / 10)} />
        <NumberBox number={value % 10} />
        <DarkGray16 bold style={{ position: 'relative' }}>
          명
          <img
            style={{
              position: 'absolute',
              left: '-2px',
              top: '-20px',
              width: '45px',
            }}
            src={
              require('asset/event/event_5years_for_parents/completed_mark_event.png')
                .default
            }
          />
        </DarkGray16>
      </FlexBox>
      <Space height={4} />

      <White12 style={{ fontSize: '10px' }} bold>
        {moment().format('MM월 DD일 hh시 기준')}
      </White12>
    </Container>
  );
};
const NumberBox = ({ number }) => {
  return (
    <div
      style={{
        background: '#FFFFFF',
        border: '1px solid #696969',
        borderRadius: '6px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 0.25rem',
      }}
    >
      <DarkGray16 bold center>
        {number}
      </DarkGray16>
    </div>
  );
};
const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const Container = styled(VerticalLayout)`
  height: 87px;
  background: #f69f30;
`;

export default LimitedPlayKitCount;
