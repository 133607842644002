import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { PickupMethodNew } from 'model';
import { Title, Subtitle } from './style';
import { RequestStep8 } from './RequestStep8';

export const RequestStep7 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [feeConfirmShow, setFeeConfirmShown] = useState(false);

  const onSelect = (x: PickupMethodNew) => {
    if (x === PickupMethodNew.Car) {
      showConfirm(
        '선생님 차량 이용시, 매칭이\n어려울 수 있으니 다른 픽업 방법도\n중복으로 선택해주세요.',
        '선생님 차량에 설치할 카시트는\n부모님이 따로 준비해주셔야 합니다.',
      );
    } else if (
      !feeConfirmShow &&
      (x === PickupMethodNew.Bus || x === PickupMethodNew.Taxi)
    ) {
      showConfirm(
        '교통비는 선생님과 조율하여\n개별적으로 지급해드려야 하니\n참고해주세요!',
      );
      setFeeConfirmShown(true);
    }
  };

  useEffect(() => {
    if (!customerStore.applyForm.pickup) return;

    customerStore.applyForm.pickupMethod = customerStore.applyForm.pickup.join(
      ',',
    );
  }, [customerStore.applyForm.pickup]);

  return (
    <div style={{ padding: '40px 20px' }}>
      <Title>픽업 방법을 선택해 주세요.</Title>
      <Space height={60} />

      <MultiSelect
        twoColumns
        items={[
          { label: '도보이동', value: PickupMethodNew.Walk },
          { label: '대중교통', value: PickupMethodNew.Bus },
          { label: '택시', value: PickupMethodNew.Taxi },
          { label: '선생님 차량', value: PickupMethodNew.Car },
          { label: '스쿨버스', value: PickupMethodNew.SchoolBus },
          { label: '픽업 불필요', value: PickupMethodNew.None },
        ]}
        value={customerStore.applyForm.pickup}
        onSelect={onSelect}
        onChange={e => (customerStore.applyForm.pickup = e)}
      />
      <Space height={150} />
      <Bottom
        progress={(1 / 11) * 7}
        disabled={customerStore.applyForm.pickup.length === 0}
        onClickNext={() => onNext(RequestStep8)}
      />
    </div>
  );
});
