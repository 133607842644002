import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AnimatedNumbers from '../../page-mobile/customer/Ani';

import { HorizontalLayout } from 'atom/layout';
import { useStores } from 'state';
import { useIsMobile } from 'util/index';

export const SitterCount = ({}) => {
  const isMobile = useIsMobile();
  const { valueStore } = useStores();
  const [value, setValue] = useState('1100');

  useEffect(() => {
    (async () => {
      //setValue(await valueStore.getValue('hero_count'));
      setValue(await valueStore.getValue('active_heroes'));
    })();
  }, []);

  return (
    <Container isMobile={isMobile}>
      <AnimatedNumbers
        key={value}
        includeComma
        animateToNumber={+value}
        fontStyle={{ fontSize: isMobile ? 34 : 60, fontWeight: 700 }}
        // @ts-ignore
        configs={(number, index) => {
          return { mass: 1, tension: 230 * (index + 1), friction: 140 };
        }}
      />
      &nbsp; 명
    </Container>
  );
};

const Container = styled(HorizontalLayout)<any>`
  font-size: 60px;
  font-weight: 700;

  > div {
    gap: 16px;
  }
  > div > div:nth-child(1),
  > div > div:nth-child(n + 3) {
    width: 100px;
    height: 100px !important;

    background: white;
    border: 2px solid #696969;
    border-radius: 20px;

    text-align: center;
  }
  > div > div:nth-child(2) {
    transform: translateY(24px);
  }

  ${({ isMobile }) =>
    isMobile
      ? `
    font-size: 34px;

    .active {
      margin-top: -2px;
    }

    > div {
      gap: 4px;
    }
    > div > div:nth-child(1), > div > div:nth-child(n + 3) {
      width: 48px !important;
      height: 48px !important;
  
      background: white;
      border: 2px solid #696969;
      border-radius: 12px;
  
      text-align: center;
    }
    > div > div:nth-child(2) {
      transform: translateY(0px);
    }
  `
      : `
  `}
`;
