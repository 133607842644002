import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { useStores } from 'state';
import { useIsMobile } from 'util/index';

interface BannerProps {
  index?: number;
}
export const Banner = ({ index = 0 }: BannerProps) => {
  const { bannerStore } = useStores();
  const [banners, setBanners] = useState<any[]>();
  const isMobile = useIsMobile();
  const banner = banners?.find(x => x.seq === index);

  useEffect(() => {
    (async () => {
      setBanners(await bannerStore.getBanners());
    })();
  }, []);

  if (!banners || banners.length === 0 || !banner) return <></>;

  if (!banner['is_exposed']) return <></>;
  return (
    <Container isMobile={isMobile}>
      <Slider autoplay infinite autoplaySpeed={3000}>
        <BannerImage
          isMobile={isMobile}
          src={banner['image_url']}
          onClick={() =>
            banner['link_url'] && window.open(banner['link_url'], '_blank')
          }
        />
        {/* {banners.map(x => (
          <BannerImage
            key={x.imgPath}
            isMobile={isMobile}
            src={`https://api.woorihero.com${x.imgPath}`}
            onClick={() => x.content && window.open(x.content, '_blank')}
          />
        ))} */}
      </Slider>
    </Container>
  );
};

const Container = styled.div<any>`
  width: 100%;

  cursor: pointer;

  overflow: hidden;

  ${({ isMobile }) =>
    isMobile
      ? `
    height: 100px;
  `
      : `
    height: 98px;
  `}
`;
const BannerImage = styled.img<any>`
  width: 100%;

  ${({ isMobile }) =>
    isMobile
      ? `
      object-fit: contain;
    height: 100%;
  `
      : `
      object-fit: cover;
    height: 98px;
  `}
`;
