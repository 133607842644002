import React from 'react';
import styled from 'styled-components';

import { Space, VerticalLayout } from 'atom/layout';
import { Black40 } from 'atom/text';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { FaqAccordianItem } from 'component/faq';
import { FaqContents } from 'content';

export const FaqPage = ({}) => {
  return (
    <>
      <Header />
      <Container>
        <Space height={100} />
        <Black40 bold center>
          자주 묻는 질문
        </Black40>
        <Space height={63} />
        <FaqContainer>
          {FaqContents.map((x, idx) => (
            <FaqAccordianItem key={idx} data={x} />
          ))}
        </FaqContainer>
        <Space height={200} />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div``;
const FaqContainer = styled.div`
  max-width: 1000px;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
`;
