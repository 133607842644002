import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import CountDown from 'react-countdown';
import { RoundButton } from 'atom/button';
import { HorizontalLayout, Space } from 'atom/layout';
import { useStores } from 'state';
import { DarkGray24, LightGray14 } from 'atom/text';
import { ApplyStep1 } from './ApplyStep1';
import { Labeled } from 'atom/input';
import { TextField } from '@material-ui/core';
import { VerificationStep } from 'model';
import { useHistory } from 'react-router-dom';
import Timer, { threeMinutes } from 'component-mobile/login/Timer';
import { okLogin } from 'util/token';
import { useAuthDispatch } from '../../../context/AuthContext';

export const ApplyStep0 = observer(({ Bottom, onNext }) => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { sitterStore } = useStores();
  const [step, setStep] = useState(VerificationStep.InputNumber);
  const [timerDate, setTimerDate] = useState(Date.now());

  const onSendCode = async () => {
    const phone = sitterStore.signUpForm.phone;
    if (phone.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    try {
      await sitterStore.sendVerificationCode(phone);
      setStep(VerificationStep.Sent);
      setTimerDate(Date.now());
    } catch (e) {
      alert(e.message || '인증번호 전송이 실패했습니다.');
    }
  };

  const onVerifyCode = async () => {
    const phone = sitterStore.signUpForm.phone;
    const accessNumber = sitterStore.signUpForm.accessNumber;

    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }

    try {
      const {
        ok,
        result,
        message,
        token,
        refreshToken,
      } = await sitterStore.confirmVerificationCode(
        phone,
        Number(accessNumber),
      );
      if (ok) {
        //인증번호 일치함
        if (token) {
          //이미 회원인 사람
          //hero만 다음 스탭으로 갈 수 있음.
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          if (type === 'HERO') {
            onNext(ApplyStep1);
          } else {
            showConfirm('히어로 선생님만 교육신청 가능합니다.');
            history.push('/');
          }
        } else {
          //번호 인증했지만 회원가입이 필요한 사람.
          onNext(ApplyStep1);
        }
      } else {
        alert(message || '번호인증에 오류가 발생했습니다.');
      }
    } catch (e) {
      alert(e.message || '번호인증에 실패했습니다.');
    }
  };

  return (
    <>
      <DarkGray24 bold>
        언제나 가까이,
        <br />
        우리동네 돌봄히어로
      </DarkGray24>
      <Space height={12} />
      <LightGray14>현재 서울시에서만 가능한 서비스입니다.</LightGray14>
      <Space height={45} />

      <Labeled label="휴대폰 번호">
        <HorizontalLayout style={{ alignItems: 'end' }}>
          <TextField
            style={{ flex: 1 }}
            value={sitterStore.signUpForm.phone}
            disabled={step !== VerificationStep.InputNumber}
            onChange={e =>
              (sitterStore.signUpForm.phone = e.target.value.replace(/\D/g, ''))
            }
          />
          <Space width={12} />
          <RoundButton
            variant="primary"
            disabled={step !== VerificationStep.InputNumber}
            style={{
              width: '112px',
              height: '42px',
              padding: 0,
              fontSize: '15px',
            }}
            onClick={onSendCode}
          >
            인증번호 전송
          </RoundButton>
        </HorizontalLayout>
      </Labeled>
      <Space height={40} />

      {step === VerificationStep.InputNumber && <Space height={77} />}
      {step !== VerificationStep.InputNumber && (
        <Labeled label="인증번호를 입력해주세요">
          <HorizontalLayout style={{ alignItems: 'end', position: 'relative' }}>
            <TextField
              style={{ flex: 1 }}
              value={sitterStore.signUpForm.accessNumber}
              onChange={e =>
                (sitterStore.signUpForm.accessNumber = e.target.value.replace(
                  /\D/g,
                  '',
                ))
              }
            />
            <Space width={12} />
            <div style={{ position: 'absolute', right: '130px', top: '10px' }}>
              <CountDown date={timerDate + threeMinutes} renderer={Timer} />
            </div>
            <RoundButton
              variant="secondary"
              style={{
                width: '112px',
                height: '42px',
                padding: 0,
                fontSize: '15px',
              }}
              onClick={onVerifyCode}
            >
              인증하기
            </RoundButton>
          </HorizontalLayout>
        </Labeled>
      )}
      <Space height={20} />
    </>
  );
});
