import React, { CSSProperties, useState } from 'react';
import { VerticalLayout, HorizontalLayout, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { useIsMobile } from 'util/index';

interface ApplyWayItemProps {
  label: string;
  funnelCode: string;
}
interface SitterApplyWayButtonsProps {
  funnel: string;
  onClickFunnel: (item: ApplyWayItemProps) => void;
}
export const SitterApplyWayButtons = ({
  funnel,
  onClickFunnel,
  ...props
}: SitterApplyWayButtonsProps) => {
  const items = [
    { label: '여성 인력개발 기관', funnelCode: 'WO' },
    { label: '서울시 혹은 구청 홈페이지', funnelCode: 'GH' },
    { label: '워크넷', funnelCode: 'WN' },
    { label: '알바몬', funnelCode: 'AM' },
    { label: '벼룩시장', funnelCode: 'BM' },
    { label: '현수막', funnelCode: 'BA' },
    { label: 'SNS 광고', funnelCode: 'SA' },
    { label: '인터넷 검색', funnelCode: 'IN' },
    { label: '아파트 게시판', funnelCode: 'AA' },
    { label: '아파트 우편함', funnelCode: 'PO' },
    { label: '지인 추천', funnelCode: 'RE' },
    { label: '주민센터 포스터', funnelCode: 'JP' },

    // { label: '구인구직 사이트', funnelCode: 'JH' },
    //   { label: '홍보 문자', funnelCode: 'PM' },
  ];

  const isMobile = useIsMobile();
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: isMobile ? 'center' : 'start',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {items.map(item => (
        <RoundButton
          variant={funnel === item.label ? 'secondary' : 'gray'}
          style={{
            width: '196px',
            height: '50px',
            fontSize: '15px',
            padding: 0,
            margin: '5px',
          }}
          onClick={() => {
            onClickFunnel(item);
          }}
        >
          {item.label}
        </RoundButton>
      ))}
    </div>
  );
};
