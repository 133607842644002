import React from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';

import { Color } from 'constant';

interface DateRangePickerProps {
  startDate: DateTime;
  endDate: DateTime;
  onChange: (startDate: DateTime, endDate: DateTime) => void;
}
export const DateRangePicker = ({
  startDate,
  endDate,
  onChange,
}: DateRangePickerProps) => {
  return (
    <Holder>
      <Container>
        <Calendar
          showDoubleView
          selectRange
          minDate={DateTime.local().toJSDate()}
          className={!!endDate ? 'selected' : 'selecting'}
          formatDay={(locale, date) => `${date.getDate()}`}
          value={[endDate?.toJSDate(), startDate?.toJSDate()]}
          calendarType="US"
          onClickDay={v => {
            onChange(startDate, DateTime.fromJSDate(v));
          }}
          onChange={v => {
            onChange(DateTime.fromJSDate(v[0]), DateTime.fromJSDate(v[1]));
          }}
        />
      </Container>
    </Holder>
  );
};

const Holder = styled.div`
  position: relative;
`;
const Container = styled.div<any>`
  position: absolute;

  padding: 24px 24px;

  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  z-index: 100;
`;
