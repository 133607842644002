import { TextField } from '@material-ui/core';
import { RoundButton } from 'atom/button';
import { Labeled } from 'atom/input';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, Primary12 } from 'atom/text';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'state';
import { DateTime } from 'luxon';
import { HeaderCustomerSignUp } from 'component-mobile/header';
import ApplySignUpPageStep2 from './ApplySignUpPageStep2';
import { HeaderSignUpInFrame } from 'component-mobile/header/HeaderSignUpInFrame';
import { ApplyStep2 } from './ApplyStep2';
import { getAge } from 'util/index';

const ApplySignUpPageStep1 = observer(({ setHasHeader, onNext }) => {
  const history = useHistory();
  const { sitterStore } = useStores();
  const [nameWarningKey, setNameWarningKey] = useState(null);
  const nameWarning = { 1: '이름을 입력해주세요' };
  const [birthWarningKey, setBirthWarningKey] = useState(null);
  const birthWarning = {
    1: '생일을 입력해주세요',
    2: '30세 이상 67세 이하 여성만 가입이 가능합니다.',
  };

  const checkForm = () => {
    const birthday = sitterStore.signUpForm.birth;
    const age = getAge(birthday);

    if (sitterStore.signUpForm.name.length < 2) {
      setNameWarningKey(1);
      return false;
    }

    if (birthday.length !== 6) {
      setBirthWarningKey(1);
      return false;
    }
    if (age + 1 > 67 || age + 1 <= 30) {
      setBirthWarningKey(2);
      return false;
    }

    setNameWarningKey(null);
    setBirthWarningKey(null);

    return true;
  };
  const registerHero = () => {
    if (checkForm()) {
      onNext(ApplySignUpPageStep2);
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    setHasHeader(false);
    if (!sitterStore.signUpForm.phone) {
      history.goBack();
      //휴대폰 번호 인증이 안되었다면 현재 페이지 접근 불가.
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore, setHasHeader]);
  return (
    <>
      <HeaderSignUpInFrame
        progress={(1 / 6) * 100}
        onGoback={() => {
          onNext(ApplyStep2);
        }}
      />
      <VerticalLayout>
        <DarkGray24 bold>
          교육 신청을 위해
          <br />
          회원가입이 필요합니다.
        </DarkGray24>
        <Space height={64} />
        <Labeled label="성함" labelStyle={{ fontSize: '18px' }} gap={10}>
          <TextField
            placeholder="본인 이름을 입력해주세요."
            variant="outlined"
            fullWidth
            value={sitterStore.signUpForm.name}
            onChange={e => (sitterStore.signUpForm.name = e.target.value)}
          />
        </Labeled>
        {nameWarningKey ? (
          <Primary12 style={{ margin: '4px 0 2px 4px' }}>
            {nameWarning[nameWarningKey]}
          </Primary12>
        ) : (
          <Space height={24} />
        )}
        <Space height={24} />
        <Labeled
          label="생년월일 6자리"
          labelStyle={{ fontSize: '18px' }}
          gap={10}
        >
          <TextField
            placeholder="예)641021"
            variant="outlined"
            fullWidth
            value={sitterStore.signUpForm.birth}
            onChange={e =>
              (sitterStore.signUpForm.birth = e.target.value.replace(/\D/g, ''))
            }
          />
        </Labeled>
        {birthWarningKey ? (
          <Primary12 style={{ margin: '4px 0 2px 4px' }}>
            {birthWarning[birthWarningKey]}
          </Primary12>
        ) : (
          <Space height={24} />
        )}
        <Space height={119} />
      </VerticalLayout>
      <RoundButton
        style={{
          width: '320px',
          height: '56px',
          padding: 0,
          fontSize: '16px',
          margin: 'auto',
        }}
        onClick={registerHero}
      >
        다음
      </RoundButton>
    </>
  );
});

export default ApplySignUpPageStep1;
