import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray14, LightGray11, Primary24 } from 'atom/text';
import React from 'react';

const TopMainInfoBox = ({ info }) => {
  const { title, subTitle, description } = info;
  return (
    <HorizontalLayout center style={{ margin: '16px 0' }}>
      <Primary24 bold>{title}</Primary24>
      <VerticalLayout center style={{ width: '170px', marginLeft: '55px' }}>
        <DarkGray14 center>{subTitle}</DarkGray14>
        <Space height={4} />
        <LightGray11 center>{description}</LightGray11>
      </VerticalLayout>
    </HorizontalLayout>
  );
};

export default TopMainInfoBox;
