import { RoundButton } from 'atom/button';
import { ImageBox } from 'atom/image';
import { VerticalLayout } from 'atom/layout';
import { DarkGray32, DarkGray40, Primary48, White24 } from 'atom/text';
import Apply from 'component/event/5yearsForParents/Apply';
import EventNotices from 'component/event/5yearsForParents/EventNotices';
import Reviews from 'component/event/5yearsForParents/Reviews';
import RewardDetail1 from 'component/event/5yearsForParents/RewardDetail1';
import RewardDetail1HowTo from 'component/event/5yearsForParents/RewardDetail1HowTo';
import RewardDetail2 from 'component/event/5yearsForParents/RewardDetail2';
import RewardDetail2HowTo from 'component/event/5yearsForParents/RewardDetail2HowTo';
import { Footer } from 'component/footer';
import { URL } from 'constant';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useStores } from 'state/index';
import styled from 'styled-components';

const Event5YearsForParents = () => {
  const { valueStore } = useStores();
  const [value, setValue] = useState(99);
  const applyRef = useRef(null);
  const scrollToApply = () => applyRef.current.scrollIntoView();
  useEffect(() => {
    (async () => {
      setValue(Number(await valueStore.getValue('event_playkit_count')));
    })();
  }, []);
  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/event/event_5years_for_parents/main.jpg').default}
          minHeight={1974}
        >
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '120px',
              transform: 'translate(-90%, 0%)',
            }}
          >
            <img
              style={{
                width: '660px',
              }}
              src={
                require('asset/event/event_5years_for_parents/award.svg')
                  .default
              }
            />
            <Primary48
              style={{
                fontSize: '75px',
                fontWeight: 'bold',
              }}
            >
              우리동네 돌봄히어로
            </Primary48>
            <Primary48
              style={{
                fontSize: '60px',
                fontWeight: 'bold',
              }}
            >
              5주년 감사 <span style={{ color: '#484848' }}>이벤트</span>
            </Primary48>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                alignItems: 'center',
              }}
            >
              <RoundLineText>이벤트 기간</RoundLineText>
              <span
                style={{
                  marginLeft: '1rem',
                  fontWeight: 700,
                  fontSize: '25px',
                  lineHeight: '70px',
                  textAlign: 'center',
                  color: '#484848',
                }}
              >
                2023년 01월 1일 ~ 2023년 3월 31일
              </span>
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              right: '50%',
              top: '10px',
              transform: 'translate(140%, 0%)',
            }}
          >
            <img
              style={{
                width: '550px',
              }}
              src={
                require('asset/event/event_5years_for_parents/mainRight.png')
                  .default
              }
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '45%',
              whiteSpace: 'pre-line',
              transform: 'translate(-50%, -45%)',
              fontSize: '48px',
              fontWeight: 'bold',
              color: '#484848',
              textAlign: 'center',
            }}
          >
            {`돌봄히어로가 5주년을 맞아 준비한
            2가지 혜택을 공개합니다. 🎉🎉`}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              position: 'absolute',
              left: '10%',
              bottom: '10%',
              transform: 'translate(-10%, -10%)',
              justifyContent: 'center',
            }}
          >
            <RoundMemoBox title={'혜택1'} limitText={`100명\n한정`}>
              <VerticalLayout center>
                <DarkGray40 bold>창의놀이 10회 이용권 + 준비물</DarkGray40>
                <DarkGray40 bold>
                  <span
                    style={{
                      fontSize: '30px',
                      fontWeight: 'normal',
                      textDecoration: 'line-through',
                    }}
                  >
                    130,000원
                  </span>{' '}
                  0원
                </DarkGray40>
                <img
                  style={{
                    width: '530px',
                  }}
                  src={
                    require('asset/event/event_5years_for_parents/heroPlay_1.png')
                      .default
                  }
                />
              </VerticalLayout>
            </RoundMemoBox>
            <RoundMemoBox title={'혜택2'} limitText={`무제한`}>
              <VerticalLayout center>
                <DarkGray40
                  bold
                  center
                >{`이벤트 신청과 카카오톡 친구 추가만해도\n창의놀이 가이드(PDF) 무료 제공`}</DarkGray40>
                <img
                  style={{
                    marginTop: '4rem',
                    width: '365px',
                  }}
                  src={
                    require('asset/event/event_5years_for_parents/heroPlay_2.png')
                      .default
                  }
                />
              </VerticalLayout>
            </RoundMemoBox>
          </div>
        </ImageBox>
        <div
          style={{
            width: '100%',
            height: '290px',
            background: '#F69F30',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <DarkGray32 bold style={{ marginRight: '1rem' }}>
            남은 선착순 인원
          </DarkGray32>
          <NumberBox number={Math.floor(value / 10)} />
          <NumberBox number={value % 10} />
          <DarkGray32 style={{ position: 'relative', marginLeft: '1rem' }} bold>
            명
            <img
              style={{
                position: 'absolute',
                right: '-30%',
                top: '-90px',
                width: '140px',
                transform: 'translate(30%, 0)',
              }}
              src={
                require('asset/event/event_5years_for_parents/completed_mark_event.png')
                  .default
              }
            />
          </DarkGray32>
          <div style={{ position: 'absolute', bottom: '2.5rem' }}>
            <White24 style={{ fontSize: '28px' }}>
              {moment().format('MM월 DD일 hh시 기준')}
            </White24>
          </div>
        </div>
        <Reviews />
        <Seperator />
        <RewardDetail1 />
        <RewardDetail1HowTo scrollToApply={scrollToApply} />
        <Seperator />
        <RewardDetail2 />
        <RewardDetail2HowTo scrollToApply={scrollToApply} />
        <div style={{ width: '100%' }} ref={applyRef}>
          <Apply />
        </div>
        <Seperator />
        <EventNotices />
      </Container>
      <StickyMenuBar>
        <RoundButton
          style={{
            padding: '17px 32px',
            width: '440px',
            height: '79px',
            fontWeight: 'bold',
            fontSize: '32px',
            marginRight: '61px',
          }}
          onClick={scrollToApply}
        >
          이벤트 신청하기
        </RoundButton>
        <AddKaKaoFriendBtn />
      </StickyMenuBar>
      <Footer />
    </>
  );
};

const StickyMenuBar = styled.div`
  float: left;
  position: fixed;
  width: 100%;
  height: 160px;
  bottom: 0px;
  z-index: 999;
  background: rgba(255, 210, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled(VerticalLayout)`
  font-family: 'Noto Sans KR';
  position: relative;
`;

const Seperator = styled.div`
  width: 100%;
  height: 36px;
  background: #d9d9d9;
`;

const NumberBox = ({ number }) => {
  return (
    <div
      style={{
        background: '#FFFFFF',
        border: '2px solid #696969',
        borderRadius: '10px',
        width: '98px',
        height: '98px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 1rem',
      }}
    >
      <DarkGray40 bold center>
        {number}
      </DarkGray40>
    </div>
  );
};

const RoundLineText = styled.div`
  border: 2px solid #f69f30;
  border-radius: 60px;
  width: 210px;
  height: 60px;
  display: flex;
  line-height: 50px;
  justify-content: center;
  align-items: center;
  color: #f69f30;
  font-size: 24px;
  font-weight: bold;
`;

const RoundTextBox = styled.div`
  background: #000000;
  border-radius: 100px;
  padding: 15px 55px;
  text-align: center;
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
`;

const RedDotSticker = styled.div`
  width: 180px;
  height: 180px;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  background: #f64343;
  border-radius: 50%;
  white-space: pre-line;
`;

const RoundBgBox = styled.div`
  width: 723px;
  height: 676px;
  left: 0px;
  top: 39px;
  background: #ffffff;
  border-radius: 69px;
  position: relative;
  padding: 2rem;
  margin: 0 2rem;
`;

const RoundMemoBox = ({ children, title, limitText }) => {
  return (
    <RoundBgBox>
      <RoundTextBox
        style={{
          width: '276px',
          position: 'absolute',
          left: '50%',
          top: '-5%',
          transform: 'translate(-50%, -5%)',
        }}
      >
        {title}
      </RoundTextBox>
      <div style={{ marginTop: '1rem' }}>{children}</div>
      <RedDotSticker
        style={{
          position: 'absolute',
          right: '-6%',
          bottom: '-10%',
          transform: 'translate(6%, 10%)',
        }}
      >
        {limitText}
      </RedDotSticker>
    </RoundBgBox>
  );
};

const KakaoButton = styled.button`
  width: 440px;
  height: 79px;
  margin-left: 61px;
  background: #f9e54d;
  border-radius: 100px;
  border: none;

  cursor: pointer;
  &:hover {
    filter: contrast(1.5);
  }
`;
export const AddKaKaoFriendBtn = ({}) => {
  return (
    <KakaoButton onClick={() => window.open(URL.Kakao)}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: '53px',
            marginRight: '1rem',
          }}
          src={
            require('asset/event/event_5years_for_parents/kakaoIcon.png')
              .default
          }
        />
        <DarkGray32
          style={{
            color: '#2D2D2D',
          }}
          bold
        >
          카카오톡 추가하기
        </DarkGray32>
      </div>
    </KakaoButton>
  );
};

export default Event5YearsForParents;
