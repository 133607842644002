import { RoundButton } from 'atom/button';
import { Space } from 'atom/layout';
import { DarkGray12, DarkGray18, LightGray10, Primary16 } from 'atom/text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Step = ({ number, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10%',
        padding: '16px 0',
      }}
    >
      <Primary16 bold style={{ fontSize: '15px' }}>{`STEP${number}`}</Primary16>
      <Space width={14} />
      {children}
    </div>
  );
};
const HowToDo = () => {
  const history = useHistory();
  return (
    <div
      style={{
        width: '100%',
        height: '538px',
        background: '#fffbf7',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Space height={64} />
      <DarkGray18 bold>창의놀이 이용방법</DarkGray18>
      <Space height={27} />
      <Info>
        <Step number={1}>
          <DarkGray12>하단에 신청하기 버튼을 클릭합니다.</DarkGray12>
        </Step>
        <hr style={{ width: '80%', alignSelf: 'center' }} />
        <Step number={2}>
          <DarkGray12> 정기돌봄을 신청해주세요.</DarkGray12>
        </Step>
        <hr style={{ width: '80%', alignSelf: 'center' }} />
        <Step number={3}>
          <div style={{ textAlign: 'start' }}>
            <DarkGray12>
              쿠폰 코드 <span style={{ fontWeight: 'bold' }}>‘playkitsd'</span>{' '}
              를
            </DarkGray12>
            <DarkGray12>적으면 참가가 완료됩니다.</DarkGray12>
          </div>
        </Step>
        <hr style={{ width: '80%', alignSelf: 'center' }} />
        <Step number={4}>
          <div style={{ textAlign: 'start' }}>
            <DarkGray12>신청이 완료되면 히어로 센터에서</DarkGray12>
            <DarkGray12>연락을 드립니다.</DarkGray12>
          </div>
        </Step>
      </Info>
      <Space height={20} />
      <LightGray10>
        *3개월 이용 전 취소시 이벤트 혜택이 없어질 수 있습니다.{' '}
      </LightGray10>
      <Space height={27} />
      <RoundButton
        style={{
          width: '204px',
          height: '40px',
          fontSize: '14px',
          padding: 0,
        }}
        onClick={() => history.push('/service/apply/q1')}
      >
        서비스 신청하기
      </RoundButton>
    </div>
  );
};

const Info = styled.div`
  width: 297px;
  height: 259px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  backdrop-filter: blur(4px);
  border-radius: 45px;
`;

export default HowToDo;
