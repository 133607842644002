import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { HorizontalLayout, Space, Push } from 'atom/layout';
import { useStores } from 'state';
import { useIsMobile } from 'util/index';

export const Header = ({

}) => {
  const isMobile = useIsMobile(true);
  const history = useHistory();
  const { drawerStore } = useStores();

  // PC에서 모바일 페이지 보여주는 경우
  if (!isMobile) return <></>;

  return (
    <Holder>
      <Container>
        <Space width={23} />
        <Logo
          onClick={() => history.replace('/')}
        />
        <Push />
        <Space width={23} />
        <Menu
          onClick={() => drawerStore.show = true}
        />
        <Space width={23} />
      </Container>
    </Holder>
  )
};

const Holder = styled.header`
  position: relative;

  width: 100%;
  height: 56px;
`;
const Container = styled(HorizontalLayout)`
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 56px;

  background: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  
  align-items: center;

  z-index: 10;
`;
const Logo = styled.img.attrs({
  src: require('asset/logo-small.svg').default,
})`
  width: 40px;

  cursor: pointer;
`;
const Menu = styled.img.attrs({
  src: require('asset/mobile/menu.svg').default,
})`
  width: 24px;

  cursor: pointer;
`;
