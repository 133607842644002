import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { isNil } from 'lodash';

import { HorizontalLayout, VerticalLayout, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { Paper } from 'atom/deco';
import { Progress } from 'atom/progress';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { useStores } from 'state';
import { usePrices } from 'state/hook/value';
import { RegisterStep1 } from './register';
import {
  RequestFaq,
  RequestStep1,
  RequestStep2,
  RequestStep3,
  RequestStep4,
  RequestStep5Regular,
  RequestStep6,
  RequestStep7,
  RequestStep8,
  RequestPersonalityCheck1,
  RequestPersonalityCheck2,
  RequestPersonalityCheck3,
  RequestPersonalityCheck4,
  RequestConfirm,
  RequestDone,
  RequestAlreadyDone,
  RequestChildInfo1,
} from './request';
import { useAuthContext } from '../../context/AuthContext';
import { getUserType } from 'util/token';
import { RequestStep12 } from './request/RequestStep12';
import { ApplySignupStep1 } from './register/ApplySignupStep1';
import { ApplySignupStep2 } from './register/ApplySignupStep2';

export const CustomerRequestPage = ({}) => {
  const { isLogin } = useAuthContext();
  const { customerStore } = useStores();
  const history = useHistory();
  const [stepHistory, setStepHistory] = useState<React.FC<any>[]>([]);
  const [FormComponent, setFormComponent] = useState<React.FC<any>>(
    isLogin ? RequestStep1 : RegisterStep1,
  );
  const [loading, setLoading] = useState(false);

  const onClickPrev = () => {
    if (stepHistory.length === 1) return;
    stepHistory.pop();
    setStepHistory([...stepHistory]);
    setFormComponent(stepHistory[stepHistory.length - 1]);
  };
  const onLogin = async () => {
    //해당 유저의 돌봄신청 이력이 있나 체크 하고, 유무에 따라 보여질 화면 다름.
    if (isLogin) {
      if (getUserType() === 'HERO') {
        showConfirm('히어로 선생님은 돌봄신청을 할 수 없습니다.');
        history.push('/');
      } else {
        // 이미 신청한 내역이 있는지 확인
        try {
          setLoading(true);
          const { ok: isOkToApply } = await customerStore.getCares();
          //유저 아이디로 돌봄신청내역이 있는지 확인
          if (isOkToApply) {
            setFormComponent(RequestStep1);
          } else {
            setFormComponent(RequestAlreadyDone);
          }
        } catch (e) {
          alert(`돌봄내역 조회에 실패했습니다.${e.message}`);
          history.push('/');
        } finally {
          setLoading(false);
        }
      }
    } else {
      setFormComponent(RegisterStep1); //번호 인증으로
    }
  };

  useEffect(() => {
    customerStore.resetApplyForm();
    if (isLogin) {
      //로그인 된 경우에만 체크
      onLogin();
    }
  }, []);
  useEffect(() => {
    if (!FormComponent) return;

    //히스토리 저장
    if (stepHistory.includes(FormComponent) === false)
      setStepHistory([...stepHistory, FormComponent]);

    // 아이 등록시 url 변화없도록 하는 것 같음.
    if (FormComponent === RequestStep8) {
      setStepHistory(stepHistory =>
        stepHistory.filter(
          x =>
            ![
              RequestChildInfo1,
              RequestPersonalityCheck1,
              RequestPersonalityCheck2,
              RequestPersonalityCheck3,
              RequestPersonalityCheck4,
            ].includes(x),
        ),
      );
    }
    if (FormComponent === RequestStep12) {
      //회원가입후 쿠폰입력화면에서 이전 누를 경우, 회원가입 화면 안보여주기
      setStepHistory(prev =>
        prev.filter(x => ![ApplySignupStep1, ApplySignupStep2].includes(x)),
      );
    }

    window.history.replaceState(
      {},
      '돌봄 서비스 신청하기',
      FormComponent === RequestConfirm
        ? `/service/apply/done`
        : `/service/apply/q${stepHistory.length + 1}`,
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [FormComponent]);

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <Header />
      <Container>
        <Paper style={{ paddingTop: '30px' }}>
          <HorizontalLayout>
            <Logo />
            <Space width={15} />
            <Title>돌봄 서비스 신청하기</Title>
          </HorizontalLayout>
          <Space height={31} />
          <hr />

          {!!FormComponent && (
            <FormContainer>
              <FormComponent
                Bottom={props => (
                  <FormBottom
                    progress={10}
                    disabled={false}
                    onClickPrev={onClickPrev}
                    {...props}
                  />
                )}
                onClickPrev={onClickPrev}
                onLogin={onLogin}
                onNext={setFormComponent}
              />
            </FormContainer>
          )}
          {!FormComponent && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

interface FormBottomProps {
  progress: number;
  disabled: boolean;
  nextLabel?: string;
  onClickPrev: () => void;
  onClickNext: () => void;
}
const FormBottom = ({
  progress,
  disabled = false,
  nextLabel,
  onClickPrev,
  onClickNext,
}: FormBottomProps) => {
  return (
    <>
      {!isNil(progress) && (
        <>
          <Progress value={progress * 100} />
          <Space height={40} />
        </>
      )}

      <HorizontalLayout>
        {!!onClickPrev && (
          <>
            <RoundButton
              variant="white"
              style={{ width: '130px', padding: '0px' }}
              onClick={onClickPrev}
            >
              이전
            </RoundButton>
            <Space width={10} />
          </>
        )}
        <RoundButton fill disabled={disabled} onClick={onClickNext}>
          {nextLabel || '다음'}
        </RoundButton>
      </HorizontalLayout>
    </>
  );
};

const Container = styled.div`
  display: flex;

  background-color: #f7f7f7;

  justify-content: center;

  padding-top: 30px;
  padding-bottom: 200px;
`;
const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 300px;

  align-items: center;
  justify-content: center;
`;
const Logo = styled.img.attrs({
  src: require('asset/logo-small.svg').default,
})`
  width: 42px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 500;

  letter-spacing: -1.2px;
`;
const FormContainer = styled.div`
  padding: 50px 80px 10px 80px;
`;
