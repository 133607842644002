import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24 } from 'atom/text';
import MyApplyHistory from 'component/customer/MyApplyHistory';
import MyInfo from 'component/customer/MyInfo';
import { Footer } from 'component/footer';
import { Header } from 'component/header';

import TabBasic from 'component/tab/TabBasic';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getUserName, getUserType } from 'util/token';

const MyPageCustomer = props => {
  const [tab, setTab] = useState('applyInfo');
  const history = useHistory();

  useEffect(() => {
    const type = getUserType();
    if (type !== 'USER') {
      history.push('/login');
    }
  }, [history]);
  return (
    <>
      <Header />
      <Container>
        <Paper style={{ width: '900px', paddingTop: '30px' }}>
          <VerticalLayout center>
            <Space height={64} />
            <DarkGray24 bold>{`${getUserName()}님 마이페이지`}</DarkGray24>
            <Space height={89} />
            <div style={{ width: '900px', margin: 'auto' }}>
              <TabBasic
                items={[
                  { label: '돌봄 서비스 신청내역', value: 'applyInfo' },
                  { label: '회원정보 수정', value: 'userInfo' },
                ]}
                value={tab}
                onChange={setTab}
              />
            </div>
            {tab === 'applyInfo' && <MyApplyHistory />}
            {tab === 'userInfo' && <MyInfo />}
          </VerticalLayout>
          <Space height={244} />
        </Paper>
      </Container>
      <Footer />
    </>
  );
};
const Container = styled.div`
  display: flex;

  background-color: #f7f7f7;

  justify-content: center;

  padding-top: 30px;
  padding-bottom: 200px;
`;

const Paper = styled.div`
  width: 900px;

  border: solid 1px #e5e5e5;
  background: white;

  transition: all 0.33s linear;
`;
export default MyPageCustomer;
