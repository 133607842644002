import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray15, DarkGray18, LightGray11 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const ServiceInfo = props => {
  return (
    <VerticalLayout center>
      <DarkGray18 bold>히어로만의 특별한 돌봄 서비스</DarkGray18>
      <Space height={32} />
      <Point>Point 1</Point>
      <Space height={16} />
      <DarkGray15 bold>등하원 및 돌봄부터</DarkGray15>
      <DarkGray15>
        <span style={{ fontWeight: 'bold' }}>창의놀이까지 </span>
        한번에 해결해요!
      </DarkGray15>
      <Space height={8} />
      <LightGray11>
        돌봄 선생님이 놀이키트를 가지고 아이와 창의 놀이를 진행해요
      </LightGray11>
      <Space height={24} />
      <img
        style={{ width: '242px' }}
        src={
          require('asset/mobile/event/event_playkit/m-serviceInfo1.png').default
        }
      />
      <Space height={32} />
      <Point>Point 2</Point>
      <Space height={16} />
      <DarkGray15 bold>창의성 발달 뿐만 아니라 언어 발달까지</DarkGray15>
      <Space height={8} />
      <LightGray11>
        상호작용 질문을 통해서 아이가 다양한 표현을 할 수 있도록 도와줘요
      </LightGray11>
      <Space height={24} />

      <img
        style={{ width: '242px' }}
        src={
          require('asset/mobile/event/event_playkit/m-serviceInfo2.png').default
        }
      />
      <Space height={32} />
      <Point>Point 3</Point>
      <Space height={16} />
      <DarkGray15>
        놀이재료를
        <span style={{ fontWeight: 'bold' }}>미리 준비할 필요가 없어요</span>
      </DarkGray15>
      <Space height={8} />
      <LightGray11>
        개별 포장된 재료들을 창의놀이 시작 전 집으로 배송해드려요.
      </LightGray11>
      <Space height={24} />

      <img
        style={{ width: '242px' }}
        src={
          require('asset/mobile/event/event_playkit/m-serviceInfo3.png').default
        }
      />
      <Space height={64} />
    </VerticalLayout>
  );
};

const Point = styled.span`
  border: 2px solid #f69f30;
  border-radius: 25px;
  color: #f69f30;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  width: 76.5px;
  height: 20px;
  line-height: 14px;
`;

export default ServiceInfo;
