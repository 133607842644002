import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { HorizontalLayout, Push, Space, VerticalLayout } from 'atom/layout';
import { Checkbox } from 'atom/input';
import { Color } from 'constant';
import { useStores } from 'state';
import { Backdrop } from './Backdrop';
import { Gray20 } from 'atom/text';

interface ServiceSelectionPopupProps {
  onClose: () => void;
}
export const ServiceSelectionPopup = observer(
  ({ onClose }: ServiceSelectionPopupProps) => {
    const { customerStore } = useStores();

    return (
      <Backdrop onClose={onClose}>
        <Container>
          <Space height={24} />
          <Gray20 bold center>
            가사 서비스 선택
          </Gray20>
          <Space height={24} />
          <VerticalLayout
            center
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '140px',
              border: '1px solid #b7b7b7',
              borderRadius: '8px',
              marginLeft: '24px',
              marginRight: '24px',
            }}
          >
            <HorizontalLayout
              center
              style={{ width: '200px' }}
              onClick={() => (customerStore.form.cleaningDetail = 'cleaning')}
            >
              <Checkbox
                value={customerStore.form.cleaningDetail === 'cleaning'}
                onChange={x => {
                  if (x) {
                    customerStore.form.cleaningDetail = 'cleaning';
                  }
                }}
              />
              <Space width={16} />
              <div
                style={{
                  width: '100%',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color:
                    customerStore.form.cleaningDetail === 'cleaning'
                      ? '#f69f30'
                      : '#c0c0c0',
                }}
              >
                청소
              </div>
            </HorizontalLayout>

            <Space height={8} />
            <div
              style={{ width: '300px', height: '1px', background: '#ececec' }}
            />
            <Space height={8} />

            <HorizontalLayout
              center
              style={{ width: '200px' }}
              onClick={() => (customerStore.form.cleaningDetail = 'cooking')}
            >
              <Checkbox
                value={customerStore.form.cleaningDetail === 'cooking'}
                onChange={x => {
                  if (x) {
                    customerStore.form.cleaningDetail = 'cooking';
                  }
                }}
              />
              <Space width={16} />
              <div
                style={{
                  width: '100%',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color:
                    customerStore.form.cleaningDetail === 'cooking'
                      ? '#f69f30'
                      : '#c0c0c0',
                }}
              >
                요리
              </div>
            </HorizontalLayout>
          </VerticalLayout>
          <Space height={24} />

          <div
            style={{ color: '#484848', fontSize: '12px', textAlign: 'center' }}
          >
            * 청소와 요리 둘중 하나만 서비스 선택이 가능합니다.
          </div>
          <BottomButton onClick={onClose}>적용</BottomButton>
        </Container>
      </Backdrop>
    );
  },
);

const Container = styled.div`
  position: relative;
  width: 400px;
  height: 338px;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  position: absolute;
  bottom: 0px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
