import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, DarkGray32, DarkGray44, LightGray24 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const ServiceInfo = props => {
  return (
    <VerticalLayout center>
      <DarkGray44 bold>히어로만의 특별한 돌봄 서비스</DarkGray44>
      <Space height={58} />
      <Point>Point 1</Point>
      <Space height={48} />
      <DarkGray32>
        <span style={{ fontWeight: 'bold' }}>
          등하원 및 돌봄부터 창의놀이까지
        </span>
        한번에 해결해요!
      </DarkGray32>
      <Space height={12} />
      <LightGray24>
        돌봄 선생님이 놀이키트를 가지고 아이와 창의 놀이를 진행해요
      </LightGray24>
      <Space height={62} />
      <img
        style={{ width: '670px' }}
        src={require('asset/event/event_playkit/serviceInfo1.png').default}
      />
      <Space height={84} />
      <Point>Point 2</Point>
      <Space height={48} />
      <DarkGray32 bold>창의성 발달 뿐만 아니라 언어 발달까지</DarkGray32>
      <Space height={12} />
      <LightGray24>
        상호작용 질문을 통해서 아이가 다양한 표현을 할 수 있도록 도와줘요
      </LightGray24>
      <Space height={62} />

      <img
        src={require('asset/event/event_playkit/serviceInfo2.png').default}
      />
      <Space height={84} />
      <Point>Point 3</Point>
      <Space height={48} />
      <DarkGray32>
        놀이재료를
        <span style={{ fontWeight: 'bold' }}>미리 준비할 필요가 없어요</span>
      </DarkGray32>
      <Space height={12} />
      <LightGray24>
        개별 포장된 재료들을 창의놀이 시작 전 집으로 배송해드려요.
      </LightGray24>
      <Space height={62} />

      <img
        src={require('asset/event/event_playkit/serviceInfo3.png').default}
      />
      <Space height={84} />
    </VerticalLayout>
  );
};

const Point = styled.span`
  border: 2px solid #f69f30;
  border-radius: 25px;
  color: #f69f30;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  width: 187.43px;
  height: 49px;
`;

export default ServiceInfo;
