import { RoundButton } from 'atom/button';
import { Space } from 'atom/layout';
import { DarkGray32, DarkGray44, LightGray24, Primary32 } from 'atom/text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Step = ({ number, children }) => {
  return (
    <div style={{ display: 'flex', marginLeft: '10%', padding: '59px 0' }}>
      <Primary32 bold>{`STEP${number}`}</Primary32>
      <Space width={65} />
      {children}
    </div>
  );
};
const HowToDo = () => {
  const history = useHistory();
  return (
    <div
      style={{
        width: '100%',
        height: '1290px',
        background: '#fffbf7',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Space height={120} />
      <DarkGray44 bold>창의놀이 이용방법</DarkGray44>
      <Space height={80} />
      <Info>
        <Step number={1}>
          <DarkGray32>하단에 신청하기 버튼을 클릭합니다.</DarkGray32>
        </Step>
        <hr style={{ width: '80%', alignSelf: 'center' }} />
        <Step number={2}>
          <DarkGray32> 정기돌봄을 신청해주세요.</DarkGray32>
        </Step>
        <hr style={{ width: '80%', alignSelf: 'center' }} />
        <Step number={3}>
          <DarkGray32>
            쿠폰 코드 <span style={{ fontWeight: 'bold' }}>‘playkitsd'</span> 를
            적으면 참가가 완료됩니다.
          </DarkGray32>
        </Step>
        <hr style={{ width: '80%', alignSelf: 'center' }} />
        <Step number={4}>
          <DarkGray32>
            신청이 완료되면 히어로 센터에서 연락을 드립니다.
          </DarkGray32>
        </Step>
        <LightGray24
          style={{
            position: 'absolute',
            right: '10px',
            bottom: '-60px',
          }}
        >
          *3개월 이용 전 취소시 이벤트 혜택이 없어질 수 있습니다.{' '}
        </LightGray24>
      </Info>
      <Space height={140} />
      <RoundButton
        style={{
          width: '440px',
          height: '79px',
          fontSize: '32px',
          padding: 0,
        }}
        onClick={() => history.push('/service/apply/q1')}
      >
        서비스 신청하기
      </RoundButton>
    </div>
  );
};

const Info = styled.div`
  width: 1096px;
  height: 697px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  backdrop-filter: blur(4px);
  border-radius: 45px;
`;

export default HowToDo;
