import React, { useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useHistory, useLocation } from 'react-router-dom';

import { VerticalLayout, HorizontalLayout, Push, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import {
  Black15,
  Black23,
  White40,
  Black18,
  DarkGray16,
  Black25,
  DarkGray14,
} from 'atom/text';
import { Banner } from 'component/banner';
import { Header } from 'component-mobile/header';
import { Footer } from 'component-mobile/footer';
import { ConfirmPopup, AppDownloadPopup } from 'component-mobile/popup';
import { ReviewCard } from 'component-mobile/home/ReviewCard';
import { GuideSlide } from 'component-mobile/home/GuideSlide';
import { ActivityCard, SitterCard } from 'component-mobile/home';
import {
  SitterInterviewContents,
  ActivityContents,
  SitterReviewContents,
  GuideContents,
} from 'content';
import { URL } from 'constant';
import { useTopmost } from 'util/topmost';

import { ReactComponent as GoogleBtn } from 'asset/mobile/home/btn_google_store.svg';
import { ReactComponent as AppleBtn } from 'asset/mobile/home/btn_app_store.svg';

interface ArrowProp {
  type: string;
  onClick?: any;
}
const SitterInterviewArrow = ({ type, onClick }: ArrowProp) => {
  const url =
    type === 'prev'
      ? require('asset/mobile/home/slide_arrow_prev_round.svg').default
      : require('asset/mobile/home/slide_arrow_next_round.svg').default;
  return (
    <SitterInterviewArrowContainer onClick={onClick} position={type}>
      <img style={{ width: 50, height: 50 }} src={url} />
    </SitterInterviewArrowContainer>
  );
};
const GuideArrow = ({ type, onClick }: ArrowProp) => {
  const url =
    type === 'prev'
      ? require('asset/home/slide_back.png').default
      : require('asset/home/slide_front.png').default;
  return (
    <GuideArrowContainer onClick={onClick} position={type}>
      <img style={{ width: 19.2, height: 33 }} src={url} />
    </GuideArrowContainer>
  );
};

export const HomePage = ({}) => {
  const topmost = useTopmost();
  const history = useHistory();
  const { search } = useLocation();
  const p = new URLSearchParams(search || '');

  useEffect(() => {
    if (p.get('scroll')) {
      const id = p.get('scroll');
      const el = document.getElementById(id);
      if (el) {
        setTimeout(() => {
          el.scrollIntoView();
        }, 100);
      }
    }
  }, [p.get('scroll'), p.get('c')]);
  useEffect(() => {
    return;
    if (!localStorage.getItem('firstRun')) {
      const id = topmost.show(
        <AppDownloadPopup onClose={() => topmost.dismiss(id)} />,
      );
      localStorage.setItem('firstRun', 'true');
    }
  }, []);

  return (
    <>
      {/* <Space height={56} />
       */}
      <Header />
      <Banner index={1} />
      <Container center>
        <IntroImageWrap>
          <IntroWrap center>
            <Space height={25} />
            <Awards />
            <Space height={23} />

            <Black25 bold center>
              동네에서 찾는,
              <br />
              전문 놀이돌봄 선생님!
            </Black25>
            <DarkGray14>우리아이 믿고 맡기는, 우리동네 돌봄히어로</DarkGray14>
            <Space height={23} />

            <RoundButton
              style={{ width: '200px', padding: '0px' }}
              onClick={() => history.push('/service/apply')}
            >
              돌봄서비스 신청
            </RoundButton>
            <Space height={10} />
            <RoundButton
              variant="white"
              style={{ width: '200px', padding: '0px' }}
              onClick={() => history.push('/hero/apply')}
            >
              히어로 선생님 지원
            </RoundButton>
          </IntroWrap>
        </IntroImageWrap>
        {/* <Banner index={1} /> */}
        <Space height={58} />

        <Black23 bold center>
          우리동네 돌봄히어로를
          <br />
          믿을 수 있는 이유, 세가지!
        </Black23>
        <Space height={40} />

        <img
          style={{ width: '320px', height: 'auto', minHeight: '300px' }}
          src={require('asset/mobile/home/content1.png').default}
        />
        <Space height={50} />
        <img
          style={{ width: '320px', height: 'auto', minHeight: '300px' }}
          src={require('asset/mobile/home/content2.png').default}
        />
        <Space height={50} />
        <img
          style={{ width: '320px', height: 'auto', minHeight: '300px' }}
          src={require('asset/mobile/home/content3.png').default}
        />
        <Space height={45} />

        <GrayBackground>
          <Space height={90} />
          <Black23 bold center>
            우리 히어로 선생님들은, <br />
            이런 분들이랍니다!
          </Black23>
          <Space height={50} />
          <Black18 semiBold center className="highlight">
            까다로운 7단계 검증과정을 통한 선발!
          </Black18>
          <Space height={20} />
          <Black15 center>
            서류심사와 면접심사에 합격하신 분들을 대상으로
            <br />
            교육을 진행하며 신원 검증이 모두 완료된
            <br />
            선생님들만 활동이 가능해요
          </Black15>
          <Space height={30} />
          <img
            style={{ width: '320px', height: 'auto', minHeight: '300px' }}
            src={require('asset/mobile/home/content4.png').default}
          />
          <Space height={100} />

          <Black18 semiBold center className="highlight">
            보수교육과 피드백을 통한 꾸준한 성장 관리!
          </Black18>
          <Space height={20} />
          <Black15 center>
            선발 후 관리가 더 중요하기에 매달 보수교육을
            <br />
            진행하고 부모님으로부터 받은 피드백을 바탕으로
            <br />
            선생님 역량을 개선하고 있어요!
          </Black15>
          <Space height={50} />

          <div style={{ width: '100%' }}>
            <Slider variableWidth infinite={false} slidesToShow={1}>
              {ActivityContents.map((x, idx) => (
                <ActivityCard key={idx} data={x} />
              ))}
            </Slider>
          </div>
          <Space height={63} />

          <Black18 semiBold center className="highlight">
            아이들을 사랑하는 마음과
          </Black18>
          <Black18 semiBold center className="highlight">
            숙련된 노하우로 준비된 선생님!
          </Black18>
          <Space height={20} />
          <Black15 center>
            아이들의 신체적/정서적 안전을 지키는 것은 기본.
            <br />
            1000가지 놀이법을 활용하여
            <br />
            아이들과 행복한 시간을 보내요
          </Black15>
          <Space height={68} />

          <SitterInterviewSlideWrap>
            <Slider
              centerMode
              variableWidth
              slidesToShow={1}
              prevArrow={<SitterInterviewArrow type={'prev'} />}
              nextArrow={<SitterInterviewArrow type={'next'} />}
            >
              {SitterInterviewContents.map((x, idx) => (
                <SitterCard key={idx} data={x} />
              ))}
            </Slider>
          </SitterInterviewSlideWrap>
          <Space height={100} />
        </GrayBackground>
        <Space height={40} />

        <Black23 medium center>
          히어로를 만난 부모님들의 실제 후기
        </Black23>
        <Space height={60} />
        <SitterReviewSlideWrap>
          <Slider
            centerMode
            variableWidth
            autoplay
            autoplaySpeed={4000}
            infinite
          >
            {SitterReviewContents.map((x, idx) => (
              <ReviewCard key={idx} data={x} />
            ))}
          </Slider>
        </SitterReviewSlideWrap>

        <Space height={105} />
        <Black18 semiBold center className="highlight">
          우리동네 돌봄히어로!
        </Black18>
        <Black18 semiBold center className="highlight">
          추천할 수 밖에 없어요~
        </Black18>
        <Space height={30} />
        <VideoReview />
        <Space height={15} />
        <VideoReviewTextWrap>
          “육아 휴직이 끝나 만나게 된 우리동네 돌봄히어로,
          <br />
          우리 아이를 가장 잘 이해해주는 선생님 덕분에 현준이도
          <br />
          저도 하루하루가 행복해요. 선생님이 아예 등·하원부터
          <br />
          놀이 돌봄까지 해주시니까, 걱정 없이 복직에 성공했답니다.”
          <Space height={24} />
          <VideoReviewName>
            - 5살 현준이 엄마 <span>김혜원님</span>
          </VideoReviewName>
        </VideoReviewTextWrap>
        <Space height={46} />

        {/*}
        <GrayBackground>
          <Space height={56} />
          <Black15 center>
            우리동네 돌봄히어로,<br/>
            이렇게 이용하세요.
          </Black15>
          <Space height={14} />
          <Black23 bold id="guide">
            이용안내
          </Black23>
          <Space height={21} />
          <div style={{width: '100%', overflow: 'hidden'}}>
            <Slider
              dots
              fade
              slidesToShow={1}
              infinite={false}
              prevArrow={<GuideArrow type={'prev'}/>}
              nextArrow={<GuideArrow type={'next'}/>}
            >
              {GuideContents.map((x, idx) => (
                <GuideSlide
                  key={idx}
                  data={x}
                />
              ))}
            </Slider>
          </div>
          <Space height={26} />
          <HorizontalLayout>
            <BtnWrap
              //onClick={() => window.open(URL.AndroidApp, '_blank')}
              onClick={() => showConfirm('COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.')}
            >
              <GoogleStore />
            </BtnWrap>
            <Space width={10} />
            <BtnWrap
              //onClick={() => window.open(URL.IosApp, '_blank')}
              onClick={() => showConfirm('COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.')}
            >
              <AppStore />
            </BtnWrap>
          </HorizontalLayout>
          <Space height={56} />
        </GrayBackground>
              */}

        <Space height={24} />
        <GrayBackground>
          <Space height={48} />
          <SectionText>
            히어로와 함께 하는
            <br />
            협력기관
          </SectionText>
          <Space height={34} />
          <CompaniesContainer>
            <Slider dots slidesToShow={1}>
              <Companies2 />
              <Companies1 />
            </Slider>
          </CompaniesContainer>
          <Space height={48} />
        </GrayBackground>

        {/* <Banner index={3} /> */}
      </Container>
      <Footer />
    </>
  );
};

const IntroImageWrap = styled.div`
  position: relative;
  width: 100%;
  height: 490px;

  background: url(${require('asset/mobile/home/intro_background.jpg').default});
  background-size: cover;
  background-position-y: bottom;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 266px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;
const IntroWrap = styled(VerticalLayout)`
  position: relative;
`;
const Awards = styled.img.attrs({
  src: require('asset/home/awards.png').default,
})`
  width: 249px;
  height: 35px;
`;
const Container = styled(VerticalLayout)`
  width: 100%;

  overflow-x: hidden;
`;
const GrayBackground = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background: #f9f9f9;
  align-items: center;
`;
const SectionText = styled.div`
  font-size: 23px;
  font-weight: bold;
  text-align: center;

  line-height: 1.52;
  letter-spacing: -1.15px;
`;
const SitterInterviewSlideWrap = styled.div`
  overflow: hidden;

  .slick-slide {
    opacity: 0.5;
  }
  .slick-active {
    opacity: 1;
  }
`;
const VideoReview = styled.iframe.attrs({
  src: 'https://www.youtube.com/embed/MGS7_akEZ1o',
  frameBorder: 0,
})`
  width: 320px;
  height: 185px;

  border-radius: 6px;
  overflow: hidden;
`;
const VideoReviewTextWrap = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.58px;
  color: #484848;
`;
const VideoReviewName = styled.div`
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.58px;

  color: #434343;

  & > span {
    font-weight: 700;
  }
`;
const GoToTopFAB = styled.img.attrs({
  src: require('asset/mobile/home/top.svg').default,
  onClick: () => window.scrollTo(0, 0),
})`
  position: fixed;
  right: 0px;
  bottom: 70px;

  width: 80px;

  cursor: pointer;
`;

const FAB = styled.img.attrs({
  src: require('asset/mobile/home/kakao.png').default,
  onClick: () => window.open(URL.Kakao, '_blank'),
})`
  position: fixed;
  right: 0px;
  bottom: 0px;

  width: 80px;

  cursor: pointer;
`;
const BtnWrap = styled.div`
  z-index: 1;
`;
const GoogleStore = styled(GoogleBtn)``;
const AppStore = styled(AppleBtn)``;
const CompaniesContainer = styled.div`
  max-width: 320px;
  width: 320px;
  position: relative;

  overflow-x: hidden;

  padding-bottom: 40px;
`;
const Companies1 = styled.img.attrs({
  src: require('asset/mobile/home/companies.png').default,
})`
  align-self: center;

  width: calc(100% - 60px);
  max-width: 320px;
`;
const Companies2 = styled.img.attrs({
  src: require('asset/mobile/home/companies2.png').default,
})`
  align-self: center;

  width: calc(100% - 60px);
  max-width: 320px;
`;
const SitterInterviewArrowContainer = styled.div<any>`
  position: absolute;
  top: 50%;
  ${({ position }) =>
    position === 'prev'
      ? `left: calc(50% - 165px)`
      : ` right: calc(50% - 165px)`};
  transform: translateY(-50%);

  z-index: 10;
`;
const GuideArrowContainer = styled.div<any>`
  position: absolute;
  top: 150px;
  ${({ position }) => (position === 'prev' ? `left: 50px` : ` right: 50px`)};

  z-index: 10;
`;
const SitterReviewSlideWrap = styled.div`
  width: 100vw;
  overflow: hidden;

  .slick-active {
    opacity: 1;

    & .review-card {
      border: solid 1.5px #f69f30;
    }
  }
`;
