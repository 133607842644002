import React from 'react';
import styled from 'styled-components';

import {HorizontalLayout, Push, Space} from 'atom/layout';
import { SitterReviewContent } from 'content';
import { Color } from 'constant';

interface ReviewCardProps {
  data: SitterReviewContent;
  onClick?: () => void;
};
export const ReviewCard = ({
  data,
  onClick,
}: ReviewCardProps) => {
  return (
    <Container
      onClick={onClick}
      className={'review-card'}
    >
      <ImageWrap>
        <Image
          src={data.thumbnail}
        />
      </ImageWrap>
      <IntroTextWrap>
        <div>
          <img src={require('asset/common/star5.png').default} />
          <Space height={7} />
          <Title>{data.title}</Title>
          <Space height={5} />
          <p dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
        <HorizontalLayout center style={{justifyContent: 'space-between'}}>
          <NameText>
            {data.author}
          </NameText>
          <a href={data.url} target="_blank">
            <HorizontalLayout center>
              <ShowMoreText>
                더보기
              </ShowMoreText>
              <img src={require('asset/icon/arrow_right_orange.png').default} />
            </HorizontalLayout>
          </a>
        </HorizontalLayout>
      </IntroTextWrap>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 346px;
  height: 424px;
  overflow: hidden;

  background: white;
  border-radius: 10px;
  box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.16);

  margin: 23px;
`;
const ImageWrap = styled.div`
  width: 100%;
  height: 195px;
  
  background-color: #FFEDD5;
  
  overflow: hidden;
`
const Image = styled.img`
  width: 100%;
  height: 195px;

  object-fit: cover;
`;
const IntroTextWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  padding: 15px;

  font-size: 13px;
  font-weight: 300;

  line-height: 1.79;
  white-space: pre-line;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 17px;
  
  line-height: 1.65;
  letter-spacing: -0.85px;
  color: black;
`
const NameText = styled.div`
  font-size: 15px;
  font-weight: 700;

  line-height: 1.43;
  letter-spacing: -0.42px;
`;
const ShowMoreText = styled.div`
  margin-right: 10px;
  color: ${Color.Primary};

  font-size: 15px;
  font-weight: bold;
  
  letter-spacing: -0.75px;
`;
