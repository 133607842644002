import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import { Space } from 'atom/layout';
import { Company } from 'constant';
import { Black16, LightGray16, Gray20, Primary16 } from 'atom/text';
import { Title } from './style';
import { useMyTrainingSchedule } from 'state/hook';
import { getUserId } from 'util/token';
import { getDate, getTime } from 'util/index';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import { CircularProgress } from '@material-ui/core';

export const ApplyDone = observer(({}) => {
  const history = useHistory();

  const [geo, setGeo] = useState({ lat: 37.5482207, lng: 127.0529857 });
  const {
    data: {
      fee,
      host_start_time,
      host_end_time,
      supplies,
      note,
      center_name,
      center_address,
      center_guide,
    },
    loading,
  } = useMyTrainingSchedule({ userId: Number(getUserId()) });

  useEffect(() => {
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(center_address, (result, status) => {
      if (status === kakao.maps.services.Status.OK) {
        setGeo({
          lat: Number(result[0].y),
          lng: Number(result[0].x),
        });
      }
    });
  }, [center_address]);
  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <Title>교육 예약이 완료되었습니다!</Title>
      <Space height={18} />
      <Gray20 bold>
        교육담당 매니저가
        <br />
        다시한번 연락드리겠습니다.
      </Gray20>
      <Space height={40} />
      <hr />
      <Space height={22} />

      <NoticeItem
        title="교육일정"
        content={`${getDate(host_start_time)} ${getTime(
          host_start_time,
        )} ~ ${getTime(host_end_time)}`}
        comment="*교육에 불참하게 될 시, 최소 하루 전에 미리 연락주세요."
      />
      <hr />
      <Space height={30} />

      <NoticeItem
        title="교육비"
        content={`${
          fee === 0
            ? '무료'
            : `${fee.toLocaleString()}원\n계좌 : ${Company.BankAccount}`
        }`}
        comment={
          fee === 0 ? '' : '*교육 하루 전까지는 꼭 입금을 한 뒤 참석해주세요.'
        }
      />

      {/*2022.4월부터 교육비 무료로 변경됨 -> 2022.11.30부터 다시 유료화.
          // 이전 컴포넌트 주석처리
          * <NoticeItem
          title="교육비"
          content={'교육비 : 3만원\n계좌 : ' + Company.BankAccount}
          comment="*교육 하루 전까지는 꼭 입금을 한 뒤 참석해주세요."
        /> */}
      <hr />
      <Space height={30} />

      <NoticeItem title="준비물" content={supplies} />
      <hr />
      <Space height={30} />

      <NoticeItem title="교육당일 안내" content={note} />
      <hr />
      <Space height={30} />

      <NoticeItem
        title="교육장소"
        content={`${center_name} \n ${center_address}`}
        comment={center_guide}
      />
      <Map center={geo} style={{ width: '100%', height: '200px' }} level={5}>
        <MapMarker position={geo} />
      </Map>
      <Space height={54} />
      <RoundButton
        style={{ width: '100%' }}
        onClick={() => history.replace('/')}
      >
        홈으로 가기
      </RoundButton>
    </>
  );
});

interface NoticeItemProps {
  title: string;
  content?: string;
  comment?: string;
}
const NoticeItem = ({ title, content, comment }: NoticeItemProps) => {
  return (
    <>
      <Primary16 bold>{title.replace(/\\n/g, '\n')}</Primary16>
      <Space height={12} />
      {content && <Black16 semiBold>{content.replace(/\\n/g, '\n')}</Black16>}
      {comment && (
        <>
          <Space height={12} />
          <LightGray16 semiBold>{comment.replace(/\\n/g, '\n')}</LightGray16>
        </>
      )}
      <Space height={30} />
    </>
  );
};
