import { Space } from 'atom/layout';
import { DarkGray32, DarkGray40 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const LimitedCount = props => {
  function leftPad(value: number) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  function toStringByFormatting() {
    const source = new Date();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());
    const hour = leftPad(source.getHours());
    return `${month}월 ${day}일 ${hour}시`;
  }

  return (
    <>
      <Space height={90} />
      <Container>
        <DarkGray32 bold>남은 선착순 인원</DarkGray32>
        <Space width={20} />
        <NumberBox>1</NumberBox>
        <NumberBox>0</NumberBox>
        <DarkGray40 bold>명</DarkGray40>
        <ComplitedSticker />
      </Container>
      <Space height={50} />
      <p style={{ fontSize: '28px', color: '#ED584E' }}>
        {toStringByFormatting()} 기준
      </p>
    </>
  );
};
const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;
const NumberBox = styled.span`
  display: inline-block;
  width: 98px;
  height: 98px;
  border-radius: 10px;
  border: 2px solid #696969;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  font-size: 40px;
  margin-right: 20px;
  line-height: 93px;
`;
const ComplitedSticker = styled.img.attrs({
  src: require('asset/event/event_playkit/completed_mark.png').default,
})`
  position: absolute;
  right: -50px;
  top: -80px;
`;
export default LimitedCount;
