import { RoundButton } from 'atom/button';
import { Space } from 'atom/layout';
import {
  DarkGray10,
  DarkGray14,
  DarkGray18,
  DarkGray20,
  LightGray12,
} from 'atom/text';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Overlay, SliderWithDots } from 'atom/display';
import { useTopmost } from 'util/index';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Pagination } from 'swiper';
import { WorkBasicPage } from 'page-mobile/sitter/works/WorkBasicPage';
import { WorkPremiumPage } from 'page-mobile/sitter/works/WorkPremiumPage';
import { WorkExtraPage } from 'page-mobile/sitter/works/WorkExtraPage';
import { AdditionalServicePopup } from './AdditionalServicePopup';

export const HeroWorks = () => {
  const [serviceSheet, setServiceSheet] = useState<string | null>();
  const topmost = useTopmost();
  SwiperCore.use([Pagination]);

  const onClickBasicService = () => {
    setServiceSheet('basic');
  };
  const onClickPremiumService = () => {
    setServiceSheet('premium');
  };
  const onClickExtraService = () => {
    setServiceSheet('extra');
  };

  // const onClickBasicService = () => {
  //   const id = topmost.show(
  //     <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
  //       <WorkBasicPage sheet onClose={() => topmost.dismiss(id)} />
  //     </Overlay>,
  //   );
  // };

  // const onClickPremiumService = () => {
  //   const id = topmost.show(
  //     <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
  //       <WorkPremiumPage sheet onClose={() => topmost.dismiss(id)} />
  //     </Overlay>,
  //   );
  // };
  // const onClickExtraService = () => {
  //   const id = topmost.show(
  //     <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
  //       <WorkExtraPage sheet onClose={() => topmost.dismiss(id)} />
  //     </Overlay>,
  //   );
  // };

  return (
    <div style={{ textAlign: 'center' }}>
      <DarkGray18 style={{ fontWeight: 700 }}>
        아이돌봄 선생님은 어떤일을 하나요?
      </DarkGray18>
      <Space height={18} />
      <LightGray12>
        선생님이 원하는 업무만 선택해서 일하실 수 있어요!
      </LightGray12>
      <Space height={10} />
      <div
        style={{
          display: 'block',
          maxWidth: '100vw',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <StyledSwiper
          slidesPerView={1}
          spaceBetween={50}
          pagination={{ clickable: true }}
          className="mySwiper"
        >
          <SwiperSlide>
            {' '}
            <ImgContainer>
              <img
                src={require('asset/event/event_sd/work1_bg.jpg').default}
                style={{ width: '248px' }}
              />
              <ContentBox>
                <Space height={57} />
                <DarkGray14
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    marginTop: '25px',
                  }}
                >
                  <span style={{ color: '#F69F30' }}>아이 일상을 돕는</span>{' '}
                  등하원 및 놀이돌봄
                </DarkGray14>
                <Space height={16} />
                <img
                  src={require('asset/event/event_sd/work1.png').default}
                  style={{ width: '212px' }}
                />
                <DarkGray10 style={{ padding: '20px' }}>
                  · 선생님이 집으로 방문하여 아이의 등하원 및<br /> 놀이돌봄을
                  진행하여 안전한 케어를 도와줘요.
                  <br />
                  <br />· 실내 놀이 / 야외 놀이 / 책 읽기 / 목욕 /<br />
                  숙제지도 등 전반적인 아이돌봄을 진행해요.
                </DarkGray10>
                <RoundButton
                  style={{
                    width: '195px',
                    height: '32px',
                    fontSize: '12px',
                    padding: 0,
                  }}
                  variant="outlined"
                  onClick={onClickBasicService}
                >
                  상세 정보 보기
                </RoundButton>
              </ContentBox>
              <PayBox>시급 10,000원 ~ </PayBox>
            </ImgContainer>
          </SwiperSlide>
          <SwiperSlide>
            {' '}
            <ImgContainer>
              <img
                src={require('asset/event/event_sd/work2_bg.jpg').default}
                style={{ width: '248px' }}
              />
              <ContentBox>
                <Space height={57} />
                <DarkGray14
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    marginTop: '25px',
                  }}
                >
                  <span style={{ color: '#F69F30' }}>
                    놀이로 찾는 아이 재능
                  </span>{' '}
                  창의놀이
                </DarkGray14>
                <Space height={16} />
                <img
                  src={require('asset/event/event_sd/work2.png').default}
                  style={{ width: '212px' }}
                />
                <DarkGray10 style={{ padding: '20px' }}>
                  · 매주 다양한 히어로 놀이를 진행하여 <br /> 아이에게 다양한
                  경험을 제공해요.
                  <br />
                  <br />· 예술가 / 발명가 / 과학자 / 자유놀이 등 <br />
                  100여가지의 다양한 놀이를 진행해요.
                </DarkGray10>
                <RoundButton
                  style={{
                    width: '195px',
                    height: '32px',
                    fontSize: '12px',
                    padding: 0,
                  }}
                  variant="outlined"
                  onClick={onClickPremiumService}
                >
                  상세 정보 보기
                </RoundButton>
              </ContentBox>
              <PayBox>시급 12,000원 ~ </PayBox>
            </ImgContainer>
          </SwiperSlide>
          {/* <SwiperSlide>
            {' '}
            <ImgContainer>
              <img
                src={require('asset/event/event_sd/work2_bg.jpg').default}
                style={{ width: '248px' }}
              />
              <ContentBox>
                <Space height={57} />
                <DarkGray14
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    marginTop: '25px',
                  }}
                >
                  <span style={{ color: '#F69F30' }}>쾌적한 육아환경</span>{' '}
                  가사서비스
                </DarkGray14>
                <Space height={16} />
                <img
                  src={require('asset/event/event_sd/work3.png').default}
                  style={{ width: '212px' }}
                />
                <DarkGray10 style={{ padding: '20px' }}>
                  · 아이가 없을 때 가정으로 미리 방문해 쾌적한 <br /> 돌봄이
                  이루어질 수 있도록 도와줘요.
                  <br />
                  <br />· 선생님의 안전을 위해 아이와 관련된 가사 <br />
                  or 요리 중 한 가지 서비스만 진행해요.
                </DarkGray10>
                <RoundButton
                  style={{
                    width: '195px',
                    height: '32px',
                    fontSize: '12px',
                    padding: 0,
                  }}
                  variant="outlined"
                  onClick={onClickExtraService}
                >
                  상세 정보 보기
                </RoundButton>
              </ContentBox>
              <PayBox>시급 12,000원 ~ </PayBox>
            </ImgContainer>
          </SwiperSlide> */}
        </StyledSwiper>
      </div>
      <AdditionalServicePopup
        show={!!serviceSheet}
        type={serviceSheet as any}
        onClickApply={() => setServiceSheet(null)}
        onClose={() => setServiceSheet(null)}
      />
    </div>
  );
};

const ImgContainer = styled.div`
  position: relative;
  margin-right: 35px;
  margin-left: 35px;
`;

const ContentBox = styled.div`
  position: absolute;
  top: 0;

  left: 50%;
  transform: translateX(-50%);
  width: 248px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PayBox = styled.div`
  position: absolute;
  width: auto;
  height: 22px;
  padding: 3px;
  right: 40px;
  top: 45px;

  font-weight: 700;
  font-size: 8px;

  border: 1px solid #ff9030;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ff9030;
`;
const StyledSwiper = styled(Swiper)`
margin-top:40px;
  .swiper-pagination-bullet-active {
    background-color: orange;
  }
  
  .swiper-container {
    
    
  }
  .swiper-slide-active{
    position: relative !important;
    height: 500px;
  }
  .swiper-pagination-bullets {
    position: absolute !important;
    left: 0px !important;
    bottom:20px !important;
    
    z-index:999;
`;
