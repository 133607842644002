import React, { useState } from 'react';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';
import { TextField } from '@material-ui/core';

import { Black16 } from 'atom/text';
import { Space, Push, HorizontalLayout } from 'atom/layout';
import { Color } from 'constant';
import { Backdrop } from './Backdrop';

enum AddressStep {
  Search,
  Details,
}

interface AddressPopupProps {
  value: string;
  initialDetailAddress?: string;
  restrictToSeoul?: boolean; // 서울시만 입력 가능하도록 제한 여부
  onSubmit: (x: string, y: string) => void;
  onClose: () => void;
}
export const AddressPopup = ({
  value,
  initialDetailAddress,
  restrictToSeoul = true,
  onSubmit,
  onClose,
}: AddressPopupProps) => {
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState(
    initialDetailAddress || '',
  );
  const [step, setStep] = useState(AddressStep.Search);
  const [showBalloon, setShowBalloon] = useState(false);

  const onComplete = data => {
    // https://github.com/bernard-kms/react-daum-postcode
    let fullAddress = data.roadAddress;
    let extraAddress = '';

    if (data.bname !== '') {
      extraAddress += data.bname;
    }
    if (data.buildingName !== '') {
      extraAddress +=
        extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
    }
    fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';

    // if (data.addressType === 'R') {
    //   if (data.bname !== '') {
    //     extraAddress += data.bname;
    //   }
    //   if (data.buildingName !== '') {
    //     extraAddress +=
    //       extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
    //   }
    //   fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    // }

    if (restrictToSeoul && !fullAddress.startsWith('서울')) {
      showConfirm('현재 서울시에서만 서비스 가능합니다.');
      return;
    }

    setAddress(fullAddress);
    setStep(AddressStep.Details);
  };
  const onAdd = () => {
    if (detailAddress.length === 0) {
      setShowBalloon(true);
      return;
    }

    onSubmit(address, detailAddress);
    onClose();
  };

  return (
    <Backdrop onClose={onClose}>
      <Container
        style={{ width: step === AddressStep.Search ? '480px' : '400px' }}
        onClick={e => e.stopPropagation()}
      >
        <CloseButton onClick={onClose} />
        {step === AddressStep.Search && (
          <>
            <DaumPostcode
              style={{ height: '470px', borderRadius: '10px' }}
              onComplete={onComplete}
            />
            <BottomButton onClick={onClose}>취소</BottomButton>
          </>
        )}
        {step === AddressStep.Details && (
          <>
            <div style={{ padding: '30px' }}>
              <HorizontalLayout>
                <Black16 bold>주소 추가</Black16>
                <Push />
              </HorizontalLayout>
              <Space height={20} />
              <TextField
                variant="outlined"
                style={{ width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <img
                      style={{ width: '16px', marginRight: '12px' }}
                      src={require('asset/icon/flag.svg').default}
                    />
                  ),
                }}
                inputProps={{ readOnly: true }}
                value={address}
              />
              <Space height={10} />
              <TextField
                variant="outlined"
                placeholder="상세주소를 입력해주세요."
                style={{ width: '100%' }}
                inputProps={{ maxLength: 32 }}
                value={detailAddress}
                onChange={e => setDetailAddress(e.target.value)}
              />
            </div>
            <BottomButton onClick={onAdd}>주소 추가</BottomButton>
            {showBalloon && (
              <InputDetailBalloon onClick={() => setShowBalloon(false)} />
            )}
          </>
        )}
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  position: relative;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  transform: translateZ(0);
  will-change: transform;

  --animate-duration: 0.4s;

  input {
    font-size: 12px !important;
  }
`;
const CloseButton = styled.img.attrs({
  src: require('asset/common/close.svg').default,
})`
  position: absolute;
  right: 32px;
  top: 24px;
  width: 15px;
  height: 15px;

  cursor: pointer;
`;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
const InputDetailBalloon = styled.img.attrs({
  src: require('asset/common/input_address.svg').default,
})`
  position: absolute;
  bottom: 40px;
  left: calc(50% - 115px);
  width: 234px;
`;
