import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { VerticalLayout, Space, HorizontalLayout, Push } from 'atom/layout';
import { RoundButton } from 'atom/button';
import {
  Black14,
  LightGray12,
  LightGray16,
  Gray25,
  White24,
  Gray24,
  Gray12,
  Gray16,
  Gray28,
  Gray18,
  Primary18,
  LightLightGray12,
  LightGray10,
  DarkGray16,
  White16,
  LightGray14,
  Primary24,
  DarkGray14,
  DarkGray18,
  DarkGray24,
} from 'atom/text';
import { ImageBox } from 'atom/image';
import { FaqAccordianItem } from 'component/faq';
import {
  PlayKitPackageTable,
  PremiumServiceHourlyPricingTable,
  PremiumServiceMinTable,
} from 'component/customer';
import { useFaq } from 'state/hook';
import { useIsMobile } from 'util/index';
import { Tab } from 'component/tab';
import { useRef } from 'react';
import { useEffect } from 'react';

interface IntroPremiumPageProps {
  sheet?: boolean; // 튀어나온 시트 모드인지
  injectedHistory?: any;
  isScrollTo?: boolean; //서비스신청> 추가서비스 > 자세히보기 > 2-4세 탭으로 스크롤 할경우
  onClickApply?: () => void;
  onClose?: () => void;
}
export const IntroPremiumPage = ({
  sheet,
  injectedHistory,
  isScrollTo,
  onClickApply,
  onClose,
}: IntroPremiumPageProps) => {
  const isMobile = useIsMobile(true);
  const history = useHistory() || injectedHistory;
  const [tab, setTab] = useState<string>('24');
  const faq = useFaq('premium');
  const isApplying =
    window.location.href.includes('apply') ||
    window.location.href.includes('event_sd'); // 지원 중 상세보기인지, 이벤트 페이지인지
  const tabRef = useRef(null);

  useEffect(() => {
    if (isScrollTo) {
      tabRef.current.scrollIntoView();
    }
  }, []);
  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/customer/service/premium/top.jpg').default}
          imageStyle={{
            imageRendering: '-webkit-optimize-contrast',
            filter: 'brightness(0.8)',
          }}
          minHeight={isMobile ? 240 : 380}
          maxHeight={isMobile ? 240 : 380}
        >
          <Sticker>
            <White16
              bold
              style={{
                position: 'absolute',
                right: '13px',
                top: '16px',
              }}
            >
              추천연령
              <br />
              4세 이상
            </White16>
          </Sticker>
          <Space height={isMobile ? 40 : 126} />
          <HorizontalLayout>
            <Space width={isMobile ? 40 : 100} />
            <VerticalLayout>
              <White24 bold style={{ fontSize: '28px' }}>
                놀이로 찾는 아이의 재능
                <br />
                <span style={{ color: '#f69F30' }}>창의 놀이 패키지</span>
              </White24>
              {!isApplying && (
                <>
                  <Space height={isMobile ? 32 : 52} />
                  <RoundButton
                    variant="primary"
                    style={{ padding: '0px 22px' }}
                    onClick={() => history.push('/service/apply')}
                  >
                    서비스 신청하기
                  </RoundButton>
                </>
              )}
            </VerticalLayout>
          </HorizontalLayout>
        </ImageBox>
        <VerticalLayout center style={{ width: '100%', background: '#F6F6F6' }}>
          <Space height={40} />
          <LightGray14>아이가 자라나는 특별한 시간</LightGray14>
          <Space height={10} />
          <Primary24 bold>창의놀이 패키지</Primary24>
          <Space height={32} />
          <img
            src={
              require('asset/customer/service/premium/playWithChild.svg')
                .default
            }
          />
          <Space height={24} />
          <div
            style={{
              padding: '24px 16px',
              background: '#fff',
              borderRadius: '16px',
              border: '1px solid #D9d9d9d9',
            }}
          >
            <DarkGray14 center>
              우리동네 돌봄히어로에서 자체 제작한 <br />
              창의 놀이는 답이 정해져 있지 않습니다.
              <br />
              베테랑 히어로선생님들의 상호작용을 통해
              <br />
              아이들이 스스로 답을 찾아가고
              <br />
              아이들의 생각을 확장할 수 있도록 도와줍니다.
            </DarkGray14>
          </div>
          <Space height={40} />
        </VerticalLayout>
        <VerticalLayout
          center
          style={{ width: '100%', background: '#fff', position: 'relative' }}
        >
          <Space height={isMobile ? 10 : 75} />
          {/* <SVG
            src={
              require('asset/customer/service/premium/intro_sizeup.svg').default
            }
            style={{
              maxWidth: '100%',
              overflow: 'visible',
              transform: 'translateX(-3%)',
            }}
          /> */}
          <LightGray14>돌봄시간을 즐겁게</LightGray14>
          <Primary24 bold center>
            돌봄 부터 창의 놀이까지 <br />
            더욱 풍성한 돌봄시간!
          </Primary24>
          <Space height={isMobile ? 10 : 50} />
          <SVG
            src={
              require('asset/customer/service/premium/introText.svg').default
            }
          />
          <Space height={24} />
          <img
            src={
              require('asset/customer/service/premium/intro_cards.png').default
            }
            style={{
              imageRendering: '-webkit-optimize-contrast',
              width: isMobile ? 'calc(100% - 50px)' : '500px',
            }}
          />
          <Space height={40} />
          <DarkGray18 center>
            우리동네 돌봄히어로에서는
            <br />
            <span style={{ fontWeight: 'bold', color: '#F69F30' }}>
              아이돌봄 부터 창의놀이까지
            </span>
            <br />
            한번에 다 가능해요!
          </DarkGray18>
          <Space height={16} />
          <LightGray14>
            * 창의 놀이 서비스는 기본 돌봄시간 안에 진행됩니다.
            <br /> 예) 기본 놀이돌봄 3시간(창의 놀이시간 포함)
          </LightGray14>
          <Space height={40} />
        </VerticalLayout>

        <VerticalLayout center style={{ width: '100%', background: '#F6F6F6' }}>
          <Space height={40} />
          <DarkGray24 bold center>
            창의놀이 패키지로
            <br />
            아이의 호기심을 자극해봐요!
          </DarkGray24>
          <Space height={12} />
          <img
            style={{
              width: '380px',
            }}
            src={require('asset/customer/service/premium/heroPlay.png').default}
          />
          <Space height={62} />

          <HorizontalLayout center>
            <img
              src={require('asset/customer/service/premium/artist.svg').default}
              style={{ width: '32px', marginRight: '8px' }}
            />
            <Gray18 bold>미래의 피카소 예술가 놀이</Gray18>
          </HorizontalLayout>
          <Space height={16} />
          <DarkGray14 center>
            다양한 색감 재료로 아이들의 창의성과 <br />
            자기표현을 도와주는 놀이예요.
            <br />
            색채 표현법을 배우고 <br />
            소근육 조작 능력을 발달시키는 예술가 놀이로
            <br />
            우리 아이의 잠재워진 상상력을 깨워 보아요!
          </DarkGray14>
          <Space height={24} />

          <img
            src={require('asset/customer/service/premium/58_2.png').default}
            style={{
              width: isMobile ? '240px' : '314px',
              imageRendering: '-webkit-optimize-contrast',
            }}
          />
          <Space height={40} />

          <HorizontalLayout center>
            <img
              src={
                require('asset/customer/service/premium/inventor.svg').default
              }
              style={{ width: '32px', marginRight: '8px' }}
            />
            <Gray18 bold>미래의 에디슨 발명가 놀이</Gray18>
          </HorizontalLayout>
          <Space height={16} />
          <DarkGray14 center>
            재활용 재료를 활용하여 발명품을 만드는 놀이에요.
            <br />
            사고의 유연성을 기르고 문제해결능력을 향상시키는
            <br />
            발명가 놀이로 우리 아이의 자기주도성을 발현시켜 보아요!
          </DarkGray14>
          <Space height={24} />

          <img
            src={require('asset/customer/service/premium/58_3.png').default}
            style={{
              width: isMobile ? '240px' : '314px',
              imageRendering: '-webkit-optimize-contrast',
            }}
          />
          <Space height={40} />

          <HorizontalLayout center>
            <img
              src={
                require('asset/customer/service/premium/scientist.svg').default
              }
              style={{ width: '32px', marginRight: '8px' }}
            />
            <Gray16 bold>미래의 아이슈타인 과학자 놀이</Gray16>
          </HorizontalLayout>
          <Space height={16} />
          <DarkGray14 center>
            다양한 재료들을 활용하여 과학원리를 배우는 놀이에요.
            <br />
            실험을 통해 원리를 직접 추론해보며,
            <br />
            다양한 실험을 통해 우리아이 탐구력을 향상시켜 보아요!
          </DarkGray14>
          <Space height={24} />

          <img
            src={require('asset/customer/service/premium/play3.png').default}
            style={{
              width: isMobile ? '240px' : '314px',
              imageRendering: '-webkit-optimize-contrast',
            }}
          />
          <Space height={40} />

          <HorizontalLayout center>
            <img
              src={
                require('asset/customer/service/premium/freePlay.svg').default
              }
              style={{ width: '32px', marginRight: '8px' }}
            />
            <Gray16 bold>상상을 펼치는 자유 놀이</Gray16>
          </HorizontalLayout>
          <Space height={16} />
          <DarkGray14 center>
            다양한 재료들을 활용하여 자유롭게 표현해보는 놀이에요.
            <br />
            책을 읽고 스토리와 연관된 다양한 질문들을 통해
            <br />
            생각을 확장시켜나가며 표현력과 창의력을 증진시켜요.
          </DarkGray14>
          <Space height={16} />

          <img
            src={require('asset/customer/service/premium/play4.png').default}
            style={{
              width: isMobile ? '240px' : '314px',
              imageRendering: '-webkit-optimize-contrast',
            }}
          />
          <Space height={40} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: 'rgba(246, 159, 48, 0.04)' }}
        >
          {/* <SVG
            src={require('asset/customer/service/premium/intro2.svg').default}
            style={{ width: '100%', overflow: 'visible', marginTop: '-16px' }}
          /> */}
          <Space height={40} />
          <Gray24 center bold>
            창의 놀이 <br /> 어떻게 진행 되나요?
          </Gray24>
          <Space height={10} />
          <LightGray14 center>
            놀이키트를 활용하여 정해진 시간에 놀이가 진행돼요!
          </LightGray14>
          <Space height={62} />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: isMobile ? 'center' : 'start',
              justifyContent: 'center',
              marginTop: '20px',
              marginLeft: '5px',
              position: 'relative',
            }}
          >
            {isMobile ? (
              <SVG
                src={
                  require('asset/customer/service/premium/playFlow_mobile.svg')
                    .default
                }
                style={{
                  width: '58px',
                  overflow: 'visible',
                  position: 'absolute',
                  top: 0,
                  left: '5px',
                }}
              />
            ) : (
              <SVG
                src={
                  require('asset/customer/service/premium/playFlow.svg').default
                }
                style={{
                  width: '80px',
                  overflow: 'visible',
                }}
              />
            )}
            <VerticalLayout
              center
              style={{ transform: 'translate(25px,-5px)' }}
            >
              <Space height={3} />
              <DarkGray16 bold>재료탐색으로 아이의 흥미를 유발해요.</DarkGray16>
              <Space height={9} />
              <LightGray12>
                스토리텔링과 다양한 질문으로 아이들의 흥미를
                <br />
                이끌어내고 재료를 탐색하며 재료와 더욱 친해져요.
              </LightGray12>
              <Space height={13} />
              <img
                src={
                  require('asset/customer/service/premium/playFlow_new_1.png')
                    .default
                }
                style={{
                  width: isMobile ? '240px' : '278px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <Space height={isMobile ? 42 : 32} />

              <DarkGray16 bold>놀이키트를 활용하여 놀이를 진행해요.</DarkGray16>
              <Space height={9} />
              <LightGray12>
                놀이를 진행할 때마다 선생님과의 상호작용을 통해 <br />
                생각을 확장시켜 나가요.
              </LightGray12>
              <Space height={13} />
              <img
                src={
                  require('asset/customer/service/premium/playFlow_2.png')
                    .default
                }
                style={{
                  width: isMobile ? '240px' : '278px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />
              <Space height={isMobile ? 45 : 28} />

              <DarkGray16 bold>
                {isMobile
                  ? '완성품으로 놀이를 하고 선생님과\n함께 정리해요.'
                  : '완성품으로 놀이를 하고 선생님과 함께 정리해요.'}
              </DarkGray16>
              <LightGray12>
                놀이를 진행할 때마다 선생님과의 상호작용을 통해
                <br />
                생각을 확장시켜 나가요.
              </LightGray12>
              <Space height={13} />
              <img
                src={
                  require('asset/customer/service/premium/playFlow_3.png')
                    .default
                }
                style={{
                  width: isMobile ? '240px' : '278px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
              />

              <Space height={40} />
            </VerticalLayout>
          </div>
        </VerticalLayout>
        <VerticalLayout center style={{ width: '100%', background: '#F6F6F6' }}>
          <Space height={40} />
          <DarkGray24 bold center>
            놀이 키트는
            <br />
            <span style={{ color: '#F69F30' }}>어떻게 구성</span> 되어 있나요?
          </DarkGray24>
          <Space height={12} />
          <LightGray14 center>
            매달 달라지는 커리큘럼으로 아이가 심심할 틈이 없도록
            <br />
            다양한 주제의 재료를 혼합하여 보내드립니다.
          </LightGray14>
          <Space height={40} />
          <img
            src={require('asset/customer/service/premium/playkit.png').default}
            style={{
              width: isMobile ? '240px' : '278px',
              imageRendering: '-webkit-optimize-contrast',
            }}
          />
          <Space height={40} />

          <div
            style={{
              padding: '24px 16px',
              background: '#fff',
              borderRadius: '16px',
              border: '1px solid #D9d9d9d9',
            }}
          >
            <DarkGray14 style={{ lineHeight: '180%' }}>
              • 커리큘럼, 놀이 도안, 놀이 재료로 구성되어있습니다.
              <br />• 각 놀이에 따른 놀이키트는 아이 가정으로 배송됩니다.
            </DarkGray14>
          </div>
          <Space height={80} />
          <DarkGray14 center>이용가이드</DarkGray14>
          <DarkGray24 bold center>
            간편하게 신청하는
            <br />
            창의 놀이
          </DarkGray24>
          <Space height={40} />
          <SVG
            src={
              require('asset/customer/service/premium/howToUsePlayKits.svg')
                .default
            }
          />
          <Space height={40} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', padding: '', background: 'white' }}
        >
          <Space height={40} />
          <Gray24 bold center>
            창의놀이 패키지 <br />
            비용 안내
          </Gray24>
          <Space height={40} />
          <PlayKitPackageTable width="320px" />
          <Space height={30} />
          <LightLightGray12 style={{ width: '100%', maxWidth: '320px' }}>
            • 창의놀이는 비정기 돌봄 신청시에는 이용이 불가능합니다.
            <br />
            • 창의놀이는 기본돌봄 시간에 포함되어 진행됩니다.
            <br />
            • 패키지비용은 기본 돌봄 이용 이외 별도 비용으로,
            <br />
            &nbsp;&nbsp;놀이 재료 및 서비스 추가 비용 모두 포함된 금액입니다.
            <br />
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              • 서비스 시작 시 최초 1회 필수 준비물 비용 30,000원이 부과됩니다.
            </span>
          </LightLightGray12>

          <Space height={40} />

          <RoundButton
            variant="primary"
            style={
              isMobile
                ? {
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    bottom: '20px',
                  }
                : {
                    width: '100%',
                    borderRadius: '0px',
                    position: 'absolute',
                    bottom: '0px',
                  }
            }
            onClick={() => {
              if (onClickApply) onClickApply();
              else if (sheet) onClose?.();
              else history.push('/service/apply');
            }}
          >
            서비스 신청하기
          </RoundButton>
          <Space height={52} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: '#f9f9f9', padding: '0px 40px' }}
        >
          <Space height={64} />
          <Gray24 bold center>
            Q & A
          </Gray24>
          <Space height={32} />
          <FaqContainer>
            {faq.map((x, idx) => (
              <FaqAccordianItem key={idx} data={x} />
            ))}
          </FaqContainer>
          <Space height={32} />
          <RoundButton
            variant="primary"
            style={
              isMobile
                ? {
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    bottom: '20px',
                  }
                : {
                    width: '100%',
                    borderRadius: '0px',
                    position: 'absolute',
                    bottom: '0px',
                  }
            }
            onClick={() => {
              if (onClickApply) onClickApply();
              else if (sheet) onClose?.();
              else history.push('/service/apply');
            }}
          >
            {isApplying ? '확인' : '서비스 신청하기'}
          </RoundButton>
          <Space height={32} />
        </VerticalLayout>
      </Container>
    </>
  );
};

const Sticker = styled.div`
  width: 82px;
  height: 82px;
  background: #f69f30;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  bottom: 20px;
`;
const Container = styled(VerticalLayout)`
  width: 100%;
  position: static;

  background: white;

  overflow-x: hidden;
  border-radius: 6px;
`;

const FaqContainer = styled.div`
  width: 100%;

  background: #f9f9f9;

  padding: 34px 18px;

  .MuiPaper-root.MuiAccordion-root {
    padding: 5px 5px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 32px !important;
  }
  .MuiAccordionSummary-root {
    font-size: 15px !important;
  }
  .MuiAccordionDetails-root {
    font-size: 14px !important;
  }
  .MuiAccordionSummary-expandIcon {
    zoom: 0.5;
  }
`;
