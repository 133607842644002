import styled from 'styled-components';

export const FaqContainer = styled.div`
  width: 100%;

  background: #f9f9f9;

  padding: 34px 18px;

  .MuiPaper-root.MuiAccordion-root {
    padding: 5px 5px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 32px !important;
  }
  .MuiAccordionSummary-root {
    font-size: 15px !important;
  }
  .MuiAccordionDetails-root {
    font-size: 14px !important;
  }
  .MuiAccordionSummary-expandIcon {
    zoom: 0.5;
  }
`;
