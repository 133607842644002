import { Button, TextField } from '@material-ui/core';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, LightGray12 } from 'atom/text';
import { Footer } from 'component/footer';
import { Header } from 'component/header';
import { Color } from 'constant';
import { observer } from 'mobx-react';
import { VerificationStep } from 'model';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuthDispatch } from '../../context/AuthContext';
import { useStores } from 'state';
import styled from 'styled-components';
import { okLogin } from 'util/token';

const LoginPage = observer(() => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { loginStore, customerStore, sitterStore } = useStores();
  const [verificationStep, setVerificationStep] = useState(
    VerificationStep.InputNumber,
  );
  const handleLogin = async () => {
    const phone = loginStore.form.phone;
    const accessNumber = loginStore.form.accessNumber;
    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }

    try {
      const {
        ok,
        result,
        token,
        refreshToken,
        message,
        code,
      } = await loginStore.confirmVerificationCode(phone, Number(accessNumber));
      if (ok) {
        const { id, name, type } = result;
        okLogin({ id, name, type, token, refreshToken });
        dispatch({
          type: 'updateLoginState',
          isLogin: true,
        });
        if (type === 'HERO') {
          history.push('/mypage/hero');
        } else {
          history.push('/mypage/parents');
        }
      } else {
        if (
          message.includes('access check ok') ||
          message.includes('temp_user')
        ) {
          //인증번호 체크 하였으나 회원이 아니거나 //인증번호 조회 결과 임시 유저일때.
          alert(`회원가입이 필요합니다`);
          customerStore.signUpForm.phone = loginStore.form.phone;
          sitterStore.signUpForm.phone = loginStore.form.phone;
          history.push('/sign-up');
        } else {
          alert(message || '인증번호 검증이 실패했습니다.');
        }
      }
    } catch (e) {
      if (
        e.message.includes('access check ok') ||
        e.message.includes('temp_user')
      ) {
        //인증번호 체크 하였으나 회원이 아니거나 //인증번호 조회 결과 임시 유저일때.
        alert(`회원가입이 필요합니다`);
        customerStore.signUpForm.phone = loginStore.form.phone;
        sitterStore.signUpForm.phone = loginStore.form.phone;
        history.push('/sign-up');
      } else {
        alert(e.message || '인증번호 검증이 실패했습니다.');
      }
    } finally {
      loginStore.resetForm();
    }
  };
  const handleSendVerificationCode = async () => {
    const phone = loginStore.form.phone;
    if (phone.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    try {
      await loginStore.sendVerificationCode(phone);
      setVerificationStep(VerificationStep.Sent);
    } catch (e) {
      alert(e.message || '인증번호 전송이 실패했습니다.');
    }
  };
  return (
    <>
      <Header />
      <VerticalLayout center>
        <Space height={160} />
        <DarkGray24 bold>로그인</DarkGray24>
        <Space height={32} />

        <TextField
          placeholder="휴대폰 번호를 입력해주세요"
          variant="outlined"
          disabled={verificationStep !== VerificationStep.InputNumber}
          InputProps={{
            style: {
              height: '55px',
              width: '347px',
            },
          }}
          value={loginStore.form.phone}
          onChange={e =>
            (loginStore.form.phone = e.target.value.replace(/\D/g, ''))
          }
        />
        <Space height={12} />

        <Container>
          <TextField
            placeholder="인증 번호를 입력해주세요"
            variant="outlined"
            InputProps={{
              style: {
                height: '55px',
                width: '347px',
              },
            }}
            inputProps={{
              maxLength: 4,
            }}
            value={loginStore.form.accessNumber}
            onChange={e =>
              (loginStore.form.accessNumber = e.target.value.replace(/\D/g, ''))
            }
          />
          <SendNumberBtn
            disabled={verificationStep !== VerificationStep.InputNumber}
            onClick={handleSendVerificationCode}
          >
            인증번호 전송
          </SendNumberBtn>
        </Container>

        <Space height={24} />
        <Button
          style={{
            width: '347px',
            height: '55px',
            backgroundColor: Color.Primary,
            fontSize: '16px',
            color: Color.white,
          }}
          onClick={handleLogin}
        >
          로그인
        </Button>
        <Space height={68} />
        <div style={{ width: '347px', textAlign: 'right' }}>
          <LightGray12>
            혹시, 돌봄히어로가 처음이신가요?{` `}
            <span
              style={{
                color: Color.Primary,
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              <Link to={'/sign-up'}>회원가입</Link>
            </span>
          </LightGray12>
          <Space height={12} />
          <LightGray12>
            휴대폰 번호 변경시에는 02-6232-0220 센터로 연락해주세요.
          </LightGray12>
        </div>
        <Space height={440} />
        <Footer />
      </VerticalLayout>
    </>
  );
});

export default LoginPage;

const Container = styled.div`
  position: relative;
`;
const SendNumberBtn = styled.button`
  width: 120px;
  height: 55px;

  background: #ffffff;
  border: 1px solid #f69f30;
  border-radius: 5px;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #f69f30;

  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    filter: contrast(1.15);
  }
  &:active {
    filter: contrast(0.8);
  }
  position: absolute;
  top: 0;
  left: 370px;

  ${({ disabled }) =>
    disabled
      ? `
  filter: grayscale(1);
  pointer-events: none;
`
      : `
`}
`;
