import { CircularProgress } from '@material-ui/core';
import React from 'react';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import { useNotice } from 'state/hook/notice';
import { useStores } from 'state/index';
import styled from 'styled-components';
import { getDate } from '../../util';

const NoticeDetail = props => {
  const params = useParams();
  const data = useNotice(params['id']);

  if (!data) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Container>
      <Title>{`${data.is_pinned ? '[필독]' : '[공지]'} ${data.title}`}</Title>
      <SubTitle>{`등록일 : ${getDate(data.created_at)}`}</SubTitle>
      <hr style={{ margin: '16px 0' }} />
      <TextViewer>
        <ReactQuill
          theme="bubble"
          value={data.content}
          readOnly
          style={{ fontSize: '12px' }}
        />
      </TextViewer>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  color: #2d2d2d;
`;
const SubTitle = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: #969696;
`;

const TextViewer = styled.div`
  .ql-editor {
    font-size: 16px;
    padding: 0;
  }
`;

export default NoticeDetail;
