import { Space } from 'atom/layout';
import { DarkGray10, DarkGray16, DarkGray32, DarkGray40 } from 'atom/text';
import React from 'react';

const Notice = props => {
  return (
    <div
      style={{
        backgroundColor: '#F9F9F9',
        width: '100%',
        height: '309px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Space height={64} />
      <DarkGray16 bold>꼭 읽어주세요</DarkGray16>
      <Space height={12} />
      <hr style={{ width: '90%' }} />
      <Space height={24} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <DarkGray10>
          • 이벤트 대상은 3개월 이상 정기 돌봄을 신청한 부모님 대상으로
          진행됩니다.
        </DarkGray10>
        <Space height={4} />
        <DarkGray10>
          • 이벤트는 1:1 돌봄일 경우에만 참여하실 수 있습니다.
        </DarkGray10>
        <Space height={4} />

        <DarkGray10>
          • 이벤트 이용 후 3개월 이전에 돌봄 취소를 진행하실 경우 위약금
          10만원이 발생합니다.
        </DarkGray10>
        <Space height={4} />

        <DarkGray10>
          • 쿠폰코드 [창의놀이 이벤트]를 통해 정보를 입력해야 참여로 인정됩니다.
        </DarkGray10>
        <Space height={4} />

        <DarkGray10>
          • 이벤트 관련 문의는 우리동네 돌봄히어로 고객센터 (02-6232-0206)로
          문의 바랍니다.
        </DarkGray10>
      </div>
    </div>
  );
};

export default Notice;
