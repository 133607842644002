import { Checkbox } from 'atom/input';
import { DarkGray24, DarkGray30, DarkGray32 } from 'atom/text';
import styled from 'styled-components';
import React from 'react';
import { HorizontalLayout } from 'atom/layout';

const ApplyTermsRow = ({ terms }) => {
  const { isChecked, title, children, onChange, isImportant, key } = terms;

  return (
    <FlexBox style={{ margin: `1rem 0`, alignItems: 'center' }}>
      <div style={{ flex: '1 1 25%' }}>
        <DarkGray32 style={{ fontSize: '34px', marginTop: '10px' }} bold>
          <span style={{ color: '#ED584E' }}>
            {isImportant ? '* ' : <span>&nbsp;&nbsp;&nbsp;</span>}
          </span>
          약관동의
        </DarkGray32>
      </div>
      <div style={{ flex: '1 1 5%' }}>
        <Checkbox
          variant="square-line"
          value={isChecked}
          onChange={() => onChange(key)}
        />
      </div>
      <div style={{ flex: '1 1 70%' }}>
        <HorizontalLayout center style={{ width: '100%' }}>
          <DarkGray24>{title}</DarkGray24>
          <div
            style={{
              paddingLeft: '1rem',
            }}
          >
            {children}
          </div>
        </HorizontalLayout>
      </div>
    </FlexBox>
  );
};

const FlexBox = styled.div`
  display: flex;
  width: 960px;
`;

export default ApplyTermsRow;
