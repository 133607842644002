import React from 'react';
import styled from 'styled-components';

import { Table } from './Table';

export const PremiumServiceMinTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>서비스 종류</th>
          <th style={{ width: '50%' }}>최대 이용 시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>창의 놀이</td>
          <td>1시간</td>
        </tr>
      </tbody>
    </Table>
  );
};

export const PlayKitPackageTable = ({ maxWidth = '400px', width = '100%' }) => {
  return (
    <Table style={{ width, maxWidth }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>놀이 횟수</th>
          <th style={{ width: '50%' }}>가격</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>12회 패키지 (4주 기준)</td>
          <td>
            <span style={{ color: '#969696', textDecoration: 'line-through' }}>
              120,000원
            </span>
            <br />
            <span style={{ color: '#F69F30', fontWeight: 'bold' }}>
              25% 할인
            </span>
            &nbsp;90,000원
          </td>
        </tr>
        <tr>
          <td>8회 패키지 (4주 기준)</td>
          <td>
            <span style={{ color: '#969696', textDecoration: 'line-through' }}>
              80,000원
            </span>
            <br />
            <span style={{ color: '#F69F30', fontWeight: 'bold' }}>
              13% 할인
            </span>
            &nbsp;70,000원
          </td>
        </tr>
        <tr>
          <td>4회 패키지 (4주 기준)</td>
          <td>40,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};
export const PremiumServiceHourlyPricingTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>이용</th>
          <th style={{ width: '50%' }}>가격</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>아이 1명</td>
          <td>20,000원</td>
        </tr>
        <tr>
          <td>아이 2명</td>
          <td>30,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};
