import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray14, DarkGray18, LightGray12, Primary18 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Pagination } from 'swiper';

const Content1 = props => {
  SwiperCore.use([Pagination]);
  const infos = [
    {
      imgPath: require('asset/mobile/b2b/content1_1.png').default,
      title: '육아 경력자',
      subTitle: `선생님들 평균 육아경력 17.5년`,
      description: `평균 육아 경험만 17.5년 육아의 달인!\n베테랑 선생님들이 아이돌봄을 책임지고 있습니다. `,
    },
    {
      imgPath: require('asset/mobile/b2b/content1_2.png').default,
      title: '근거리 매칭',
      subTitle: `집 근처 30분 선생님 매칭`,
      description: `집 근처 평균 30분 거리 이내에 있는 선생님들이 방문하여 아이들을 돌봐주십니다.`,
    },
    {
      imgPath: require('asset/mobile/b2b/content1_3.png').default,
      title: '장기돌봄 가능',
      subTitle: `평균 돌봄 기간 8개월 이상`,
      description: `우리동네 돌봄히어로 서비스 이용 부모님들은\n평균 돌봄 기간이 8개월 이상이에요!`,
    },
  ];
  return (
    <Container center>
      <Space height={64} />
      <DarkGray18
        bold
        center
      >{`우리동네 돌봄히어로가\n훌륭한 복지가 될 수 있는 이유`}</DarkGray18>
      <Space height={8} />
      <LightGray12>아이를 키워본 30~67세 여성이라면 누구나</LightGray12>
      <Space height={32} />
      <div
        style={{
          display: 'block',
          maxWidth: '100vw',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <StyledSwiper
          slidesPerView={1}
          spaceBetween={50}
          pagination={{ clickable: true }}
          className="mySwiper"
        >
          <SwiperSlide>
            <Container2 center>
              <ImgBox src={infos[0].imgPath} />
              <Space height={24} />
              <Primary18 bold center>
                {infos[0].title}
              </Primary18>
              <Space height={8} />
              <DarkGray14 bold center>
                {infos[0].subTitle}
              </DarkGray14>
              <Space height={14} />
              <DarkGray14 center>{infos[0].description}</DarkGray14>
            </Container2>
          </SwiperSlide>
          <SwiperSlide>
            <Container2 center>
              <ImgBox src={infos[1].imgPath} />
              <Space height={24} />
              <Primary18 bold center>
                {infos[1].title}
              </Primary18>
              <Space height={8} />
              <DarkGray14 bold center>
                {infos[1].subTitle}
              </DarkGray14>
              <Space height={14} />
              <DarkGray14 center>{infos[1].description}</DarkGray14>
            </Container2>
          </SwiperSlide>
          <SwiperSlide>
            <Container2 center>
              <ImgBox src={infos[2].imgPath} />
              <Space height={24} />
              <Primary18 bold center>
                {infos[2].title}
              </Primary18>
              <Space height={8} />
              <DarkGray14 bold center>
                {infos[2].subTitle}
              </DarkGray14>
              <Space height={14} />
              <DarkGray14 center>{infos[2].description}</DarkGray14>
            </Container2>
          </SwiperSlide>
        </StyledSwiper>
      </div>
    </Container>
  );
};

const Container2 = styled(VerticalLayout)`
  position: relative;
  margin-right: 35px;
  margin-left: 35px;
`;
const ImgBox = styled.img`
  width: 255px;
  height: 170px;
`;
const Container = styled(VerticalLayout)`
  width: 100%;
  height: 585px;
  background: rgba(246, 159, 48, 0.04);
`;
const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullet-active {
    background-color: orange;
  }

  .swiper-container {
  }
  .swiper-slide-active {
    position: relative !important;
    height: 370px;
  }
  .swiper-pagination-bullets {
    position: absolute !important;
    left: 0px !important;
    bottom: 20px !important;

    z-index: 999;
  }
`;

export default Content1;
