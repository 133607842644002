import { observable } from 'mobx';
import { ILoginForm } from './model';

const DefaultLoginForm = {
  phone: '',
  accessNumber: '',
} as ILoginForm;

export interface ILoginStore {
  form: ILoginForm;

  sendVerificationCode(phone: string);
  confirmVerificationCode(phone: string, accessNumber: number);
  resetForm();
}

const loginStore = observable<ILoginStore>({
  form: DefaultLoginForm,

  async sendVerificationCode(phone: string) {
    // await apiSitter.post(`/account/auth/send`, {
    //   phone,
    // });
    await apiNew.post(`/home/user/accessNumber`, { phone });
  },
  async confirmVerificationCode(phone: string, accessNumber: number) {
    // await apiSitter.post(`/account/auth/confirm`, {
    //   phone,
    //   accessNumber,
    // });

    return await apiNew.post(`/home/user/login`, { phone, accessNumber });
  },

  resetForm() {
    this.form = DefaultLoginForm;
  },
});

export default loginStore;
