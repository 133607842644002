import React, { useRef } from 'react';
import styled from 'styled-components';

interface BackdropProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
};
export const Backdrop = ({
  ...props
}: BackdropProps) => {
  const ref = useRef();
  return (
    <Container
      ref={ref}
      onMouseDown={(e) => {
        if (e.target === ref.current)
          props?.onClose();
      }}
      {...props}
    />
  );
};

const Container = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10vh;

  background: rgba(0, 0, 0, 0.5);

  z-index: 100;

  > div {
    max-width: calc(100vw - 40px);
  }
`;
