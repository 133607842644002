import { RoundButton } from 'atom/button';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray32, DarkGray44 } from 'atom/text';
import { AddKaKaoFriendBtn } from 'page/event/Event5YearsForParents';
import React from 'react';
import styled from 'styled-components';

const RewardDetail2HowTo = ({ scrollToApply }) => {
  const steps = [
    {
      title: '[Step 01]',
      content: `이벤트 신청하기`,
      children: (
        <WhiteCercleFrame>
          {' '}
          <img
            style={{ width: '170px' }}
            src={
              require('asset/event/event_5years_for_parents/reward1_detail_7-1.png')
                .default
            }
          />
        </WhiteCercleFrame>
      ),
    },
    {
      title: '[Step 02]',
      content: `카카오톡 상단 검색창에
      ‘우리동네 돌봄히어로’ 검색`,
      children: (
        <img
          style={{
            width: '370px',
          }}
          src={
            require('asset/event/event_5years_for_parents/reward2_detail_step2.png')
              .default
          }
        />
      ),
    },
    {
      title: '[Step 03]',
      content: `친구추가 클릭`,
      children: (
        <img
          style={{
            width: '310px',
          }}
          src={
            require('asset/event/event_5years_for_parents/reward2_detail_step3.png')
              .default
          }
        />
      ),
    },
    {
      title: '[Step 04]',
      content: `[이름/전화번호/거주지(구)/자녀 나이] 
      작성하고 메시지 전송`,
      children: (
        <img
          style={{
            width: '400px',
          }}
          src={
            require('asset/event/event_5years_for_parents/reward2_detail_step4.png')
              .default
          }
        />
      ),
    },
  ];
  return (
    <Container>
      <Space height={120} />
      <DarkGray44 center bold style={{ fontSize: '48px' }}>
        [혜택 2] 이벤트 참여방법
      </DarkGray44>
      <DarkGray32 center style={{ fontSize: '36px' }}>
        : 창의놀이 교안(PDF)
      </DarkGray32>
      <Space height={100} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {steps.map(step => (
          <StepContainer key={step.title} step={step} />
        ))}
      </div>
      <Space height={120} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <RoundButton
          style={{
            padding: '17px 32px',
            width: '440px',
            height: '79px',
            fontWeight: 'bold',
            fontSize: '32px',
            marginRight: '61px',
          }}
          onClick={scrollToApply}
        >
          이벤트 신청하기
        </RoundButton>
        <AddKaKaoFriendBtn />
      </div>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  width: 100%;
  height: 1467px;
  background: #ffd200;
`;

const ImgFrame = styled.div`
  width: 371px;
  height: 587px;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhiteCercleFrame = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const StepContainer = ({ step }) => {
  const { title, content, children } = step;

  return (
    <VerticalLayout center>
      <ImgFrame>{children}</ImgFrame>
      <Space height={64} />
      <DarkGray32 bold center style={{ fontSize: '34px' }}>
        {title}
      </DarkGray32>
      <Space height={12} />
      <DarkGray32 center style={{ fontSize: '34px', whiteSpace: 'pre-line' }}>
        {content}
      </DarkGray32>
    </VerticalLayout>
  );
};
export default RewardDetail2HowTo;
