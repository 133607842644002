import { RoundButton } from 'atom/button';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24 } from 'atom/text';
import { Footer } from 'component-mobile/footer';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const DoneSitter = props => {
  const history = useHistory();
  return (
    <>
      <VerticalLayout center style={{ padding: '20px' }}>
        <Space height={120} />
        <DoneImage />
        <Space height={43} />

        <DarkGray24 bold center>
          이미 가입된 휴대폰 입니다.
        </DarkGray24>
        <Space height={120} />
        <RoundButton
          style={{
            width: '320px',
            height: '56px',
            padding: 0,
            fontSize: '16px',
          }}
          onClick={() => {
            history.push('/hero/apply');
          }}
        >
          교육신청하기
        </RoundButton>
      </VerticalLayout>
      <Footer />
    </>
  );
};

export default DoneSitter;

const DoneImage = styled.img.attrs({
  src: require('asset/done.png').default,
})`
  width: 245px;
  height: 217px;
`;
