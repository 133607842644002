import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext, useAuthDispatch } from '../../../context/AuthContext';
import { getUserType } from 'util/token';

const MyPageRouter = props => {
  const history = useHistory();
  const { logout } = useAuthContext();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    const type = getUserType();
    if (type === 'HERO') {
      return history.push('/mypage/hero');
    } else if (type === 'USER') {
      return history.push('/mypage/parents');
    } else {
      //비정상적인 접근 로그아웃 처리
      logout();
      dispatch({
        type: 'updateLoginState',
        isLogin: false,
      });
      return history.push('/login');
    }
  }, [dispatch, history, logout]);

  return <></>;
};

export default MyPageRouter;
