import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';
import { Space, Push, HorizontalLayout, VerticalLayout } from 'atom/layout';
import { Black18, LightGray16 } from 'atom/text';
import { Checkbox } from 'atom/input';
import { useTrainingSchedules } from 'state/hook';
import { useStores } from 'state';
import { ITrainingSchedule } from 'model';
import { Title } from './style';
import { getDate, getTime } from 'util/index';
import { ReactComponent as ScheduleSvg } from 'asset/sitter/apply/schedule.svg';
import { useAuthContext } from '../../../context/AuthContext';
import { ApplyDone } from './ApplyDone';
import { getUserId } from 'util/token';
import { useHistory } from 'react-router-dom';
import { ApplyStepSignup } from './ApplyStepSignup';

export const ApplyStep2 = observer(({ Bottom, onNext }) => {
  const history = useHistory();
  const { isLogin } = useAuthContext();
  const { sitterStore } = useStores();
  const schedules = useTrainingSchedules();
  const [schedule, setSchedule] = useState<number>();

  const onClickNext = async () => {
    if (isLogin) {
      //이미 회원이면
      try {
        sitterStore.form.userId = Number(getUserId());
        const { ok, message } = await sitterStore.applyTraining(
          sitterStore.form,
        );

        if (ok) {
          //교육일정등록 완료
          onNext(ApplyDone);
        } else {
          alert(message || '교육일정 등록에 실패하였습니다.');
          if (message === '교육 신청 내역이 있습니다.') {
            history.push('/login');
          }
        }
      } catch (e) {
        alert(e.message || '교육일정 등록에 오류가 발생하였습니다.');
      }
    } else {
      onNext(ApplyStepSignup);
    }
  };
  useEffect(() => {
    if (!schedule) return;

    const s = schedules.find(x => x.id === schedule);
    if (s) {
      sitterStore.form.trainingId = s.id;
    }
  }, [schedule, schedules, sitterStore.form]);

  return (
    <>
      {/* <div style={{ position: 'absolute', top: '40px', right: '36px' }}>
        <StepIndicator value={0} maxStep={2} />
      </div> */}
      <Title>참석 가능한 교육 일자를 선택해주세요.</Title>
      <Space height={8} />
      <LightGray16>
        *교육을 수료하신 후 원하시는 근거리에서 돌봄이 가능합니다.
      </LightGray16>
      <Space height={45} />
      <ScheduleSvg style={{ width: '396px' }} />
      <Space height={50} />

      <InfoBox>당일 교육 5시간 진행</InfoBox>
      <Space height={30} />
      <hr />
      <Space height={30} />

      {schedules.length === 0 && (
        <HorizontalLayout fill center>
          <Push />
          <CircularProgress />
          <Push />
        </HorizontalLayout>
      )}
      {schedules.map(x => (
        <React.Fragment key={x.id}>
          <TrainingCenterItem
            data={x}
            value={schedule === x.id}
            onChange={e => {
              if (e) setSchedule(x.id);
            }}
          />
          <Space height={20} />
          <hr />
          <Space height={20} />
        </React.Fragment>
      ))}

      <Space height={54} />

      <Bottom disabled={!schedule} onClickNext={onClickNext} />
    </>
  );
});

interface TrainingCenterItemProps {
  data: ITrainingSchedule;
  value: boolean;
  onChange: (x: boolean) => void;
}
const TrainingCenterItem = ({
  data,
  value,
  onChange,
}: TrainingCenterItemProps) => {
  return (
    <HorizontalLayout>
      <VerticalLayout>
        <Black18 bold>{data.center_name || '성수 교육장'}</Black18>
        <Space height={10} />
        <LightGray16 semiBold>
          교육시간: &nbsp;
          {`${getDate(data.host_start_time)} ${getTime(
            data.host_start_time,
          )} ~ ${getTime(data.host_end_time)}`}
        </LightGray16>
        <Space height={8} />
        <LightGray16 semiBold>
          교육장소: &nbsp;{data.center_address}
        </LightGray16>
      </VerticalLayout>
      <Push />
      <Space width={32} />
      <Checkbox variant="checkmark" value={value} onChange={onChange} />
    </HorizontalLayout>
  );
};

const InfoBox = styled.div`
  display: flex;
  width: 403px;
  height: 72.6px;

  font-size: 20px;
  font-weight: 500;

  border-radius: 8px;
  background-color: #f3f4f5;

  align-items: center;
  justify-content: center;
`;
