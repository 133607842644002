import { ConfirmPopup, YesNoPopup } from 'component-mobile/popup';
import TermsPopup from 'component-mobile/popup/TermsPopup';
import { FC, ReactChildren } from 'react';
import { useTopmost, ITopmost } from './topmost';

let gTopmost: ITopmost | null = null;

const showConfirm = (
  message: string,
  comment?: string,
  onClose?: () => void,
) => {
  const id = gTopmost!.show(
    <ConfirmPopup
      message={message}
      comment={comment}
      onClose={() => {
        onClose?.();
        gTopmost!.dismiss(id);
      }}
    />,
  );
};
const showYesNoPopup = (
  message: string,
  onYes: () => void,
  onNo?: () => void,
) => {
  const id = gTopmost!.show(
    <YesNoPopup
      message={message}
      onYes={onYes}
      onNo={onNo}
      onClose={() => gTopmost!.dismiss(id)}
    />,
  );
};

export const showTermsPopup = (
  title: string,
  content: React.ReactNode,
  onClose?: () => void,
) => {
  const id = gTopmost!.show(
    <TermsPopup
      title={title}
      content={content}
      onClose={() => {
        onClose?.();
        gTopmost!.dismiss(id);
      }}
    />,
  );
};

export const PopupHelper = () => {
  const topmost = useTopmost();
  gTopmost = topmost;

  // @ts-ignore
  window.showConfirm = showConfirm;
  // @ts-ignore
  window.showYesNoPopup = showYesNoPopup;

  return <></>;
};
