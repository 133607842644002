import { ImageBox } from 'atom/image';
import { Space, VerticalLayout } from 'atom/layout';
import {
  DarkGray10,
  DarkGray18,
  DarkGray24,
  Primary18,
  Primary24,
} from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Main5YearEvent = props => {
  return (
    <ImageBox
      minHeight={562}
      src={
        require('asset/event/event_5years_for_parents/mobile/main_mobile.jpg')
          .default
      }
    >
      <div
        style={{
          position: 'absolute',
          left: '10%',
          top: '12px',
          transform: 'translate(-2%, 0%)',
        }}
      >
        <img
          style={{
            width: '180px',
          }}
          src={
            require('asset/event/event_5years_for_parents/award.svg').default
          }
        />
        <Primary24 bold>우리동네 돌봄히어로</Primary24>
        <DarkGray24
          bold
          style={{
            letterSpacing: '-0.04rem',
          }}
        >
          5주년 감사 이벤트 🎉
        </DarkGray24>
        <img
          src={
            require('asset/event/event_5years_for_parents/mobile/mobile_mainText2.svg')
              .default
          }
        />
      </div>

      <div
        style={{
          position: 'absolute',
          right: '50%',
          top: '35px',
          transform: 'translate(185%, 0%)',
        }}
      >
        <img
          style={{
            width: '90px',
          }}
          src={
            require('asset/event/event_5years_for_parents/mobile/mobile_main_side2.png')
              .default
          }
        />
      </div>

      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '27%',
          whiteSpace: 'pre-line',
          letterSpacing: '-0.04rem',
          transform: 'translate(-50%, 0)',
          fontSize: '13px',
          fontWeight: 'bold',
          color: '#484848',
          textAlign: 'center',
        }}
      >
        {`돌봄히어로가 5주년을 맞아 준비한
            2가지 혜택을 공개합니다. 🎉🎉`}
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          position: 'absolute',
          left: '10%',
          bottom: '5%',
          transform: 'translate(-10%, -10%)',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RoundMemoBox title={'혜택1'} limitText={`100명\n한정`}>
          <FlexBox style={{ alignItems: 'center' }}>
            <img
              style={{ width: '130px' }}
              src={
                require('asset/event/event_5years_for_parents/mobile/mobile_main_reward1.png')
                  .default
              }
            />
            <VerticalLayout center>
              <DarkGray10 center bold style={{ color: '#D4594B' }}>{`창의놀이 
10회 이용권 + 준비물`}</DarkGray10>
              <Space height={6} />
              <DarkGray10 bold>
                <span
                  style={{
                    fontWeight: 'normal',
                    textDecoration: 'line-through',
                  }}
                >
                  130,000원{' '}
                </span>{' '}
                0원
              </DarkGray10>
            </VerticalLayout>
          </FlexBox>
        </RoundMemoBox>
        <Space height={36} />
        <RoundMemoBox title={'혜택2'} limitText={`무제한`}>
          <FlexBox
            style={{ alignItems: 'center', justifyContent: 'space-around' }}
          >
            <img
              style={{ height: '94px' }}
              src={
                require('asset/event/event_5years_for_parents/mobile/mobile_main_reward2.png')
                  .default
              }
            />
            <VerticalLayout center>
              <DarkGray10 center bold>
                {`이벤트 신청만 해도`}
              </DarkGray10>
              <DarkGray10 center bold style={{ color: '#D4594B' }}>{`창의놀이
                가이드(pdf)`}</DarkGray10>
              <DarkGray10 center bold>
                {`무료 제공`}
              </DarkGray10>
            </VerticalLayout>
          </FlexBox>
        </RoundMemoBox>
      </div>
    </ImageBox>
  );
};
const RoundBgBox = styled.div`
  width: 251px;
  height: 120px;
  background: #ffffff;
  border-radius: 1rem;
  position: relative;
  padding: 1rem;
`;
const RoundTextBox = styled.div`
  background: #000000;
  border-radius: 100px;
  padding: 4px 22px;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
`;

const RedDotSticker = styled.div`
  width: 42px;
  height: 42px;
  font-weight: 700;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  background: #f64343;
  border-radius: 50%;
  white-space: pre-line;
`;

const RoundMemoBox = ({ children, title, limitText }) => {
  return (
    <RoundBgBox>
      <RoundTextBox
        style={{
          position: 'absolute',
          left: '50%',
          top: '-5%',
          transform: 'translate(-50%, -5%)',
        }}
      >
        {title}
      </RoundTextBox>
      <div>{children}</div>
      <RedDotSticker
        style={{
          position: 'absolute',
          right: '-6%',
          bottom: '-10%',
          transform: 'translate(6%, 10%)',
        }}
      >
        <div> {limitText}</div>
      </RedDotSticker>
    </RoundBgBox>
  );
};

const FlexBox = styled.div`
  display: flex;
`;
export default Main5YearEvent;
