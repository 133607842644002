import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { HorizontalLayout, Space, Push } from 'atom/layout';
import { useIsMobile } from 'util/index';
import { DarkGray14, DarkGray18 } from 'atom/text';
import { LinearProgress } from '@material-ui/core';

export const HeaderMyPageApply = ({ title = '신청내역' }) => {
  const isMobile = useIsMobile(true);
  const history = useHistory();

  // PC에서 모바일 페이지 보여주는 경우
  if (!isMobile) return <></>;

  return (
    <>
      <Holder>
        <Container>
          <img
            style={{ marginLeft: '10px' }}
            src={require('asset/icon/backArrow.svg').default}
            onClick={() => history.goBack()}
          />
          <Push />
          <DarkGray18 style={{ transform: 'translate(-20px, 0)' }} bold>
            {title}
          </DarkGray18>
          <Push />
        </Container>
      </Holder>
    </>
  );
};

const Holder = styled.header`
  position: relative;

  width: 100%;
  height: 56px;
`;
const Container = styled(HorizontalLayout)`
  padding: 0 13px;

  width: 100%;
  height: 56px;

  background: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

  align-items: center;

  z-index: 10;
`;
