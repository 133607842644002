import React from 'react';
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import { MobileContextProvider } from 'util/index';

import { HomePage } from 'page/HomePage';
import { CustomerPricingPage } from 'page/customer/CustomerPricingPage';
import { CustomerRequestPage } from 'page/customer/CustomerRequestPage';
import { CustomerIntroPage } from 'page/customer/CustomerIntroPage';
import { SitterIntroPage } from 'page/sitter/SitterIntroPage';
import { FaqPage } from 'page/FaqPage';
import { SitterApplyPage } from 'page/sitter/SitterApplyPage';
import { IntroBasicPage } from 'page/customer/intro/IntroBasicPage';
import { IntroPremiumPage } from 'page/customer/intro/IntroPremiumPage';
import { IntroExtraPage } from 'page/customer/intro/IntroExtraPage';
import { EventSungDongPage } from 'page/event/EventSungDongPage';
import EventPlayKit from 'page/event/EventPlayKit';
import LoginPage from 'page/login/LoginPage';
import SignUpMainPage from 'page/sign-up/SignUpMainPage';
import CustomerSignUpPage from 'page/sign-up/CustomerSignUpPage';
import CustomerPhoneCheckPage from 'page/sign-up/CustomerPhoneCheckPage';
import SitterPhoneCheckPage from 'page/sign-up/SitterPhoneCheckPage';
import SitterSignUpPage from 'page/sign-up/SitterSignUpPage';
import MyPageCustomer from 'page/customer/my-page/MyPageCustomer';
import MyPageSitter from 'page/customer/my-page/MyPageSitter';
import { useAuthContext } from './context/AuthContext';
import MyPageRouter from 'page/customer/my-page/MyPageRouter';
import Event5YearsForParents from 'page/event/Event5YearsForParents';
import B2bPage from 'page/customer/b2b/B2bPage';

const ScrollToTop = withRouter(({ history }) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

const NavPC = () => {
  const { isLogin } = useAuthContext();

  return (
    <MobileContextProvider isMobile={false}>
      <style>
        {`#root {
          min-width: 800px;
        }`}
      </style>
      <BrowserRouter>
        <ScrollToTop />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route exact path="/" component={HomePage} />
          <Route exact path="/faq" component={FaqPage} />

          {/* Event page */}
          <Route exact path="/event_sd" component={EventSungDongPage} />
          <Route exact path="/event_playkit" component={EventPlayKit} />
          <Route exact path="/event_5years" component={Event5YearsForParents} />

          {/* Customer */}
          <Route
            exact
            path="/service/pricing"
            component={CustomerPricingPage}
          />
          <Route exact path="/service/apply" component={CustomerRequestPage} />
          <Route
            exact
            path="/service/apply/q1"
            component={CustomerRequestPage}
          />
          <Route exact path="/service/intro" component={CustomerIntroPage} />
          <Route exact path="/service/intro/basic" component={IntroBasicPage} />
          <Route
            exact
            path="/service/intro/premium"
            component={IntroPremiumPage}
          />
          <Route exact path="/service/intro/extra" component={IntroExtraPage} />
          <Route exact path="/service/b2b" component={B2bPage} />

          {/* Sitter */}
          <Route exact path="/hero/about" component={SitterIntroPage} />
          <Route exact path="/hero/apply" component={SitterApplyPage} />
          <Route exact path="/hero/apply/q1" component={SitterApplyPage} />

          {/*my page */}
          {isLogin ? (
            <Switch>
              <Route exact path="/login" component={MyPageRouter} />
              <Route exact path="/mypage/parents" component={MyPageCustomer} />
              <Route exact path="/mypage/hero" component={MyPageSitter} />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          ) : (
            <Switch>
              {/*signup signin */}
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/sign-up" component={SignUpMainPage} />
              <Route
                exact
                path="/sign-up/parents/phone"
                component={CustomerPhoneCheckPage}
              />
              <Route
                exact
                path="/sign-up/parents/info"
                component={CustomerSignUpPage}
              />
              <Route
                exact
                path="/sign-up/hero/phone"
                component={SitterPhoneCheckPage}
              />
              <Route
                exact
                path="/sign-up/hero/info"
                component={SitterSignUpPage}
              />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          )}
          <Route path="*" render={() => <Redirect to="/" />} />
          <Redirect to="/" />
        </AnimatedSwitch>
      </BrowserRouter>
    </MobileContextProvider>
  );
};
export default NavPC;
