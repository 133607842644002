import styled from 'styled-components';

export const Title = styled.div`
  font-size: 23px;
  font-weight: bold;

  line-height: 1.48;
  letter-spacing: -1.15px;
`;
export const Subtitle = styled.div`
  color: #989898;

  font-size: 16px;
  font-weight: 500;

  letter-spacing: -0.8px;
`;
