import styled from 'styled-components';

import { Color } from 'constant';

interface TableProps {
  align?: 'center' | 'start';
  fontSize?: number;
  padding?: '6px 20px' | '20px 25px';
}
export const Table = styled.table<TableProps>`
  width: calc(100% - 80px);
  max-width: 619px;
  
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  border-style: hidden;



  td,
  th {
    border: 1px solid white;
  }
  thead {
    background: ${Color.Primary};
  }
  tbody tr:nth-child(2n + 1) {
    background: #f5f5f5;
  }
  tbody tr:nth-child(2n + 2) {
    background: #f9f9f9;
  }
  th {
    color: white;

    font-size: 13px;
    font-weight: 500;
    line-height: 1.73;
    padding: 6px 10px;
  }
  td {
    color: #484848;

    font-size: 13px;
    font-weight: 400;

    line-height: 1.73;
    letter-spacing: -0.7px;

    padding: 6px 20px;

    ${({ fontSize = 13 }) => `
      font-size: ${fontSize}px;
    `}
    ${({ align = 'center' }) =>
      align === 'center'
        ? `
      text-align: center;
    `
        : `
      vertical-align: baseline;
    `}
    ${({ padding = '6px 20px' }) => `
    padding:${padding};
    `}
  }
  filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.2));
`;
