import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { Propensities } from 'constant';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestPersonalityCheck2 } from './RequestPersonalityCheck2';

//제외됨 2022.03.10
export const RequestPersonalityCheck1 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [value, setValue] = useState<number[]>([]);

  // useEffect(() => {
  //   if (!customerStore.childForm.propensity) return;

  //   setValue(
  //     customerStore.childForm.propensity
  //       .split('\n')
  //       .map(x => Propensities.find(y => y.label === x)?.value),
  //   );
  // }, []);
  // useEffect(() => {
  //   customerStore.childForm.propensity = value
  //     .map(x => Propensities.find(y => y.value === x).label)
  //     .join('\n');
  // }, [value]);

  return (
    <>
      <Title>
        우리 아이는 어떤 성향을
        <br />
        가지고 있나요?
      </Title>
      <Space height={8} />
      <Subtitle>*중복으로 선택이 가능합니다.</Subtitle>
      <Space height={41} />

      <MultiSelect items={Propensities} value={value} onChange={setValue} />
      <Space height={60} />
      <Bottom
        disabled={value.length === 0}
        progress={(1 / 5) * 2}
        onClickNext={() => onNext(RequestPersonalityCheck2)}
      />
    </>
  );
});
