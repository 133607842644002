// https://github.com/SeeSoRuFree/woorihero-webapp-front/blob/master/src/components/new_care/store/newCare.slice.ts

export enum CareKind {
  Regular = '정기',
  Once = '비정기',
}
export enum CareKindNew {
  Regular = 1,
  Once = 0,
}

export enum CareType {
  DropOff = '등원 + 놀이돌봄',
  PickUp = '하원 + 놀이돌봄',
  PlayOnly = '놀이돌봄만',
  Premium = '창의 놀이',
  Cleaning = '청소 서비스',
  Cooking = '요리 서비스',
}
export enum CareTypeNew {
  DropOff = 1,
  PickUp = 2,
  PlayOnly = 3,
}
export const isAddonCareType = (x: CareType) => {
  return (
    x === CareType.Premium || x === CareType.Cleaning || x === CareType.Cooking
  );
};

export enum PickupMethod {
  Walk = '도보 이동',
  Bus = '대중 교통',
  Taxi = '택시',
  Car = '선생님 차량',
  SchoolBus = '스쿨 버스',
  None = '픽업 불필요',
}
export enum PickupMethodNew {
  Walk = 1,
  Bus = 2,
  Taxi = 3,
  Car = 4,
  SchoolBus = 5,
  None = 6,
}

export interface ICareInfo {
  careAddress: string;
  careType: any[];
  childInfo: any[];
  carePlan: any[];
  isRegular: string;
  orderId: number;
}

export interface IFeeInfo {
  careFee: number;
  totalHour: number;
  serviceFee: number;
  serviceType: string;
  cntOfWeek: number;
  totalFee: number;
}
