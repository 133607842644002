import React, { useState } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';

import { RoundButton } from 'atom/button';
import { Push } from 'atom/layout';
import { DatePicker } from 'atom/input';
import { Color } from 'constant';
import { Backdrop } from './Backdrop';

interface DatePickerPopupProps {
  value: DateTime;
  onChange: (value: DateTime) => void;
  onClose: () => void;
}
export const DatePickerPopup = ({
  value,
  onChange,
  onClose,
}: DatePickerPopupProps) => {
  const [date, setDate] = useState(value);

  const onSubmit = () => {
    onChange(date);
    onClose();
  };

  return (
    <Backdrop onClose={onClose}>
      <Container>
        <CalendarContainer>
          <Calendar
            formatDay={(locale, date) => `${date.getDate()}`}
            minDate={DateTime.local().toJSDate()}
            value={date?.toJSDate()}
            calendarType="US"
            onChange={v => {
              setDate(DateTime.fromJSDate(v));
            }}
          />
        </CalendarContainer>
        <BottomButton onClick={onSubmit}>적용</BottomButton>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  position: relative;
  width: 400px;
  height: 420px;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const CalendarContainer = styled.div`
  padding: 20px 20px;
`;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  position: absolute;
  bottom: 0px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
