import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray14, DarkGray18, LightGray12 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Content2 = props => {
  return (
    <Container center>
      <Space height={64} />
      <DarkGray18 bold center>
        육아 복지
      </DarkGray18>
      <Space height={8} />
      <LightGray12 semiBold>
        훌륭한 인재 확보와 유지를 위한 필수조건
      </LightGray12>
      <Space height={32} />
      <Img src={require('asset/mobile/b2b/content2.png').default} />
      <Space height={32} />
      <DarkGray14>
        업무 중 자녀 걱정, 돌봄 스트레스 <Bold>DOWN!</Bold>
      </DarkGray14>
      <DarkGray14>
        회사 만족도, 업무 집중도, 성과는 <Bold>UP!</Bold>
      </DarkGray14>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  height: 399px;
`;
const Img = styled.img`
  width: 290px;
  height: 110px;
`;
const Bold = styled.span`
  font-weight: bold;
`;

export default Content2;
