import { Space, VerticalLayout } from 'atom/layout';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DarkGray44, DarkGray52, Primary48 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Reviews = props => {
  const whatToDos = [
    {
      src: require('asset/event/event_5years_for_parents/whatToDo_1.png')
        .default,
      title: '전문 양성교육 및 보수교육 진행',
      content: `아이의 기질/성향을 이해하고, 아이와의 상호작용 질을\n높이기 위한 전문 양성교육과 보수교육을 진행합니다.`,
    },
    {
      src: require('asset/event/event_5years_for_parents/whatToDo_2.png')
        .default,
      title: '아이돌봄 실습을 통한 역량 강화',
      content: `돌봄 실습을 통하여 실제 실무 역량을\n향상시키게 됩니다.`,
    },
    {
      src: require('asset/event/event_5years_for_parents/whatToDo_3.png')
        .default,
      title: '1000가지 히어로 놀이법 제공',
      content: `히어로에서 자체 개발한 다양한 놀이법을\n활용하여 양질의 돌봄서비스를 제공합니다.`,
    },
  ];
  return (
    <Container>
      <DarkGray52
        center
        style={{
          marginTop: '120px',
          fontSize: '70px',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          우리동네 좋은 선생님
        </span>{' '}
        대신 찾아주는
      </DarkGray52>
      <Primary48
        bold
        center
        style={{
          fontSize: '70px',
        }}
      >
        집 근처 돌봄 서비스, 돌봄히어로!
      </Primary48>
      <Space height={120} />
      <DarkGray44 center style={{ fontSize: '48px' }}>
        돌봄히어로는{' '}
        <span style={{ fontWeight: 'bold' }}>
          동네에 숨어 계시는 좋은 선생님
        </span>
        을 발굴/양성하기 위해
      </DarkGray44>
      <DarkGray44 center style={{ fontSize: '48px' }}>
        <span style={{ fontWeight: 'bold' }}>
          서울시 및 여성 관련 기관 등과 협력하여 선생님을 양성
        </span>
        하고 있습니다.
      </DarkGray44>
      <Space height={78} />
      <img
        style={{
          display: 'block',
          width: '1012px',
          margin: 'auto',
        }}
        src={
          require('asset/event/event_5years_for_parents/review_map.png').default
        }
      />
      <Space height={120} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {whatToDos.map((item, index) => (
          <ImageCard
            key={item.title}
            index={index + 1}
            title={item.title}
            content={item.content}
            src={item.src}
          />
        ))}
      </div>
      <Space height={120} />
      <DarkGray44 style={{ fontSize: '48px' }} bold center>
        이용중인 부모님들의 만족도{' '}
        <span
          style={{
            color: '#ED584E',
          }}
        >
          9.8점
        </span>{' '}
        서비스!
      </DarkGray44>
      <DarkGray44 style={{ fontSize: '48px' }} center>
        좋은 혜택과 함께 이번 기회에 이용해보세요!
      </DarkGray44>
      <Space height={120} />
      <Swiper
        width={1920}
        slidesPerView={4.3}
        spaceBetween={550}
        loop={true}
        initialSlide={2}
        autoplay={true}
        centeredSlides={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            style={{ width: '580px' }}
            src={require('asset/event/event_playkit/review1.png').default}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={{ width: '580px' }}
            src={require('asset/event/event_playkit/review2.png').default}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={{ width: '580px' }}
            src={require('asset/event/event_playkit/review3.png').default}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={{ width: '580px' }}
            src={require('asset/event/event_playkit/review4.png').default}
          />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 3002px;
  background: #fffbf7;
`;

const CardIndex = styled.div`
  font-weight: 700;
  font-size: 100px;
  line-height: 40px;
  transform: translate(-2rem, 0);

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.1em;
  color: #969696;
`;
const CardTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 1rem;

  color: #484848;
`;

const CardContent = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;
  color: #484848;
`;

const ImageCard = ({ src, index, title, content }) => {
  return (
    <div
      style={{
        width: '520px',
        margin: '0 1rem',
      }}
    >
      <img
        style={{
          width: '480px',
          height: '320px',
        }}
        src={src}
      />
      <div style={{ display: 'flex' }}>
        <CardIndex>{index}</CardIndex>
        <VerticalLayout>
          <CardTitle>{title}</CardTitle>
          <CardContent>{content}</CardContent>
        </VerticalLayout>
      </div>
    </div>
  );
};
export default Reviews;
