import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Backdrop, Box, CircularProgress, TextField } from '@material-ui/core';
import { DateTime } from 'luxon';

import { RoundButton } from 'atom/button';
import { Space, HorizontalLayout } from 'atom/layout';

import { Checkbox, Labeled } from 'atom/input';
import { Black16, DarkGray15, Gray16, Primary15 } from 'atom/text';

import { AddressPopup } from 'component/popup';
import { VerificationStep } from 'model';
import { useStores } from 'state';
import { getAge, useTopmost } from 'util/index';
import { Title } from './style';
import { ApplyDone } from './ApplyDone';
import { Color } from 'constant';
import { applyWays } from 'page/sign-up/SitterSignUpPage';
import { Link, useHistory } from 'react-router-dom';
import { okLogin } from 'util/token';
import { useAuthDispatch } from '../../../context/AuthContext';

export const ApplyStepSignup = observer(({ Bottom, onNext }) => {
  const topmost = useTopmost();
  const inputNameRef = useRef(null);
  const inputBirthRef = useRef(null);
  const inputPhoneRef = useRef(null);
  const inputAddressRef = useRef(null);
  const inputCommentRef = useRef(null);
  const btnFunnelRef = useRef(null);

  const dispatch = useAuthDispatch();
  const history = useHistory();
  const { sitterStore } = useStores();
  const [birthday, setBirthday] = useState('');
  const [loading, setLoading] = useState(false);

  const hadAllAgree = () =>
    sitterStore.signUpForm.isServiceAgree &&
    sitterStore.signUpForm.isPrivacyAgree &&
    sitterStore.signUpForm.isMarketingAgree;
  const changeAgree = (value: boolean) => {
    sitterStore.signUpForm.isServiceAgree = value;
    sitterStore.signUpForm.isPrivacyAgree = value;
    sitterStore.signUpForm.isMarketingAgree = value;
  };
  const onClickAddress = () => {
    const id = topmost.show(
      <AddressPopup
        value=""
        restrictToSeoul={false}
        initialDetailAddress={''}
        onSubmit={(x, y) => {
          sitterStore.signUpForm.address = x;
          sitterStore.signUpForm.detailAddress = y;
        }}
        onClose={() => topmost.dismiss(id)}
      />,
    );
  };
  const setFocus = ref => {
    ref.current.focus();
  };
  const checkForm = () => {
    if (getAge(birthday) + 1 > 67) {
      showConfirm('죄송합니다.\n만 66세까지만\n지원이 가능합니다.');
      return false;
    }
    if (sitterStore.signUpForm.name.length < 2) {
      showConfirm('성함을 입력해주세요.', '', () => {
        setFocus(inputNameRef);
      });

      return false;
    }
    if (birthday.length !== 6) {
      showConfirm('생일을 입력해주세요.', '', () => {
        setFocus(inputBirthRef);
      });
      return false;
    }
    if (sitterStore.signUpForm.phone.length < 10) {
      showConfirm('휴대폰 번호를 입력해주세요.', '', () => {
        setFocus(inputPhoneRef);
      });
      return false;
    }
    if (sitterStore.signUpForm.address.length <= 0) {
      showConfirm('주소를 입력해주세요.', '', () => {
        setFocus(inputAddressRef);
      });
      return false;
    }
    if (
      !sitterStore.signUpForm.address.startsWith('서울')
      //&& !sitterStore.signUpForm.address.startsWith('경기')
    ) {
      showConfirm(
        '죄송합니다.\n히어로 선생님은 서울 거주자만 지원 가능합니다.',
      );
      return false;
    }

    if (
      sitterStore.signUpForm.hasNurture === false &&
      sitterStore.signUpForm.hasNurtureExpert.length <= 0
    ) {
      showConfirm('자녀 외 돌봄경험을 입력해주세요.', '', () => {
        setFocus(inputCommentRef);
      });
      return false;
    }
    if (sitterStore.signUpForm.applyRoute <= 0) {
      showConfirm(
        '우리동네 돌봄히어로를\n어떤경로로 알게되셨는지 선택해주세요.',
        '',
        () => {
          btnFunnelRef.current.scrollIntoView();
        },
      );
      return false;
    }
    if (
      sitterStore.signUpForm.isPrivacyAgree === false ||
      sitterStore.signUpForm.isServiceAgree === false
    ) {
      showConfirm('필수 약관을 동의해주세요.');
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (checkForm()) {
      try {
        setLoading(true);
        const {
          ok,
          result,
          token,
          message,
          refreshToken,
        } = await sitterStore.signUpHero(sitterStore.signUpForm);
        if (ok) {
          //회원가입 성공 및 로그인처리
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          // 교육일정 등록
          sitterStore.form.userId = Number(id);
          try {
            const {
              ok: ok2,
              message: message2,
            } = await sitterStore.applyTraining(sitterStore.form);
            if (ok2) {
              onNext(ApplyDone);
            } else {
              alert(message2 || '교육일정 등록에 실패하였습니다.');
              if (message2 === '교육 신청 내역이 있습니다.') {
                history.push('/login');
              }
            }
          } catch (e2) {
            alert(e2.message || '교육일정 등록에 오류가 발생하였습니다.');
          }
          sitterStore.resetForm();
          sitterStore.resetSignUpForm();
        } else {
          alert(message || '회원가입중 오류가 발생하였습니다.');
        }
      } catch (e) {
        alert(e.message || '회원가입에 실패하였습니다.');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (birthday.length === 6) {
      let date = DateTime.fromFormat(birthday, 'yyMMdd');
      if (!date.isValid) {
        alert('잘못된 생일입니다.');
        return;
      }
      if (date.year >= 2025) date = date.minus({ years: 100 });
      sitterStore.signUpForm.birth = birthday; //date.toISO();
    }
  }, [birthday]);
  console.log(loading);

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <>
        <Title>
          교육 신청을 위해
          <br />
          회원가입이 필요합니다.
        </Title>
        <Space height={43} />

        <Labeled label="성함">
          <TextField
            inputRef={inputNameRef}
            placeholder="성함을 입력해주세요"
            variant="outlined"
            style={{ width: '100%' }}
            value={sitterStore.signUpForm.name}
            onChange={e => (sitterStore.signUpForm.name = e.target.value)}
          />
        </Labeled>
        <Space height={20} />

        <Labeled label="생년월일 6자리">
          <TextField
            inputRef={inputBirthRef}
            placeholder="(예시)641021"
            variant="outlined"
            inputProps={{ maxLength: 6 }}
            style={{ width: '100%' }}
            value={birthday}
            onChange={e => setBirthday(e.target.value)}
          />
        </Labeled>
        <Space height={20} />

        <Labeled label="휴대폰 번호">
          <TextField
            inputRef={inputPhoneRef}
            disabled
            variant="outlined"
            inputProps={{
              maxLength: 11,
            }}
            style={{ width: '100%' }}
            value={sitterStore.signUpForm.phone}
            onChange={e =>
              (sitterStore.signUpForm.phone = e.target.value.replace(/\D/g, ''))
            }
          />
          <Space height={10} />
        </Labeled>
        <Space height={60} />
        <hr />
        <Space height={40} />

        <Black16 bold>주소</Black16>
        <Space height={10} />
        <Gray16>주소를 입력해주세요</Gray16>
        <Space height={16} />
        <TextField
          inputRef={inputAddressRef}
          placeholder="(예시) 서울시 관악구 조원동"
          variant="outlined"
          disabled
          style={{ width: '100%' }}
          value={`${sitterStore.signUpForm.address}`}
          onClick={onClickAddress}
          InputProps={
            sitterStore.signUpForm.address
              ? {
                  startAdornment: (
                    <img
                      style={{ width: '16px', marginRight: '12px' }}
                      src={require('asset/icon/flag_gray.svg').default}
                    />
                  ),
                }
              : {}
          }
        />
        <Space height={10} />

        {sitterStore.signUpForm.detailAddress && (
          <TextField
            placeholder=" 우리빌라 105동 202호"
            variant="outlined"
            disabled
            style={{ width: '100%' }}
            value={`${sitterStore.signUpForm.detailAddress}`}
            onClick={onClickAddress}
          />
        )}
        <Space height={50} />
        <hr />
        <Space height={40} />

        <Labeled label="자녀 양육 경험">
          <HorizontalLayout center>
            <Checkbox
              value={sitterStore.signUpForm.hasNurture}
              onChange={e => {
                if (e) sitterStore.signUpForm.hasNurture = true;
              }}
            />
            <Space width={10} />
            <Black16 bold>유</Black16>
            <Space width={100} />

            <Checkbox
              value={!sitterStore.signUpForm.hasNurture}
              onChange={e => {
                if (e) {
                  showConfirm(
                    '자녀 양육경험이 없으실 경우,\n필수로 돌봄경험이 있으셔야 합니다.',
                  );
                  sitterStore.signUpForm.hasNurture = false;
                }
              }}
            />
            <Space width={10} />
            <Black16 bold>무</Black16>
          </HorizontalLayout>
        </Labeled>
        <Space height={44} />
        <Labeled label="자녀 외 돌봄경험">
          <textarea
            ref={inputCommentRef}
            placeholder={`자녀 외에 돌봄경험이 있으시다면 자유롭게 작성해주세요. 양육경험이 없으실 경우, 필수로 돌봄경험이 있으셔야 합니다. (예 : 어린이집 보조교사 경험 등)`}
            value={sitterStore.signUpForm.hasNurtureExpert}
            onChange={e =>
              (sitterStore.signUpForm.hasNurtureExpert = e.target.value)
            }
          />
        </Labeled>
        <Space height={50} />
        <hr ref={btnFunnelRef} />
        <Space height={35} />
        <Labeled
          label="6. 히어로를 알게 된 경로"
          labelStyle={{ fontSize: '18px', width: '390px' }}
          gap={10}
        >
          <Space height={24} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'start',
              alignItems: 'center',
              width: '390px',
            }}
          >
            {applyWays.map(item => (
              <RoundButton
                variant={
                  sitterStore.signUpForm.applyRoute === item.value
                    ? 'secondary'
                    : 'gray'
                }
                style={{
                  width: '180px',
                  height: '50px',
                  fontSize: '15px',
                  padding: 0,
                  margin: '5px',
                }}
                onClick={() => (sitterStore.signUpForm.applyRoute = item.value)}
              >
                {item.label}
              </RoundButton>
            ))}{' '}
          </div>
        </Labeled>
        <Space height={50} />
        <hr />
        <Space height={40} />

        <Title>
          우리동네 돌봄히어로
          <br />
          서비스 이용약관 동의가 필요해요.
        </Title>
        <Space height={57} />
        <InnerBox>
          <TermsContainer>
            <Checkbox
              variant="square-line"
              value={hadAllAgree()}
              onChange={value => changeAgree(value)}
            />
            <Space width={15} />
            <DarkGray15 bold>전체 동의하기</DarkGray15>
          </TermsContainer>
          <Space height={26} />
          <hr style={{ width: '370px', alignSelf: 'center' }} />
          <Space height={26} />
          <TermsInerBox>
            <TermsContainer>
              <Checkbox
                variant="square-line"
                value={sitterStore.signUpForm.isServiceAgree}
                onChange={value =>
                  (sitterStore.signUpForm.isServiceAgree = value)
                }
              />
              <Space width={15} />
              <DarkGray15 bold>
                <span style={{ color: Color.Primary }}>[필수]</span> 서비스
                이용약관
              </DarkGray15>
            </TermsContainer>
            <MoreBtn url={'/terms/service-terms.html'} />
          </TermsInerBox>
          <Space height={26} />
          <TermsInerBox>
            <TermsContainer>
              <Checkbox
                variant="square-line"
                value={sitterStore.signUpForm.isPrivacyAgree}
                onChange={value =>
                  (sitterStore.signUpForm.isPrivacyAgree = value)
                }
              />
              <Space width={15} />
              <DarkGray15 bold>
                <span style={{ color: Color.Primary }}>[필수]</span> 개인정보
                수집 및 이용 동의
              </DarkGray15>
            </TermsContainer>
            <MoreBtn url={'/terms/privacy-policy.html'} />
          </TermsInerBox>
          <Space height={26} />
          <TermsInerBox>
            <TermsContainer>
              <Checkbox
                variant="square-line"
                value={sitterStore.signUpForm.isMarketingAgree}
                onChange={value =>
                  (sitterStore.signUpForm.isMarketingAgree = value)
                }
              />
              <Space width={15} />
              <DarkGray15 bold>
                <span style={{ color: Color.Primary }}>[선택]</span> 혜택/정보
                수신동의
              </DarkGray15>
            </TermsContainer>
            <MoreBtn url={'https://woorihero.com'} />
          </TermsInerBox>
        </InnerBox>

        <Space height={54} />
        <Bottom label="완료" onClickNext={onSubmit} />
      </>
    );
  }
});

const MoreBtn = ({ url }) => {
  return (
    <Link to={url} target={'_blank'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '63px',
        }}
      >
        <Primary15 bold style={{ textDecoration: 'underline' }}>
          자세히
        </Primary15>

        <img
          style={{ width: '10px' }}
          src={require('asset/icon/arrow_right_orange.svg').default}
        ></img>
      </div>
    </Link>
  );
};

const InnerBox = styled.div`
  width: 390px;
  display: flex;
  flex-direction: column;
`;
const TermsContainer = styled.div`
  display: flex;
`;
const TermsInerBox = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
`;
