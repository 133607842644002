import { VerticalLayout } from 'atom/layout';
import Apply from 'component-mobile/b2b/Apply';
import Content1 from 'component-mobile/b2b/Content1';
import Content2 from 'component-mobile/b2b/Content2';
import Content3 from 'component-mobile/b2b/Content3';
import TopMain from 'component-mobile/b2b/TopMain';
import { Footer } from 'component-mobile/footer';
import { Header } from 'component-mobile/header';
import { useRef } from 'react';
import styled from 'styled-components';

const B2bPage = props => {
  const applyRef = useRef(null);
  const moveToApply = () => {
    applyRef.current.scrollIntoView();
  };
  return (
    <>
      <Header />
      <VerticalLayout>
        <TopMain />
        <Content1 />
        <Content2 />
        <Content3 moveToApply={moveToApply} />
        <Content4Img src={require('asset/mobile/b2b/content4.png').default} />
        <div ref={applyRef} style={{ width: '100%' }}>
          <Apply />
        </div>
      </VerticalLayout>
      <Footer />
    </>
  );
};

export default B2bPage;

const Content4Img = styled.img`
  width: 100%;
`;
