import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
const Reviews = () => {
  return (
    <Swiper
      width={1920}
      slidesPerView={4.3}
      spaceBetween={550}
      loop={true}
      initialSlide={2}
      autoplay={true}
      centeredSlides={true}
      className="mySwiper"
    >
      <SwiperSlide>
        <img
          style={{ width: '580px' }}
          src={require('asset/event/event_playkit/review1.png').default}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          style={{ width: '580px' }}
          src={require('asset/event/event_playkit/review2.png').default}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          style={{ width: '580px' }}
          src={require('asset/event/event_playkit/review3.png').default}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          style={{ width: '580px' }}
          src={require('asset/event/event_playkit/review4.png').default}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default Reviews;
