import { TextareaAutosize, TextField } from '@material-ui/core';
import { DarkGray32 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const ApplyInputRow = ({ input }) => {
  const {
    label,
    value,
    onChange,
    children,
    spaceHeight,
    placeholder,
    isImportant = false,
    isTextArea = false,
    type = 'text',
  } = input;

  return (
    <FlexBox style={{ margin: `${spaceHeight}px 0`, alignItems: 'start' }}>
      <div style={{ flex: '1 1 25%' }}>
        <DarkGray32 style={{ fontSize: '34px', marginTop: '10px' }} bold>
          <span style={{ color: '#ED584E' }}>
            {isImportant ? '* ' : <span>&nbsp;&nbsp;&nbsp;</span>}
          </span>
          {label}
        </DarkGray32>
      </div>
      <div style={{ flex: '1 1 50%', width: '100%' }}>
        {isTextArea ? (
          <TextareaAutosize
            type={type}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            value={value}
            onChange={onChange}
          />
        ) : (
          <TextField
            type={type}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            value={value}
            onChange={onChange}
            InputProps={{
              style: {
                height: '72px',
                backgroundColor: 'white',
              },
            }}
          />
        )}
      </div>
      <div style={{ flex: '1 1 25%', width: '100%' }}>{children}</div>
    </FlexBox>
  );
};

const FlexBox = styled.div`
  width: 960px;
  display: flex;
`;

export default ApplyInputRow;
