import { observable } from 'mobx';
import { ITrainingSchedule } from 'model';

import { ISitterRegisterForm, ISitterSighUpForm } from './model';

const DefaultRegisterForm = {
  name: '',
  phone: '',
  hasExperience: true,
  experienceComment: '',
  address: '',
  terms: [false, false, false],
  birthday: '',
  targetName: null,
  targetDate: '',
  targetPlace: '',
  targetMapLink: null,
  funnel: '', //유입경로
} as ISitterRegisterForm;

const DefaultSignUpForm = {
  phone: '',
  name: '',
  isMarketingAgree: false,
  isServiceAgree: false,
  birth: '', // 6자리
  address: '',
  detailAddress: '',
  accessNumber: '',
  recommendCode: null,
  isPrivacyAgree: false,
  hasNurture: true, // 자녀양육경험
  hasOtherExperience: true, //자녀외 양육경험
  hasNurtureExpert: '', //자녀외 양육경험 코멘트
  applyRoute: null, //유입경로
} as ISitterSighUpForm;

export interface ISitterStore {
  form: ISitterRegisterForm;
  signUpForm: ISitterSighUpForm;

  sendVerificationCode(phone: string); //ok
  confirmVerificationCode(phone: string, accessNumber: number); //ok
  signUpHero(form: ISitterSighUpForm); //ok

  getTrainningSchedules(): Promise<any>; //ok

  resetForm();
  resetSignUpForm();
  applyTraining(form: ISitterRegisterForm);
  submitRegister(form: ISitterRegisterForm): Promise<void>;
  getMyTrainingSchedule(id: number);

  //mypage
  getMyInfo(id: string, type: string);
  updateMyInfo(body);

  getMyApplyInfo(id: string);
}
const sitterStore = observable<ISitterStore>({
  form: DefaultRegisterForm,
  signUpForm: DefaultSignUpForm,

  async sendVerificationCode(phone: string) {
    await apiNew.post(`/home/user/accessNumber`, {
      phone,
    });
  },
  async confirmVerificationCode(phone: string, accessNumber: number) {
    return await apiNew.post(`/home/user/accessNumber/check`, {
      phone,
      accessNumber,
    });
  },

  async getTrainningSchedules() {
    const { result } = await apiNew.get('/home/training/trainingList');
    return result;
  },
  async getMyTrainingSchedule(id: number) {
    return await apiNew.get('/home/mypage/hero/training', {
      id,
    });
  },

  resetForm() {
    this.form = JSON.parse(JSON.stringify(DefaultRegisterForm));
  },
  resetSignUpForm() {
    this.signUpForm = DefaultSignUpForm;
  },
  async signUpHero(form: ISitterSighUpForm) {
    return await apiNew.post('/home/user/signup/hero', {
      ...form,
    });
  },
  async submitRegister(form: ISitterRegisterForm) {
    return await api.post(`/hero/application`, {
      ...form,
    });
  },

  async applyTraining(form: ISitterRegisterForm) {
    return await apiNew.post('/home/training/training', {
      ...form,
    });
  },
  async getMyInfo(id: string, type: string) {
    return await apiNew.get(`/home/mypage/`, {
      id,
      type,
    });
  },
  async updateMyInfo(body) {
    return await apiNew.put('/home/mypage/hero', {
      ...body,
    });
  },
  async getMyApplyInfo(id: string) {
    return await apiNew.get('/home/mypage/hero/training', {
      id,
    });
  },
});

export default sitterStore;
