import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray10, DarkGray18, LightGray12, Primary18 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Reviews = props => {
  SwiperCore.use([Pagination]);
  const whatToDos = [
    {
      src: require('asset/event/event_5years_for_parents/mobile/review_detail_1.png')
        .default,
      title: `전문 양성교육 및 
            보수교육 진행`,
      content: `아이의 기질/성향을 이해하고, 
            아이와의 상호작용 질을 
            높이기 위한 전문 양성교육과 
            보수교육을 진행합니다.`,
    },
    {
      src: require('asset/event/event_5years_for_parents/mobile/review_detail_2.png')
        .default,
      title: `아이돌봄 실습을 통한
        돌봄 역량강화`,
      content: `돌봄 실습을 통하여 
        실제 돌봄 실무 역량을 
        향상시키게 됩니다.`,
    },
    {
      src: require('asset/event/event_5years_for_parents/mobile/review_detail_3.png')
        .default,
      title: `자체개발한 1000가지
        히어로 놀이법 제공`,
      content: `히어로에서 자체 개발한
        다양한 놀이 법들을 활용하여
        양질의 돌봄서비스를 제공합니다.`,
    },
  ];

  return (
    <Container center>
      <Space height={64} />
      <DarkGray18 bold center>
        우리동네 좋은 선생님 대신 찾아주는
      </DarkGray18>
      <Primary18 bold center>
        집 근처 돌봄 서비스, 돌봄히어로!
      </Primary18>
      <Space height={16} />
      <DarkGray10>
        <span style={{ fontWeight: 'bold' }}>
          동네에 숨어 계시는 좋은 선생님
        </span>
        을 발굴/양성하기 위해
      </DarkGray10>
      <DarkGray10>
        <span style={{ fontWeight: 'bold' }}>
          서울시 및 여성 관련 기관 등과 협력하여 선생님을 양성
        </span>
        하고 있습니다.
      </DarkGray10>
      <Space height={24} />
      <img
        style={{ width: '261px' }}
        src={
          require('asset/event/event_5years_for_parents/mobile/mobile_review_map.png')
            .default
        }
      />
      <Space height={32} />
      {whatToDos.map((item, idx) => (
        <ImageCard
          key={item.title}
          index={idx + 1}
          src={item.src}
          title={item.title}
          content={item.content}
        />
      ))}
      <Space height={48} />
      <DarkGray18 bold>이용 중인 부모님들의</DarkGray18>
      <DarkGray18 bold>
        만족도 <span style={{ color: '#ED584E' }}>9.8점</span> 히어로 돌봄
        서비스!
      </DarkGray18>
      <Space height={8} />
      <LightGray12 semiBold>
        좋은 혜택과 함께 이번기회에 이용해보세요!
      </LightGray12>
      <Space height={34} />
      <div
        style={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        <StyledSwiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          initialSlide={0}
          centeredSlides={true}
          className="mySwiper"
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <img
              style={{ width: '269px' }}
              src={
                require('asset/mobile/event/event_playkit/m-review1.png')
                  .default
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              style={{ width: '269px' }}
              src={
                require('asset/mobile/event/event_playkit/m-review2.png')
                  .default
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              style={{ width: '269px' }}
              src={
                require('asset/mobile/event/event_playkit/m-review3.png')
                  .default
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              style={{ width: '269px' }}
              src={
                require('asset/mobile/event/event_playkit/m-review4.png')
                  .default
              }
            />
          </SwiperSlide>
        </StyledSwiper>
      </div>
      <Space height={54} />
    </Container>
  );
};
const Container = styled(VerticalLayout)`
  background: rgba(246, 159, 48, 0.04);
`;

const CardIndex = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 48px;
  transform: translate(-1rem, 0);

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.1em;
  color: #969696;
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  margin: 0.5rem 0;
  color: #484848;
`;

const CardContent = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  white-space: pre-line;
  color: #484848;
`;

const ImageCard = ({ src, index, title, content }) => {
  return (
    <VerticalLayout
      center
      style={{
        width: '182px',
        margin: '1rem',
      }}
    >
      <img
        style={{
          width: '180px',
          height: '120px',
        }}
        src={src}
      />
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <CardIndex>{index}</CardIndex>
        <VerticalLayout>
          <CardTitle>{title}</CardTitle>
          <CardContent>{content}</CardContent>
        </VerticalLayout>
      </div>
    </VerticalLayout>
  );
};

const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullet-active {
    background-color: orange;
  }
  
  .swiper-container {
    
    
  }
  .swiper-slide-active{
    position: relative !important;
    height: 400px;
  }
  .swiper-pagination-bullets {
    position: absolute !important;
    left: 0px !important;
    bottom:20px !important;
    
    z-index:1;
`;

export default Reviews;
