import { RoundButton } from 'atom/button';
import { VerticalLayout } from 'atom/layout';
import LimitedPlayKitCount from 'component-mobile/event/5yearsForParents/LimitedPlayKitCount';
import Main5YearEvent from 'component-mobile/event/5yearsForParents/Main5YearEvent';
import Reviews from 'component-mobile/event/5yearsForParents/Reviews';
import Reward1 from 'component-mobile/event/5yearsForParents/Reward1';
import Reward1HowTo from 'component-mobile/event/5yearsForParents/Reward1HowTo';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { URL } from 'constant';
import { DarkGray10 } from 'atom/text';
import Reward2 from 'component-mobile/event/5yearsForParents/Reward2';
import Reward2HowTo from 'component-mobile/event/5yearsForParents/Reward2HowTo';
import ApplyEvent from 'component-mobile/event/5yearsForParents/ApplyEvent';
import { Footer } from 'component-mobile/footer';
import NoticesEvent from 'component-mobile/event/5yearsForParents/NoticesEvent';

const Event5YearsForParents = props => {
  const applyRef = useRef(null);
  const scrollToApply = () => applyRef.current.scrollIntoView();
  const BtnGroup = () => {
    return (
      <FlexBox>
        <RoundButton
          style={{
            fontSize: '10px',
            width: '120px',
            height: '32px',
            padding: 0,
            marginRight: '0.5rem',
          }}
          onClick={scrollToApply}
        >
          서비스 신청하기
        </RoundButton>
        <AddKaKaoFriendBtn />
      </FlexBox>
    );
  };
  return (
    <Container>
      <Main5YearEvent />
      <LimitedPlayKitCount />
      <Reviews />
      <Reward1 />
      <Reward1HowTo BtnGroup={BtnGroup} />
      <Reward2 />
      <Reward2HowTo BtnGroup={BtnGroup} />
      <div ref={applyRef}>
        <ApplyEvent />
      </div>
      <Seperator />
      <NoticesEvent />
      <StickyMenuBar>
        <BtnGroup />
      </StickyMenuBar>
      <Footer />
    </Container>
  );
};

const Seperator = styled.div`
  width: 100%;
  height: 12px;
  background: #d9d9d9;
  overflow: overlay;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;
const Container = styled(VerticalLayout)`
  font-family: 'Noto Sans KR';
  min-width: 360px;
  position: relative;
`;
const KakaoButton = styled.button`
  width: 120px;
  height: 32px;
  margin-left: 0.5rem;
  background: #f9e54d;
  border-radius: 30px;
  border: none;

  cursor: pointer;
  &:hover {
    filter: contrast(1.5);
  }
`;

const StickyMenuBar = styled.div`
  float: left;
  position: fixed;
  width: 100%;
  min-width: 360px;
  height: 56px;
  bottom: 0px;
  z-index: 999;
  background: rgba(255, 210, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddKaKaoFriendBtn = () => {
  return (
    <KakaoButton onClick={() => window.open(URL.Kakao)}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: '15px',
            marginRight: '.5rem',
          }}
          src={
            require('asset/event/event_5years_for_parents/kakaoIcon.png')
              .default
          }
        />
        <DarkGray10
          style={{
            color: '#2D2D2D',
          }}
          bold
        >
          카카오톡 추가하기
        </DarkGray10>
      </div>
    </KakaoButton>
  );
};

export default Event5YearsForParents;
