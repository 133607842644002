import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { CareKind, CareKindNew, CareType, CareTypeNew } from 'model';
import { Title, Subtitle } from './style';
import { RequestStep4 } from './RequestStep4';
import { RequestStep5Irregular, RequestStepAdditionalServices } from '.';

export const RequestStep2 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();

  const onClickNext = () => {
    if (customerStore.applyForm.type === CareKindNew.Once)
      onNext(RequestStep5Irregular);
    else onNext(RequestStep4);
    //else onNext(RequestStepAdditionalServices);
  };

  useEffect(() => {
    if (!customerStore.applyForm.careTypes) return;

    //TODO 순서 정렬 체크
    customerStore.applyForm.careTypes = customerStore.applyForm.careTypes.sort(
      (a, b) => {
        return Order[a] > Order[b] ? 1 : -1;
      },
    );
  }, [customerStore.applyForm.careTypes]);

  return (
    <>
      <Title>
        돌봄 종류를
        <br />
        선택해주세요.
      </Title>
      <Space height={8} />
      <Subtitle>
        *중복으로 선택이 가능합니다.
        <br />
        *등하원이 필요없을 경우, 놀이돌봄만 선택해주세요.
      </Subtitle>
      <Space height={60} />

      <MultiSelect
        items={[
          { label: '등원 + 놀이돌봄', value: CareTypeNew.DropOff },
          { label: '하원 + 놀이돌봄', value: CareTypeNew.PickUp },
          { label: '놀이돌봄만', value: CareTypeNew.PlayOnly },
        ]}
        value={customerStore.applyForm.careTypes}
        onChange={e => (customerStore.applyForm.careTypes = e)}
      />
      <Space height={60} />
      <Bottom
        progress={(1 / 11) * 2}
        disabled={customerStore.applyForm.careTypes.length === 0}
        onClickNext={onClickNext}
      />
    </>
  );
});

const Order = {
  [CareTypeNew.DropOff]: 1,
  [CareTypeNew.PickUp]: 2,
  [CareTypeNew.PlayOnly]: 3,
};
