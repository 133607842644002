import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Space } from '../layout';

interface UnpaddingProps {
  vertical?: boolean;
  horizontal?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
}
export const Unpadding = ({
  vertical = true,
  horizontal = true,
  children,
  ...props
}: UnpaddingProps) => {
  return (
    <>
      <Container
        vertical={vertical}
        horizontal={horizontal}
        {...props}
      >
        {children}
      </Container>
      {vertical && <Space height={20} />}
    </>
  );
};

const Container = styled.div<Partial<UnpaddingProps>>`
  ${({ horizontal }) => horizontal ? `
    margin-left: -${20}px;
    margin-right: -${20}px;
  ` : `
  `}
  ${({ vertical }) => vertical ? `
    /* margin-top: -${20}px; */
    margin-bottom: -${20}px;
  ` : `
  `}
`;
