import React from 'react';
import styled from 'styled-components';
import { HorizontalLayout, Space } from 'atom/layout';
import { useIsMobile } from 'util/index';
import { DarkGray18 } from 'atom/text';
import { LinearProgress } from '@material-ui/core';

export const HeaderSignUpInFrameCustomer = ({
  progress,
  title = '',
  onGoback,
}) => {
  const isMobile = useIsMobile(true);

  // PC에서 모바일 페이지 보여주는 경우
  if (!isMobile) return <></>;

  return (
    <>
      <Holder>
        <Container>
          <Space width={23} />
          <img
            src={require('asset/icon/backArrow.svg').default}
            onClick={onGoback}
          />

          <DarkGray18
            style={{ margin: 'auto', transform: 'translate(-10px, 0)' }}
            bold
          >
            {title}
          </DarkGray18>
          <Space width={23} />
        </Container>
        <LinearProgressBar
          color="primary"
          variant={'determinate'}
          value={progress}
        />
      </Holder>
    </>
  );
};

const LinearProgressBar = styled(LinearProgress)`
  position: fixed;
  top: 55px;
  left: 0px;
  width: 100%;
  z-index: 11;
`;
const Holder = styled.header`
  position: relative;

  width: 100%;
  height: 56px;
`;
const Container = styled(HorizontalLayout)`
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 56px;

  background: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

  align-items: center;

  z-index: 10;
`;
