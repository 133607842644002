import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { Gray14, Gray20, LightGray10, LightGray14, Primary24, Primary18 } from 'atom/text';
import { HorizontalLayout, Push, Space } from 'atom/layout';
import { useIsMobile } from 'util/index';

interface ExtraServiceCardProps {
  image: string;
  title: string;
  content: string;
  onClick: () => void;
};
export const ExtraServiceCard = ({
  image,
  title,
  content,
  onClick,
}: ExtraServiceCardProps) => {
  const isMobile = useIsMobile();

  const Title = isMobile ? Primary18 : Primary24;
  const Subtitle = isMobile ? Gray14 : Gray20;
  const Detail = isMobile ? LightGray10 : LightGray14;

  return (
    <Container
      isMobile={isMobile}
      onClick={onClick}
    >
      <Image
        isMobile={isMobile}
        src={image}
      />
      <Space height={24} />
      <Title center bold>
        {title}
      </Title>
      <Space height={12} />
      <Subtitle center style={{ whiteSpace: 'pre' }}>
        {content.replace('\\n', '\n')}
      </Subtitle>
      <Push />
      <HorizontalLayout center>
        <Push />
        <Detail className="detail" semiBold>
          자세히 보기
        </Detail>
        <SVG
          style={{ transform: 'translateY(1px)', width: isMobile ? '12px' : 'auto' }}
          src={require('asset/icon/arrow_right.svg').default}
        />
        <Space width={isMobile ? 12 : 24} />
      </HorizontalLayout>
      <Space height={isMobile ? 12 : 24} />
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 496px;

  background: #fff;
  box-shadow: 3px 4px 16px #dedede;
  border-radius: 24px;
  box-sizing: border-box;

  overflow: hidden;
  cursor: pointer;

  transition: all 0.25s ease;

  svg {
    color: #969696;
  }
  &:hover {
    transform: scale(1.015);
    border: 2px solid #e9901e;

    img { 
      filter: saturate(1.35);
    }
    .detail, svg {
      color: #e9901e;
    }
  }

  ${({ isMobile }) => isMobile ? `
    width: 230px;
    height: 320px;

    margin-left: 16px;
    margin-right: 16px;
  ` : `
  `}
`;
const Image = styled.img<any>`
  width: 100%;
  height: 295px;

  object-fit: cover;

  ${({ isMobile }) => isMobile ? `
    height: 174px;
  ` : `
  `}
`;
