import {
  Button,
  CircularProgress,
  TextField,
  withStyles,
} from '@material-ui/core';
import { Labeled } from 'atom/input';
import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { LightGray11, LightGray18 } from 'atom/text';
import { AddressPopup } from 'component/popup';
import { Color } from 'constant';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext, useAuthDispatch } from '../../context/AuthContext';
import { useStores } from 'state/index';
import { useTopmost } from 'util/index';
import { getUserId, getUserType } from 'util/token';
//TODO 이 파일이 속해있는 폴더위치가 잘못되어있다. 상위 폴더로 이동 시킬 것.

const MyInfo = props => {
  const topmost = useTopmost();
  const [myInfo, setMyInfo] = useState({
    name: '',
    address: '',
    detail_address: '',
    phone: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sitterStore } = useStores();
  const history = useHistory();
  const { logout } = useAuthContext();
  const dispatch = useAuthDispatch();

  const onClickEdit = async () => {
    if (isEdit) {
      try {
        const { ok, message } = await sitterStore.updateMyInfo({
          id: Number(getUserId()),
          ...myInfo,
          detailAddress: myInfo.detail_address,
          city: myInfo.address.split(' ')[1],
        });
        if (ok) {
          showConfirm('회원정보가 변경되었습니다.');
        } else {
          alert(`회원정보 변경에 실패하였습니다. ${message}`);
        }
      } catch (e) {
        alert(`회원정보 변경에 실패하였습니다. ${e.message}`);
      }
    }
    setIsEdit(prev => !prev);
  };
  const checkWithdrawal = () => {
    showYesNoPopup('정말 탈퇴하시겠습니까?', withdrawal);
  };
  const withdrawal = async () => {
    try {
      const { ok, message } = await sitterStore.updateMyInfo({
        id: Number(getUserId()),
        ...myInfo,
        detailAddress: myInfo.detail_address,
        city: myInfo.address.split(' ')[1],
        status: 3, //탈퇴 상태
      });
      if (ok) {
        logout();
        dispatch({
          type: 'updateLoginState',
          isLogin: false,
        });
        showConfirm('회원탈퇴 되었습니다.');
        history.push('/');
      } else {
        alert(`회원탈퇴에 실패하였습니다. ${message}`);
      }
    } catch (e) {
      alert(`회원탈퇴를 시도했으나, 오류가 있습니다. ${e.message}`);
    }
  };
  const editAddress = () => {
    if (isEdit) {
      const id = topmost.show(
        <AddressPopup
          value=""
          initialDetailAddress={''}
          onSubmit={(x, y) => {
            setMyInfo(prev => ({
              ...prev,
              address: x,
              detail_address: y,
            }));
          }}
          onClose={() => topmost.dismiss(id)}
        />,
      );
    }
  };

  useEffect(() => {
    const getMyinfo = async () => {
      setLoading(true);
      try {
        const { ok, result, message } = await sitterStore.getMyInfo(
          getUserId(),
          getUserType(),
        );
        if (ok) {
          setMyInfo(result);
        } else {
          alert(`회원정보 조회에 실패하였습니다. : ${message}`);
        }
      } catch (e) {
        alert(`회원정보 조회에 실패하였습니다. : ${e.message}`);
      } finally {
        setLoading(false);
      }
    };
    getMyinfo();
  }, []);

  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <Space height={64} />
      <VerticalLayout center>
        <Labeled label="1. 성함" labelStyle={{ fontSize: '18px' }} gap={10}>
          <DarkerDisabledTextField
            value={myInfo.name}
            disabled
            variant="outlined"
            style={{ width: '390px' }}
          />
        </Labeled>
        <Space height={28} />

        <Labeled label="2. 주소" labelStyle={{ fontSize: '18px' }} gap={10}>
          <TextField
            value={`${myInfo.address}`}
            onClick={editAddress}
            disabled={!isEdit}
            variant="outlined"
            style={{ width: '390px' }}
            InputProps={
              myInfo.address
                ? {
                    startAdornment: (
                      <img
                        style={{ width: '16px', marginRight: '12px' }}
                        src={require('asset/icon/flag_gray.svg').default}
                      />
                    ),
                  }
                : {}
            }
          />
          <Space height={10} />
          <TextField
            value={`${myInfo.detail_address}`}
            onClick={editAddress}
            disabled={!isEdit}
            variant="outlined"
            style={{ width: '390px' }}
          />
        </Labeled>
        <Space height={28} />
        <Labeled
          label="3. 핸드폰 번호"
          labelStyle={{ fontSize: '18px' }}
          gap={10}
        >
          <DarkerDisabledTextField
            value={myInfo.phone}
            disabled
            variant="outlined"
            style={{ width: '390px' }}
          />
        </Labeled>
        <div style={{ width: '390px' }}>
          <LightGray18>
            성함 및 핸드폰 번호 변경을 원하실 경우 히어로센터
            <br />
            (02-6232-0220)로 연락해주세요.
          </LightGray18>
        </div>
        <Space height={64} />
        <Button
          style={{
            width: '390px',
            height: '55px',
            fontSize: '16px',
            color: isEdit ? Color.white : Color.Primary,
          }}
          variant={isEdit ? 'contained' : 'outlined'}
          color={'primary'}
          onClick={onClickEdit}
        >
          {isEdit ? '저장 하기' : '수정 하기'}
        </Button>
        <Space height={120} />

        <HorizontalLayout
          center
          style={{ width: '390px', justifyContent: 'right' }}
        >
          <Button
            onClick={() => {
              logout();
              dispatch({
                type: 'updateLoginState',
                isLogin: false,
              });
              history.push('/');
            }}
          >
            <LightGray11
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              로그아웃
            </LightGray11>
          </Button>

          <LightGray11>{` | `}</LightGray11>
          <Button onClick={checkWithdrawal}>
            <LightGray11
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              회원탈퇴
            </LightGray11>
          </Button>
        </HorizontalLayout>
      </VerticalLayout>
    </>
  );
};

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: Color.darkGray,
      backgroundColor: '#ECECEC',
    },
  },
})(TextField);

export default MyInfo;
