import React from 'react';
import styled, { CSSProperties } from 'styled-components';

interface HighlightedTextProps {
  children: React.ReactNode;
  style?: CSSProperties;
};
export const HighlightedText = ({
  children,
  style,
  ...props
}: HighlightedTextProps) => {
  return (
    <Container
      style={style}
      {...props}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
`;
