import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { WeekdayPicker } from 'atom/input';
import { RoundButton } from 'atom/button';
import { Space, HorizontalLayout } from 'atom/layout';
import { Color } from 'constant';
import { RequestFaqContents } from 'content';
import { Title, Subtitle } from './style';
import { RequestDone } from './RequestDone';

export const RequestFaq = observer(({
  onNext,
}) => {
  return (
    <>
      <Title>
        잠깐! 자주 묻는 질문을 <br/>
        꼭 확인해주세요 :)
      </Title>
      <Space height={32} />

      {RequestFaqContents.map((x, idx) => (
        <React.Fragment
          key={idx}
        >
          <FaqItem
            {...x}
          />
          <Space height={35} />
        </React.Fragment>
      ))}
      <Space height={17} />

      <Footer>
        그 외 궁금하신 점은 Q&A를 확인해주세요:)
      </Footer>
      <Space height={56} />
      <RoundButton
        style={{ width: '100%' }}
        onClick={() => onNext(RequestDone)}
      >
        확인
      </RoundButton>
    </>
  );
});

const Footer = styled.div`
  font-size: 17px;
  font-weight: bold;
  text-align: center;

  color: #707070;
`;

interface FaqItemProps {
  question: string;
  answer: string;
};
const FaqItem = ({
  question,
  answer,
}: FaqItemProps) => {
  return (
    <div>
      <QuestionText>
        Q. {question}
      </QuestionText>
      <Space height={20} />
      <AnswerContainer>
        {answer}
      </AnswerContainer>
    </div>
  );
};
const QuestionText = styled.div`
  font-size: 17px;
  font-weight: bold;

  letter-spacing: -0.43px;
  color: ${Color.Primary};
`;
const AnswerContainer = styled.div`
  color: #707070;

  font-size: 15px;
  font-weight: 500;

  line-height: 1.47;
  letter-spacing: -0.38px;

  border-radius: 8px;
  background-color: rgba(112, 112, 112, 0.05);

  padding: 17px 17px;
`;
