import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { UnderlinedText } from 'atom/text';
import { ChildPopup } from 'component/popup';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestStep11 } from './RequestStep11';

export const RequestStep10 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();

  const onSelect = (value: number) => {
    if (value === 4) customerStore.applyForm.petCompanion = [];
  };

  useEffect(() => {
    if (!customerStore.applyForm.petCompanion) return;

    customerStore.applyForm.pet = customerStore.applyForm.petCompanion
      .filter(x => x !== 4)
      .join(',');
  }, [customerStore.applyForm.petCompanion]);

  return (
    <>
      <Title>반려동물이 있나요?</Title>
      <Space height={8} />
      <Subtitle>*중복으로 선택이 가능합니다.</Subtitle>
      <Space height={60} />

      <MultiSelect
        twoColumns
        items={[
          { label: '강아지', value: 1 },
          { label: '고양이', value: 2 },
          { label: '기타', value: 3 },
          { label: '없어요', value: 4 },
        ]}
        value={
          customerStore.applyForm.petCompanion.length === 0
            ? [4]
            : customerStore.applyForm.petCompanion.filter(x => x !== 4)
        }
        onSelect={onSelect}
        onChange={e => (customerStore.applyForm.petCompanion = e)}
      />
      <Space height={60} />
      <Bottom
        progress={(1 / 11) * 10}
        onClickNext={() => onNext(RequestStep11)}
      />
    </>
  );
});
