import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray16 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const NoticesEvent = props => {
  return (
    <Container center>
      <Space height={64} />
      <DarkGray16 bold>꼭 읽어주세요</DarkGray16>
      <Space height={11} />
      <Line />
      <Space height={37} />
      <TextArea>
        {`• 이벤트 참여를 희망하실 경우 [이벤트 신청하기]에 정보 기재 후 
        [지금 바로 신청하기] 버튼을 눌러 이벤트에 신청해주셔야 합니다.  
      • 이벤트 (혜택1)은 이벤트 기간내 3개월 이상 정기 돌봄을 신청 및 시작한 고객을 대상으로 제공됩니다.
      • 이벤트 (혜택1)은 신규 서비스 이용 고객만 참여할 수 있습니다.
      • 이벤트 페이지 [지금 바로 신청하기] 외 다른 방법으로 돌봄 서비스를 매칭한 경우에는 이벤트 혜택을 제공하지 않습니다.
       • 이벤트 (혜택1)으로 제공되는 창의놀이 이용권(10회)은 첫 돌봄 활동 시작일부터 3개월(12주) 동안 사용하실 수 있습니다.
       • 이벤트 (혜택1)은 타인에게 양도 및 판매할 수 없습니다.
       • 이벤트 (혜택1) 제공 조건 관련하여 보증금(10만원)이 부과되며, 
       제공 조건(정기 돌봄 3개월)을 충족할 경우 100% 반환됩니다.
       • 이벤트 (혜택1, 혜택2) 수량이 모두 소진될 경우 이벤트가 조기 마감될 수 있습니다.
       • 이벤트 (혜택1, 혜택2)는 최초 1회만 참여 가능하며 이벤트 기간이 종료된 후에는 참여할 수 없습니다.
       • 이벤트 관련 문의는 우리동네 돌봄히어로 고객센터 (02-6232-0206)로 문의 바랍니다.
        `}
      </TextArea>
      <Space height={64} />
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  background: #ececec;
`;
const Line = styled.div`
  width: 80%;
  height: 2px;
  background: #d9d9d9;
`;

const TextArea = styled.div`
  width: 75%;
  white-space: pre-line;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  color: #484848;
`;
export default NoticesEvent;
