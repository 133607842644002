import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import { Labeled } from 'atom/input';
import { HorizontalLayout, Space } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { ApplySignupStep2 } from './ApplySignupStep2';

export const ApplySignupStep1 = observer(({ Bottom, onNext, onLogin }) => {
  const { customerStore } = useStores();

  const checkForm = () => {
    if (customerStore.signUpForm.name.length < 2) {
      showConfirm('성함을 입력해주세요.');

      return false;
    }

    if (customerStore.signUpForm.email.length <= 0) {
      showConfirm('이메일 주소를 입력해주세요.');
      return false;
    }
    return true;
  };
  const clickOnNext = () => {
    if (checkForm()) {
      onNext(ApplySignupStep2);
    }
  };

  return (
    <>
      <Title>
        서비스 신청을 위해
        <br />
        회원가입이 필요합니다.
      </Title>
      <Space height={50} />

      <Labeled label="1. 성함" labelStyle={{ fontSize: '18px' }} gap={10}>
        <TextField
          placeholder="성함을 입력해주세요"
          variant="outlined"
          style={{ width: '390px' }}
          value={customerStore.signUpForm.name}
          onChange={e => (customerStore.signUpForm.name = e.target.value)}
        />
      </Labeled>
      <Space height={40} />

      <Labeled
        label="2. 이메일 주소"
        labelStyle={{ fontSize: '18px' }}
        gap={10}
      >
        <TextField
          placeholder="이메일 주소를 입력해주세요"
          variant="outlined"
          style={{ width: '390px' }}
          value={customerStore.signUpForm.email}
          onChange={e => (customerStore.signUpForm.email = e.target.value)}
        />
      </Labeled>
      <Space height={50} />
      <Bottom progress={(1 / 2) * 1} onClickNext={clickOnNext} />
    </>
  );
});
