import { ImageBox } from 'atom/image';
import { Space } from 'atom/layout';
import { DarkGray20, DarkGray24, DarkGray40, LightGray28 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

export const Benefits = () => {
  return (
    <ImageBox
      src={require('asset/event/event_sd/event_sd_benefit_bg.jpg').default}
      minHeight={926}
      style={{
        textAlign: 'center',
      }}
    >
      <Space height={116} />
      <DarkGray40 style={{ fontWeight: 700 }}>
        지금 바로 활동하면, 혜택이 와르르!
      </DarkGray40>
      <LightGray28>
        교육 수료 후 바로 활동하신 선생님에 한해서 제공됩니다.
      </LightGray28>
      <Space height={78} />
      <Container>
        <img
          src={require('asset/event/event_sd/benefit1.png').default}
          style={{ width: '336px', margin: '0 15px' }}
        />
        <img
          src={require('asset/event/event_sd/benefit2.png').default}
          style={{ width: '336px', margin: '0 15px' }}
        />
        <img
          src={require('asset/event/event_sd/benefit3.png').default}
          style={{ width: '336px', margin: '0 15px' }}
        />
      </Container>
    </ImageBox>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
