import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { HorizontalLayout, VerticalLayout, Space } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { Paper } from 'atom/deco';
import { Progress } from 'atom/progress';
import { Header } from 'component-mobile/header';
import { useStores } from 'state';
import { ApplyStep1, ApplyStep0 } from './apply';
import { useAuthContext } from '../../context/AuthContext';
import { getUserType } from 'util/token';

export const SitterApplyPage = ({}) => {
  const history = useHistory();
  const { isLogin } = useAuthContext();
  const { sitterStore } = useStores();
  const [hasHeader, setHasHeader] = useState(true);
  const [FormComponent, setFormComponent] = useState<React.FC<any>>(
    isLogin ? ApplyStep1 : ApplyStep0,
  );
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isLogin) {
      const type = getUserType();
      if (type !== 'HERO') {
        showConfirm('부모님은 히어로 교육 신청이 불가합니다.');
        history.goBack();
      }
    }
  }, []);
  useEffect(() => {
    sitterStore.resetForm();
  }, []);
  useEffect(() => {
    setCount(count => count + 1);

    window.history.replaceState(
      {},
      '히어로선생님 지원하기',
      `/hero/apply/q${count}`,
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [FormComponent]);

  return (
    <>
      {hasHeader && <Header />}
      <Container>
        <FormComponent
          Bottom={props => <FormBottom {...props} />}
          onNext={setFormComponent}
          setHasHeader={setHasHeader}
        />
      </Container>
    </>
  );
};

interface FormBottomProps {
  label?: string;
  disabled: boolean;
  onClickNext: () => void;
}
const FormBottom = ({
  label,
  disabled = false,
  onClickNext,
}: FormBottomProps) => {
  return (
    <>
      <RoundButton
        style={{ width: '100%', height: '62px' }}
        disabled={disabled}
        onClick={onClickNext}
      >
        {label || '다음'}
      </RoundButton>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: white;

  justify-content: center;

  padding: 43px 20px;
`;
