import React from 'react';
import { LinearProgress, withStyles } from '@material-ui/core';

import { Color } from 'constant';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: Color.Primary,
  },
}))(LinearProgress);

interface ProgressProps {
  value: number;
}
export const Progress = ({ value }: ProgressProps) => {
  return <BorderLinearProgress variant="determinate" value={value} />;
};
