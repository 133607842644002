import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { uniq } from 'lodash';
import { Space, Push } from 'atom/layout';
import { ServiceCard } from 'atom/care';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep6 } from './RequestStep6';
import { RequestStepPremiumPackage } from './RequestStepPremiumPackage';
import { usePrices } from 'state/hook/value';
import { price } from 'util/index';
import _ from 'lodash';

export const RequestStepHeroType = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const prices = usePrices();
  const isRegular =
    customerStore.applyForm.type === 1 && //정기 신청 이면서
    uniq(customerStore.applyForm.schedules.flatMap(x => x.weekday)).length > 1; //주 2회이상 신청하는 경우

  const getCareerRequestEnum = () => {
    const types = customerStore.applyForm.heroTypes;
    if (types.length === 3) {
      return 7;
    }
    if (types.length === 1 && types.includes('일반')) {
      return 1;
    }
    if (types.length === 1 && types.includes('경력')) {
      return 2;
    }
    if (types.length === 1 && types.includes('프로')) {
      return 3;
    }
    if (types.length === 2 && types.includes('프로')) {
      if (types.includes('경력')) {
        return 5;
      } else {
        return 6;
      }
    } else {
      return 4;
    }
  };
  const _onNext = () => {
    customerStore.applyForm.careerRequest = String(getCareerRequestEnum());
    if (customerStore.applyForm.heroTypes.length < 3)
      showConfirm(
        `원하시는 선생님이 계시지 않을 경우, \n경력 상관 없이 활동가능한 선생님을 추천해드립니다.`,
      );
    //창의놀이 서비스 잠정중단 2022.09.13
    onNext(RequestStep6);
    // if (customerStore.applyForm.type === 1) {
    //   onNext(RequestStepPremiumPackage);
    // } else {
    //   // 비정기는 창의놀이 패키지 신청 못함
    //   onNext(RequestStep6);
    // }
  };

  return (
    <>
      <Title>원하시는 선생님이 있나요?</Title>
      <Space height={8} />
      <Subtitle>
        *중복 선택이 가능합니다.
        <br />
        *선생님 경력에 따라 돌봄료가 달라질 수 있습니다.
      </Subtitle>
      <Space height={53} />

      <ServiceCard
        title="일반 선생님"
        price={`시간당 ${
          isRegular
            ? price(prices['정기_신입_1:1_day'])
            : price(prices['비정기_신입_1:1_day'])
        }원`}
        body={
          '히어로만의 까다로운 7단계 인증과정을 통과한\n믿을 수 있는 선생님이에요'
        }
        value={customerStore.applyForm.heroTypes.includes('일반')}
        anyChecked={customerStore.applyForm.heroTypes.length > 0}
        onChange={e => {
          if (e)
            customerStore.applyForm.heroTypes = uniq([
              ...customerStore.applyForm.heroTypes,
              '일반',
            ]);
          else
            customerStore.applyForm.heroTypes = customerStore.applyForm.heroTypes.filter(
              x => x !== '일반',
            );
        }}
      />
      <Space height={27} />

      <ServiceCard
        title="경력 선생님"
        price={`시간당 ${
          isRegular
            ? price(prices['정기_경력_1:1_day'])
            : price(prices['비정기_경력_1:1_day'])
        }원`}
        body={'히어로에서 500시간 혹은 1년 이상 활동한\n 경력 선생님들이에요'}
        value={customerStore.applyForm.heroTypes.includes('경력')}
        anyChecked={customerStore.applyForm.heroTypes.length > 0}
        onChange={e => {
          if (e)
            customerStore.applyForm.heroTypes = uniq([
              ...customerStore.applyForm.heroTypes,
              '경력',
            ]);
          else
            customerStore.applyForm.heroTypes = customerStore.applyForm.heroTypes.filter(
              x => x !== '경력',
            );
        }}
      />
      <Space height={27} />
      <ServiceCard
        title="프로 선생님"
        price={`시간당 ${
          isRegular
            ? price(prices['정기_프로_1:1_day'])
            : price(prices['비정기_프로_1:1_day'])
        }원`}
        body={'히어로에서 1000시간 혹은 3년 이상 활동한\n 프로 선생님들이에요 '}
        value={customerStore.applyForm.heroTypes.includes('프로')}
        anyChecked={customerStore.applyForm.heroTypes.length > 0}
        onChange={e => {
          if (e)
            customerStore.applyForm.heroTypes = uniq([
              ...customerStore.applyForm.heroTypes,
              '프로',
            ]);
          else
            customerStore.applyForm.heroTypes = customerStore.applyForm.heroTypes.filter(
              x => x !== '프로',
            );
        }}
      />

      <Space height={30} />
      <Bottom
        disabled={customerStore.applyForm.heroTypes.length === 0}
        progress={(1 / 11) * 5}
        onClickNext={_onNext}
      />
    </>
  );
});
