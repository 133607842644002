import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { VerticalLayout, Space, HorizontalLayout, Push } from 'atom/layout';
import { RoundButton } from 'atom/button';
import {
  Primary18,
  Gray16,
  LightGray12,
  Gray25,
  White24,
  Gray28,
} from 'atom/text';
import { ImageBox } from 'atom/image';

import { useIsMobile } from 'util/index';
import { WorkMinTable, HourlyPricingTable } from 'component/sitter';

interface WorkBasicPageProps {
  sheet?: boolean; // 튀어나온 시트 모드인지
  injectedHistory?: any;
  onClickApply?: () => void;
  onClose?: () => void;
}
export const WorkBasicPage = ({
  sheet,
  injectedHistory,
  onClickApply,
  onClose,
}: WorkBasicPageProps) => {
  const isMobile = useIsMobile(true);
  const history = useHistory() || injectedHistory;

  const isApplying =
    window.location.href.includes('apply') ||
    window.location.href.includes('event_sd'); // 지원 중 상세보기인지, 이벤트 페이지인지

  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/customer/service/basic/top.jpg').default}
          imageStyle={{ filter: 'brightness(0.8)' }}
          minHeight={isMobile ? 240 : 380}
          maxHeight={isMobile ? 240 : 380}
        >
          <Space height={isMobile ? 40 : 126} />
          <HorizontalLayout>
            <Space width={isMobile ? 40 : 100} />
            <VerticalLayout>
              <White24 bold style={{ fontSize: isMobile ? '24px' : '26px' }}>
                아이의 일상생활을 돕는
                <br />
                <span style={{ color: '#f69F30' }}>등하원 및 놀이돌봄</span>
              </White24>
              <Space height={isMobile ? 32 : 52} />
            </VerticalLayout>
          </HorizontalLayout>
        </ImageBox>

        <VerticalLayout center style={{ width: '100%', background: '#f9f9f9' }}>
          <SVG
            src={
              isMobile
                ? require('asset/sitter/work/basic/intro.svg').default
                : require('asset/sitter/work/basic/intro.svg').default
            }
            style={{ maxWidth: '100vw', overflow: 'visible' }}
          />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{
            width: 'calc(100%)',
            background: 'rgba(246, 159, 48, 0.04)',
          }}
        >
          <Space height={82} />
          <Gray28 center bold style={{ fontSize: isMobile ? '24px' : '28px' }}>
            등하원 및 놀이돌봄은 <br />
            이렇게 진행돼요!
          </Gray28>
          <Space height={62} />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'start',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            {isMobile ? (
              <SVG
                src={
                  require('asset/customer/service/basic/flow_mobile.svg')
                    .default
                }
                style={{
                  width: '55px',
                  overflow: 'visible',
                }}
              />
            ) : (
              <SVG
                src={
                  require('asset/customer/service/basic/flow_resize.svg')
                    .default
                }
                style={{
                  width: '80px',
                  overflow: 'visible',
                }}
              />
            )}
            <Space width={20} />
            <VerticalLayout style={{ transform: 'translateY(-5px)' }}>
              <Space height={3} />
              <Primary18 bold style={{ fontSize: isMobile ? '16px' : '18px' }}>
                아이와 함께하는 등하원/등하교
              </Primary18>
              <Space height={32} />
              <FlowImage
                src={require('asset/customer/service/basic/flow1.jpg').default}
                style={{ width: isMobile ? '218px' : '278px' }}
              />
              <Space height={isMobile ? 36 : 28} />

              <Primary18 bold style={{ fontSize: isMobile ? '16px' : '18px' }}>
                깔끔한 손 씻기부터 식사 및 간식지도
              </Primary18>
              <Space height={32} />
              <FlowImage
                src={require('asset/customer/service/basic/flow2.jpg').default}
                style={{ width: isMobile ? '218px' : '278px' }}
              />
              <Space height={isMobile ? 46 : 32} />

              <Primary18 bold style={{ fontSize: isMobile ? '16px' : '18px' }}>
                장난감으로 재미있는 놀이 진행
              </Primary18>
              <Space height={30} />
              <FlowImage
                src={require('asset/customer/service/basic/flow3.jpg').default}
                style={{ width: isMobile ? '218px' : '278px' }}
              />
              <Space height={94} />
            </VerticalLayout>
          </div>
        </VerticalLayout>

        <VerticalLayout
          center
          style={{
            width: 'calc(100% - 80px)',
            position: 'static',
            background: 'white',
          }}
        >
          <Space height={64} />
          <Gray28 bold center>
            등하원 및 놀이돌봄
            <br />
            최소 업무 시간 안내
          </Gray28>
          <Space height={32} />
          <WorkMinTable />
          <Space height={64} />

          <Gray28 bold center>
            시급안내
          </Gray28>
          <Space height={32} />
          <HourlyPricingTable />
          <Space height={12} />

          <LightGray12 right style={{ width: '100%', maxWidth: '400px' }}>
            *비정기의 경우 시간당 2000원이 추가됩니다.
          </LightGray12>
          <LightGray12 right style={{ width: '100%', maxWidth: '400px' }}>
            *선생님 경력에 따라 돌봄료가 달라질 수 있습니다.
          </LightGray12>
          <Space height={32} />

          <RoundButton
            variant="primary"
            style={
              isMobile
                ? {
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    // height: '7%',
                    bottom: '20px',
                  }
                : {
                    width: '101%',
                    borderRadius: '0px',
                    position: 'absolute',
                    left: '0px',
                    bottom: '0px',
                  }
            }
            onClick={() => {
              if (onClickApply) onClickApply();
              else if (sheet) onClose?.();
              else history.push('/service/apply');
            }}
          >
            {isApplying ? '확인' : '서비스 신청하기'}
          </RoundButton>
          <Space height={52} />
        </VerticalLayout>
      </Container>
    </>
  );
};

const Container = styled(VerticalLayout)`
  width: calc(100% + 2px);
  position: static;

  background: white;

  overflow-x: hidden;
`;
const FlowImage = styled.img`
  width: 278px;

  image-rendering: -webkit-optimize-contrast;
`;
