import { observable } from 'mobx';

import {
  SitterReviewContent,
  IFaqContent,
  SitterInterviewContent,
} from 'content';
import { INoticeContent } from 'src/content/Notices';
import { CostTableData } from './hook/value';

export interface IValueStore {
  getValue(key: string): Promise<string>;

  getPrices(): Promise<CostTableData>;
  getReviews(): Promise<SitterReviewContent[]>;
  getHeroIntros(): Promise<SitterInterviewContent[]>;
  getFaq(type: string): Promise<IFaqContent[]>;
  getNotices(query: object): Promise<INoticeContent[]>;
  getNotice(id: string): Promise<INoticeContent>;
}
const valueStore = observable<IValueStore>({
  async getValue(key: string) {
    const { result } = await apiNew.get(`/home/property/${key}`);
    return result;
  },
  async getReviews() {
    return await api.get(`/content/review`, {});
  },
  async getHeroIntros() {
    return await api.get(`/content/intro`, {});
  },
  async getPrices() {
    // const v = await api.get(`/value/price`, {});
    const data = await apiNew.get(`/home/cost/last-version`, {});

    console.log('check value:', JSON.stringify(data['result']));
    return data['result'];
  },
  async getNotices(query) {
    const data = await apiNew.get('/home/notice', query);
    return data['result'];
  },
  async getNotice(id) {
    const data = await apiNew.get(`/home/notice/${id}`);
    return data['result'];
  },
  async getFaq(type: string) {
    // const items = await api.get(`/notification/faq`, {
    //   type,
    // });
    const items = [
      {
        id: 10,
        title: '왜 아이중점 가사서비스 인가요?',
        content:
          '<p><span style="color: #000000;">돌봄 중에 가사일을 하게 되면 아이에게 온전히 집중할 수 없게 됩니다. 가장 중요한 것은 아이의 안전이기에 아이가 없는 시간을 활용하여 부모님의 수고를 덜어드리고 싶었습니다.&nbsp;</span></p><p style="line-height: 138%;"><span style="color: #000000;">아이가 노는 공간, 아이의 식기류, 아이가 먹는 음식 등 아이에게만 초점을 맞춘 가사서비스를 통해 부모님과 아이, 그리고 히어로 선생님이 서로 만족할 수 있는 서비스를 제공합니다.</span></p>',
        type: 'extra',
      },
      {
        id: 11,
        title: '돌봄제외하고 가사서비스만 이용할 수는 없나요?',
        content:
          '<p><span style="color: #000000;">한 명의 히어로 선생님이 돌봄부터 가사서비스까지 전담하여 제공하고 있기에 가사서비스만 따로 이용할 수 없는 점 양해부탁드립니다.</span></p>',
        type: 'extra',
      },
      {
        id: 12,
        title: '청소와 요리서비스 둘 다 이용할 수는 없나요?',
        content:
          '<p><span style="color: #000000;">히어로 선생님의 안전 및 진행될 아이돌봄에 집중하실 수 있도록 한 가지의 서비스만 추가로 제공하는 것을 원칙으로 하고 있습니다.</span></p>',
        type: 'extra',
      },
      {
        id: 13,
        title: '같은 선생님이 돌봄부터 가사서비스를 해주시는걸까요?',
        content:
          '<p><span style="color: #000000;">우리동네 돌봄히어로는 한 명의 선생님이 한 가정의 아이돌봄부터 가사서비스까지 전담합니다. 두 명의 선생님과 소통하실 필요 없이 한 명의 선생님과 조율하시면 되며 보다 안심하실 수 있습니다.</span></p>',
        type: 'extra',
      },
      {
        id: 14,
        title: '불가능 업무를 협의할 수는 없을까요?',
        content:
          '<p><span style="color: #000000;">히어로 선생님은 가사 도우미가 아닌 아이돌봄 선생님으로 아이와 관련된 업무만 진행이 가능합니다. 그 외 부모님과 관련된 가사일이나 반려동물 관련 일은 불가합니다.</span></p>',
        type: 'extra',
      },
      {
        id: 17,
        title: '돌봄을 제외하고 창의 놀이 서비스만 이용할 수는 없나요?',
        content:
          '<p style="line-height: 120%;"><span style="color: #505050;">우리동네 돌봄히어로는 등하원 및 놀이 돌봄 서비스를 중점으로 운영하고 있습니다.&nbsp;</span></p><p style="line-height: 120%;"><span style="color: #505050;">창의 놀이는 부가 서비스로 정기 기본 돌봄 연계 시에만 이용이 가능합니다.</span></p><p style="line-height: 120%;"></p>',
        type: 'premium',
      },
      {
        id: 19,
        title: '1:2의 경우 아이들의 나이차가 나는데 어떤 놀이를 제공받나요?',
        content:
          '<p><span style="color: #505050;">창의놀이의 사용 권장 연령은 만 4-8세 입니다. &nbsp;</span></p><p style="line-height: 120%;"><span style="color: #505050;">사용 권장 연령을 참고해 신청해주세요.</span></p><p style="line-height: 120%;"><span style="color: #505050;">두 아이의 나이차가 있을 때는 연령이 낮은 아이가 </span></p><p style="line-height: 120%;"><span style="color: #505050;">높은 연령의 놀이를 진행하기에는 어려움이 있을 수 있기 때문에</span></p><p style="line-height: 120%;"><span style="color: #505050;">기본적으로 연령이 낮은 아이에게 맞춰 놀이를 진행합니다.</span></p>',
        type: 'premium',
      },
      {
        id: 20,
        title: '같은 선생님이 돌봄부터 창의놀이를 해주시는 걸까요?',
        content:
          '<p><span style="color: #505050;">우리동네 돌봄히어로는 한 명의 선생님이 한 가정의 아이 돌봄부터 창의 놀이까지 전담합니다.&nbsp;</span></p><p style="line-height: 120%;"><span style="color: #505050;">두 명의 선생님과 소통하실 필요 없이 한 명의 선생님과 조율하시면 되며 보다 안심하실 수 있습니다.</span></p>',
        type: 'premium',
      },
      {
        id: 21,
        title: '필수 준비물을 꼭 구입해야하나요?',
        content:
          '<p><span style="color: #505050;">필수 준비물은 창의놀이에 꼭 필요한 준비물로 서비스 시작 시 최초 1회 구입이 필요합니다. (비용 30,000원)</span></p><p style="line-height: 120%;"><span style="color: #505050;">필수 준비물은 각 가정에 한 번만 제공되는 고정 준비물입니다.</span></p><p style="line-height: 120%;"><span style="color: #505050;">필수 준비물에는 핑거 물감, 트레이, 펀치, 딱풀, 마스킹 테이프, 목공 풀 등이 있습니다.</span></p><p style="line-height: 120%;"><span style="color: #505050;">재료가 모두 소진됐을 시 히어로 센터에서 추가로 구입을 하실 수 있습니다.</span></p>',
        type: 'premium',
      },
      {
        id: 22,
        title: '[돌봄관련] 활동 시간은 어떻게 되나요?',
        content:
          '<p>돌봄 시간은 최소 2시간부터로 최소활동시간을 보장해드리고 있으며 </p><p>활동시간은 가정마다 상이할 수 있습니다. </p>',
        type: 'hero',
      },
      {
        id: 23,
        title: '[돌봄관련] 정부에서 하는 돌봄인가요?',
        content:
          '<p>우리동네 돌봄히어로는 사설 돌봄업체로 정부에서 운영하고 있는 아이돌봄사업이 아닙니다.</p><p>다만, 서울시와 각 구 여성인력개발센터 등과 선생님 양성교육을 협력해서 진행하고 있습니다.</p>',
        type: 'hero',
      },
      {
        id: 24,
        title: '[돌봄관련] 4대 보험 가입이 되나요?',
        content:
          '<p>우리동네 돌봄히어로는 직업소개업 및 교육업으로 4대 보험 적용은 해당되지 않습니다.</p><p>같은 이유로 퇴직금 또한 지급이 불가합니다.</p>',
        type: 'hero',
      },
      {
        id: 25,
        title: '[돌봄관련] 공휴일에도 일을 하나요?',
        content:
          '<p>주말 및 법적공휴일에는 보통 일을 하지 않지만 간혹 서비스를 필요로 하는 가정이 있을 수 있습니다.</p><p>가능하실 경우, 자유롭게 지원해주시고 활동비는 주말비용으로 책정됩니다.</p>',
        type: 'hero',
      },
      {
        id: 26,
        title: '[활동료] 급여는 어떤 방식으로 받나요?',
        content:
          '<p>활동비는 우리동네 돌봄히어로에서 정산 후 선생님께 지급해드리고 있습니다.</p><p>정기의 경우, 4주 기준으로 활동비를 책정합니다. 4주가 되는 당일에 상세내역서를 안내드리며</p><p>안내드린 그 주 금요일에 지급됩니다. 비정기의 경우, 돌봄이 종료된 다음 날(공휴일 제외) 상세내역서를 안내드립니다.</p>',
        type: 'hero',
      },
    ];
    return items
      .filter(x => x.type === type)
      .map(x => ({
        id: x.id,
        question: x.title,
        answer: x.content,
      }));
  },
});
export default valueStore;
