import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';

import { HorizontalLayout, VerticalLayout, Space, Push } from 'atom/layout';
import { RoundButton } from 'atom/button';
import { Progress } from 'atom/progress';
import { Header } from 'component-mobile/header';
import { useStores } from 'state';
import { usePrices } from 'state/hook/value';
import { isIOS } from 'util/index';
import { RegisterStep1 } from './register';
import {
  RequestStep1,
  RequestStep8,
  RequestPersonalityCheck1,
  RequestPersonalityCheck2,
  RequestPersonalityCheck3,
  RequestPersonalityCheck4,
  RequestConfirm,
  RequestAlreadyDone,
  RequestChildInfo1,
} from './request';
import { useAuthContext } from '../../context/AuthContext';
import { getUserType } from 'util/token';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { RequestStep12 } from './request/RequestStep12';
import ApplySignupStep1 from './register/ApplySignupStep1';
import ApplySignupStep2 from './register/ApplySignupStep2';

export const CustomerRequestPage = ({}) => {
  const { isLogin } = useAuthContext();
  const { customerStore } = useStores();
  const history = useHistory();
  const [hasHeader, setHasHeader] = useState(true);
  const [stepHistory, setStepHistory] = useState<React.FC<any>[]>([]);
  const [FormComponent, setFormComponent] = useState<React.FC<any>>(
    isLogin ? RequestStep1 : RegisterStep1,
  );
  const [loading, setLoading] = useState(false);

  const onClickPrev = () => {
    if (stepHistory.length === 1) return;
    stepHistory.pop();
    setStepHistory([...stepHistory]);
    setFormComponent(stepHistory[stepHistory.length - 1]);
  };
  const onLogin = async () => {
    if (isLogin) {
      if (getUserType() === 'HERO') {
        showConfirm('히어로 선생님은 돌봄신청을 할 수 없습니다.');
        history.push('/');
      } else {
        setLoading(true);

        try {
          const { ok: isOkToApply } = await customerStore.getCares();
          if (isOkToApply) {
            setFormComponent(RequestStep1);
          } else {
            setFormComponent(RequestAlreadyDone);
          }
        } catch (e) {
          alert(`돌봄내역 조회에 실패했습니다.${e.message}`);
          history.push('/');
        } finally {
          setLoading(false);
        }
      }
    } else {
      setFormComponent(RegisterStep1);
    }
  };

  useEffect(() => {
    customerStore.resetApplyForm();
    if (isLogin) {
      //로그인 된 경우에만 체크
      onLogin();
    }
  }, []);
  useEffect(() => {
    if (!FormComponent) return;

    if (stepHistory.includes(FormComponent) === false)
      setStepHistory([...stepHistory, FormComponent]);

    if (FormComponent === RequestStep8) {
      setStepHistory(stepHistory =>
        stepHistory.filter(
          x =>
            ![
              RequestChildInfo1,
              RequestPersonalityCheck1,
              RequestPersonalityCheck2,
              RequestPersonalityCheck3,
              RequestPersonalityCheck4,
            ].includes(x),
        ),
      );
    }
    if (FormComponent === RequestStep12) {
      //회원가입후 쿠폰입력화면에서 이전 누를 경우, 회원가입 화면 안보여주기
      setStepHistory(prev =>
        prev.filter(x => ![ApplySignupStep1, ApplySignupStep2].includes(x)),
      );
    }

    window.history.replaceState(
      {},
      '돌봄 서비스 신청하기',
      FormComponent === RequestConfirm
        ? `/service/apply/done`
        : `/service/apply/q${stepHistory.length + 1}`,
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [FormComponent]);
  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      {hasHeader && <Header />}
      <Container>
        {!!FormComponent && (
          <FormComponent
            Bottom={props => (
              <FormBottom
                progress={10}
                disabled={false}
                onClickPrev={onClickPrev}
                {...props}
              />
            )}
            setHasHeader={setHasHeader}
            onLogin={onLogin}
            onClickPrev={onClickPrev}
            onNext={setFormComponent}
          />
        )}
      </Container>
    </>
  );
};

interface FormBottomProps {
  progress: number;
  disabled: boolean;
  nextLabel?: string;
  onClickPrev: () => void;
  onClickNext: () => void;
}
const FormBottom = ({
  progress,
  disabled = false,
  nextLabel,
  onClickPrev,
  onClickNext,
}: FormBottomProps) => {
  return (
    <>
      <Push />
      {!isNil(progress) && (
        <>
          <Progress value={progress * 100} />
          <Space height={24} />
        </>
      )}

      <HorizontalLayout>
        {!!onClickPrev && (
          <>
            <RoundButton variant="white" onClick={onClickPrev}>
              이전
            </RoundButton>
            <Space width={10} />
          </>
        )}
        <RoundButton fill disabled={disabled} onClick={onClickNext}>
          {nextLabel || '다음'}
        </RoundButton>
      </HorizontalLayout>
    </>
  );
};

const Container = styled.div.attrs({
  style: {
    minHeight: isIOS ? '-webkit-fill-available' : undefined,
  },
})`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 56px - 71px);

  justify-content: flex-start;

  padding: 0px 0px;
`;
