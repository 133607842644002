import { Space, VerticalLayout } from 'atom/layout';
import Apply from 'component/b2b/Apply';
import Content1 from 'component/b2b/Content1';
import Content2 from 'component/b2b/Content2';
import Content3 from 'component/b2b/Content3';
import Content4 from 'component/b2b/Content4';
import TopMain from 'component/b2b/TopMain';
import { Footer } from 'component/footer';
import { Header } from 'component/header';
import React, { useRef } from 'react';
import styled from 'styled-components';

const B2bPage = props => {
  const applyRef = useRef(null);
  const moveToApply = () => {
    applyRef.current.scrollIntoView();
  };
  return (
    <>
      <Header />
      <VerticalLayout center style={{ minWidth: '1500px' }}>
        <TopMain moveToApply={moveToApply} />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 moveToApply={moveToApply} />
        <Content5 src={require('asset/b2b/content5.jpg').default} />
        <div ref={applyRef} style={{ width: '100%' }}>
          <Apply />
        </div>
      </VerticalLayout>
      <Footer />
    </>
  );
};
const Content5 = styled.img`
  width: 100%;
`;
export default B2bPage;
