import { TextField } from '@material-ui/core';
import { RoundButton } from 'atom/button';
import { Labeled } from 'atom/input';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, Primary12 } from 'atom/text';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'state';

import { HeaderCustomerSignUp } from 'component-mobile/header';

const SitterSignUpPageStep1 = observer(() => {
  const history = useHistory();
  const { sitterStore } = useStores();
  const [nameWarningKey, setNameWarningKey] = useState(null);
  const nameWarning = { 1: '이름을 입력해주세요' };

  const checkForm = () => {
    if (sitterStore.signUpForm.name.length < 2) {
      setNameWarningKey(1);
      return false;
    }

    return true;
  };
  const registerHero = () => {
    if (checkForm()) {
      history.push('/sign-up/hero/info2');
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.goBack();
      //휴대폰 번호 인증이 안되었다면 현재 페이지 접근 불가.
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <HeaderCustomerSignUp progress={(1 / 7) * 100} />
      <VerticalLayout style={{ padding: '25px' }}>
        <DarkGray24 bold>
          성함을 <br />
          입력해주세요.
        </DarkGray24>
        <Space height={64} />
        <Labeled label="성함" labelStyle={{ fontSize: '18px' }} gap={10}>
          <TextField
            placeholder="본인 이름을 입력해주세요."
            variant="outlined"
            fullWidth
            value={sitterStore.signUpForm.name}
            onChange={e => (sitterStore.signUpForm.name = e.target.value)}
          />
        </Labeled>
        {nameWarningKey ? (
          <Primary12 style={{ margin: '4px 0 2px 4px' }}>
            {nameWarning[nameWarningKey]}
          </Primary12>
        ) : (
          <Space height={24} />
        )}
        <Space height={220} />
      </VerticalLayout>
      <RoundButton
        style={{
          width: '320px',
          height: '56px',
          padding: 0,
          fontSize: '16px',
          margin: 'auto',
        }}
        onClick={registerHero}
      >
        다음
      </RoundButton>
    </>
  );
});

export default SitterSignUpPageStep1;
