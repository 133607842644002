import { RoundButton } from 'atom/button';
import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray44, LightGray28 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';
import Content4InfoBox from './Content4InfoBox';
const Content4 = ({ moveToApply }) => {
  const infos = [
    {
      imagePath: require('asset/b2b/content4_1.png').default,
      title: '전용 바우처',
      description: `제휴 기업 직원들이\n쉽게 사용할 수 있는 전용 상품권`,
    },
    {
      imagePath: require('asset/b2b/content4_2.png').default,
      title: '특별 할인/혜택',
      description: `제휴 기업 전용 할인/혜택 제공`,
    },
    {
      imagePath: require('asset/b2b/content4_3.png').default,
      title: '전담 매니저',
      description: `제휴 기업 임직원 전담 매니저 배정\n[신청 안내 - 매칭 - CS]`,
    },
  ];
  return (
    <Container center>
      <Space height={160} />
      <DarkGray44 style={{ fontSize: '48px' }} bold>
        우리동네 돌봄히어로 제휴 혜택
      </DarkGray44>
      <LightGray28 style={{ fontSize: '34px' }} semiBold>
        직접 찾을 필요 없이 담당 매니저가 찾아드려요!{' '}
      </LightGray28>
      <Space height={120} />
      <HorizontalLayout>
        {infos.map(info => (
          <Content4InfoBox key={info.title} info={info} />
        ))}
      </HorizontalLayout>
      <Space height={120} />
      <RoundButton
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          width: '450px',
          height: '72px',
        }}
        onClick={moveToApply}
      >
        돌봄히어로 추천하기
      </RoundButton>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  width: 100%;
  height: 1250px;
  background: rgba(246, 159, 48, 0.04);
`;
export default Content4;
