import { Space, VerticalLayout } from 'atom/layout';
import React from 'react';
import styled from 'styled-components';

const RewardDetail2 = props => {
  return (
    <Container center>
      <Space height={120} />
      <RoundTextBox>혜택2</RoundTextBox>
      <Space height={300} />
      <CercleFrame>
        <img
          style={{
            width: '364px',
          }}
          src={
            require('asset/event/event_5years_for_parents/heroPlay_2.png')
              .default
          }
        />
      </CercleFrame>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  width: 100%;
  height: 1216px;
  background: #fff8eb;
`;
const RoundTextBox = styled.div`
  width: 276px;
  background: #000000;
  border-radius: 100px;
  padding: 15px 55px;
  text-align: center;
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
`;

const CercleFrame = styled.div`
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: #fadb3d;
  display: felx;
  justify-content: center;
  align-items: center;
`;
export default RewardDetail2;
