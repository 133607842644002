import { Button, TextField } from '@material-ui/core';
import { RoundButton } from 'atom/button';
import { Paper } from 'atom/deco';
import { Checkbox, Labeled } from 'atom/input';
import { HorizontalLayout, Space } from 'atom/layout';
import { Black16, DarkGray15, DarkGray24, Primary15 } from 'atom/text';
import { Header } from 'component/header';
import { AddressPopup } from 'component/popup';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Color } from 'constant';
import { Link, useHistory } from 'react-router-dom';
import { useStores } from 'state';
import styled from 'styled-components';
import { Footer } from 'component/footer';
import { getAge, useTopmost } from 'util/index';
import { okLogin } from 'util/token';
import { useAuthDispatch } from '../../context/AuthContext';

export const applyWays = [
  { label: '여성 인력개발 기관', value: 1 },
  { label: '서울시 혹은 구청 홈페이지', value: 2 },
  { label: '워크넷', value: 3 },
  { label: '알바몬', value: 4 },
  { label: '벼룩시장', value: 5 },
  { label: '현수막', value: 6 },
  { label: 'SNS 광고', value: 7 },
  { label: '인터넷 검색', value: 8 },
  { label: '아파트 게시판', value: 9 },
  { label: '아파트 우편함', value: 10 },
  { label: '지인 추천', value: 11 },
  { label: '주민센터 포스터', value: 12 },
  { label: '전단지', value: 13 },
  { label: '당근마켓', value: 14 },
  // { label: '구인구직 사이트', value: 'JH' },
  //   { label: '홍보 문자', value: 'PM' },
];

const SitterSignUpPage = observer(() => {
  const history = useHistory();
  const topmost = useTopmost();
  const dispatch = useAuthDispatch();
  const { sitterStore } = useStores();

  const hadAllAgree = () =>
    sitterStore.signUpForm.isServiceAgree &&
    sitterStore.signUpForm.isPrivacyAgree &&
    sitterStore.signUpForm.isMarketingAgree;
  const changeAgree = (value: boolean) => {
    sitterStore.signUpForm.isServiceAgree = value;
    sitterStore.signUpForm.isPrivacyAgree = value;
    sitterStore.signUpForm.isMarketingAgree = value;
  };

  const checkForm = () => {
    const birthday = sitterStore.signUpForm.birth;
    if (getAge(birthday) + 1 > 67) {
      showConfirm('죄송합니다.\n만 66세까지만\n지원이 가능합니다.');
      return false;
    }
    if (sitterStore.signUpForm.name.length < 2) {
      showConfirm('성함을 입력해주세요.');

      return false;
    }
    if (birthday.length !== 6) {
      showConfirm('생일을 입력해주세요.');
      return false;
    }

    if (sitterStore.signUpForm.address.length <= 0) {
      showConfirm('주소를 입력해주세요.');
      return false;
    }

    if (
      !sitterStore.signUpForm.address.startsWith('서울')
      //&& !sitterStore.signUpForm.address.startsWith('경기')
    ) {
      showConfirm(
        '죄송합니다.\n히어로 선생님은 서울 거주자만 지원 가능합니다.',
      );
      return false;
    }

    if (
      sitterStore.signUpForm.hasNurture === false &&
      sitterStore.signUpForm.hasNurtureExpert.length <= 0
    ) {
      showConfirm('자녀 외 돌봄경험을 입력해주세요.');
      return false;
    }
    if (sitterStore.signUpForm.applyRoute <= 0) {
      showConfirm(
        '우리동네 돌봄히어로를\n어떤경로로 알게되셨는지 선택해주세요.',
      );
      return false;
    }
    if (
      sitterStore.signUpForm.isPrivacyAgree === false ||
      sitterStore.signUpForm.isServiceAgree === false
    ) {
      showConfirm('필수 약관을 동의해주세요.');
      return false;
    }

    return true;
  };
  const registerHero = async () => {
    if (checkForm()) {
      try {
        const {
          ok,
          result,
          token,
          message,
          refreshToken,
        } = await sitterStore.signUpHero(sitterStore.signUpForm);
        if (ok) {
          //회원가입 성공
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          showConfirm('축하드려요!\n히어로 가족이 되셨습니다');
          history.push('/login');
          sitterStore.resetSignUpForm();
        } else {
          alert(message || '회원가입중 오류가 발생하였습니다.');
        }
      } catch (e) {
        alert(e.message || '회원가입에 실패하였습니다.');
      }
    }
  };
  const onClickAddress = () => {
    const id = topmost.show(
      <AddressPopup
        value=""
        restrictToSeoul={false}
        initialDetailAddress={''}
        onSubmit={(x, y) => {
          sitterStore.signUpForm.address = x;
          sitterStore.signUpForm.detailAddress = y;
        }}
        onClose={() => topmost.dismiss(id)}
      />,
    );
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.goBack();
      //휴대폰 번호 인증이 안되었다면 현재 페이지 접근 불가.
    }
    // return () => {
    //   sitterStore.resetSignUpForm();
    // };
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <Header />
      <Container>
        <Paper
          style={{
            position: 'relative',
            paddingTop: '64px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <DarkGray24 bold>선생님 회원가입</DarkGray24>
          <Space height={64} />
          <Labeled label="1. 성함" labelStyle={{ fontSize: '18px' }} gap={10}>
            <TextField
              placeholder="성함을 입력해주세요"
              variant="outlined"
              style={{ width: '390px' }}
              value={sitterStore.signUpForm.name}
              onChange={e => (sitterStore.signUpForm.name = e.target.value)}
            />
          </Labeled>
          <Space height={24} />
          <Labeled
            label="2. 생년월일 6자리"
            labelStyle={{ fontSize: '18px' }}
            gap={10}
          >
            <TextField
              placeholder="예) 650925"
              variant="outlined"
              style={{ width: '390px' }}
              inputProps={{ maxLength: 6 }}
              value={sitterStore.signUpForm.birth}
              onChange={e =>
                (sitterStore.signUpForm.birth = e.target.value.replace(
                  /\D/g,
                  '',
                ))
              }
            />
          </Labeled>

          <Space height={28} />
          <Labeled label="3. 주소" labelStyle={{ fontSize: '18px' }} gap={10}>
            <TextField
              placeholder="주소를 입력해주세요"
              variant="outlined"
              disabled
              InputProps={
                sitterStore.signUpForm.address
                  ? {
                      startAdornment: (
                        <img
                          style={{ width: '16px', marginRight: '12px' }}
                          src={require('asset/icon/flag_gray.svg').default}
                        />
                      ),
                    }
                  : {}
              }
              style={{ width: '390px' }}
              value={`${sitterStore.signUpForm.address}`}
              onClick={onClickAddress}
            />
            <Space height={10} />
            {sitterStore.signUpForm.detailAddress && (
              <TextField
                variant="outlined"
                disabled
                style={{ width: '390px' }}
                value={`${sitterStore.signUpForm.detailAddress}`}
                onClick={onClickAddress}
              />
            )}
          </Labeled>
          <Space height={24} />

          <hr style={{ width: '390px' }} />
          <Space height={24} />
          <Labeled
            label="4. 자녀 양육 경험"
            labelStyle={{ fontSize: '18px' }}
            gap={10}
          >
            <HorizontalLayout
              style={{ width: '390px', justifyContent: 'space-around' }}
            >
              <HorizontalLayout>
                <Checkbox
                  variant="circle"
                  value={sitterStore.signUpForm.hasNurture}
                  onChange={e => {
                    if (e) sitterStore.signUpForm.hasNurture = true;
                  }}
                />

                <Space width={10} />
                <Black16>경험 있음</Black16>
              </HorizontalLayout>
              <Space width={100} />
              <HorizontalLayout>
                <Checkbox
                  value={!sitterStore.signUpForm.hasNurture}
                  onChange={e => {
                    if (e) {
                      showConfirm(
                        '자녀 양육경험이 없으실 경우,\n필수로 돌봄경험이 있으셔야 합니다.',
                      );
                      sitterStore.signUpForm.hasNurture = false;
                    }
                  }}
                />
                <Space width={10} />
                <Black16>경험 없음</Black16>
              </HorizontalLayout>
            </HorizontalLayout>
          </Labeled>
          <Space height={36} />
          <Labeled
            label="5. 자녀 외 양육 경험"
            labelStyle={{ fontSize: '18px' }}
            gap={10}
          >
            <HorizontalLayout
              style={{ width: '390px', justifyContent: 'space-around' }}
            >
              <HorizontalLayout>
                <Checkbox
                  variant="circle"
                  value={sitterStore.signUpForm.hasOtherExperience}
                  onChange={e => {
                    if (e) sitterStore.signUpForm.hasOtherExperience = true;
                  }}
                />

                <Space width={10} />
                <Black16>경험 있음</Black16>
              </HorizontalLayout>
              <Space width={100} />
              <HorizontalLayout>
                <Checkbox
                  value={!sitterStore.signUpForm.hasOtherExperience}
                  onChange={e => {
                    if (e) {
                      showConfirm(
                        '자녀 양육경험이 없으실 경우,\n필수로 돌봄경험이 있으셔야 합니다.',
                      );
                      sitterStore.signUpForm.hasOtherExperience = false;
                    }
                  }}
                />
                <Space width={10} />
                <Black16>경험 없음</Black16>
              </HorizontalLayout>
            </HorizontalLayout>
            <Space height={24} />
            <textarea
              placeholder={`자녀 외에 돌봄경험이 있으시다면 자유롭게 작성해주세요. 양육경험이 없으실 경우, 필수로 돌봄경험이 있으셔야 합니다. (예 : 어린이집 보조교사 경험 등)`}
              value={sitterStore.signUpForm.hasNurtureExpert}
              onChange={e =>
                (sitterStore.signUpForm.hasNurtureExpert = e.target.value)
              }
            ></textarea>
          </Labeled>
          <Space height={36} />

          <Labeled
            label="6. 히어로를 알게 된 경로"
            labelStyle={{ fontSize: '18px', width: '390px' }}
            gap={10}
          >
            <Space height={24} />
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'start',
                alignItems: 'center',
                width: '390px',
              }}
            >
              {applyWays.map(item => (
                <RoundButton
                  variant={
                    sitterStore.signUpForm.applyRoute === item.value
                      ? 'secondary'
                      : 'gray'
                  }
                  style={{
                    width: '180px',
                    height: '50px',
                    fontSize: '15px',
                    padding: 0,
                    margin: '5px',
                  }}
                  onClick={() =>
                    (sitterStore.signUpForm.applyRoute = item.value)
                  }
                >
                  {item.label}
                </RoundButton>
              ))}{' '}
            </div>
          </Labeled>
          <Space height={32} />
          <hr style={{ width: '390px' }} />
          <Space height={32} />
          <DarkGray24 bold> 이용약관 동의</DarkGray24>
          <Space height={29} />

          <InnerBox>
            <TermsContainer>
              <Checkbox
                variant="square-line"
                value={hadAllAgree()}
                onChange={value => changeAgree(value)}
              />
              <Space width={15} />
              <DarkGray15 bold>전체 동의하기</DarkGray15>
            </TermsContainer>
            <Space height={26} />
            <hr style={{ width: '370px', alignSelf: 'center' }} />
            <Space height={26} />
            <TermsInerBox>
              <TermsContainer>
                <Checkbox
                  variant="square-line"
                  value={sitterStore.signUpForm.isServiceAgree}
                  onChange={value =>
                    (sitterStore.signUpForm.isServiceAgree = value)
                  }
                />
                <Space width={15} />
                <DarkGray15 bold>
                  <span style={{ color: Color.Primary }}>[필수]</span> 서비스
                  이용약관
                </DarkGray15>
              </TermsContainer>
              <MoreBtn url={'/terms/service-terms.html'} />
            </TermsInerBox>
            <Space height={26} />
            <TermsInerBox>
              <TermsContainer>
                <Checkbox
                  variant="square-line"
                  value={sitterStore.signUpForm.isPrivacyAgree}
                  onChange={value =>
                    (sitterStore.signUpForm.isPrivacyAgree = value)
                  }
                />
                <Space width={15} />
                <DarkGray15 bold>
                  <span style={{ color: Color.Primary }}>[필수]</span> 개인정보
                  수집 및 이용 동의
                </DarkGray15>
              </TermsContainer>
              <MoreBtn url={'/terms/privacy-policy.html'} />
            </TermsInerBox>
            <Space height={26} />
            <TermsInerBox>
              <TermsContainer>
                <Checkbox
                  variant="square-line"
                  value={sitterStore.signUpForm.isMarketingAgree}
                  onChange={value =>
                    (sitterStore.signUpForm.isMarketingAgree = value)
                  }
                />
                <Space width={15} />
                <DarkGray15 bold>
                  <span style={{ color: Color.Primary }}>[선택]</span> 혜택/정보
                  수신동의
                </DarkGray15>
              </TermsContainer>
              <MoreBtn url={'https://woorihero.com'} />
            </TermsInerBox>
          </InnerBox>
          <Space height={64} />
          <Button
            style={{
              width: '390px',
              height: '55px',
              backgroundColor: Color.Primary,
              fontSize: '16px',
              color: Color.white,
            }}
            onClick={registerHero}
          >
            가입 하기
          </Button>
        </Paper>
      </Container>
      <Footer />
    </>
  );
});

const InnerBox = styled.div`
  width: 390px;
  display: flex;
  flex-direction: column;
`;
const TermsContainer = styled.div`
  display: flex;
`;
const TermsInerBox = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;

  background-color: #f7f7f7;

  justify-content: center;

  padding-top: 30px;
  padding-bottom: 200px;
`;

const MoreBtn = ({ url }) => {
  return (
    <Link to={url} target={'_blank'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '63px',
        }}
      >
        <Primary15 bold style={{ textDecoration: 'underline' }}>
          자세히
        </Primary15>

        <img
          style={{ width: '10px' }}
          src={require('asset/icon/arrow_right_orange.svg').default}
        ></img>
      </div>
    </Link>
  );
};
export default SitterSignUpPage;
