import React, { useState, useEffect } from 'react';

import { useStores } from 'state';
import { SitterInterviewContent, SitterReviewContent } from 'content';
const RESOURCE_ENDPOINT = '/home/image/load';
export const useReviews = () => {
  const { valueStore } = useStores();
  const reviews = [
    {
      id: 1,
      author: '구윤나님',
      title: '5살 이현이 엄마',
      content:
        '<p><span style="color: rgb(0, 0, 0);">“3시간 동안 책읽기, 그림그리기, 오리기, 블록놀이, 쉴틈없이 꽉꽉 채워 놀아주시고 아이들과 함께 정리놀이까지 해주셨어요. 육아에 지친 엄마들에게 정말 히어로가 되어주시네요“</span></p>',
      thumbnail: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/07/140545_놀이사진_구윤나2.png`,
      star: 0,
      url: 'https://blog.naver.com/danceqn/221269102473',
    },
    {
      id: 2,
      author: '윤경희님',
      title: '3살 서준이 엄마',
      content:
        '<p><span style="color: rgb(0, 0, 0);">“서준이가 만지고하는 촉감놀이를 좋아하신다는 말을 기억하시고 뻥튀기를 가져오셨어요. 아이가 얼마나 좋아하던지, 아이가 관심 가지는 것에 맞춘 아이주도 놀이를 해주셔서 너무 좋았어요”</span></p>',
      thumbnail: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/07/140543_02 서준맘의 육아일기.png`,
      star: 0,
      url: 'https://m.blog.naver.com/ykhee23/221513367651',
    },
    {
      id: 3,
      author: '이혜진님',
      title: '6살 지용이 엄마',
      content:
        '<p><span style="color: rgb(0, 0, 0);">“아들내미가 정말 즐거워했고 선생님도 활동적인 우리 아이 놀아주시느라 힘드셨을텐데 최선을 다해주시는 모습이 보기 좋았어요“</span></p>',
      thumbnail: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/07/140641_03 사랑이가득한꿀쥬네.png`,
      star: 0,
      url: 'https://blog.naver.com/jump841024/221229081142',
    },
    {
      id: 4,
      author: '이미혜님',
      title: '5살 민준이 엄마',
      content:
        '<p><span style="color: rgb(0, 0, 0);">“우리 준이가 매우 매우 활발하고 로봇에 관심이 많은데 선생님께서 우리 아이가 관심 있어 하는 부분에 같이 반응해주시니까 우리 준이가 더 더 신나서 완전 하이퍼 준이가 되었어요.”</span></p>',
      thumbnail: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/07/140739_04 달콤한 쥬니네.png`,
      star: 0,
      url: 'https://blog.naver.com/mhlee1771/221183412336',
    },
    {
      id: 6,
      author: '강남욱님',
      title: '6살 예지맘',
      content:
        '<p><span style="color: rgb(0, 0, 0);">“엄마 껌딱지 둘째가 태어난 이후로 첫째가 항상 엄마가 자기와 놀아주지 않는다며 서운해 하곤 했어요. 선생님이 오셔서 눈높이에 맞춰 신나게 놀아주시니 첫째가 덜 서운해하고 저도 한명 한명 집중하며 케어할수 있어 좋아요.</span></p>',
      thumbnail: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/07/140959_05 spazio.png`,
      star: 0,
      url: 'https://blog.naver.com/knw1124/221187655349',
    },
  ];
  const [data, setData] = useState<SitterReviewContent[]>(reviews);

  // useEffect(() => {
  //   (async () => {
  //     setData(await valueStore.getReviews());
  //   })();
  // }, []);

  return data;
};
export const useHeroIntro = () => {
  const intro = [
    {
      id: 1,
      url: 'https://blog.naver.com/sitterhero/221398114778',
      content:
        '<p><span style="color: #000000;">저는 아이들의 눈높이에서 </span></p><p><span style="color: #000000;">아이를 바라보며 소통하는 게 </span></p><p><span style="color: #000000;">제일 중요하다고 생각해요.&nbsp;&nbsp;</span></p><p style="line-height: 138%;"><span style="color: #000000;">아이들이 저마다의 자기다움으로 </span></p><p style="line-height: 138%;"><span style="color: #000000;">잘 자라날 수 있도록 </span></p><p style="line-height: 138%;"><span style="color: #000000;">사랑으로 계속 주면서 돌보고 있어요.</span></p>',
      sitterName: '신영숙 선생님',
      sitterInfo: '히어로 11기',
      portrait: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/04/183057_1신영숙_2.png`,
    },
    {
      id: 2,
      url: 'https://blog.naver.com/sitterhero/221402232501',
      content:
        '<p><span style="color: #000000;">아이를 먼저 키운 엄마로서</span></p><p><span style="color: #000000;">저의 양육 노하우가 도움이 </span></p><p><span style="color: #000000;">될 수 있다고 생각합니다.</span></p><p><span style="color: #000000;">아이에게 행복한 모습을 많이 보여주고</span></p><p><span style="color: #000000;">가슴이 따뜻하게 표현하며 </span></p><p><span style="color: #000000;">안아 주면서 사랑을 주고 싶어요.</span></p><p style="line-height: 138%;"></p>',
      sitterName: '이미희  선생님',
      sitterInfo: '히어로 7기',
      portrait: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/17/130937_이미희쌤.png`,
    },
    {
      id: 8,
      url: 'https://blog.naver.com/sitterhero/222501379906',
      content:
        '<p><span style="color: #000000;">아이를 만나면 네 마음을 </span></p><p><span style="color: #000000;">이해할 수 있도록 노력할게. </span></p><p><span style="color: #000000;">눈 높이를 맞춰줄게. </span></p><p><span style="color: #000000;">이렇게 말하고 싶어요. </span></p><p><span style="color: #000000;">저는 그게 제일 중요하다고 생각하거든요. </span></p><p><span style="color: #000000;">아이의 눈높이에서 이해하려고</span></p><p><span style="color: #000000;"> 노력하고 싶어요.</span></p>',
      sitterName: '이충자 선생님',
      sitterInfo: '히어로 16기',
      portrait: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/17/141332_이충자-선생님-누끼-.png`,
    },
    {
      id: 9,
      url: 'https://blog.naver.com/sitterhero/222536692675',
      content:
        '<p><span style="color: #000000;">아이들은 처음에 당연히 낯을 가리지만, </span></p><p><span style="color: #000000;">진심으로 애정을 담아서 대하다 보면 </span></p><p><span style="color: #000000;">아이들도 애정을 보내주게 돼요. </span></p><p><span style="color: #000000;">그럴 때 가장 보람이 느껴져요. </span></p><p><span style="color: #000000;">아이들이 나를 믿고 따르는구나라는 게 </span></p><p><span style="color: #000000;">느껴지는 순간이 너무 좋아요.</span></p>',
      sitterName: '유경희 선생님',
      sitterInfo: '히어로 5기',
      portrait: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/17/140044_유경희-선생님-누끼.png`,
    },
    {
      id: 10,
      url: 'https://blog.naver.com/sitterhero/222619811872',
      content:
        '<p><span style="color: #000000;">아직 어려 마음에 있는 이야기를 </span></p><p><span style="color: #000000;">정확하게 이야기 못하더라도 </span></p><p><span style="color: #000000;">선생님이 너의 마음을 빨리 </span></p><p><span style="color: #000000;">캐치해서 노력할게라고 </span></p><p><span style="color: #000000;">아이에게 말해주고 싶어요.</span></p>',
      sitterName: '임주경 선생님',
      sitterInfo: '히어로 64기',
      portrait: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/17/140114_임주경-선생님-누끼.png`,
    },
    {
      id: 11,
      url: 'https://blog.naver.com/sitterhero/222620779599',
      content:
        '<p><span style="color: #000000;">아이와 어떤 한 가지를 해도 </span></p><p><span style="color: #000000;">생각 없이 하는 경우는 없어요. </span></p><p><span style="color: #000000;">아이가 이런 걸 하면 더 발전을 하고 </span></p><p><span style="color: #000000;">어휘력이 생기겠지 </span></p><p><span style="color: #000000;">숫자 개념이 생기겠지 생각하면서 </span></p><p><span style="color: #000000;">매 순간 다 아이에게 도움 될 걸 </span></p><p><span style="color: #000000;">생각하면서 대화를 해요.</span></p>',
      sitterName: '이영수 선생님',
      sitterInfo: '히어로 49기',
      portrait: `${process.env.REACT_APP_API_URL}${RESOURCE_ENDPOINT}/22/01/17/140144_이영수-선생님-누끼.png`,
    },
  ];
  const { valueStore } = useStores();
  const [data, setData] = useState<SitterInterviewContent[]>(intro);

  // useEffect(() => {
  //   (async () => {
  //     setData(await valueStore.getHeroIntros());
  //   })();
  // }, []);

  return data;
};

type TimeOfDay = 'day' | 'night';
type Level = '신입' | '경력' | '프로' | 'cleaning' | 'premium';
type Regularity = '정기' | '비정기';

// customer와 hero에서 사용할 타입을 별도로 정의
type PriceCategory = Record<`${Regularity}_${Level}_1:1_${TimeOfDay}`, number> &
                     Record<`${Regularity}_${Level}_1:2_${TimeOfDay}`, number>;

interface PriceData {
  customer: Partial<PriceCategory>; // 모든 속성을 필수로 하지 않음
  hero: Partial<PriceCategory>;     // 모든 속성을 필수로 하지 않음
}


interface CostService {
  id: number;
  type: number;
  seq: number;
  name: string;
  is_caretype_use: boolean;
  is_level_use: boolean;
  version_id: number;
  is_shown: boolean;
}

interface CostLevel {
  id: number;
  grade: number;
  seq: number;
  name: string;
  content: string;
  hour: number;
  month: number;
  version_id: number;
  is_shown: boolean;
}

interface CostFee {
  id: number;
  is_night: boolean;
  version_id: number;
  service_id: number;
  level_id: number;
  regular_one_fee: number;
  regular_two_fee: number;
  irregular_one_fee: number;
  irregular_two_fee: number;
  regular_one_salary: number;
  regular_two_salary: number;
  irregular_one_salary: number;
  irregular_two_salary: number;
  is_shown: boolean;
  c_cost_service: CostService;
  c_cost_level: CostLevel;
}

export interface CostTableData {
  id: number;
  version_name: string;
  start_at: string;
  end_at: string | null;
  memo: string | null;
  is_used: boolean;
  night_time_start: string;
  night_time_end: string;
  is_update_able: boolean;
  c_cost_service: CostService[];
  c_cost_level: CostLevel[];
  c_cost_fee: CostFee[];
}

export let sharedPrices = null;
export const usePrices = () => {
  const { valueStore } = useStores();
  const price: PriceData = {
    //2024.10.20 배포기준
    customer: {
      '정기_신입_1:1_day': 15000,
      '정기_신입_1:2_day': 18000,
      '비정기_신입_1:1_day': 17000,
      '비정기_신입_1:2_day': 20000,
      '정기_경력_1:1_day': 17000,
      '정기_경력_1:2_day': 20000,
      '비정기_경력_1:1_day': 19000,
      '비정기_경력_1:2_day': 22000,
      '정기_프로_1:1_day': 21000,
      '정기_프로_1:2_day': 24000,
      '비정기_프로_1:1_day': 23000,
      '비정기_프로_1:2_day': 26000,
      // '정기_cleaning_1:1_day': 15000,
      // '정기_cleaning_1:2_day': 15000,
      // '비정기_cleaning_1:1_day': 15000,
      // '비정기_cleaning_1:2_day': 15000,
      // '정기_premium_1:1_day': 20000,
      // '정기_premium_1:2_day': 30000,
      // '비정기_premium_1:1_day': 21000,
      // '비정기_premium_1:2_day': 31000,
      '정기_신입_1:1_night': 17000,
      '정기_신입_1:2_night': 20000,
      '비정기_신입_1:1_night': 18000,
      '비정기_신입_1:2_night': 21000,
      '정기_경력_1:1_night': 19000,
      '정기_경력_1:2_night': 22000,
      '비정기_경력_1:1_night': 20000,
      '비정기_경력_1:2_night': 23000,
      '정기_프로_1:1_night': 23000,
      '정기_프로_1:2_night': 26000,
      '비정기_프로_1:1_night': 24000,
      '비정기_프로_1:2_night': 27000,
      // '정기_cleaning_1:1_night': 17000,
      // '정기_cleaning_1:2_night': 17000,
      // '비정기_cleaning_1:1_night': 17000,
      // '비정기_cleaning_1:2_night': 17000,
      // '정기_premium_1:1_night': 22000,
      // '정기_premium_1:2_night': 32000,
      // '비정기_premium_1:1_night': 23000,
      // '비정기_premium_1:2_night': 33000,
    },
    hero: {
      '정기_신입_1:1_day': 11000,
      '정기_신입_1:2_day': 14000,
      '비정기_신입_1:1_day': 12000,
      '비정기_신입_1:2_day': 15000,
      '정기_경력_1:1_day': 12000,
      '정기_경력_1:2_day': 15000,
      '비정기_경력_1:1_day': 13000,
      '비정기_경력_1:2_day': 16000,
      '정기_프로_1:1_day': 13000,
      '정기_프로_1:2_day': 16000,
      '비정기_프로_1:1_day': 14000,
      '비정기_프로_1:2_day': 17000,
      // '정기_cleaning_1:1_day': 12000,
      // '정기_cleaning_1:2_day': 12000,
      // '비정기_cleaning_1:1_day': 12000,
      // '비정기_cleaning_1:2_day': 12000,
      // '정기_premium_1:1_day': 12000,
      // '정기_premium_1:2_day': 15000,
      // '비정기_premium_1:1_day': 13000,
      // '비정기_premium_1:2_day': 16000,
      '정기_신입_1:1_night': 13000,
      '정기_신입_1:2_night': 16000,
      '비정기_신입_1:1_night': 13000,
      '비정기_신입_1:2_night': 16000,
      '정기_경력_1:1_night': 14000,
      '정기_경력_1:2_night': 17000,
      '비정기_경력_1:1_night': 14000,
      '비정기_경력_1:2_night': 17000,
      '정기_프로_1:1_night': 15000,
      '정기_프로_1:2_night': 18000,
      '비정기_프로_1:1_night': 15000,
      '비정기_프로_1:2_night': 18000,
      // '정기_cleaning_1:1_night': 14000,
      // '정기_cleaning_1:2_night': 14000,
      // '비정기_cleaning_1:1_night': 14000,
      // '비정기_cleaning_1:2_night': 14000,
      // '정기_premium_1:1_night': 14000,
      // '정기_premium_1:2_night': 17000,
      // '비정기_premium_1:1_night': 15000,
      // '비정기_premium_1:2_night': 18000,
    },
  };

  const [data, setData] = useState<Record<string, number>>(price.customer);

useEffect(() => {
  (async () => {
    const costData: CostTableData = await valueStore.getPrices();

      // 가격 정보를 저장할 객체를 빈 객체로 초기화
      const resultPrice: PriceData = {customer:{},hero:{}};


    // JSON 데이터를 맵핑하는 함수
    costData.c_cost_fee.map((fee) => {
      const isNight = fee.is_night ? 'night' : 'day'; // day/night 구분
      const serviceName = fee.c_cost_service.name; // 서비스 이름 (ex: 등하원 놀이)
      const levelName = {1:'신입',2:'경력',3:'프로'}[fee.c_cost_level.grade]; // 레벨 이름 (ex: 일반, 경력, 프로)

      // 요금이 정기/비정기인지 구분
      const isAddOnService = ['창의놀이','청소'].includes(serviceName);

      // 키 생성 (예: '정기_일반_1:1_day', '비정기_경력_1:2_night')
      const customerRegKid1 = `정기_${isAddOnService? serviceName:levelName}_1:1_${isNight}`; //ex) 정기_일반_1:1_day
      const customerRegKid2 = `정기_${isAddOnService? serviceName:levelName}_1:2_${isNight}`;
      const customerIrRegKid1 = `비정기_${isAddOnService? serviceName:levelName}_1:1_${isNight}`;
      const customerIrRegKid2 = `비정기_${isAddOnService? serviceName:levelName}_1:2_${isNight}`;
      const heroRegKid1 = `정기_${isAddOnService? serviceName:levelName}_1:1_${isNight}`;
      const heroRegKid2 = `정기_${isAddOnService? serviceName:levelName}_1:2_${isNight}`;
      const heroIrRegKid1 = `비정기_${isAddOnService? serviceName:levelName}_1:1_${isNight}`;
      const heroIrRegKid2 = `비정기_${isAddOnService? serviceName:levelName}_1:2_${isNight}`;

        // 고객 가격 설정
        resultPrice['customer'][customerRegKid1] = fee.regular_one_fee;
        resultPrice['customer'][customerRegKid2] = fee.regular_two_fee;
        resultPrice['customer'][customerIrRegKid1] = fee.irregular_one_fee;
        resultPrice['customer'][customerIrRegKid2] = fee.irregular_two_fee;

        // 히어로 가격 설정
        resultPrice.hero[heroRegKid1] = fee.regular_one_salary;
        resultPrice.hero[heroRegKid2] = fee.regular_two_salary;
        resultPrice.hero[heroIrRegKid1] = fee.irregular_one_salary;
        resultPrice.hero[heroIrRegKid2] = fee.irregular_two_salary;
    });

    // console.log('resultPrice',resultPrice);
    setData(resultPrice.customer);
  })();
}, []);


  return data;
};
export const useSiteValue = (key: string) => {
  const { valueStore } = useStores();
  const [data, setData] = useState<string>('');

  useEffect(() => {
    (async () => {
      setData(await valueStore.getValue(key));
    })();
  }, []);

  return data;
};
