import { Space, VerticalLayout } from 'atom/layout';
import {
  DarkGray10,
  DarkGray12,
  DarkGray14,
  DarkGray16,
  DarkGray18,
  LightGray10,
  LightGray11,
  Primary12,
  White16,
} from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Reward1 = props => {
  const playSteps = [
    {
      title: `책을 읽으며 다양한 질문과 
      이야기를 만들어요.`,
      content: `책을 읽으며 다양한 스토리를 통해 아이들의 흥미를
      이끌어내어주며 아이의 생각을 확장시켜줘요.`,
      src: require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_5-1.png')
        .default,
    },
    {
      title: `오늘의 놀이 주제를 선정해요.`,
      content: `책에 있는 스토리를 활용하거나,
      아이가 흥미를 가질만한 상황을 기반으로 
      오늘 진행할 놀이 주제를 선정해요.`,
      src: require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_5-2.png')
        .default,
    },
    {
      title: `다양한 놀이 재료들을 탐색하며,
      놀이를 진행해요.`,
      content: `히어로에서 제공한 다양한 재료들을 탐색하며,
      놀이주제와 연관된 놀이를 진행해요.`,
      src: require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_5-3.png')
        .default,
    },
    {
      title: `놀이를 마무리해요.`,
      content: `질문을 통해 아이가 무엇을 느꼈는지 공감해주며, 
      아이가 놀이과정을 통해 
      생각이 자라날 수 있도록 격려해줘요.`,
      src: require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_5-4.png')
        .default,
    },
  ];
  return (
    <div>
      <Container center>
        <Space height={64} />
        <RoundTextBox>혜택1</RoundTextBox>
        <Space height={24} />
        <DarkGray18>
          이벤트 혜택을 통해 <BoldText>기본 돌봄 비용</BoldText>으로
        </DarkGray18>
        <DarkGray18>
          <BoldText>창의놀이까지 덤으로 경험</BoldText>해보세요!
        </DarkGray18>
        <Space height={24} />
        <img
          style={{
            width: '214px',
          }}
          src={
            require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_1.png')
              .default
          }
        />
        <Space height={32} />
        <VerticalLayout
          center
          style={{ background: '#ED584E', width: '100%', height: '272px' }}
        >
          <Space height={24} />
          <White16 bold>🎉 돌봄히어로 BEST 추천 돌봄 구성 🎉</White16>

          <Space height={24} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImageCard
              src={
                require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_2Big.png')
                  .default
              }
            />
            <White16 style={{ margin: '0 15px' }} bold>
              +
            </White16>
            <ImageCard
              src={
                require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_3Big.png')
                  .default
              }
            />
          </div>
        </VerticalLayout>
        <Space height={64} />
        <DarkGray16 bold>
          ①{' '}
          <span
            style={{
              background:
                'linear-gradient(to top, #FFD202 30%, transparent 30%) ',
            }}
          >
            돌봄히어로의 창의놀이는 어떻게 다른가요?
          </span>
        </DarkGray16>
        <Space height={24} />
        <WhiteTextBox>
          <DarkGray12 center bold style={{ fontSize: '11px' }}>
            {`창의놀이는 선생님과 아이와의 상호작용 질을 높이기 위해
전문 양성 교육 및 보수교육을 수료한 선생님들만 활동하고 있어요.

창의놀이는 아이와 함께 책을 읽는 과정으로부터 시작되며,
스토리와 연관된 다양한 질문을 만들어보기도 하고
상황에 맞는 주제를 설정하며 아이주도를 기반으로 
자유롭게 진행되는 놀이입니다.`}
          </DarkGray12>
        </WhiteTextBox>
        <Space height={16} />
        <img
          style={{ width: '305px' }}
          src={
            require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_4.png')
              .default
          }
        />
        <Space height={64} />
        <DarkGray16 bold>
          ②{' '}
          <span
            style={{
              background:
                'linear-gradient(to top, #FFD202 30%, transparent 30%) ',
            }}
          >
            돌봄히어로의 창의놀이는 어떻게 진행되나요?
          </span>
        </DarkGray16>
        <Space height={36} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'start',
          }}
        >
          <img
            style={{ margin: '0.5rem 2rem 0 0' }}
            src={
              require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_5-left.svg')
                .default
            }
          />
          <div>
            {playSteps.map(item => (
              <div key={item.title}>
                <StepBox
                  title={item.title}
                  content={item.content}
                  src={item.src}
                />
                {/* <Space height={26} /> */}
              </div>
            ))}
          </div>
        </div>
        <Space height={36} />

        <DarkGray16 bold>
          ③{' '}
          <span
            style={{
              background:
                'linear-gradient(to top, #FFD202 30%, transparent 30%) ',
            }}
          >
            준비물은 어떻게 구성 되어 있나요?
          </span>
        </DarkGray16>
        <Space height={24} />
        <img
          style={{ width: '290px' }}
          src={
            require('asset/event/event_5years_for_parents/mobile/mobile_reward1_detail_6.png')
              .default
          }
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            width: '100%',
            marginRight: '25%',
          }}
        >
          <LightGray10 bold>
            * 기본 준비물 이미지는 실제와 다를 수 있습니다.
          </LightGray10>
        </div>
        <Space height={24} />
        <WhiteTextBox>
          <DarkGray10
            style={{ fontSize: '11px' }}
            semiBold
            center
          >{`놀이 진행에 필요한 기본 준비물(도화지, 클레이, 색종이,
         펀치, 가위 등)과 아이의 창의력 증진에 필요한 50여가지의
         다양한 재료들로 구성되어 있습니다. 
        `}</DarkGray10>
        </WhiteTextBox>
        <Space height={64} />
      </Container>
    </div>
  );
};

const BoldText = styled.span`
  font-weight: bold;
`;
const Container = styled(VerticalLayout)`
  background: #fff8eb;
`;
const RoundTextBox = styled.div`
  background: #000000;
  border-radius: 100px;
  padding: 4px 22px;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
`;
const ImageCardFrame = styled(VerticalLayout)`
  width: 120px;
  height: 180px;
`;
const ImageCard = ({ src }) => {
  return (
    <ImageCardFrame>
      <img src={src} style={{ width: '100%' }} />
    </ImageCardFrame>
  );
};

const WhiteTextBox = styled.div`
  width: 321px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
`;

const StepBoxFrame = styled(VerticalLayout)`
  width: 242px;
  height: 287px;
`;
const StepBox = ({ title, content, src }) => {
  return (
    <StepBoxFrame>
      <DarkGray14 bold style={{ whiteSpace: 'pre-line', margin: '0.25rem 0' }}>
        {title}
      </DarkGray14>
      <LightGray11 style={{ whiteSpace: 'pre-line', margin: '0.5rem 0' }}>
        {content}
      </LightGray11>
      <img
        style={{
          width: '240px',
          height: '160px',
        }}
        src={src}
      />
    </StepBoxFrame>
  );
};
export default Reward1;
