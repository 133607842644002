import React, { useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Paper } from 'atom/deco';
import { Checkbox, Labeled } from 'atom/input';
import { Space } from 'atom/layout';
import { DarkGray15, DarkGray24, LightGray18, Primary15 } from 'atom/text';
import { Footer } from 'component/footer';
import { Header } from 'component/header';
import { observer } from 'mobx-react';
import { Color } from 'constant';
import { useStores } from 'state';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { okLogin } from 'util/token';
import { useAuthDispatch } from '../../context/AuthContext';

const MoreBtn = ({ url }) => {
  return (
    <Link to={url} target={'_blank'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '63px',
        }}
      >
        <Primary15 bold style={{ textDecoration: 'underline' }}>
          자세히
        </Primary15>

        <img
          style={{ width: '10px' }}
          src={require('asset/icon/arrow_right_orange.svg').default}
        ></img>
      </div>
    </Link>
  );
};
const CustomerSignUpPage = observer(() => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { customerStore } = useStores();
  const hadAllAgree = () =>
    customerStore.signUpForm.isServiceAgree &&
    customerStore.signUpForm.isPrivacyAgree &&
    customerStore.signUpForm.isMarketingAgree;
  const changeAgree = (value: boolean) => {
    customerStore.signUpForm.isServiceAgree = value;
    customerStore.signUpForm.isPrivacyAgree = value;
    customerStore.signUpForm.isMarketingAgree = value;
  };

  const checkForm = () => {
    if (customerStore.signUpForm.name.length < 2) {
      showConfirm('성함을 입력해주세요.');

      return false;
    }

    if (customerStore.signUpForm.email.length <= 0) {
      showConfirm('이메일 주소를 입력해주세요.');
      return false;
    }
    if (
      customerStore.signUpForm.isServiceAgree === false ||
      customerStore.signUpForm.isPrivacyAgree === false
    ) {
      showConfirm('필수 약관을 동의해주세요.');
      return false;
    }

    return true;
  };

  const registerCostomer = async () => {
    if (checkForm()) {
      try {
        const {
          ok,
          result,
          token,
          message,
          refreshToken,
        } = await customerStore.signUp(customerStore.signUpForm);
        if (ok) {
          //회원가입성공. 로그인처리
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          showConfirm('축하드려요!\n히어로 가족이 되셨습니다');
          history.push('/login');
          customerStore.resetSignUpForm();
        } else {
          alert(message || '회원가입에 실패하였습니다.');
        }
      } catch (e) {
        alert(e.message || '회원가입에 실패하였습니다.');
      }
    }
  };

  useEffect(() => {
    if (!customerStore.signUpForm.phone) {
      history.goBack();
      //휴대폰 번호 인증이 안되었다면 현재 페이지 접근 불가.
    }
    // return () => {
    //   customerStore.resetSignUpForm();
    // };
  }, [customerStore, customerStore.signUpForm.phone, history]);
  return (
    <>
      <Header />
      <Container>
        <Paper
          style={{
            position: 'relative',
            paddingTop: '64px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <DarkGray24 bold>부모님 회원가입</DarkGray24>
          <Space height={64} />
          <Labeled label="1. 성함" labelStyle={{ fontSize: '18px' }} gap={10}>
            <TextField
              placeholder="성함을 입력해주세요"
              variant="outlined"
              style={{ width: '390px' }}
              value={customerStore.signUpForm.name}
              onChange={e => (customerStore.signUpForm.name = e.target.value)}
            />
          </Labeled>
          <Space height={24} />
          <Labeled
            label="2. 이메일 주소"
            labelStyle={{ fontSize: '18px' }}
            gap={10}
          >
            <TextField
              placeholder="이메일 주소를 입력해주세요"
              variant="outlined"
              style={{ width: '390px' }}
              value={customerStore.signUpForm.email}
              onChange={e => (customerStore.signUpForm.email = e.target.value)}
            />
          </Labeled>
          <Space height={24} />
          <Labeled
            label="3. 핸드폰 번호"
            labelStyle={{ fontSize: '18px' }}
            gap={10}
          >
            <TextField
              variant="outlined"
              style={{ width: '390px' }}
              disabled
              value={customerStore.signUpForm.phone}
            />
          </Labeled>
          <Space height={4} />
          <InnerBox>
            <LightGray18>핸드폰 번호 변경을 원하실 경우 히어로센터</LightGray18>
            <LightGray18>(02-6232-0220)로 연락해주세요.</LightGray18>
          </InnerBox>
          <Space height={24} />

          <hr style={{ width: '390px' }} />
          <Space height={24} />
          <DarkGray24 bold>이용약관 동의</DarkGray24>
          <Space height={29} />
          <InnerBox>
            <TermsContainer>
              <Checkbox
                variant="square-line"
                value={hadAllAgree()}
                onChange={value => changeAgree(value)}
              />
              <Space width={15} />
              <DarkGray15 bold>전체 동의하기</DarkGray15>
            </TermsContainer>
            <Space height={26} />
            <hr style={{ width: '370px', alignSelf: 'center' }} />
            <Space height={26} />
            <TermsInerBox>
              <TermsContainer>
                <Checkbox
                  variant="square-line"
                  value={customerStore.signUpForm.isServiceAgree}
                  onChange={value =>
                    (customerStore.signUpForm.isServiceAgree = value)
                  }
                />
                <Space width={15} />
                <DarkGray15 bold>
                  <span style={{ color: Color.Primary }}>[필수]</span> 서비스
                  이용약관
                </DarkGray15>
              </TermsContainer>
              <MoreBtn url={'/terms/service-terms.html'} />
            </TermsInerBox>
            <Space height={26} />
            <TermsInerBox>
              <TermsContainer>
                <Checkbox
                  variant="square-line"
                  value={customerStore.signUpForm.isPrivacyAgree}
                  onChange={value =>
                    (customerStore.signUpForm.isPrivacyAgree = value)
                  }
                />
                <Space width={15} />
                <DarkGray15 bold>
                  <span style={{ color: Color.Primary }}>[필수]</span> 개인정보
                  수집 및 이용 동의
                </DarkGray15>
              </TermsContainer>
              <MoreBtn url={'/terms/privacy-policy.html'} />
            </TermsInerBox>
            <Space height={26} />
            <TermsInerBox>
              <TermsContainer>
                <Checkbox
                  variant="square-line"
                  value={customerStore.signUpForm.isMarketingAgree}
                  onChange={value =>
                    (customerStore.signUpForm.isMarketingAgree = value)
                  }
                />
                <Space width={15} />
                <DarkGray15 bold>
                  <span style={{ color: Color.Primary }}>[선택]</span> 혜택/정보
                  수신동의
                </DarkGray15>
              </TermsContainer>
              <MoreBtn url={'https://woorihero.com'} />
            </TermsInerBox>
          </InnerBox>
          <Space height={64} />
          <Button
            style={{
              width: '390px',
              height: '55px',
              backgroundColor: Color.Primary,
              fontSize: '16px',
              color: Color.white,
            }}
            onClick={registerCostomer}
          >
            가입 하기
          </Button>
        </Paper>
      </Container>
      <Footer />
    </>
  );
});

const InnerBox = styled.div`
  width: 390px;
  display: flex;
  flex-direction: column;
`;
const TermsContainer = styled.div`
  display: flex;
`;
const TermsInerBox = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div`
  display: flex;

  background-color: #f7f7f7;

  justify-content: center;

  padding-top: 30px;
  padding-bottom: 200px;
`;
export default CustomerSignUpPage;
