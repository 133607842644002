import { Space, VerticalLayout } from 'atom/layout';
import { Color } from 'constant';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Footer } from 'component-mobile/footer';
import { HeaderMyPageEdit } from 'component-mobile/header/HeaderMyPageEdit';
import { Labeled } from 'atom/input';
import {
  Button,
  CircularProgress,
  TextField,
  withStyles,
} from '@material-ui/core';
import { LightGray11, LightGray12 } from 'atom/text';
import { getUserId, getUserType } from 'util/token';
import { useAuthContext, useAuthDispatch } from '../../context/AuthContext';
import { useStores } from 'state/index';
import { useTopmost } from 'util/index';
import { AddressPopup } from 'component/popup';

const MyPageSitter = props => {
  const topmost = useTopmost();
  const [myInfo, setMyInfo] = useState({
    name: '',
    address: '',
    detail_address: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);

  const { sitterStore } = useStores();
  const history = useHistory();
  const { logout } = useAuthContext();
  const dispatch = useAuthDispatch();

  const handleSave = async () => {
    try {
      const { ok, message } = await sitterStore.updateMyInfo({
        id: Number(getUserId()),
        ...myInfo,
        detailAddress: myInfo.detail_address,
        city: myInfo.address.split(' ')[1],
      });
      if (ok) {
        showConfirm('회원정보가 변경되었습니다.');
      } else {
        alert(`회원정보 변경에 실패하였습니다. ${message}`);
      }
    } catch (e) {
      alert(`회원정보 변경에 실패하였습니다. ${e.message}`);
    }
  };
  const checkWithdrawal = () => {
    showYesNoPopup('정말 탈퇴하시겠습니까?', withdrawal);
  };
  const withdrawal = async () => {
    try {
      const { ok, message } = await sitterStore.updateMyInfo({
        id: Number(getUserId()),
        ...myInfo,
        detailAddress: myInfo.detail_address,
        city: myInfo.address.split(' ')[1],
        status: 3, //탈퇴 상태
      });
      if (ok) {
        logout();
        dispatch({
          type: 'updateLoginState',
          isLogin: false,
        });
        showConfirm('회원탈퇴 되었습니다.');
        history.push('/');
      } else {
        alert(`회원탈퇴에 실패하였습니다. ${message}`);
      }
    } catch (e) {
      alert(`회원탈퇴를 시도했으나, 오류가 있습니다. ${e.message}`);
    }
  };

  const editAddress = () => {
    const id = topmost.show(
      <AddressPopup
        value=""
        initialDetailAddress={''}
        onSubmit={(x, y) => {
          setMyInfo(prev => ({
            ...prev,
            address: x,
            detail_address: y,
          }));
        }}
        onClose={() => topmost.dismiss(id)}
      />,
    );
  };

  useEffect(() => {
    const type = getUserType();
    if (type !== 'HERO') {
      history.push('/login');
    }
  }, [history]);

  useEffect(() => {
    const getMyinfo = async () => {
      setLoading(true);

      try {
        const { ok, result, message } = await sitterStore.getMyInfo(
          getUserId(),
          getUserType(),
        );
        if (ok) {
          setMyInfo(result);
        } else {
          alert(`회원정보 조회에 실패하였습니다. : ${message}`);
        }
      } catch (e) {
        alert(`회원정보 조회에 실패하였습니다. : ${e.message}`);
      } finally {
        setLoading(false);
      }
    };
    getMyinfo();
  }, []);
  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <HeaderMyPageEdit handleSave={handleSave} />
      <Space height={32} />
      <VerticalLayout center style={{ width: '100%', padding: '20px' }}>
        <Labeled
          label="1. 성함"
          labelStyle={{ fontSize: '14px', width: '320px' }}
          gap={10}
        >
          <DarkerDisabledTextField
            value={myInfo.name}
            disabled
            variant="outlined"
            fullWidth
          />
        </Labeled>
        <Space height={24} />

        <Labeled
          label="2. 주소"
          labelStyle={{ fontSize: '14px', width: '320px' }}
          gap={10}
        >
          <TextField
            value={`${myInfo.address}`}
            onClick={editAddress}
            variant="outlined"
            fullWidth
            InputProps={
              myInfo.address
                ? {
                    startAdornment: (
                      <img
                        style={{ width: '16px', marginRight: '12px' }}
                        src={require('asset/icon/flag_gray.svg').default}
                      />
                    ),
                  }
                : {}
            }
          />
          <Space height={10} />
          <TextField
            value={`${myInfo.detail_address}`}
            onClick={editAddress}
            variant="outlined"
            fullWidth
          />
        </Labeled>
        <Space height={24} />
        <Labeled
          label="3. 핸드폰 번호"
          labelStyle={{ fontSize: '14px', width: '320px' }}
          gap={10}
        >
          <DarkerDisabledTextField
            value={myInfo.phone}
            disabled
            variant="outlined"
            fullWidth
          />
        </Labeled>
        <Space height={8} />

        <div style={{ width: '320px' }}>
          <LightGray12>
            성함 및 핸드폰 번호 변경을 원하실 경우 히어로센터
            <br />
            (02-6232-0220)로 연락해주세요.
          </LightGray12>
        </div>
        <Space height={220} />
        <Container>
          <Button
            onClick={() => {
              logout();
              dispatch({
                type: 'updateLoginState',
                isLogin: false,
              });
              history.push('/');
            }}
          >
            <LightGray11
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              로그아웃
            </LightGray11>
          </Button>

          <LightGray11>{` | `}</LightGray11>
          <Button onClick={checkWithdrawal}>
            <LightGray11
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              회원탈퇴
            </LightGray11>
          </Button>
        </Container>
      </VerticalLayout>
      <Footer />
    </>
  );
};
const Container = styled.div`
  width: 320px;
  display: flex;
  justify-content: right;
  align-items: center;
`;
const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: Color.darkGray,
      backgroundColor: '#ECECEC',
    },
  },
})(TextField);
export default MyPageSitter;
