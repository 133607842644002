import { RoundButton } from 'atom/button';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24 } from 'atom/text';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'state';
import { HeaderCustomerSignUp } from 'component-mobile/header';
import { applyWays } from 'page/sign-up/SitterSignUpPage';

const SitterSignUpPageStep6 = observer(() => {
  const history = useHistory();
  const { sitterStore } = useStores();
  const checkForm = () => {
    if (sitterStore.signUpForm.applyRoute <= 0) {
      showConfirm(
        '우리동네 돌봄히어로를\n어떤경로로 알게되셨는지 선택해주세요.',
      );
      return false;
    }

    return true;
  };
  const registerHero = () => {
    if (checkForm()) {
      history.push('/sign-up/hero/info7');
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.push('/sign-up/hero/phone');
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <HeaderCustomerSignUp progress={(6 / 7) * 100} />
      <VerticalLayout style={{ padding: '25px' }}>
        <DarkGray24 bold>
          히어로를 알게 된 경로를
          <br />
          선택해주세요.
        </DarkGray24>
        <Space height={30} />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {applyWays.map(item => (
            <RoundButton
              variant={
                sitterStore.signUpForm.applyRoute === item.value
                  ? 'secondary'
                  : 'gray'
              }
              style={{
                width: '152px',
                height: '40px',
                fontSize: '12px',
                padding: 0,
                margin: '5px',
              }}
              onClick={() => (sitterStore.signUpForm.applyRoute = item.value)}
            >
              {item.label}
            </RoundButton>
          ))}
        </div>
      </VerticalLayout>
      <Space height={20} />
      <RoundButton
        style={{
          width: '320px',
          height: '56px',
          padding: 0,
          fontSize: '16px',
          margin: 'auto',
        }}
        onClick={registerHero}
      >
        다음
      </RoundButton>
    </>
  );
});

export default SitterSignUpPageStep6;
