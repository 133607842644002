import { RoundButton } from 'atom/button';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24 } from 'atom/text';
import { Footer } from 'component-mobile/footer';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const WellcomeSitter = props => {
  const history = useHistory();
  return (
    <>
      <VerticalLayout center style={{ padding: '20px' }}>
        <Space height={120} />
        <DoneImage />
        <Space height={32} />

        <DarkGray24 bold center>
          축하드려요! <br />
          히어로 가족이 되셨습니다
        </DarkGray24>
        <Space height={120} />
        <RoundButton
          style={{
            width: '320px',
            height: '56px',
            padding: 0,
            fontSize: '16px',
          }}
          onClick={() => {
            history.push('/hero/apply');
          }}
        >
          교육신청하기
        </RoundButton>
      </VerticalLayout>
      <Footer />
    </>
  );
};

export default WellcomeSitter;

const DoneImage = styled.img.attrs({
  src: require('asset/done.png').default,
})`
  width: 214px;
  height: 190px;
`;
