import React from 'react';
import styled from 'styled-components';

import { Push, Space } from 'atom/layout';
import { SitterInterviewContent } from 'content';
import { Color } from 'constant';

interface SitterCardProps {
  data: SitterInterviewContent;
  onClick?: () => void;
};
export const SitterCard = ({
  data,
  onClick,
}: SitterCardProps) => {
  return (
    <Container
      onClick={onClick}
    >
      <Image
        src={data.portrait}
      />
      <IntroText
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
      <Space height={20} />
      <NameText>
        <b>{data.sitterName}</b>
        &nbsp;
        ({data.sitterInfo})
      </NameText>
      <Push />
      <ShowMoreText
        onClick={() => window.open(data.url, '_blank')}
      >
        인터뷰 더보기&nbsp;
        <img
          style={{ width: '8px', height: '15px', transform: 'translate(10px, 2px)' }}
          src={require('asset/icon/arrow_right_orange.png').default}
        />
      </ShowMoreText>
    </Container>
  );
};
const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 644px;
  height: 400px;

  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);

  padding: 68px 61px;
  padding-bottom: 40px;
  margin: 23px;
`;
const Image = styled.img`
  position: absolute;
  right: 23px;
  bottom: 0px;

  width: 290px;
  height: 385px;

  object-fit: cover;
  z-index: 0;
`;
const IntroText = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 500;

  line-height: 1.03;
  white-space: pre-line;
  
  z-index: 1;

  p {
    margin: 0px 0px;
    line-height: 130% !important;
  }
`;
const NameText = styled.div`
  font-size: 18px;
  font-weight: 300;

  line-height: 1.43;
  letter-spacing: -0.42px;
`;
const ShowMoreText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Color.Primary};

  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;

  letter-spacing: -0.9px;

  cursor: pointer;
`;
