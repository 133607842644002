import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { useIsMobile } from 'util/index';

interface SliderWithDotsProps {
  children: React.ReactNode;
}
export const SliderWithDots = ({ children }: SliderWithDotsProps) => {
  const isMobile = useIsMobile();

  return (
    <Container
      dots
      centerMode
      variableWidth
      adaptiveHeight
      infinite={false}
      isMobile={isMobile}
    >
      {children}
    </Container>
  );
};

const Container = styled(Slider)<any>`
  .slick-dots {
    bottom: -72px;

    li {
      width: 24px;
      height: 24px;
    }
    li button:before {
      font-size: 18px;
    }
  }

  ${({ isMobile }) =>
    isMobile
      ? `
    .slick-dots {

      li {
        width: 12px;
        height: 12px;
      }
      li button:before {
        font-size: 9px;
      }
    }
  `
      : `
  `}
`;
