import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Space } from 'atom/layout';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestConfirm } from './RequestConfirm';
import { RegisterStep2 } from '../register';
import { RequestStep12 } from './RequestStep12';
import { useAuthContext } from '../../../context/AuthContext';
import ApplySignupStep1 from '../register/ApplySignupStep1';

export const RequestStep11 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const { isLogin } = useAuthContext();
  const onClickNext = () => {
    if (isLogin) return onNext(RequestStep12); //쿠폰확인으로 연결
    return onNext(ApplySignupStep1); //회원가입으로 연결
  };

  return (
    <div style={{ padding: '40px 20px' }}>
      <Title>
        돌봄 중 요청사항을
        <br />
        적어주세요.
      </Title>
      <Space height={8} />
      <Subtitle>
        {/* *성향, 병력, 낮잠시간, 기저귀 착용여부 등 자유롭게 적어주세요.
        <br />
        *아이 외부 스케쥴도 함께 적어주세요. */}
        * 돌봄 중&#40;또는 구인시&#41; 요청사항을 자유롭게 상세히 적어주세요.{' '}
        <br />
        <span style={{ marginLeft: '10px' }}>
          - 필요한 돌봄 업무, 등하원 방법 및 소요시간, 아이 외부 스케쥴,
          돌봄시작일 협의 가능여부 등
        </span>
        <br />
        * 돌봄신청 내용 중 전화상담 후 변경사항이 있을 경우 남겨주세요. <br />
        <span style={{ color: 'red' }}>
          * &#60;서울형 아이돌봄&#62;, &#60;소상공인 민간아이돌봄&#62; 지원
          가정의 경우 ex&#41; &#60;서울형 아이돌봄&#62;이라고 기재해주세요.
        </span>
      </Subtitle>
      <Space height={51} />

      <textarea
        placeholder="입력란 (200자 이내)"
        maxLength={200}
        value={customerStore.applyForm.userRequest}
        onChange={e => (customerStore.applyForm.userRequest = e.target.value)}
      />
      <Space height={60} />
      <Bottom
        disabled={customerStore.applyForm.userRequest.length === 0}
        progress={(1 / 11) * 11}
        onClickNext={onClickNext}
      />
    </div>
  );
});
