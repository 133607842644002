import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Backdrop } from '@material-ui/core';

interface OverlayProps {
  style?: CSSProperties;
  children: React.ReactNode;
  onClose: () => void;
}
export const Overlay = ({ style, children, onClose }: OverlayProps) => {
  return (
    <Backdrop open style={{ zIndex: 10000 }} onClick={onClose}>
      <Container style={style} onClick={e => e.stopPropagation()}>
        {children}
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  position: relative;

  border-radius: 16px;
  -webkit-background-clip: padding-box;
  overflow: hidden;

  z-index: 10000;

  > div {
    height: 85vh;

    border-radius: 16px;
    -webkit-background-clip: padding-box;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
