import React from 'react';
import styled from 'styled-components';

import { usePrices } from 'state/hook/value';
import { Table } from './Table';
import { price } from 'util/index';

export const ExtraServiceMinTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>서비스 종류</th>
          <th style={{ width: '50%' }}>최대 시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>아이중점 가사서비스</td>
          <td>1시간</td>
        </tr>
      </tbody>
    </Table>
  );
};
export const ExtraServiceHourlyPricingTable = () => {
  const prices = usePrices();

  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>이용 시간</th>
          <th style={{ width: '50%' }}>가격</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1시간</td>
          <td>{price(prices['정기_cleaning_1:1_day'])}원</td>
        </tr>
      </tbody>
    </Table>
  );
};
