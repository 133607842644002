import React, { useState } from 'react';
import styled from 'styled-components';
import MuiDrawer from '@material-ui/core/Drawer';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import { Space, Push, VerticalLayout, HorizontalLayout } from 'atom/layout';
import { Color, URL } from 'constant';
import { useStores } from 'state';
import { DarkGray14, DarkGray16, DarkGray18, Primary18 } from 'atom/text';
import { useAuthContext } from '../../context/AuthContext';
import { getUserType, getUserName } from 'util/token';

interface DrawerProps {}
export const Drawer = observer(({}: DrawerProps) => {
  const { isLogin } = useAuthContext();
  const history = useHistory();
  const { drawerStore } = useStores();

  const closeAndNavigate = (to: string) => {
    drawerStore.show = false;

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    history.push(to);
  };

  const AvatarArea = ({ mypageInfoLink }) => {
    const name = getUserName();
    return (
      <HorizontalLayout center onClick={() => closeAndNavigate(mypageInfoLink)}>
        <VerticalLayout>
          <DarkGray18 bold>{`${name}님`}</DarkGray18>
          <DarkGray16>안녕하세요</DarkGray16>
        </VerticalLayout>
        <Push />
        <Avatar />
        <Space width={11} />
        <ArrowRight />
      </HorizontalLayout>
    );
  };
  const getLoginedMenu = () => {
    const type = getUserType();
    if (type === 'HERO') {
      return (
        <>
          <AvatarArea mypageInfoLink={'/mypage/hero/info'} />
          <Space height={24} />
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <AppyIcon />
            <Space width={10} />
            <Link
              aria-label="교육 신청내역"
              to={'/mypage/hero/apply'}
              onClick={() => closeAndNavigate('/mypage/hero/apply')}
            >
              <DarkGray14> {`교육 신청내역`}</DarkGray14>
            </Link>
          </div>
        </>
      );
    } else if (type === 'USER') {
      return (
        <>
          <AvatarArea mypageInfoLink={'/mypage/user/info'} />
          <Space height={24} />
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <AppyIcon />
            <Space width={10} />

            <Link
              aria-label="서비스 신청내역 "
              to={'/mypage/user/apply'}
              onClick={() => closeAndNavigate('/mypage/user/apply')}
            >
              <DarkGray14> {`서비스 신청내역 `}</DarkGray14>
            </Link>
          </div>
        </>
      );
    } else {
      <div>로그인 정보 누락 오류</div>;
    }
  };

  return (
    <SMuiDrawer
      open={drawerStore.show}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={() => (drawerStore.show = false)}
    >
      <Container>
        {isLogin ? (
          getLoginedMenu()
        ) : (
          <>
            <Space height={30} />
            <Primary18 bold>로그인 해주세요</Primary18>
            <Space height={15} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link
                aria-label="로그인"
                to={'/login'}
                onClick={() => closeAndNavigate('/login')}
              >
                <DarkGray14> {`로그인 >`}</DarkGray14>
              </Link>

              <Link
                aria-label={'회원가입'}
                to={'/sign-up'}
                onClick={() => closeAndNavigate('/sign-up')}
              >
                <DarkGray14
                  style={{
                    textDecoration: 'underline',
                    textUnderlineOffset: '2px',
                  }}
                >
                  처음 오셨나요?
                </DarkGray14>
              </Link>
            </div>
          </>
        )}

        {/* <HorizontalLayout>
           <Link aria-label="히어로선생님 지원" to="/hero/apply">
            <MenuButton
              variant="white"
              style={{ fontSize: '10px' }}
              onClick={() => closeAndNavigate('/hero/apply')}
            >
              히어로선생님 지원
            </MenuButton>
          </Link>
          <Space width={10} />
          <Link aria-label="돌봄서비스 신청" to="/service/apply">
            <MenuButton
              style={{ fontSize: '10px' }}
              onClick={() => closeAndNavigate('/service/apply')}
            >
              돌봄서비스 신청
            </MenuButton>
          </Link> 
        </HorizontalLayout> */}
        <Space height={20} />

        <hr />
        <MenuCategory>돌봄서비스</MenuCategory>

        <MenuItem onClick={() => closeAndNavigate('/service/intro')}>
          <Link aria-label="돌봄서비스 소개" to="/service/intro">
            돌봄서비스 소개
          </Link>
        </MenuItem>

        <MenuItem onClick={() => closeAndNavigate('/service/apply')}>
          <Link aria-label="돌봄서비스 신청" to="/service/apply">
            돌봄서비스 신청
          </Link>
        </MenuItem>

        <hr />
        <MenuCategory>히어로 선생님</MenuCategory>
        <MenuItem onClick={() => closeAndNavigate('/hero/about')}>
          <Link aria-label="히어로선생님 혜택" to="/hero/about">
            히어로선생님 혜택
          </Link>
        </MenuItem>
        <MenuItem onClick={() => closeAndNavigate('/hero/apply')}>
          <Link aria-label="히어로선생님 지원" to="/hero/apply">
            히어로선생님 지원
          </Link>
        </MenuItem>

        <hr />
        <MenuItem>
          <Link
            aria-label="이벤트"
            to={{ pathname: 'https://woorihero.oopy.io/' }}
            target="_blank"
          >
            이벤트
          </Link>
        </MenuItem>
        <MenuItem onClick={() => closeAndNavigate('/service/b2b')}>
          <Link aria-label="기업 제휴" to="/service/b2b">
            기업 제휴
          </Link>
        </MenuItem>
        <Link aria-label="자주 묻는 질문" to="/faq">
          <MenuItem onClick={() => closeAndNavigate('/faq')}>
            자주 묻는 질문
          </MenuItem>
        </Link>
        <Link aria-label="이용안내" to={`/?scroll=guide&c=${Date.now()}`}>
          <MenuItem
            onClick={() => closeAndNavigate(`/?scroll=guide&c=${Date.now()}`)}
          >
            이용안내
          </MenuItem>
        </Link>
        <Link aria-label="이용요금" to="/service/pricing">
          <MenuItem onClick={() => closeAndNavigate('/service/pricing')}>
            이용요금
          </MenuItem>
        </Link>
        <hr />
        <Space height={8} />
        <MenuItem onClick={() => window.open(URL.Call, '_blank')}>
          히어로 상담하기
        </MenuItem>
        <Space height={8} />

        <hr />
        <Space height={20} />
        <HorizontalLayout style={{ width: '100%' }}>
          <StoreButton
            src={require('asset/mobile/drawer/appstore.svg').default}
            onClick={() => {
              drawerStore.show = false;
              showConfirm(
                'COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.',
              );
            }}
          />
          <Push />
          <StoreButton
            src={require('asset/mobile/drawer/playstore.svg').default}
            onClick={() => {
              drawerStore.show = false;
              showConfirm(
                'COMING SOON!\n우리동네 돌봄히어로의 앱이\n곧 출시됩니다.',
              );
            }}
          />
        </HorizontalLayout>
      </Container>
    </SMuiDrawer>
  );
});

const SMuiDrawer = styled(MuiDrawer).attrs({
  anchor: 'right',
})``;
const Container = styled.nav`
  width: 262px;
  display: flex;
  flex-direction: column;

  padding: 34px 25px;

  hr {
    margin-bottom: 10px;
  }
  a:visited {
    color: #484848;
  }
`;
const MenuButton = styled(RoundButton)`
  display: flex;
  width: 100px;
  height: 33px;

  font-size: 11px;

  padding: 0px;

  align-items: center;
  justify-content: center;
`;
const MenuCategory = styled.li`
  color: ${Color.Primary};

  font-size: 17px;
  font-weight: bold;

  letter-spacing: -0.85px;

  margin-bottom: 14px;
`;
const MenuItem = styled.li`
  color: #484848;

  font-size: 13px;

  letter-spacing: -0.65px;

  margin-bottom: 13px;
`;
const Logo = styled.img.attrs({
  src: require('asset/mobile/drawer/logo.png').default,
})`
  width: 87px;
`;

const Avatar = styled.img.attrs({
  src: require('asset/icon/avatar.svg').default,
})``;
const AppyIcon = styled.img.attrs({
  src: require('asset/icon/applyIcon.svg').default,
})``;

const ArrowRight = styled.img.attrs({
  src: require('asset/icon/arrow_right_light.svg').default,
})``;

const StoreButton = styled.img`
  width: 103px;
  height: 28px;
`;
