import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { VerticalLayout, Space, HorizontalLayout, Push } from 'atom/layout';
import { RoundButton, SegmentTab } from 'atom/button';
import { ImageBox } from 'atom/image';
import {
  Primary24,
  LightGray12,
  Gray25,
  Gray16,
  White21,
  White24,
  LightGray16,
  Gray24,
  LightLightGray12,
  Gray28,
} from 'atom/text';
import { FaqAccordianItem } from 'component/faq';
import { Tab } from 'component/tab';
import {
  ExtraServiceHourlyPricingTable,
  ExtraServiceMinTable,
} from 'component/customer';
import {
  CleaningTable,
  DishWashingTable,
  WashingTable,
  CookingTable,
} from 'component/customer/ExtraServicesTable';
import { useFaq } from 'state/hook';
import { useIsMobile } from 'util/index';

interface IntroExtraPageProps {
  sheet?: boolean; // 튀어나온 시트 모드인지
  injectedHistory?: any;
  tab?: string;
  onClickApply?: () => void;
  onClose?: () => void;
}
export const IntroExtraPage = ({
  sheet,
  injectedHistory,
  tab: initialTab = 'cleaning',
  onClickApply,
  onClose,
}: IntroExtraPageProps) => {
  const isMobile = useIsMobile(true);
  const history = useHistory() || injectedHistory;
  const faq = useFaq('extra');
  const [tab, setTab] = useState(initialTab);
  const isApplying =
    window.location.href.includes('apply') ||
    window.location.href.includes('event_sd'); // 지원 중 상세보기인지, 이벤트 페이지인지

  let Component = null;
  if (tab === 'cleaning') {
    Component = CleaningContent;
  } else {
    Component = CookingContent;
  }

  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/customer/service/extra/top.jpg').default}
          imageStyle={{ filter: 'brightness(0.8)' }}
          minHeight={isMobile ? 240 : 380}
          maxHeight={isMobile ? 240 : 380}
        >
          <Space height={isMobile ? 40 : 126} />
          <HorizontalLayout>
            <Space width={isMobile ? 40 : 100} />
            <VerticalLayout>
              <White24 bold style={{ fontSize: '28px' }}>
                쾌적한 육아 환경을 위한
                <br />
                <span style={{ color: '#f69F30' }}>아이 중점</span> 가사 서비스
              </White24>
              {!isApplying && (
                <>
                  <Space height={isMobile ? 32 : 52} />
                  <RoundButton
                    variant="primary"
                    style={{ padding: '0px 22px' }}
                    onClick={() => history.push('/service/apply')}
                  >
                    서비스 신청하기
                  </RoundButton>
                </>
              )}
            </VerticalLayout>
          </HorizontalLayout>
        </ImageBox>

        <VerticalLayout center style={{ width: '100%', background: 'white' }}>
          <Space height={79} />
          <Primary24 bold center style={{ fontSize: isMobile ? 24 : 32 }}>
            아이 중점 가사 서비스
          </Primary24>
          <Space height={12} />
          <LightGray16 center>
            부모님의 손길을 대신하여 아이를 중심으로 한<br />
            청소와 요리서비스를 제공합니다.
          </LightGray16>
          <Space height={16} />
          <HorizontalLayout center>
            <img
              src={require('asset/customer/service/extra/cleaning.png').default}
              style={{ width: isMobile ? '160px' : '207px' }}
            />
            <img
              src={require('asset/customer/service/extra/cook.png').default}
              style={{ width: isMobile ? '160px' : '207px' }}
            />
          </HorizontalLayout>
          <Space height={16} />
          <span
            style={{
              width: isMobile ? '100%' : '60%',
              fontSize: '12px',
              textAlign: isMobile ? 'center' : 'right',
              color: '#ed584e',
            }}
          >
            · 청소와 요리 서비스는 각각 제공됩니다.
          </span>
          <Space height={35} />
          <Gray16 center>
            일하고 살림까지 하면 하루가 너무 부족해요!
            <br />
            히어로 선생님이 아이돌봄부터
            <br />
            아이를 위한 청소와 요리까지 도와드려요.
          </Gray16>
          <Space height={isMobile ? 32 : 144} />
        </VerticalLayout>

        <VerticalLayout center style={{ width: '100%', background: 'white' }}>
          <SVG
            src={
              isMobile
                ? require('asset/customer/service/extra/intro_mobile.svg')
                    .default
                : require('asset/customer/service/extra/intro.svg').default
            }
            style={{ maxWidth: 'calc(100%)', overflow: 'visible' }}
          />
          <Space height={32} />
        </VerticalLayout>

        <Tab
          items={[
            { label: '청소', value: 'cleaning' },
            { label: '요리', value: 'cooking' },
          ]}
          value={tab}
          onChange={setTab}
        />

        <Space height={32} />

        <Component />
        <Space height={64} />

        <VerticalLayout
          center
          style={{ width: 'calc(100% - 80px)', background: 'white' }}
        >
          <Space height={64} />
          <Gray24 bold center style={{ fontSize: isMobile ? 24 : 28 }}>
            아이 중점 가사 서비스 <br />
            이용 시간 안내
          </Gray24>
          <Space height={34} />
          <ExtraServiceMinTable />
          <Space height={12} />
          <LightLightGray12 right style={{ width: '100%', maxWidth: '400px' }}>
            *비정기 돌봄 신청시 이용이 불가합니다.
            <br />
            *가사 서비스는 기본돌봄 연계시 이용이 가능합니다.
          </LightLightGray12>
          <Space height={64} />
          <Gray24 bold center style={{ fontSize: isMobile ? 24 : 28 }}>
            시간당 요금
          </Gray24>
          <Space height={32} />
          <ExtraServiceHourlyPricingTable />
          <Space height={12} />
          <LightLightGray12 right style={{ width: '100%', maxWidth: '400px' }}>
            *공휴일/주말/야간 이용시 2,000원이 추가됩니다. <br />
          </LightLightGray12>
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{ width: '100%', background: '#f9f9f9', padding: '0px 40px' }}
        >
          <Space height={64} />
          <Gray24 bold center>
            Q & A
          </Gray24>
          <Space height={32} />
          <FaqContainer>
            {faq.map((x, idx) => (
              <FaqAccordianItem key={idx} data={x} />
            ))}
          </FaqContainer>
          <Space height={32} />
          <RoundButton
            variant="primary"
            style={
              isMobile
                ? {
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    bottom: '20px',
                  }
                : {
                    width: '100%',
                    borderRadius: '0px',
                    position: 'absolute',
                    bottom: '0px',
                  }
            }
            onClick={() => {
              if (onClickApply) onClickApply();
              else if (sheet) onClose?.();
              else history.push('/service/apply');
            }}
          >
            {isApplying ? '확인' : '서비스 신청하기'}
          </RoundButton>
          <Space height={32} />
        </VerticalLayout>
      </Container>
    </>
  );
};

const CleaningContent = () => {
  const isMobile = useIsMobile(true);
  const [tab, setTab] = useState(0);

  return (
    <>
      <VerticalLayout
        center
        style={{ width: '100%', background: 'white', padding: '0px 20px' }}
      >
        <Space height={64} />
        <Gray28 center bold>
          청소 서비스
        </Gray28>
        <Space height={12} />
        <LightGray16 center>
          아이가 늘 쾌적한 환경에서 <br />잘 성장할 수 있도록 도와줘요.
        </LightGray16>
        <Space height={32} />

        <SegmentTab
          items={['청소', '설거지', '빨래']}
          value={tab}
          onChange={setTab}
          isMobile={isMobile}
        />
        <Space height={33} />

        <img
          src={
            {
              0: require('asset/customer/service/extra/1.png').default,
              1: require('asset/customer/service/extra/2.png').default,
              2: require('asset/customer/service/extra/3.png').default,
            }[tab]
          }
          style={{
            width: isMobile ? '240px' : '317px',
            imageRendering: '-webkit-optimize-contrast',
          }}
        />
        <Space height={49} />

        {tab === 0 && <CleaningTable />}
        {tab === 1 && <DishWashingTable />}
        {tab === 2 && <WashingTable />}

        <Space height={64} />
        <VerticalLayout
          center
          style={{ width: 'calc(100% - 80px)', background: 'white' }}
        >
          {isMobile ? (
            <Gray24 center bold>
              청소 서비스는 <br />
              어떻게 진행되나요?
            </Gray24>
          ) : (
            <Gray28 center bold>
              청소 서비스는 어떻게 진행 되나요?
            </Gray28>
          )}
          <Space height={32} />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'start',
              justifyContent: 'center',
              marginTop: '40px',
              marginLeft: '5px',
            }}
          >
            {isMobile ? (
              <SVG
                src={
                  require('asset/customer/service/extra/flow1_cleaning_mobile.svg')
                    .default
                }
                style={{
                  width: '55px',
                  overflow: 'visible',
                  marginRight: '10px',
                }}
              />
            ) : (
              <SVG
                src={
                  require('asset/customer/service/extra/flow1_cleaning.svg')
                    .default
                }
                style={{
                  width: '83px',
                  overflow: 'visible',
                  marginLeft: '30px',
                  marginRight: '30px',
                }}
              />
            )}
            <VerticalLayout
              style={{
                transform: 'translateY(-5px)',
              }}
            >
              <Space height={isMobile ? 0 : 3} />
              <Gray16 bold>
                {isMobile
                  ? '돌봄이 없는 시간에 청소서비스를 \n진행해요.'
                  : '돌봄이 없는 시간에 청소서비스를 진행해요.'}
              </Gray16>
              <Space height={isMobile ? 27 : 20} />
              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow1.jpg').default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow1.jpg').default
                  }
                />
              )}

              <Space height={isMobile ? 46 : 75} />

              <Gray16 bold>
                {isMobile
                  ? `방 청소 기본 구역을 중심으로 청소를 
                진행해요.`
                  : `방 청소 기본 구역을 중심으로 청소를 진행해요.`}
              </Gray16>
              <Space height={isMobile ? 45 : 20} />

              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow_cleaning_2.jpg')
                      .default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow_cleaning_2.jpg')
                      .default
                  }
                />
              )}

              <Space height={isMobile ? 27 : 62} />

              <Gray16 bold>
                {isMobile
                  ? `아이와 관련된 식기류 설거지와
                  빨래도 진행해요.`
                  : `아이와 관련된 식기류 설거지와 빨래도 진행해요.`}
              </Gray16>
              <Space height={27} />

              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow_cleaning_3.jpg')
                      .default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow_cleaning_3.jpg')
                      .default
                  }
                />
              )}

              <Space height={isMobile ? 45 : 55} />

              <Gray16 bold>
                {isMobile
                  ? `뒷정리 후 늦지 않게 아이를
              하원하러 가요.`
                  : '뒷정리 후 늦지 않게 아이를 하원하러 가요.'}
              </Gray16>
              <Space height={15} />

              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow2.jpg').default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow2.jpg').default
                  }
                />
              )}
            </VerticalLayout>
          </div>
        </VerticalLayout>
      </VerticalLayout>
    </>
  );
};
const CookingContent = () => {
  const isMobile = useIsMobile(true);

  return (
    <>
      <VerticalLayout
        center
        style={{ width: '100%', background: 'white', padding: '0px 20px' }}
      >
        <Space height={64} />
        <Gray28 center bold>
          요리 서비스
        </Gray28>
        <Space height={12} />
        <LightGray16 center>
          아이가 늘 쾌적한 환경에서 <br />잘 성장할 수 있도록 도와줘요.
        </LightGray16>
        <Space height={32} />

        <CookingTable />
        <Space height={64} />
        <VerticalLayout
          center
          style={{ width: 'calc(100% - 80px)', background: 'white' }}
        >
          {isMobile ? (
            <Gray24 center bold>
              요리 서비스는 <br />
              어떻게 진행되나요?
            </Gray24>
          ) : (
            <Gray28 center bold>
              요리 서비스는 어떻게 진행 되나요?
            </Gray28>
          )}
          <Space height={32} />

          <HorizontalLayout>
            {isMobile ? (
              <SVG
                src={require('asset/customer/service/extra/flow2.svg').default}
                style={{ width: '55px', overflow: 'visible' }}
              />
            ) : (
              <SVG
                src={require('asset/customer/service/extra/flow2.svg').default}
                style={{ width: '55px', overflow: 'visible' }}
              />
            )}
            <Space width={8} />
            <VerticalLayout style={{ transform: 'translateY(-5px)' }}>
              <Space height={3} />
              <Gray16 bold>
                {isMobile
                  ? '아이가 없는 시간에 요리 서비스를\n진행해요.'
                  : '아이가 없는 시간에 요리 서비스를 진행해요.'}
              </Gray16>
              <Space height={isMobile ? 28 : 20} />

              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow1.jpg').default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow1.jpg').default
                  }
                />
              )}
              <Space height={40} />

              <Gray16 bold>
                {isMobile
                  ? '손질된 재료를 가지고 아이가\n좋아하는 반찬을 만들어요.'
                  : '손질된 재료를 가지고 아이가 좋아하는\n반찬을 만들어요.'}
              </Gray16>
              <Space height={isMobile ? 28 : 25} />

              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow_cooking_2.jpg')
                      .default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow_cooking_2.jpg')
                      .default
                  }
                />
              )}
              <Space height={isMobile ? 58 : 15} />

              <Gray16 bold>밥을 짓고 국거리도 만들어요.</Gray16>
              <Space height={isMobile ? 40 : 20} />

              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow_cooking_3.jpg')
                      .default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow_cooking_3.jpg')
                      .default
                  }
                />
              )}
              <Space height={isMobile ? 42 : 35} />

              <Gray16 bold>
                {isMobile
                  ? '뒷정리 후 늦지 않게 아이를\n하원하러 가요.'
                  : '뒷정리 후 늦지 않게 아이를 하원하러 가요.'}
              </Gray16>
              <Space height={21} />

              {isMobile ? (
                <FlowImageMobile
                  src={
                    require('asset/customer/service/extra/flow2.jpg').default
                  }
                />
              ) : (
                <FlowImage
                  src={
                    require('asset/customer/service/extra/flow2.jpg').default
                  }
                />
              )}
            </VerticalLayout>
          </HorizontalLayout>
        </VerticalLayout>
      </VerticalLayout>
    </>
  );
};

const Container = styled(VerticalLayout)`
  width: 100%;
  position: static;

  background: white;

  overflow-x: hidden;
  border-radius: 6px;
`;
const FlowImage = styled.img`
  width: 277px;
  height: 185px;

  image-rendering: -webkit-optimize-contrast;
`;

const FlowImageMobile = styled.img`
  width: 230px;
  height: 150px;

  image-rendering: -webkit-optimize-contrast;
`;

const FaqContainer = styled.div`
  width: 100%;

  background: #f9f9f9;

  padding: 34px 18px;

  .MuiPaper-root.MuiAccordion-root {
    padding: 5px 5px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 32px !important;
  }
  .MuiAccordionSummary-root {
    font-size: 15px !important;
  }
  .MuiAccordionDetails-root {
    font-size: 14px !important;
  }
  .MuiAccordionSummary-expandIcon {
    zoom: 0.5;
  }
`;
