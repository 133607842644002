import React from 'react';
import styled from 'styled-components';

import { HorizontalLayout, Push, Space } from 'atom/layout';
import { Primary16, Gray12, Gray14, LightGray10 } from 'atom/text';
import { Checkbox } from 'atom/input';

interface AdditionalServiceCardProps {
  serviceType: 'premium' | 'cleaning';
  value: boolean;
  onChange: (x: boolean) => void;
  onDetail?: () => void;
}
export const AdditionalServiceCard = ({
  serviceType,
  value,
  onChange,
  onDetail,
}: AdditionalServiceCardProps) => {
  return (
    <AdditionalServiceCardContainer onClick={() => onChange(!value)}>
      <HorizontalLayout center>
        <Checkbox variant="square" value={value} onChange={() => {}} />
        <Space width={8} />
        <Primary16 bold>
          {
            {
              premium: '창의 놀이',
              cleaning: '가사서비스',
            }[serviceType]
          }
        </Primary16>
        <Push />
        <Gray12>
          {
            {
              premium: '시간당 20,000원 ',
              cleaning: '시간당 15,000원 ',
            }[serviceType]
          }
        </Gray12>
      </HorizontalLayout>
      <Space height={10} />
      <Gray14>
        {
          {
            premium: '예술가/과학자/건축가/발명가 놀이 등',
            cleaning: '청소/요리 서비스',
          }[serviceType]
        }
      </Gray14>
      <Space height={3} />
      <LightGray10>
        {
          {
            premium:
              '* 최대 주 2회 이용 가능합니다. \n*서비스 시작시 최초 1회 필수 준비물 비용 30,000원이 부과됩니다.',
            cleaning:
              '*하루 최대 1시간, 돌봄 전후로 이용 가능합니다.\n*서로의 안전을 위해 아이가 없는 시간에 이루어집니다.',
          }[serviceType]
        }
      </LightGray10>
      <Space height={11} />
      <DetailButton
        onClick={e => {
          e.stopPropagation();
          onDetail?.();
        }}
      >
        자세히 보기
      </DetailButton>
    </AdditionalServiceCardContainer>
  );
};

const AdditionalServiceCardContainer = styled.div`
  border-radius: 16px;
  border: solid 1px #b7b7b7;

  padding: 12px 19px;
`;
const DetailButton = styled.div`
  display: flex;
  width: 100%;
  height: 44px;

  border-radius: 10px;
  background-color: #f5f2f2;

  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  color: #484848;

  cursor: pointer;
`;
