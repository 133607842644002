import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray10, DarkGray12, DarkGray18 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Reward1HowTo = ({ BtnGroup }) => {
  const steps = [
    {
      title: '[Step 01]',
      content: `이벤트 신청하기`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_7-1.png')
        .default,
    },
    {
      title: '[Step 02]',
      content: `서비스 신청 &
          집근처 선생님 서칭`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_7-2.png')
        .default,
    },
    {
      title: '[Step 03]',
      content: `집근처 선생님 매칭 &
          이벤트 혜택 적용`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_7-3.png')
        .default,
    },
  ];
  return (
    <Container center>
      <Space height={64} />
      <DarkGray18 bold>[혜택 1] 이벤트 참여방법</DarkGray18>
      <Space height={8} />
      <DarkGray12>: 창의놀이 이용권 + 준비물 0원</DarkGray12>
      <Space height={32} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {steps.map(step => (
          <StepContainer key={step.title} step={step} />
        ))}
      </div>
      <Space height={24} />
      <div style={{ width: '80%', display: 'flex' }}>
        <DarkGray10 bold>{`🎁 창의놀이 (10회) 이용권과 준비물은 
정기돌봄 3개월 이상 신청하신 분들에 한해서 제공됩니다.`}</DarkGray10>
      </div>
      <Space height={48} />
      <BtnGroup />
      <Space height={64} />
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  background: #ffd200;
`;
const WhiteCercleFrame = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const StepContainer = ({ step }) => {
  const { src, title, content } = step;
  return (
    <VerticalLayout center>
      <WhiteCercleFrame>
        <img style={{ width: '47.5px' }} src={src} />
      </WhiteCercleFrame>
      <Space height={24} />
      <DarkGray12 bold center style={{ fontSize: '11px' }}>
        {title}
      </DarkGray12>
      <Space height={12} />
      <DarkGray12 center style={{ fontSize: '11px', whiteSpace: 'pre-line' }}>
        {content}
      </DarkGray12>
    </VerticalLayout>
  );
};
export default Reward1HowTo;
