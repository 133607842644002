import { RoundButton } from 'atom/button';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray32, DarkGray40, DarkGray44 } from 'atom/text';
import { AddKaKaoFriendBtn } from 'page/event/Event5YearsForParents';
import React from 'react';
import styled from 'styled-components';

const RewardDetail1HowTo = ({ scrollToApply }) => {
  const steps = [
    {
      title: '[Step 01]',
      content: `이벤트 신청하기`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_7-1.png')
        .default,
    },
    {
      title: '[Step 02]',
      content: `서비스 신청 &
      집근처 선생님 서칭`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_7-2.png')
        .default,
    },
    {
      title: '[Step 03]',
      content: `집근처 선생님 매칭 &
      이벤트 혜택 적용`,
      src: require('asset/event/event_5years_for_parents/reward1_detail_7-3.png')
        .default,
    },
  ];
  return (
    <>
      <Container>
        <Space height={120} />
        <DarkGray44 center bold style={{ fontSize: '48px' }}>
          [혜택 1] 이벤트 참여방법
        </DarkGray44>
        <DarkGray32 center style={{ fontSize: '36px' }}>
          : 창의놀이 이용권 + 준비물 0원
        </DarkGray32>
        <Space height={100} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {steps.map(step => (
            <StepContainer key={step.title} step={step} />
          ))}
        </div>
        <Space height={78} />
        <DarkGray32 style={{ fontSize: '34px' }} bold center>
          🎁 창의놀이 (10회) 이용권과 준비물은 정기돌봄 3개월 이상 신청하신
          분들에 한해서 제공됩니다.
        </DarkGray32>
        <Space height={120} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RoundButton
            style={{
              padding: '17px 32px',
              width: '440px',
              height: '79px',
              fontWeight: 'bold',
              fontSize: '32px',
              marginRight: '61px',
            }}
            onClick={scrollToApply}
          >
            이벤트 신청하기
          </RoundButton>

          <AddKaKaoFriendBtn />
        </div>
      </Container>
      <AlertContainer>
        <Space height={40} />
        <img
          style={{ width: '108px', margin: '0 auto' }}
          src={
            require('asset/event/event_5years_for_parents/alertIcon.png')
              .default
          }
        />
        <Space height={10} />
        <DarkGray40 bold center>
          5주년 이벤트 혜택은 수량 한정 혜택이니 서둘러 상담부터 받아보세요!
        </DarkGray40>
      </AlertContainer>
    </>
  );
};

const AlertContainer = styled(VerticalLayout)`
  width: 100%;
  height: 237px;
  background: #ececec;
`;

const Container = styled(VerticalLayout)`
  width: 100%;
  height: 1325px;
  background: #ffd200;
`;

const WhiteCercleFrame = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const StepContainer = ({ step }) => {
  const { src, title, content } = step;
  return (
    <VerticalLayout center>
      <WhiteCercleFrame>
        <img style={{ width: '170px' }} src={src} />
      </WhiteCercleFrame>
      <Space height={64} />
      <DarkGray32 bold center style={{ fontSize: '34px' }}>
        {title}
      </DarkGray32>
      <Space height={12} />
      <DarkGray32 center style={{ fontSize: '34px', whiteSpace: 'pre-line' }}>
        {content}
      </DarkGray32>
    </VerticalLayout>
  );
};
export default RewardDetail1HowTo;
