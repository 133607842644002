import React from 'react';
import styled from 'styled-components';

interface NoCareDataProps {}
export const NoCareData = ({}: NoCareDataProps) => {
  return (
    <Container>
      <InnerText>등록된 돌봄이 없습니다</InnerText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 62px;

  border-radius: 8px;
  background-color: #f3f4f5;

  align-items: center;
  justify-content: center;
`;
const InnerText = styled.div`
  color: #484848;

  font-size: 16px;
  font-weight: 500;

  letter-spacing: -0.4px;
`;
