import { Space } from 'atom/layout';
import React from 'react';
import styled from 'styled-components';
import { useIsMobile } from 'util/index';

const Coupone = props => {
  const isMobile = useIsMobile();
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Container isMobile={isMobile}>
        <Space height={20} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: isMobile ? '15px' : '20px',
            paddingRight: isMobile ? '15px' : '20px',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              color: '#484848',
              fontSize: isMobile ? '14px' : '1rem',
            }}
          >
            [성동구 놀이키트 쿠폰]
          </span>
          <span
            style={{
              color: '#989898',
              fontSize: isMobile ? '11px' : '13px',
              padding: '2px 4px',
              border: '1px solid #989898',
              borderRadius: '8px',
            }}
          >
            상품지급
          </span>
        </div>
        <Space height={isMobile ? 10 : 20}></Space>
        <span
          style={{
            marginLeft: isMobile ? '15px' : '28px',
            color: '#989898',
            fontWeight: 400,
            fontSize: isMobile ? '14px' : '1rem',
          }}
        >
          놀이키트 무료 증정권
        </span>
        <span
          style={{
            marginLeft: isMobile ? '15px' : '28px',
            color: '#989898',
            fontSize: '14px',
            marginTop: '5px',
          }}
        >
          2022.07.01 ~ 2022.12.31까지
        </span>
      </Container>
      <Space height={30} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CouponInfoText style={{ color: '#989898', fontSize: '13px' }}>
          - 정기 돌봄 신청 시 쿠폰 적용이 가능합니다.
        </CouponInfoText>
        <CouponInfoText>
          - 다른 할인 혜택과 중복 사용이 불가합니다.
        </CouponInfoText>
        <CouponInfoText>
          - 쿠폰 별 사용기간이 상이하며, 사용 기간이 만료된 쿠폰은 자동
          소멸됩니다.
        </CouponInfoText>
      </div>
    </div>
  );
};

const CouponInfoText = styled.span`
  color: #989898;
  font-size: 13px;
  margin-left: 10px;
  word-spacing: -0.5px;
`;
const Container = styled.div<any>`
  display: flex;
  flex-direction: column;

  width: 420px;
  height: 130px;

  background: #fff;
  box-shadow: 3px 4px 16px #dedede;
  border-radius: 24px;
  box-sizing: border-box;

  overflow: hidden;
  cursor: pointer;

  &:hover {
  }

  ${({ isMobile }) =>
    isMobile
      ? `
    width: 320px;
    height: 110px;
    border-radius: 10px;
  `
      : `
  `}
`;

export default Coupone;
