import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray20, Primary20 } from 'atom/text';
import { Footer } from 'component/footer';
import { Header } from 'component/header';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SignUpMainPage = props => {
  return (
    <>
      <Header />
      <VerticalLayout center>
        <Space height={140} />
        <Link to={'/sign-up/parents/phone'}>
          <div
            style={{
              width: '340px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={require('asset/sign-up/userImg.svg').default} />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DarkGray20 bold>우리 아이 맡기기</DarkGray20>
              <Primary20 bold>부모님이에요.</Primary20>
            </div>
            <img src={require('asset/icon/arrow_right.svg').default} />
          </div>
        </Link>
        <Space height={42} />
        <hr style={{ width: '720px' }} />
        <Space height={42} />
        <Link to={'/sign-up/hero/phone'}>
          <div
            style={{
              width: '340px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={require('asset/sign-up/heroImg.svg').default} />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DarkGray20 bold>히어로 활동하기</DarkGray20>
              <Primary20 bold>선생님이에요.</Primary20>
            </div>
            <img src={require('asset/icon/arrow_right.svg').default} />
          </div>
        </Link>
        <Space height={200} />
      </VerticalLayout>
      <Footer />
    </>
  );
};

export default SignUpMainPage;
