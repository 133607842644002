import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { UnderlinedText } from 'atom/text';
import { ChildPopup } from 'component/popup';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { ChildItem, NoChildrenData } from 'atom/child';
import { RequestStep10 } from './RequestStep10';

export const RequestStep9 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();

  const onSelect = (value: number) => {
    if (value === 6) customerStore.applyForm.companion = [];
  };

  useEffect(() => {
    if (!customerStore.applyForm.companion) return;

    customerStore.applyForm.companionInfo = customerStore.applyForm.companion
      .filter(x => x !== 6)
      .join(',');
    // if (customerStore.applyForm.companion.length === 0)
    //   customerStore.form.nok = '계시지 않아요';
    // else
    //   customerStore.form.nok = customerStore.applyForm.companion
    //     .filter(x => x !== 'x')
    //     .join(',');
  }, [customerStore.applyForm.companion]);

  return (
    <div style={{ padding: '40px 20px' }}>
      <Title>
        선생님 방문 시,
        <br />
        누구와 함께 있나요?
      </Title>
      <Space height={8} />
      <Subtitle>*중복으로 선택이 가능합니다.</Subtitle>
      <Space height={60} />

      <MultiSelect
        twoColumns
        items={[
          { label: '아빠', value: 1 },
          { label: '엄마', value: 2 },
          { label: '할머니', value: 3 },
          { label: '할아버지', value: 4 },
          { label: '도우미 이모님', value: 5 },
          { label: '계시지 않아요', value: 6 },
        ]}
        value={
          customerStore.applyForm.companion.length === 0
            ? [6]
            : customerStore.applyForm.companion.filter(x => x !== 6)
        }
        onSelect={onSelect}
        onChange={e => (customerStore.applyForm.companion = e)}
      />
      <Space height={80} />
      <Bottom
        progress={(1 / 11) * 9}
        onClickNext={() => onNext(RequestStep10)}
      />
    </div>
  );
});
