import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import { HorizontalLayout, VerticalLayout, Space } from 'atom/layout';
import { ImageBox } from 'atom/image';
import { month } from 'component/event/LimitedSeats';

import {
  DarkGray32,
  DarkGray12,
  LightGray10,
  DarkGray18,
  LightGray12,
  DarkGray14,
  Primary18,
  Primary32,
  DarkGray30,
} from 'atom/text';
import { LimitedSeats } from 'component-mobile/event/LimitedSeats';
import { SupportInfos } from 'component-mobile/event/SupportInfos';
import { Benefits } from 'component-mobile/event/ Benefits';
import { HeroWorks } from 'component-mobile/event/HeroWorks';
import { FaqSD } from 'component-mobile/event/FaqSD';

export const EventSungDongPage = ({}) => {
  const history = useHistory();

  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/mobile/event/event_sd/mobile_top_2.jpg').default}
          minHeight={205}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              marginTop: '60px',
              position: 'absolute',
              left: '10%',
              marginLeft: 'calc(20px/-2)',
            }}
          >
            {/* <DarkGray12>서울시 지원 선착순 무료교육</DarkGray12>
            <Primary18 bold>시급 최대 17,000원</Primary18>
            <DarkGray18 bold>
              아이와 놀면서,
              <br />
              돈도버는 최고의 일자리!
            </DarkGray18> */}
            {/* // old ver. */}
            <DarkGray12 style={{ fontWeight: 500 }}>
              2022 시-구 상향적 협력적 일자리창출 사업
            </DarkGray12>
            <div
              style={{
                color: '#ED584E',
                background:
                  'linear-gradient(to top, rgba(251, 231, 6, 0.4) 50%, transparent 50%)',
                position: 'relative',
              }}
            >
              <div
                style={{
                  color: '#ED584E',
                  fontSize: '22px',
                  position: 'absolute',
                  top: -14,
                  left: 130,
                  wordSpacing: 1,
                }}
              >
                · ·
              </div>
              <p style={{ fontSize: '15px', fontWeight: 700, margin: 0 }}>
                <span style={{ fontSize: '17px', fontWeight: 700 }}>
                  {month}
                </span>
                한 달간 교육비 무료 지원
              </p>
            </div>
            <DarkGray32 style={{ fontSize: '22px', fontWeight: 700 }}>
              아이돌봄 선생님을
              <br />
              모집 합니다
            </DarkGray32>

            <Space height={133} />
          </div>
          <div
            style={{
              width: '200px',
              fontSize: '18px',
              fontWeight: 700,
              letterSpacing: -1,
              position: 'absolute',
              zIndex: 9999,
              left: '50%',
              bottom: '0px',
              transform: 'translate(-50%, 50%)',
            }}
          >
            선착순 무료교육 <span style={{ color: '#ED584E' }}>마감임박!</span>
          </div>
        </ImageBox>
        <Space height={15} />
        <img
          src={require('asset/mobile/event/event_sd/mobile_price.svg').default}
          style={{ alignSelf: 'center' }}
        />

        <Space height={32} />
        <LimitedSeats />
        <Space height={10} />
        <LightGray10>
          * 선착순 자리가 얼마 남지 않았어요. 신청을 서둘러 주세요!
        </LightGray10>
        <Space height={24} />
        <RoundButton
          style={{
            width: '204px',
            height: '40px',
            fontSize: '14px',
            padding: 0,
          }}
          onClick={() => history.push('/hero/apply')}
        >
          지금 바로 신청하기
        </RoundButton>
        <Space height={34} />

        <ImageBox
          src={require('asset/event/event_sd/event_sd_middle_bg.jpg').default}
          minHeight={652}
        >
          <Space height={64} />
          <DarkGray18
            style={{
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            단 하루만 교육 수료하고,
            <br />집 근처에서 바로 활동해보세요!
          </DarkGray18>
          <Space height={8} />
          <LightGray12
            style={{
              textAlign: 'center',
            }}
          >
            아이를 키워본 30~67세 여성이라면 누구나
          </LightGray12>
          <Space height={32} />
          <ApplyStepsContainer>
            <HorizontalLayout
              style={{ padding: '20px 10px ', justifyContent: 'start' }}
            >
              <img
                src={
                  require('asset/mobile/event/event_sd/mobile_flow.svg').default
                }
                style={{ marginRight: '10px', height: '260px' }}
              />
              <VerticalLayout style={{ width: '232px' }}>
                <Space height={4} />
                <DarkGray12 style={{ fontWeight: 700 }}>
                  홈페이지 히어로 선생님 지원하기 클릭 후 지원
                </DarkGray12>
                <Space height={4} />
                <LightGray12>
                  * 홈페이지 신청이 힘드신 경우 02-6232-0220
                  <br />
                  으로 전화접수 가능합니다.
                </LightGray12>
                <Space height={24} />
                <DarkGray12 style={{ fontWeight: 700 }}>
                  신청한 교육날짜에 교육 진행 (단 하루, 5시간)
                </DarkGray12>
                <Space height={28} />
                <DarkGray12 style={{ fontWeight: 700 }}>
                  전문 교육 수료 후 관련 서류 제출
                </DarkGray12>
                <Space height={4} />
                <LightGray12>
                  *신분증 사본, 가족관계증명서 사본,
                  <br />
                  통장사본, 보건증(b형 간염 항체검사 포함) 제출
                </LightGray12>
                <Space height={26} />
                <DarkGray12 style={{ fontWeight: 700 }}>
                  집 근처에서 돌봄 활동 시작!
                </DarkGray12>
                <Space height={4} />
                <LightGray12>
                  돌봄을 원하는 가정과 매칭되면
                  <br />
                  히어로 선생님으로 바로 활동 가능
                </LightGray12>
              </VerticalLayout>
            </HorizontalLayout>
          </ApplyStepsContainer>
          <Space height={32} />
          <VerticalLayout center>
            <RoundButton
              style={{
                width: '204px',
                height: '40px',
                fontSize: '14px',
                padding: 0,
              }}
              onClick={() => history.push('/hero/apply')}
            >
              교육 일정 확인하기
            </RoundButton>
            <Space height={16} />
            <a href="tel:02-6232-0220">
              <RoundButton
                style={{
                  width: '204px',
                  height: '40px',
                  fontSize: '14px',
                  padding: 0,
                }}
                variant="white"
              >
                전화 지원 : 02-6232-0220
              </RoundButton>
            </a>
          </VerticalLayout>
        </ImageBox>
        <Space height={64} />
        <VerticalLayout center>
          <DarkGray18 style={{ fontWeight: 700, textAlign: 'center' }}>
            히어로 선생님으로 활동해야
            <br />
            하는 이유
            <span style={{ color: '#F69F30' }}> 세가지!</span>
          </DarkGray18>
          <Space height={8} />
          <LightGray12>
            우리동네 돌봄히어로를 통해 아이돌봄 전문가로 거듭나세요!
          </LightGray12>
          <Space height={38} />
          <SupportInfos />
          <Space height={64} />
        </VerticalLayout>
        <Benefits />
        <Space height={120} />
      </Container>
      <HeroWorks />
      <Space height={120} />
      <ImageBox
        src={require('asset/event/event_sd/event_sd_middle_bg.jpg').default}
        minHeight={549}
      >
        <VerticalLayout center>
          <Space height={64} />
          <DarkGray18 style={{ fontWeight: 700 }}>
            서울 전지역 아이돌봄 선생님 대모집 중
          </DarkGray18>
          <LightGray12>
            홈페이지 간편지원 or 전화 한번이면 지원 완료!
          </LightGray12>
          <CallBox>
            <DarkGray14 style={{ fontWeight: 700, marginTop: '16px' }}>
              히어로 교육 문의/신청
            </DarkGray14>
            <a href="tel:02-6232-0220">
              <Primary18 style={{ fontWeight: 700, marginTop: '4px' }}>
                02 -6232-0220
              </Primary18>
            </a>
            <LightGray12 style={{ marginTop: '10px' }}>
              오전 10시 ~ 오후 6시 주말 / 공휴일 제외
            </LightGray12>
            <CallIcon src={require('asset/event/event_sd/call.png').default} />
          </CallBox>
          <ApplyBox onClick={() => history.push('/hero/apply')}>
            <DarkGray14 style={{ fontWeight: 700, marginTop: '25px' }}>
              히어로 교육 신청
            </DarkGray14>

            <Primary18 style={{ fontWeight: 700, marginTop: '5px' }}>
              지금 바로 간편 신청하기
            </Primary18>
          </ApplyBox>
          <Space height={22} />
          <LightGray12>
            * 서울시에서 활동 가능하신 분만 지원해주세요.
          </LightGray12>
          <Space height={32} />
          <RoundButton
            style={{
              width: '204px',
              height: '40px',
              fontSize: '14px',
              padding: 0,
            }}
            onClick={() => history.push('/')}
          >
            홈페이지 바로가기
          </RoundButton>
        </VerticalLayout>
      </ImageBox>

      <FaqSD />
    </>
  );
};

const Container = styled(VerticalLayout)`
  font-family: 'Noto Sans KR';
`;
const ApplyStepsContainer = styled.div`
  width: 318px;
  height: 312px;
  margin: auto;
  background: #ffffff;

  /* Card */
  box-shadow: 3px 4px 16px #dedede;
  border-radius: 10px;
`;
const CallIcon = styled.img`
  position: absolute;
  width: 20px;
  bottom: 40px;
  left: 70px;
`;
const CallBox = styled.div`
  width: 320px;
  height: 105px;
  margin-top: 50px;

  background: #ffffff;
  /* Card */
  position: relative;
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  text-align: center;
  &:hover {
    border: 1px solid #ff9030;
  }
`;
const ApplyBox = styled.div`
  width: 320px;
  height: 105px;
  margin-top: 20px;
  /* Containers / White */

  background: #ffffff;

  box-sizing: border-box;
  /* Card */
  text-align: center;
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 1px solid #ff9030;
  }
`;
