import React from 'react';
import _Sheet from 'react-modal-sheet';

import { IntroBasicPage } from 'page-mobile/customer/intro/IntroBasicPage';
import { IntroExtraPage } from 'page-mobile/customer/intro/IntroExtraPage';
import { IntroPremiumPage } from 'page-mobile/customer/intro/IntroPremiumPage';

const Sheet = _Sheet as any;

interface AdditionalServicePopupProps {
  show: boolean;
  type: 'basic' | 'premium' | 'extra';
  onClickApply?: () => void;
  onClose: () => void;
}
export const AdditionalServicePopup = ({
  show,
  type,
  onClickApply,
  onClose,
}: AdditionalServicePopupProps) => {
  return (
    <Sheet isOpen={show} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header onClick={onClose} />
        <Sheet.Content>
          {{
            basic: (
              <IntroBasicPage
                sheet
                onClickApply={onClickApply}
                onClose={onClose}
              />
            ),
            premium: (
              <IntroPremiumPage
                sheet
                onClickApply={onClickApply}
                onClose={onClose}
              />
            ),
            extra: (
              <IntroExtraPage
                sheet
                onClickApply={onClickApply}
                onClose={onClose}
              />
            ),
            cleaning: (
              <IntroExtraPage
                sheet
                tab="cleaning"
                onClickApply={onClickApply}
                onClose={onClose}
              />
            ),
            cooking: (
              <IntroExtraPage
                sheet
                tab="cooking"
                onClickApply={onClickApply}
                onClose={onClose}
              />
            ),
          }[type] || <></>}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={onClose} />
    </Sheet>
  );
};
