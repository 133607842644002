import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'atom/input';
import { Space, Push, VerticalLayout } from 'atom/layout';
import { AddressPopup } from 'component/popup';
import { useTopmost } from 'util/index';

interface NoAddressDataProps {}
export const NoAddressData = ({}: NoAddressDataProps) => {
  return (
    <Container>
      <InnerText>등록된 장소가 없습니다</InnerText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 110px;

  border-radius: 8px;
  background-color: #f3f4f5;

  align-items: center;
  justify-content: center;
`;
const InnerText = styled.div`
  color: #484848;

  font-size: 16px;
  font-weight: 500;

  letter-spacing: -0.4px;
`;
