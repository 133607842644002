import { Space } from 'atom/layout';
import { DarkGray12, DarkGray16, DarkGray32, DarkGray40 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const LimitedCount = props => {
  function leftPad(value: number) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  function toStringByFormatting() {
    const source = new Date();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());
    const hour = leftPad(source.getHours());
    return `${month}월 ${day}일 ${hour}시`;
  }

  return (
    <>
      <Space height={64} />
      <DarkGray16 bold>선착순 모집이 곧 마감됩니다.</DarkGray16>
      <DarkGray16 bold>신청을 서둘러주세요! </DarkGray16>
      <Space height={35} />
      <Container>
        <DarkGray12 bold>남은 선착순 인원</DarkGray12>
        <Space width={12} />
        <NumberBox>1</NumberBox>
        <NumberBox>0</NumberBox>
        <DarkGray16 bold>명</DarkGray16>
        <ComplitedSticker />
      </Container>
      <Space height={8} />
      <p style={{ fontSize: '11px', color: '#ED584E' }}>
        {toStringByFormatting()} 기준
      </p>
    </>
  );
};
const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;
const NumberBox = styled.span`
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 10px;
  border: 2px solid #696969;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  margin-right: 12px;
  line-height: 20px;
`;
const ComplitedSticker = styled.img.attrs({
  src: require('asset/event/event_playkit/completed_mark.png').default,
})`
  width: 78px;
  position: absolute;
  right: -43px;
  top: -42px;
`;
export default LimitedCount;
