import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { Select } from 'atom/input';
import { Space, Push } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep2 } from './RequestStep2';

export const RequestStep1 = observer(({ Bottom, onNext, setHasHeader }) => {
  const { customerStore } = useStores();
  setHasHeader(true);
  return (
    <div style={{ padding: '40px 20px' }}>
      <Title>어떤 돌봄이 필요하세요?</Title>
      <Space height={53} />

      <Select
        gap={30}
        items={[
          {
            label: (
              <CareKindText>
                정기 돌봄
                <br />
                <SubText>매달 정해진 요일에 선생님이 돌봐주세요</SubText>
              </CareKindText>
            ),
            value: 1,
          },
          {
            label: (
              <CareKindText>
                비정기 돌봄
                <br />
                <SubText>단기로 정해진 날에 선생님이 돌봐주세요</SubText>
              </CareKindText>
            ),
            value: 0,
          },
        ]}
        value={customerStore.applyForm.type}
        onChange={e => {
          customerStore.applyForm.type = e;
          //정기 - 비정기 오가며 스케쥴 변경시 오류 있어서
          //정기 - 비정기 변경시 스케쥴 초기화 시켜줌
          customerStore.applyForm.schedules = [];
        }}
      />
      <Space height={200} />
      <Bottom
        progress={(1 / 11) * 1}
        onClickPrev={null}
        onClickNext={() => onNext(RequestStep2)}
      />
    </div>
  );
});

const CareKindText = styled.span`
  font-size: 17px;
  font-weight: bold;
  text-align: center;

  letter-spacing: -0.43px;
  line-height: 1;
`;
const SubText = styled.span`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.33px;
`;
