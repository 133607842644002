import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray44, LightGray28 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';
import Content2InfoBox from './Content2InfoBox';

const Content2 = props => {
  const infos = [
    {
      imagePath: require('asset/b2b/cotent2_1.png').default,
      title: '육아 경력자',
      subTitle: `선생님들 평균 육아경력 17.5년`,
      description: `평균 육아 경험만 17.5년 육아의 달인!\n베테랑 선생님들이 아이돌봄을 책임지고 있습니다. `,
    },
    {
      imagePath: require('asset/b2b/cotent2_2.png').default,
      title: '근거리 매칭',
      subTitle: `집 근처 30분 선생님 매칭`,
      description: `집 근처 평균 30분 거리 이내에 있는\n선생님들이 방문하여 아이들을 돌봐주십니다. `,
    },
    {
      imagePath: require('asset/b2b/cotent2_3.png').default,
      title: '장기돌봄 가능',
      subTitle: `평균 돌봄 기간 8개월 이상`,
      description: `우리동네 돌봄히어로 서비스 이용 부모님들은\n평균 돌봄 기간이 8개월 이상이에요!`,
    },
  ];
  return (
    <Container center>
      <Space height={160} />
      <DarkGray44 style={{ fontSize: '48px' }} bold>
        우리동네 돌봄히어로가 훌륭한 복지가 될 수 있는 이유
      </DarkGray44>
      <Space height={16} />
      <LightGray28 style={{ fontSize: '34px' }} semiBold>
        합리적인 예산으로 임직원 자녀 돌봄 지원이 가능해요.
      </LightGray28>
      <Space height={120} />
      <HorizontalLayout>
        {infos.map(info => (
          <Content2InfoBox key={info.title} info={info} />
        ))}
      </HorizontalLayout>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  width: 100%;
  height: 1069px;
  background: rgba(246, 159, 48, 0.04);
`;
export default Content2;
