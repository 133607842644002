import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray44, LightGray28 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';
import Content1InfoBox from './Content1InfoBox';
const Content1 = props => {
  const infos = [
    {
      percent: '44%',
      title: `6세 이하 자녀를 둔 가정 중\n맞벌이 가구의 비중`,
      description: '2021 통계청 자료',
    },
    {
      percent: '95%',
      title: `퇴사고민 경험이 있는\n워킹맘의 비중`,
      description: `KB금융경영연구소\n'2019 한국 워킹맘 보고서'`,
    },
    {
      percent: '27%',
      title: `아이를 믿고 맡길 곳이 없어서\n일을 중단한 경우`,
      description: `2022 제4차 육아정책 심포지엄\n‘출산/육아 관련 일 중단의 주된 이유’`,
    },
  ];
  return (
    <Container center>
      <Space height={160} />
      <DarkGray44 style={{ fontSize: '48px' }} bold>
        ‘아이 돌봄’이 중요한 복지인 이유
      </DarkGray44>
      <Space height={16} />
      <LightGray28 style={{ fontSize: '34px' }} semiBold>
        육아로 인한 유능한 직원들의 경력단절 및 인재 유출
      </LightGray28>
      <Space height={120} />
      <HorizontalLayout>
        {infos.map(item => (
          <Content1InfoBox key={item.title} info={item} />
        ))}
      </HorizontalLayout>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  background-color: #f6f6f6;
  height: 900px;
  width: 100%;
`;

export default Content1;
