import { RoundButton } from 'atom/button';
import { Checkbox, Labeled } from 'atom/input';
import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { Black16, DarkGray24 } from 'atom/text';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'state';
import { HeaderCustomerSignUp } from 'component-mobile/header';

const SitterSignUpPageStep5 = observer(() => {
  const history = useHistory();
  const { sitterStore } = useStores();

  const checkForm = () => {
    if (
      sitterStore.signUpForm.hasNurture === false &&
      sitterStore.signUpForm.hasNurtureExpert.length <= 0
    ) {
      showConfirm('자녀 외 돌봄경험을 입력해주세요.');
      return false;
    }

    if (
      sitterStore.signUpForm.hasOtherExperience === true &&
      sitterStore.signUpForm.hasNurtureExpert.length <= 0
    ) {
      showConfirm('자녀 외 돌봄경험을 입력해주세요.');
      return false;
    }

    return true;
  };
  const registerHero = () => {
    if (checkForm()) {
      history.push('/sign-up/hero/info6');
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.push('/sign-up/hero/phone');
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <HeaderCustomerSignUp progress={(5 / 7) * 100} />
      <VerticalLayout style={{ padding: '25px' }}>
        <DarkGray24 bold>
          자녀 외 양육 경험이
          <br />
          있나요?
        </DarkGray24>
        <Space height={64} />
        <Labeled
          label="자녀 외 양육 경험"
          labelStyle={{ fontSize: '18px' }}
          gap={10}
        >
          <HorizontalLayout style={{ justifyContent: 'space-around' }}>
            <HorizontalLayout>
              <Checkbox
                variant="circle"
                value={sitterStore.signUpForm.hasOtherExperience}
                onChange={e => {
                  if (e) sitterStore.signUpForm.hasOtherExperience = true;
                }}
              />

              <Space width={10} />
              <Black16>경험 있음</Black16>
            </HorizontalLayout>
            <Space width={100} />

            <HorizontalLayout>
              <Checkbox
                value={!sitterStore.signUpForm.hasOtherExperience}
                onChange={e => {
                  if (e) {
                    showConfirm(
                      '자녀 양육경험이 없으실 경우,\n필수로 돌봄경험이 있으셔야 합니다.',
                    );
                    sitterStore.signUpForm.hasOtherExperience = false;
                  }
                }}
              />
              <Space width={10} />
              <Black16>경험 없음</Black16>
            </HorizontalLayout>
          </HorizontalLayout>
          <Space height={24} />
          <textarea
            placeholder={`자녀 외에 돌봄경험이 있으시다면 자유롭게 작성해주세요. 양육경험이 없으실 경우, 필수로 돌봄경험이 있으셔야 합니다. (예 : 어린이집 보조교사 경험 등)`}
            value={sitterStore.signUpForm.hasNurtureExpert}
            onChange={e =>
              (sitterStore.signUpForm.hasNurtureExpert = e.target.value)
            }
          ></textarea>
        </Labeled>
      </VerticalLayout>
      <RoundButton
        style={{
          width: '320px',
          height: '56px',
          padding: 0,
          fontSize: '16px',
          margin: 'auto',
        }}
        onClick={registerHero}
      >
        다음
      </RoundButton>
    </>
  );
});

export default SitterSignUpPageStep5;
