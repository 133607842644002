import React from 'react';
import styled from 'styled-components';
import {ActivityContents} from "content";

interface ActivityCardProps {
  data: ActivityContents;
  onClick?: () => void;
};
export const ActivityCard = ({
  data,
  onClick,
}: ActivityCardProps) => {
  return (
    <Container
      onClick={onClick}
    >
      <Image
        src={data.image}
      />
      <Title>
        {data.title}
      </Title>
    </Container>
  );
};

const Container = styled.div`
  width: 286px;
  height: 240px;
  overflow:hidden;

  background: white;
  border-radius: 12px;
  box-shadow: 3px 4px 12px 0 rgba(0, 0, 0, 0.16);

  margin-left: 18px;
`;
const Image = styled.img`
  width: 100%;
  height: 175px;
  overflow: hidden;

  object-fit: cover;
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  
  padding: 21px 18px;
`;
