import { RoundButton } from 'atom/button';
import { ImageBox } from 'atom/image';
import { Space, VerticalLayout } from 'atom/layout';
import {
  LightGray24,
  DarkGray32,
  DarkGray40,
  DarkGray52,
  Primary34,
  LightGray20,
  DarkGray44,
} from 'atom/text';
import HowToDo from 'component/event/play_kit/HowToDo';
import LimitedCount from 'component/event/play_kit/LimitedCount';
import Notice from 'component/event/play_kit/Notice';
import Reviews from 'component/event/play_kit/Reviews';
import ServiceInfo from 'component/event/play_kit/ServiceInfo';
import { Footer } from 'component/footer';

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
const Color = {
  Primary: '#f69f30',
  Secondary: '#ffedd5',
  white: '#fff',
  lightGray: '#c0c0c0',
  darkGray: '#484848',
};
const EventPlayKit = props => {
  const history = useHistory();
  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/event/event_playkit/main_top_2.jpg').default}
          minHeight={1360}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              marginTop: '15%',
              position: 'absolute',
              left: '50%',
              transform: 'translate(-80%, -12%)',
            }}
          >
            <DarkGray32>2022 시-구 상향적 협력적 일자리창출 사업</DarkGray32>
            <DarkGray52 bold>
              <span style={{ color: Color.Primary }}> 9월 한달간 </span>정기
              돌봄 신청시
            </DarkGray52>
            <DarkGray52 bold>
              10만원 상당의{' '}
              <span style={{ color: Color.Primary }}>
                창의 놀이 서비스가 무료!
              </span>
            </DarkGray52>
            <Space height={100} />
            <RoundButton
              style={{
                width: '260px',
                height: '65px',
                fontSize: '28px',
                padding: 0,
              }}
              onClick={() => history.push('/service/apply/q1')}
            >
              서비스 신청하기
            </RoundButton>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              position: 'absolute',
              left: '50%',
              bottom: '5%',
              width: '880px',
              transform: 'translate(-50%, -5%)',
            }}
          >
            <LightGray24>
              ※ 본 이벤트는 3개월 이상 정기 돌봄을 신청한 부모님 대상으로
              진행됩니다
            </LightGray24>
            <LightGray24>
              ※ 본 이벤트는 계약금 10만원 선납 후 참여 가능하며, 3개월 정기 돌봄
              이후 환급해드립니다.
            </LightGray24>
            <LightGray24>
              ※ 본 이벤트는 1:1 돌봄일 경우에만 참여하실 수 있습니다.
            </LightGray24>
            <LightGray24>
              ※ 본 이벤트는 4-8세 연령 대상으로만 진행됩니다.
            </LightGray24>
          </div>
        </ImageBox>
        <Space height={133} />
        <DarkGray44 bold>선착순 모집이 곧 마감됩니다.</DarkGray44>
        <DarkGray44 bold>신청을 서둘러주세요!</DarkGray44>
        <LimitedCount />
        <Space height={48} />
        <RoundButton
          style={{
            width: '440px',
            height: '79px',
            fontSize: '32px',
            padding: 0,
          }}
          onClick={() => history.push('/service/apply/q1')}
        >
          지금 바로 신청하기
        </RoundButton>
        <Space height={188} />
        <div
          style={{
            width: '100%',
            height: '1184px',
            background: '#fffbf7',
            textAlign: 'center',
          }}
        >
          <Space height={188} />
          <DarkGray44 bold>이용 중인 부모님의</DarkGray44>
          <DarkGray44 bold>
            만족도 <span style={{ color: '#D4594B' }}>9.8점</span> 히어로 돌봄
            서비스
          </DarkGray44>
          <Space height={100} />
          <Reviews />
        </div>
        <Space height={188} />
        <ServiceInfo />
        <div
          style={{
            width: '100%',
            backgroundColor: '#F9F9F9',
            height: '1044px',
            textAlign: 'center',
          }}
        >
          <Space height={120} />

          <DarkGray44 bold>
            창의 놀이시간에 아이들이 만든 작품이에요!
          </DarkGray44>
          <img
            src={require('asset/event/event_playkit/serviceInfo4.png').default}
          />
        </div>
        <HowToDo />
        <Notice />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled(VerticalLayout)`
  font-family: 'Noto Sans KR';
`;

export default EventPlayKit;
