import React from 'react';
import styled from 'styled-components';

import { VerticalLayout, HorizontalLayout, Space, Push } from 'atom/layout';
import { Color, URL } from 'constant';
import { CompanyInfo } from 'content';
import { useIsMobile } from 'util/index';

export const Footer = ({}) => {
  const isMobile = useIsMobile(true);

  // PC에서 모바일 페이지 보여주는 경우
  if (!isMobile) return <></>;

  return (
    <Container>
      <DescriptionText>
        (주)우리동네히어로 <br />
        주소 : 서울특별시 성동구 성수일로12길 20, 4층 우리동네히어로 <br />
        사업자 등록번호 : 155-88-01006 | 대표자 : 손민희
        <br />
        고객센터 문의 : 02-6232-0206 / sitterhero@naver.com <br />
        (평일 오전 10시~오후 6시, 주말 및 공휴일 제외)
        <br />
        {CompanyInfo.Copyright}
      </DescriptionText>
      <Space height={36} />

      <MenuItemText>
        <span
          onClick={() =>
            window.open('https://woorihero.com/terms/privacy-policy.html')
          }
        >
          이용약관
        </span>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <span
          onClick={() =>
            window.open('https://woorihero.com/terms/service-terms.html')
          }
        >
          개인정보처리방침
        </span>
      </MenuItemText>
      <Space height={26} />

      <HorizontalLayout style={{ width: '100%' }}>
        <Push />
        <SocialButton
          src={require('asset/home/kakao.png').default}
          onClick={() => window.open(URL.Kakao)}
        />
        <Push />
        <SocialButton
          src={require('asset/home/blog.png').default}
          onClick={() => window.open(URL.Blog)}
        />
        <Push />
        <SocialButton
          src={require('asset/home/insta.png').default}
          onClick={() => window.open(URL.Instagram)}
        />
        <Push />
      </HorizontalLayout>
      <Space height={25} />
    </Container>
  );
};

const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  background: #2b2b2b;

  padding: 25px 18px;
`;
const DescriptionText = styled.div`
  color: white;

  font-size: 11px;
  font-weight: 300;

  line-height: 1.73;
  letter-spacing: -0.22px;
`;
const SocialButton = styled.img`
  width: 53px;
  height: 53px;

  cursor: pointer;

  transition: all 0.2s ease;
`;
const MenuItemText = styled.div`
  color: white;

  font-size: 13px;
  font-weight: 300;

  cursor: pointer;
`;
