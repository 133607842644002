import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Color } from 'constant';
import { RoundButton } from 'atom/button';
import { Push, Space, HorizontalLayout, VerticalLayout } from 'atom/layout';
import {
  Black17,
  DarkGray18,
  LightGray12,
  LightGray15,
  LightGray16,
} from 'atom/text';
import { Checkbox } from 'atom/input';
import { useTrainingSchedules } from 'state/hook';
import { useStores } from 'state';
import { ITrainingSchedule } from 'model';
import { Title, Subtitle } from './style';
import { getDate, getDateKor, getDayOfWeek, getTime } from 'util/index';
import { useAuthContext } from '../../../context/AuthContext';
import { getUserId } from 'util/token';
import { ApplyDone } from './ApplyDone';
import { useHistory } from 'react-router-dom';
import ApplySignUpPageStep1 from './ApplySignUpPageStep1';

export const ApplyStep2 = observer(({ Bottom, onNext }) => {
  const { sitterStore } = useStores();
  const { isLogin } = useAuthContext();
  const history = useHistory();
  const schedules = useTrainingSchedules();
  const [schedule, setSchedule] = useState<number>();

  const onClickNext = async () => {
    if (isLogin) {
      //이미 로그인한 회원이면
      try {
        sitterStore.form.userId = Number(getUserId());
        const { ok, message } = await sitterStore.applyTraining(
          sitterStore.form,
        );
        if (ok) {
          //교육일정등록 완료
          onNext(ApplyDone);
        } else {
          alert(message || '교육일정 등록에 실패하였습니다.');
          if (message === '교육 신청 내역이 있습니다.') {
            history.push('/login');
          }
        }
      } catch (e) {
        alert(e.message || '교육일정 등록에 오류가 발생하였습니다.');
      }
    } else {
      onNext(ApplySignUpPageStep1);
    }
  };
  useEffect(() => {
    if (!schedule) return;

    const s = schedules.find(x => x.id === schedule);
    if (s) {
      sitterStore.form.trainingId = s.id;
    }
  }, [schedule]);

  return (
    <>
      <Title style={{ fontSize: '24px', letterSpacing: '-1.91px' }}>
        참석 가능한 교육 일자를
        <br /> 선택해주세요.
      </Title>
      <Space height={8} />
      {/* <LightGray12>
        *교육을 수료하신 후 원하시는 근거리에서 돌봄이 가능합니다.
      </LightGray12>
      <Space height={45} />
      <img
        style={{ width: '100%' }}
        src={require('asset/mobile/sitter/apply/schedule.svg').default}
      />
      <Space height={34} />

      <hr className="thick" />
      <Space height={34} />

      <InfoBox>당일 교육 5시간 진행</InfoBox> */}
      <LightGray16>교육 수료시 원하는 근거리에서 돌봄 가능! </LightGray16>
      <Space height={24} />
      <hr />
      <Space height={24} />

      {schedules.map(x => (
        <React.Fragment key={x.id}>
          <TrainingCenterItem
            data={x}
            value={schedule === x.id}
            onChange={e => {
              if (e) setSchedule(x.id);
            }}
          />
          <Space height={20} />
          <hr />
          <Space height={20} />
        </React.Fragment>
      ))}
      <Space height={54} />

      <Bottom disabled={!schedule} onClickNext={onClickNext} />
    </>
  );
});

interface TrainingCenterItemProps {
  data: ITrainingSchedule;
  value: boolean;
  onChange: (x: boolean) => void;
}
const TrainingCenterItem = ({
  data,
  value,
  onChange,
}: TrainingCenterItemProps) => {
  return (
    <HorizontalLayout>
      <VerticalLayout>
        <DarkGray18
          bold
          style={{ color: value ? Color.Primary : Color.darkGray }}
        >
          {`${getDateKor(data.host_start_time)} ${getDayOfWeek(
            data.host_start_time,
          )} ${data.center_name}`}
        </DarkGray18>
        <Space height={20} />
        <LightGray15
          semiBold
          style={{ color: value ? Color.darkGray : Color.lightGray }}
        >
          교육일정: &nbsp;
          {`${getDate(data.host_start_time)} ${getTime(
            data.host_start_time,
          )} ~ ${getTime(data.host_end_time)}`}
        </LightGray15>
        <LightGray15
          semiBold
          center
          style={{ color: value ? Color.darkGray : Color.lightGray }}
        >
          교육장소: &nbsp;{data.center_address}
        </LightGray15>
      </VerticalLayout>
      <Push />
      <Space width={32} />
      <Checkbox variant="circle" value={value} onChange={onChange} />
    </HorizontalLayout>
  );
};
