export interface HeroPlayContents {
  id: number;
  image: string;
}

export const HeroPlayContents = [
  {
    id: 1,
    image: require('asset/customer/intro/1.jpg').default,
  },
  {
    id: 2,
    image: require('asset/customer/intro/2.jpg').default,
  },
  {
    id: 3,
    image: require('asset/customer/intro/3.jpg').default,
  },
  {
    id: 4,
    image: require('asset/customer/intro/4.jpg').default,
  },
  {
    id: 5,
    image: require('asset/customer/intro/5.jpg').default,
  },
  {
    id: 6,
    image: require('asset/customer/intro/6.jpg').default,
  },
  {
    id: 7,
    image: require('asset/customer/intro/7.jpg').default,
  },
] as HeroPlayContents[];
