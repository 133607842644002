import React from 'react';
import { Color } from 'constant';

export const threeMinutes = 1000 * 60 * 3;
const Timer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return <span style={{ color: Color.Primary }}>00:00</span>;
  } else {
    return (
      <span style={{ color: Color.Primary }}>
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  }
};

export default Timer;
