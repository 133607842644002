import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { checkAccessToken, clearSesstionStorage } from 'util/token';

const initalState = {
  isLogin: false,
  logout: () => {
    clearSesstionStorage();
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateLoginState':
      return { ...state, isLogin: action.isLogin };

    default:
      return state;
  }
};

const AuthStateContext = createContext(null);
const AuthDispatchContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAuth = async () => {
      //FIXME 장기 접속시, 토큰만료된것을 알아차리지 못함 새로고침이 필요.
      const result = await checkAccessToken();
      dispatch({
        type: 'updateLoginState',
        isLogin: result,
      });
      setLoading(false);
    };

    getAuth();
  }, []);

  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
export const useAuthContext = () => {
  const state = useContext(AuthStateContext);
  if (!state) {
    throw new Error('Cannot find MainProvider');
  }
  return state;
};

export const useAuthDispatch = () => {
  const state = useContext(AuthDispatchContext);
  if (!state) {
    throw new Error('Cannot find MainProvider');
  }
  return state;
};
