import { TextField } from '@material-ui/core';
import { RoundButton } from 'atom/button';
import { Checkbox } from 'atom/input';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray10, DarkGray18 } from 'atom/text';
import React, { useState } from 'react';
import { useStores } from 'state/index';
import styled from 'styled-components';
import { showTermsPopup } from 'util/index';
import { observer } from 'mobx-react';
import { VerificationStep } from 'model';

const ApplyEvent = observer(props => {
  const { customerStore, loginStore } = useStores();
  const Terms = {
    service: {
      title: '창의놀이 무료 이용권 이용 약관',
      content: (
        <div>
          <DarkGray10 bold>
            1. 창의놀이 무료 이용권(5주년 감사 이벤트 혜택 1) 이용약관
          </DarkGray10>
          <DarkGray10>{`5주년 감사 이벤트 혜택으로 제공되는 13만원 상당의 혜택 1의 구성은 창의놀이 10회 이용권, 창의놀이 키트, 기본 준비물로 구성되어 있습니다.`}</DarkGray10>
          <DarkGray10>{`혜택 1은 이벤트 기간내 3개월 이상 정기 돌봄을 신청 및 시작한 고객을 대상으로 제공되며 돌봄 시작 시 보증금(10만원)이 부과됩니다.`}</DarkGray10>
          <DarkGray10>{`혜택 1 보증금 정책은 아래와 같습니다.
                    - 혜택 1 제공 조건(정기 돌봄 3개월)을 충족하였을 때 보증금(10만원) 100%반환
                    - 혜택 1 제공조건(정기 돌봄 3개월) 기간 중 정기 돌봄을 비정기 돌봄으로 변경하거나 돌봄을 취소할 경우
                        -실물상품(창의놀이 키트, 기본 준비물)수령 후 7일이 지났거나 개봉한 경우 보증금 반환 불가
                        -실물상품(창의놀이 키트, 기본 준비물)수령 후 7일이 지나지않았고 실물상품을 모두 개봉하지 않은 경우 배송 반송비 (6,000원) 차감 후 보증금 반환
                `}</DarkGray10>
          <DarkGray10>{`돌봄선생님, 가정 간 기대 불일치로 발생하는 취소를 방지하기 위해, 최초 돌봄시작일로부터 2주가 지난 뒤 상담을 통해 특이사항을 확인한 후 가정의 동의하에 혜택 1이 제공됩니다.`}</DarkGray10>
          <DarkGray10>{`혜택 1은 첫 돌봄 시작일로부터 3개월 동안 사용 가능하며, 혜택 1 제공 조건(정기 돌봄 3개월) 기간 중 서비스를 취소할 경우가 아니라면 실물상품(창의놀이 키트, 기본 준비물)을 반납하지 않습니다.`}</DarkGray10>
        </div>
      ),
    },
    privacy: {
      title: '개인정보 수집 및 이용 동의 약관',
      content: (
        <div>
          <DarkGray10 bold>1. 개인정보의 수집 및 이용 목적</DarkGray10>
          <DarkGray10>본인 확인, 이벤트 안내, 혜택 제공</DarkGray10>
          <Space height={24} />
          <DarkGray10 bold>2. 수집하는 개인정보의 항목</DarkGray10>
          <DarkGray10> (1) 필수사항 : 이름, 전화번호, 거주지(구)</DarkGray10>
          <DarkGray10> (2) 선택사항 : 없음</DarkGray10>
          <Space height={24} />
          <DarkGray10 bold>3. 보유기간 및 이용기간</DarkGray10>
          <DarkGray10>
            이벤트 종료일 이후 2개월(60일)간
            <br />
            보유 및 이용 후 파기
          </DarkGray10>
          <Space height={24} />
          <DarkGray10 bold>
            4. 이벤트 참여자는 개인정보 수집 및 이용에 동의하지 않을 수
            있습니다.
          </DarkGray10>
          <DarkGray10>
            다만, 미동의 시 이벤트 참여 및 상품 제공이 제한됩니다.
          </DarkGray10>
        </div>
      ),
    },
  };
  const onClickAll = () => {
    setTermsAll(prev => {
      const newState = !prev.isChecked;
      customerStore.applyEventForm.isServiceAgree = newState;
      customerStore.applyEventForm.isPrivacyAgree = newState;
      return { ...prev, isChecked: newState };
    });
  };
  const onChangeTermsItem = key => {
    if (key === 'service') {
      const prevState = customerStore.applyEventForm.isServiceAgree;
      customerStore.applyEventForm.isServiceAgree = !prevState;
    } else {
      const prevState = customerStore.applyEventForm.isPrivacyAgree;
      customerStore.applyEventForm.isPrivacyAgree = !prevState;
    }
  };
  const [termsAll, setTermsAll] = useState({
    key: 'all',
    title: '전체 약관에 동의합니다.',
    isChecked: false,
    children: null,
    onChange: onClickAll,
  });
  const termsInputs = [
    {
      key: 'service',
      title: '[필수] 창의놀이 무료 이용권 이용약관 동의',
      isChecked: customerStore.applyEventForm.isServiceAgree,
      children: (
        <TermsMoreBtn
          onClick={() =>
            showTermsPopup(Terms.service.title, Terms.service.content)
          }
        />
      ),
      onChange: onChangeTermsItem,
    },
    {
      key: 'privacy',
      title: '[필수] 개인 정보 수집 및 이용 동의',
      isChecked: customerStore.applyEventForm.isPrivacyAgree,
      children: (
        <TermsMoreBtn
          onClick={() =>
            showTermsPopup(Terms.privacy.title, Terms.privacy.content)
          }
        />
      ),
      onChange: onChangeTermsItem,
    },
  ];
  const [verificationStep, setVerificationStep] = useState(
    VerificationStep.InputNumber,
  );
  const onClickSendNumber = async () => {
    const phoneNumber = customerStore.applyEventForm.phone;
    if (phoneNumber.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    loginStore.form.phone = phoneNumber;
    try {
      await customerStore.sendVerificationCode(phoneNumber);
      setVerificationStep(VerificationStep.Sent);
      showConfirm('인증번호를 전송하였습니다.');
    } catch (error) {
      alert(error.message || '인증번호 전송이 실패했습니다.');
    }
  };

  const onClickCheckNumber = async () => {
    const phone = loginStore.form.phone;
    const accessNumber = loginStore.form.accessNumber;

    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }

    try {
      const {
        ok,
        result,
        message,
        // token,
        // refreshToken,
      } = await customerStore.confirmVerificationCode(
        phone,
        Number(accessNumber),
      );
      if (ok) {
        showConfirm('인증되었습니다.');
        return setVerificationStep(VerificationStep.Verified);
      }
      return showConfirm(`인증번호가 일치하지 않습니다 : ${message}`);
    } catch (error) {
      showConfirm(`인증번호 검증에 오류가 있습니다 : ${error.message}`);
    }
  };

  const inputs = [
    {
      label: '부모님 성함',
      placeholder: '본인 이름을 입력해주세요.',
      value: customerStore.applyEventForm.name,
      onChange: e => (customerStore.applyEventForm.name = e.target.value),
      children: null,
      spaceHeight: 8,
    },
    {
      label: '휴대폰 번호',
      placeholder: '휴대폰 번호를 입력해주세요.',
      value: customerStore.applyEventForm.phone,
      type: 'number',
      onChange: e =>
        (customerStore.applyEventForm.phone = e.target.value.replace(
          /\D/g,
          '',
        )),
      children: (
        <SendNumberBtn
          disabled={verificationStep !== VerificationStep.InputNumber}
          onClick={onClickSendNumber}
        >
          인증번호 전송
        </SendNumberBtn>
      ),
      spaceHeight: 4,
    },
    {
      label: '',
      placeholder: '인증번호를 입력해주세요.',
      value: loginStore.form.accessNumber,
      type: 'number',
      onChange: e =>
        (loginStore.form.accessNumber = e.target.value.replace(/\D/g, '')),
      children: (
        <SendNumberBtn
          disabled={verificationStep === VerificationStep.Verified}
          onClick={onClickCheckNumber}
        >
          인증하기
        </SendNumberBtn>
      ),
      spaceHeight: 4,
    },
    {
      label: '거주지 (구)',
      placeholder: '거주지(도/시/구)를 입력해주세요,',
      value: customerStore.applyEventForm.address,
      onChange: e => (customerStore.applyEventForm.address = e.target.value),
      children: null,
      spaceHeight: 8,
    },
  ];

  const onVerifyBody = () => {
    const {
      phone,
      name,
      address,
      isServiceAgree,
      isPrivacyAgree,
    } = customerStore.applyEventForm;

    if (!name) {
      showConfirm('이름을 입력해주세요.');
      return false;
    }
    if (!phone) {
      showConfirm('휴대폰번호를 입력해주세요.');
      return false;
    }
    if (verificationStep !== VerificationStep.Verified) {
      showConfirm('휴대폰번호를 인증해주세요.');
      return false;
    }
    if (!address) {
      showConfirm('거주지를 입력해주세요.');
      return false;
    }
    if (!isServiceAgree) {
      showConfirm('이용약관을 동의해주세요.');
      return false;
    }
    if (!isPrivacyAgree) {
      showConfirm('개인정보 수집 및 이용 약관을 동의해주세요');
      return false;
    }

    return true;
  };
  const onClickApplyEvent = async () => {
    if (onVerifyBody()) {
      try {
        const { result, ok, message } = await customerStore.applyEvent(
          customerStore.applyEventForm,
        );
        console.log(result);
        if (ok) {
          customerStore.resetEventFroms();
          loginStore.resetForm();
          return showConfirm('이벤트 신청이 완료되었습니다.');
        }
        return showConfirm(`등록에 실패하였습니다. ${message}`);
      } catch (error) {
        showConfirm(`오류가 발생했습니다: ${error.message}`);
      }
    }
  };
  return (
    <Container center>
      <Space height={64} />
      <DarkGray18 bold>이벤트 신청하기</DarkGray18>
      <Space height={24} />
      <InputArea>
        {inputs.map(input => (
          <InputRow key={input.label} input={input} />
        ))}
        <Space height={22} />
        <FlexBox>
          <DarkGray10 style={{ flex: '1 1 20%', fontSize: '11px' }} bold>
            약관 동의
          </DarkGray10>
          <TermsCheck terms={termsAll} />
        </FlexBox>
        <div
          style={{
            width: '100%',
            height: '1px',
            margin: '12px 0',
            border: ' 0.5px dashed  #6F6F6E',
          }}
        ></div>
        {termsInputs.map(terms => (
          <TermsCheck key={terms.key} terms={terms} />
        ))}
      </InputArea>
      <Space height={34} />
      <RoundButton
        style={{
          width: '204px',
          height: '40px',
          fontSize: '14px',
          padding: '17px 32px',
        }}
        onClick={onClickApplyEvent}
      >
        지금바로 참여하기
      </RoundButton>
      <Space height={64} />
    </Container>
  );
});

const Container = styled(VerticalLayout)`
  background: #ececec;
`;
const InputArea = styled(VerticalLayout)`
  width: 100%;
  padding: 0 30px;
  min-width: 300px;
`;
const FlexBox = styled.div`
  display: flex;
`;
const InputRow = ({ input }) => {
  const {
    label,
    value,
    onChange,
    children,
    spaceHeight,
    placeholder,
    type = 'text',
  } = input;
  return (
    <FlexBox style={{ margin: `${spaceHeight}px 0`, alignItems: 'center' }}>
      <div style={{ flex: '1 1 20%' }}>
        <DarkGray10 style={{ fontSize: '11px' }} bold>
          {label}
        </DarkGray10>
      </div>
      <div style={{ flex: '1 1 50%', width: '100%' }}>
        <TextField
          type={type}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
          value={value}
          onChange={onChange}
          InputProps={{
            style: {
              fontSize: '9px',
              height: '32px',
              backgroundColor: 'white',
            },
          }}
        />
      </div>
      <div style={{ flex: '1 1 30%', width: '100%' }}>{children}</div>
    </FlexBox>
  );
};

const SendNumberBtn = styled.button`
  width: 83px;
  height: 32px;
  margin-left: 8px;
  background: #f69f30;
  border: 1px solid #f69f30;
  border-radius: 5px;

  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
  color: #ffffff;
  letter-spacing: -0.04rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    filter: contrast(1.15);
  }
  &:active {
    filter: contrast(0.8);
  }

  ${({ disabled }) =>
    disabled
      ? `
  filter: grayscale(1);
  pointer-events: none;
`
      : `
`}
`;

const TermsCheck = ({ terms }) => {
  const { isChecked, title, children, onChange, key } = terms;
  return (
    <FlexBox
      style={{
        flex: '1 1 80%',
        alignItems: 'center',
        marginLeft: children ? '20%' : '0px',
        marginBottom: '0.4rem',
      }}
    >
      <div style={{ flex: '1 1 7%' }}>
        <Checkbox
          variant="square"
          value={isChecked}
          onChange={() => onChange(key)}
        />
      </div>
      <div style={{ flex: '1 1 88%' }}>
        <DarkGray10 bold>{title}</DarkGray10>
      </div>
      <div
        style={{
          flex: '1 1 5%',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        {children}
      </div>
    </FlexBox>
  );
};

const TermsMoreBtn = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '63px',
        cursor: 'pointer',
      }}
    >
      <img
        style={{ width: '28px' }}
        src={
          require('asset/event/event_5years_for_parents/mobile/mobile_terms_moreBtn.svg')
            .default
        }
      ></img>
    </div>
  );
};
export default ApplyEvent;
