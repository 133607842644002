import { DateTime } from 'luxon';
import { sharedPrices as ChargePerHour } from 'state/hook/value';
import { uniq } from 'lodash';
import { CareTypeNew } from 'model';

const getWeekday = (x: string) => {
  return DateTime.fromFormat(x, 'yyyy-MM-dd').weekday - 1;
};
const getDuration = (x: string) => {
  const dt = DateTime.fromFormat(x, 'hh:mm');
  return dt.hour * 2 + dt.minute / 30;
};
const getNighttimeHours = (x: any) => {
  const s = getDuration(x.startTime);
  const e = getDuration(x.endTime);
  let t = 0;
  for (let i = s; i < e; i++) {
    if (i < 7 * 2) t++;
    if (i >= 22 * 2) t++;
  }
  return t / 2;
  //return Math.max(0, (e - 22)) - Math.max(0, (s - 22));
};
const getDaytimeHours = (x: any) => {
  const s = getDuration(x.startTime);
  const e = getDuration(x.endTime);
  let t = 0;
  for (let i = s; i < e; i++) {
    if (i >= 7 * 2 && i < 22 * 2) t++;
  }
  return t / 2;
  //return Math.min(22, e) - Math.min(22, s);
};
const service = {
  [CareTypeNew.PickUp]: '하원 + 놀이돌봄',
  [CareTypeNew.DropOff]: '등원 + 놀이돌봄',
  [CareTypeNew.PlayOnly]: '놀이돌봄만',
};
const type2priceType = (x: string) => {
  return (
    {
      '요리 서비스': 'cleaning',
      '청소 서비스': 'cleaning',
      '창의 놀이': 'premium',
    }[x] || '신입'
  );
};

export const getWeekDayCount = (schedules: any[], type?: string) => {
  //schedules.forEach(x => console.log(`테스트!!!!!!!${x.type}`));
  //놀이돌봄이면 합쳐서 평균값, 아닌건 그냥 요일만 갯수 카운트

  const care = schedules;
  //.filter(x =>type ? x.type === type : x.type.includes('놀이돌봄'),);
  //놀이돌봄은 중복선택이 가능하여 최대 3개의 스케쥴이 있을 수 있음
  const careWeekday = uniq(care.map(x => x.weekday).flat());

  // const weekDayCount = care.reduce((a, b) => {
  //   console.log(b);
  //   return a + b.weekday.length;
  // }, 0);

  return careWeekday.length;
};

export const getHours = (
  schedules: any[],
  careKind: number,
  careForm: string,
  type?: string,
) => {
  try {
    const weekdayDayTimes = schedules
      //.filter(x => (type ? x.type === type : x.type.includes('놀이돌봄')))
      .map(x => ({
        type: service[x.type],
        hours: getDaytimeHours(x), //DateTime.fromFormat(x.endTime, 'hh:mm').diff(DateTime.fromFormat(x.startTime, 'hh:mm'), 'hours').hours,
        weekday: x.weekday
          ? x.weekday.filter(y => y < 5).length
          : getWeekday(x.date) < 5
          ? 1
          : 0,
      }))
      .reduce((a, b) => {
        return a + b.weekday * b.hours;
      }, 0);
    const weekdayNightTimes = schedules
      //.filter(x => (type ? service[x.type] === type : true))
      .map(x => ({
        type: service[x.type],
        hours: getNighttimeHours(x), //DateTime.fromFormat(x.endTime, 'hh:mm').diff(DateTime.fromFormat(x.startTime, 'hh:mm'), 'hours').hours,
        weekday: x.weekday
          ? x.weekday.filter(y => y < 5).length
          : getWeekday(x.date) < 5
          ? 1
          : 0,
      }))
      .reduce((a, b) => {
        return a + b.weekday * b.hours;
      }, 0);
    const weekendTimes = schedules
      //.filter(x => (type ? service[x.type] === type : true))
      .map(x => ({
        type: service[x.type],
        hours: DateTime.fromFormat(x.endTime, 'hh:mm').diff(
          DateTime.fromFormat(x.startTime, 'hh:mm'),
          'hours',
        ).hours,
        weekday: x.weekday
          ? x.weekday.filter(y => y >= 5).length
          : getWeekday(x.date) >= 5
          ? 1
          : 0,
      }))
      .reduce((a, b) => {
        return a + b.weekday * b.hours;
      }, 0);

    const total = weekdayDayTimes + weekdayNightTimes + weekendTimes;

    return careKind === 1 ? total * 4 : total;
  } catch (e) {
    console.error(e);
  }
};
export const calcPrice = (
  schedules: any[],
  careKind: number,
  careForm: string,
  type?: string,
) => {
  try {
    const weekdayDayTimes = schedules
      //.filter(x => (type ? x.type === type : true))
      .map(x => ({
        type: service[x.type],
        hours: getDaytimeHours(x), //DateTime.fromFormat(x.endTime, 'hh:mm').diff(DateTime.fromFormat(x.startTime, 'hh:mm'), 'hours').hours,
        weekday: x.weekday
          ? x.weekday.filter(y => y < 5).length
          : getWeekday(x.date) < 5
          ? 1
          : 0,
      }))
      .reduce((a, b) => {
        const charge =
          ChargePerHour[
            `${careKind}_${type2priceType(b.type)}_${careForm}_day`
          ];
        return a + charge * (b.weekday * b.hours);
      }, 0);
    const weekdayNightTimes = schedules
      //.filter(x => (type ? x.type === type : true))
      .map(x => ({
        type: service[x.type],
        hours: getNighttimeHours(x), //DateTime.fromFormat(x.endTime, 'hh:mm').diff(DateTime.fromFormat(x.startTime, 'hh:mm'), 'hours').hours,
        weekday: x.weekday
          ? x.weekday.filter(y => y < 5).length
          : getWeekday(x.date) < 5
          ? 1
          : 0,
      }))
      .reduce((a, b) => {
        const charge =
          ChargePerHour[
            `${careKind}_${type2priceType(b.type)}_${careForm}_night`
          ];
        return a + charge * (b.weekday * b.hours);
      }, 0);
    const weekendTimes = schedules
      // .filter(x => (type ? x.type === type : true))
      .map(x => ({
        type: service[x.type],
        hours: DateTime.fromFormat(x.endTime, 'hh:mm').diff(
          DateTime.fromFormat(x.startTime, 'hh:mm'),
          'hours',
        ).hours,
        weekday: x.weekday
          ? x.weekday.filter(y => y >= 5).length
          : getWeekday(x.date) >= 5
          ? 1
          : 0,
      }))
      .reduce((a, b) => {
        const charge =
          ChargePerHour[
            `${careKind}_${type2priceType(b.type)}_${careForm}_night`
          ];
        return a + charge * (b.weekday * b.hours);
      }, 0);

    // console.log(ChargePerHour);
    // console.log(weekdayDayTimes, weekdayNightTimes, weekendTimes);

    const total = weekdayDayTimes + weekdayNightTimes + weekendTimes;

    return careKind === 1 ? total * 4 : total;
  } catch (e) {
    console.error(e);
  }
};
