import React from 'react';
import styled from 'styled-components';
import { data, month } from 'component/event/LimitedSeats';

export const LimitedSeats = () => {
  return (
    <Container>
      <TitleBox>
        <MonthBox>{month}</MonthBox>
        <p>남은 좌석 수</p>
      </TitleBox>

      {data.map(item => (
        <DateBox id={item.date}>
          <span>{item.date}</span>
          <span style={{ position: 'relative' }}>
            <NumberBox>{item.n1}</NumberBox>
            <NumberBox>{item.n2}</NumberBox>명
            {item.seats < 1 && <ComplitedSticker />}
          </span>
        </DateBox>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 257px;
  border-radius: 15px;
  background: rgba(253, 231, 144, 0.3);
  padding-bottom: 20px;
`;
const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  p {
    margin: 0px 0px;
    font-size: 16px;
    font-weight: 500;
    margin-left: 18px;
  }
`;
const MonthBox = styled.div`
  width: 42px;
  height: 42px;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  background: #f69f30;
`;
const DateBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
`;

const NumberBox = styled.span`
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 2px solid #696969;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  margin-right: 12px;
`;

const ComplitedSticker = styled.img.attrs({
  src: require('asset/event/event_sd/completed_mark.png').default,
})`
  position: absolute;
  left: -2px;
  bottom: -6px;
  width: 82%;
`;
