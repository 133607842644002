import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Space } from 'atom/layout';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestConfirm } from './RequestConfirm';
import { RegisterStep2 } from '../register';
import { CircularProgress, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { Color } from 'constant';
import Coupone from 'component/customer/Coupone';

export const RequestStep12 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [isError, setIsError] = useState(false);
  const [isShowCoupon, setIsShowCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClickCoupon = () => {
    //쿠폰값이 일치하는지 확인하고 아니면 에러
    const inputValue = customerStore.applyForm.couponCode;
    if (inputValue.toLowerCase() === 'playkitsd') {
      setIsError(false);
      setIsShowCoupon(true);
    } else {
      setIsError(true);
      setIsShowCoupon(false);
      customerStore.applyForm.couponCode = null;
    }
  };
  const onClickNext = async () => {
    if (!isShowCoupon) {
      customerStore.applyForm.couponCode = null;
    }

    setIsLoading(true);
    try {
      const { ok: isSuccess, message: msg } = await customerStore.submitApply(
        customerStore.applyForm,
      );
      if (isSuccess) {
        onNext(RequestConfirm);
      } else {
        alert(`신청중 에러가 발생했습니다. ${msg}`);
      }
    } catch (e) {
      alert(`신청중 에러가 발생했습니다. ${e.message}`);
    } finally {
      setIsLoading(false);
    }

    // if (!customerStore.user.name) {
    //   onNext(RegisterStep2);
    // } else {
    //   if (!isShowCoupon) {
    //     customerStore.applyForm.couponCode = null;
    //   }
    //   onNext(RequestConfirm);
    // }
  };
  if (isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <Title>쿠폰등록을 해주세요</Title>
      <Space height={8} />
      <Subtitle>*등록할 쿠폰이 없으시면 다음 버튼을 눌러주세요.</Subtitle>
      <Space height={51} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextField
          disabled={isShowCoupon}
          variant="outlined"
          style={{ width: '302px' }}
          placeholder="쿠폰 번호를 입력해주세요"
          value={customerStore.applyForm.couponCode}
          onChange={e =>
            (customerStore.applyForm.couponCode = e.target.value.toLocaleLowerCase())
          }
        />
        <MyButton onClick={onClickCoupon}>등록하기</MyButton>
      </div>

      <Space height={5} />
      {isError && <ErrorText>*올바른 쿠폰 번호가 아닙니다.</ErrorText>}
      <Space height={60} />
      {isShowCoupon && (
        <>
          <hr />
          <Space height={40} />
          <Coupone />
          <Space height={60} />
        </>
      )}

      <Bottom progress={(1 / 11) * 11} onClickNext={onClickNext} />
    </>
  );
});

const MyButton = styled.button`
  width: 100px;
  height: 40px;
  background-color: #f69f30;
  color: #fff;
  font-size: 15px;
  text-align: center;
  border-radius: 7px;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    filter: contrast(1.15);
  }
  &:active {
    filter: contrast(0.8);
  }
`;
const ErrorText = styled.div`
  margin-top: 5px;
  color: red;
`;
