import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, DarkGray32 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Content4InfoBox = ({ info }) => {
  const { imagePath, title, description } = info;
  return (
    <Container center>
      <ImgBox src={imagePath} />
      <Space height={64} />
      <DarkGray32 center style={{ fontSize: '34px' }} bold>
        {title}
      </DarkGray32>
      <Space height={32} />
      <DarkGray24 center>{description}</DarkGray24>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  margin: 0 90px;
`;
const ImgBox = styled.img`
  width: auto;
  height: 288px;
`;
export default Content4InfoBox;
