import React from 'react';
import styled from 'styled-components';
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import { MobileContextProvider } from 'util/index';

import { Drawer } from 'component-mobile/drawer';
import { HomePage } from 'page-mobile/HomePage';
import { CustomerPricingPage } from 'page-mobile/customer/CustomerPricingPage';
import { FaqPage } from 'page-mobile/FaqPage';
import { SitterIntroPage } from 'page-mobile/sitter/SitterIntroPage';
import { SitterApplyPage } from 'page-mobile/sitter/SitterApplyPage';
import { CustomerIntroPage } from 'page-mobile/customer/CustomerIntroPage';
import { CustomerRequestPage } from 'page-mobile/customer/CustomerRequestPage';
import { IntroBasicPage } from 'page-mobile/customer/intro/IntroBasicPage';
import { IntroPremiumPage } from 'page-mobile/customer/intro/IntroPremiumPage';
import { IntroExtraPage } from 'page-mobile/customer/intro/IntroExtraPage';
import { URL } from 'constant';
import { EventSungDongPage } from 'page-mobile/event/EventSungDongPage';
import EventPlayKit from 'page-mobile/event/EventPlayKit';
import LoginPage from 'page-mobile/login/LoginPage';
import SignUpMainPage from 'page-mobile/sign-up/SignUpMainPage';
import CustomerPhoneCheckPage from 'page-mobile/sign-up/CustomerPhoneCheckPage';
import SitterPhoneCheckPage from 'page-mobile/sign-up/SitterPhoneCheckPage';
import CustomerSignUpPageStep1 from 'page-mobile/sign-up/CustomerSignUpPageStep1';
import CustomerSignUpPageStep2 from 'page-mobile/sign-up/CustomerSignUpPageStep2';
import SitterSignUpPageStep1 from 'page-mobile/sign-up/SitterSignUpPageStep1';
import SitterSignUpPageStep2 from 'page-mobile/sign-up/SitterSignUpPageStep2';
import SitterSignUpPageStep3 from 'page-mobile/sign-up/SitterSignUpPageStep3';
import SitterSignUpPageStep4 from 'page-mobile/sign-up/SitterSignUpPageStep4';
import SitterSignUpPageStep5 from 'page-mobile/sign-up/SitterSignUpPageStep5';
import SitterSignUpPageStep6 from 'page-mobile/sign-up/SitterSignUpPageStep6';
import SitterSignUpPageStep7 from 'page-mobile/sign-up/SitterSignUpPageStep7';
import { useAuthContext } from './context/AuthContext';
import MyPageCustomer from 'page-mobile/my-page/MyPageCustomer';
import MyPageSitter from 'page-mobile/my-page/MyPageSitter';
import MyApplySitter from 'page-mobile/my-page/MyApplySitter';
import MyApplyCustomer from 'page-mobile/my-page/MyApplyCustomer';
import WellcomeSitter from 'page-mobile/sign-up/WellcomeSitter';
import WellcomeCustomer from 'page-mobile/sign-up/WellcomeCustomer';
import DoneSitter from 'page-mobile/login/DoneSitter';
import DoneCustomer from 'page-mobile/login/DoneCustomer';
import NoticeList from 'page-mobile/web-view-content/NoticeList';
import NoticeDetail from 'page-mobile/web-view-content/NoticeDetail';
import Event5YearsForParents from 'page-mobile/event/Event5YearsForParents';
import B2bPage from 'page-mobile/customer/b2b/B2bPage';

const ScrollToTop = withRouter(({ history }) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

const NavMobile = () => {
  const { isLogin } = useAuthContext();

  return (
    <MobileContextProvider isMobile={true}>
      <div className="mobile">
        <BrowserRouter>
          <ScrollToTop />
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route exact path="/" component={HomePage} />
            <Route exact path="/event_sd" component={EventSungDongPage} />
            <Route exact path="/event_playkit" component={EventPlayKit} />
            <Route
              exact
              path="/event_5years"
              component={Event5YearsForParents}
            />
            <Route exact path="/faq" component={FaqPage} />

            {/* Customer */}
            <Route
              exact
              path="/service/pricing"
              component={CustomerPricingPage}
            />
            <Route
              exact
              path="/service/apply"
              component={CustomerRequestPage}
            />
            <Route
              exact
              path="/service/apply/q1"
              component={CustomerRequestPage}
            />
            <Route exact path="/service/intro" component={CustomerIntroPage} />
            <Route
              exact
              path="/service/intro/basic"
              component={IntroBasicPage}
            />
            <Route
              exact
              path="/service/intro/premium"
              component={IntroPremiumPage}
            />
            <Route
              exact
              path="/service/intro/extra"
              component={IntroExtraPage}
            />

            <Route exact path={'/service/b2b'} component={B2bPage} />

            {/* Sitter */}
            <Route exact path="/hero/about" component={SitterIntroPage} />
            <Route exact path="/hero/apply" component={SitterApplyPage} />
            <Route exact path="/hero/apply/q1" component={SitterApplyPage} />
            {/*already done */}
            <Route exact path="/hero/done" component={DoneSitter} />
            <Route exact path="/user/done" component={DoneCustomer} />
            {/*signup done */}
            <Route exact path="/hero/wellcome" component={WellcomeSitter} />
            <Route exact path="/user/wellcome" component={WellcomeCustomer} />
            {/* webView content */}
            <Route exact path={'/notice'} component={NoticeList} />
            <Route exact path={'/notice/:id'} component={NoticeDetail} />

            {isLogin ? (
              <Switch>
                {/*mypage */}
                <Route
                  exact
                  path="/mypage/hero/info"
                  component={MyPageSitter}
                />
                <Route
                  exact
                  path="/mypage/user/info"
                  component={MyPageCustomer}
                />
                <Route
                  exact
                  path="/mypage/hero/apply"
                  component={MyApplySitter}
                />
                <Route
                  exact
                  path="/mypage/user/apply"
                  component={MyApplyCustomer}
                />
                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
            ) : (
              <Switch>
                {/* login & signup */}
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/sign-up" component={SignUpMainPage} />
                <Route
                  exact
                  path="/sign-up/parents/phone"
                  component={CustomerPhoneCheckPage}
                />
                <Route
                  exact
                  path="/sign-up/hero/phone"
                  component={SitterPhoneCheckPage}
                />
                <Route
                  exact
                  path="/sign-up/parents/info"
                  component={CustomerSignUpPageStep1}
                />
                <Route
                  exact
                  path="/sign-up/parents/info2"
                  component={CustomerSignUpPageStep2}
                />

                <Route
                  exact
                  path="/sign-up/hero/info"
                  component={SitterSignUpPageStep1}
                />
                <Route
                  exact
                  path="/sign-up/hero/info2"
                  component={SitterSignUpPageStep2}
                />

                <Route
                  exact
                  path="/sign-up/hero/info3"
                  component={SitterSignUpPageStep3}
                />
                <Route
                  exact
                  path="/sign-up/hero/info4"
                  component={SitterSignUpPageStep4}
                />
                <Route
                  exact
                  path="/sign-up/hero/info5"
                  component={SitterSignUpPageStep5}
                />
                <Route
                  exact
                  path="/sign-up/hero/info6"
                  component={SitterSignUpPageStep6}
                />
                <Route
                  exact
                  path="/sign-up/hero/info7"
                  component={SitterSignUpPageStep7}
                />
                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
            )}

            <Redirect to="/" />
          </AnimatedSwitch>
          <Drawer />
        </BrowserRouter>
      </div>
      <GoToTopFAB />
      {/* <FAB /> */}
    </MobileContextProvider>
  );
};
export default NavMobile;

const GoToTopFAB = styled.img.attrs({
  src: require('asset/mobile/home/top.svg').default,
  onClick: () => window.scrollTo(0, 0),
})`
  width: 60px;
  height: 60px;
  position: fixed;
  right: -8px;
  bottom: 70px;

  width: 80px;

  cursor: pointer;
`;

const FAB = styled.img.attrs({
  src: require('asset/mobile/home/kakao.png').default,
  onClick: () => window.open(URL.Kakao, '_blank'),
})`
  position: fixed;
  right: 0px;
  bottom: 0px;

  width: 80px;

  cursor: pointer;
`;
