import { RoundButton } from 'atom/button';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray18, LightGray12 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';
import Contaent2InfoBox from './Contaent2InfoBox';

const Content3 = ({ moveToApply }) => {
  const infos = [
    {
      imgPath: require('asset/mobile/b2b/content3_1.png').default,
      title: '전용 바우처',
      description: `제휴 기업 직원들이\n쉽게 사용할 수 있는\n전용 상품권`,
    },
    {
      imgPath: require('asset/mobile/b2b/content3_2.png').default,
      title: '특별 할인/혜택',
      description: `제휴 기업 직원 전용\n할인/혜택 제공`,
    },
    {
      imgPath: require('asset/mobile/b2b/content3_3.png').default,
      title: '전담 매니저',
      description: `제휴 기업 임직원\n전담 매니저 배정\n[신청 안내 - 매칭 - CS]`,
    },
  ];
  return (
    <Container center>
      <Space height={64} />
      <DarkGray18 bold>우리동네 돌봄히어로 제휴 혜택</DarkGray18>
      <Space height={8} />
      <LightGray12 semiBold>
        직접 찾을 필요 없이 담당 매니저가 찾아드려요!
      </LightGray12>
      <Space height={32} />
      {infos.map(info => (
        <Contaent2InfoBox info={info} />
      ))}
      <Space height={24} />
      <RoundedButton onClick={moveToApply}>서비스 신청하기</RoundedButton>
    </Container>
  );
};
const Container = styled(VerticalLayout)`
  background: rgba(250, 250, 250, 1);
  height: 698px;
`;
const RoundedButton = styled(RoundButton)`
  width: 204px;
  height: 40px;

  border-radius: 30px;

  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
export default Content3;
