import { ImageBox } from 'atom/image';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray12, DarkGray18, LightGray12, Primary16 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';
import TopMainInfoBox from './TopMainInfoBox';

const TopMain = props => {
  const infos = [
    {
      title: '44%',
      subTitle: `6세 이하 자녀를 둔 가정 중\n맞벌이 가구의 비중 `,
      description: `2021 통계청 자료`,
    },
    {
      title: '95%',
      subTitle: `퇴사고민 경험이 있는\n워킹맘의 비중`,
      description: `KB금융경영연구소\n'2019 한국 워킹맘 보고서'`,
    },
    {
      title: '27%',
      subTitle: `아이를 믿고 맡길 곳이 없어서\n일을 중단한 경우`,
      description: `2022 제4차 육아정책 심포지엄\n‘출산/육아 관련 일 중단의 주된 이유’`,
    },
  ];
  return (
    <ImageBox
      minHeight={693}
      src={require('asset/mobile/b2b/topMainBG.png').default}
    >
      <VerticalLayout center>
        <TopLeftContainer>
          <Icon src={require('asset/b2b/building.svg').default} />
          <Space height={4} />
          <DarkGray12 bold>아이를 믿고 맡길 수 있는</DarkGray12>
          <Primary16 bold>
            우리동네 돌봄히어로<BlackText>를 통해</BlackText>
          </Primary16>
          <Primary16 bold>
            만족도 높은 직원경험<BlackText>을 선물하세요.</BlackText>
          </Primary16>
        </TopLeftContainer>

        <TopRightImg src={require('asset/mobile/b2b/topMainImg.png').default} />
        <Space height={225} />
        <DarkGray18 bold center>
          ‘아이 돌봄'이 중요한 복지인 이유
        </DarkGray18>
        <Space height={8} />
        <LightGray12 center>
          육아로 인한 유능한 직원들의 경력단절 및 인재 유출
        </LightGray12>
        <Space height={60} />
        {infos.map(info => (
          <TopMainInfoBox info={info} />
        ))}
      </VerticalLayout>
    </ImageBox>
  );
};

const TopLeftContainer = styled(VerticalLayout)`
  position: absolute;
  left: calc(50% - 138px / 2 - 87px);
  top: 45px;
`;
const TopRightImg = styled.img`
  width: 100px;
  position: absolute;
  left: calc(50% - 244px / 2 + 180px);
  top: 50px;
`;
const Icon = styled.img`
  width: 14px;
  height: 17px;
`;
const BlackText = styled.span`
  color: #484848;
`;
export default TopMain;
