import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray12, DarkGray18 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Reward2 = props => {
  return (
    <Container center>
      <Space height={64} />
      <RoundTextBox>혜택 2</RoundTextBox>
      <Space height={24} />
      <DarkGray18 bold center>{`서비스 상담 신청해주신 모든 분들께
재활용품을 활용한 창의놀이 교안(PDF)을
무료로 제공해드려요.`}</DarkGray18>
      <Space height={24} />
      <CercleFrame>
        <img
          style={{
            width: '113px',
          }}
          src={
            require('asset/event/event_5years_for_parents/heroPlay_2.png')
              .default
          }
        />
      </CercleFrame>
      <Space height={64} />
    </Container>
  );
};
const Container = styled(VerticalLayout)`
  background: #fff8eb;
`;

const RoundTextBox = styled.div`
  background: #000000;
  border-radius: 100px;
  padding: 4px 22px;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
`;

const CercleFrame = styled.div`
  width: 202px;
  height: 202px;
  border-radius: 50%;
  background: #fadb3d;
  display: felx;
  justify-content: center;
  align-items: center;
`;
export default Reward2;
