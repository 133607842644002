import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { RoundButton } from 'atom/button';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { ApplyStep2 } from './ApplyStep2';

export const ApplyStep1 = observer(({ Bottom, onNext }) => {
  return (
    <>
      <Title>
        히어로 선생님으로
        <br />
        인생 2막을 시작하다!
      </Title>
      <Space height={45} />

      <ContentText>
        1. 67세 이하의 여성
        <br />
        2. 한국 국적을 가진 한국인 여성
        <br />
        3. 서울에 거주하시는 분
        <br />
        4. 자녀양육 경험이 있으신 분 (단, 미취학 자녀가 있을 경우 활동불가)
        <br />
        5. 자녀양육 경험이 없을 경우, 영유아 돌봄경력이 1년 이상 되시는 분<br />
        6. 등하원 시간에 활동이 가능하신 분<br />
        7. 아이들을 사랑하고 안전을 최우선시 하는 분<br />
        8. B형 간염 보균자가 아니며, 신체적으로 건강하신 분<br />
        9. 교육이수 후 3개월 이내에 활동이 가능하신 분<br />
        10. 스마트폰을 소지하신 분
      </ContentText>
      <Space height={54} />
      {/* <hr className="thick" />
      <Space height={40} />

      <FlowText>
        히어로 선생님이 <br />
        되기까지의 과정은 이렇습니다.
      </FlowText>
      <Space height={45} />
      <FlowImage />
      <Space height={59} /> */}

      <RoundButton
        style={{ height: '62px', width: '100%' }}
        onClick={() => onNext(ApplyStep2)}
      >
        다음
      </RoundButton>
      <Space height={20} />
    </>
  );
});

const FlowImage = styled.img.attrs({
  src: require('asset/mobile/sitter/apply/flow.svg').default,
})`
  width: calc(100% - 60px);

  align-self: center;
`;
const ContentText = styled.div`
  font-size: 15px;
  font-weight: 500;

  line-height: 2.27;
  letter-spacing: -0.75px;
`;
const FlowText = styled.div`
  color: #484848;

  font-size: 23px;
  font-weight: bold;

  line-height: 1.48;
  letter-spacing: -1.15px;
`;
