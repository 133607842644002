import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { uniq, groupBy } from 'lodash';

import { Tag } from 'atom/deco';
import { Push, Space, HorizontalLayout, VerticalLayout } from 'atom/layout';
import { Color } from 'constant';
import { getHours, calcPrice, getWeekDayCount } from 'util/index';
import { useStores } from 'state';
import { Title } from './style';
import { RequestFaq } from './RequsetFaq';
import { LightGray12 } from 'atom/text';
import { CareTypeNew, ICareInfo, IFeeInfo } from 'model';
import { CircularProgress } from '@material-ui/core';

export const RequestConfirm = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [careInfo, setCareInfo] = useState<ICareInfo>();
  const [feeInfo, setFeeInfo] = useState<IFeeInfo>();
  const [couponCode, setCouponCode] = useState(null);

  const onSubmit = async () => {
    onNext(RequestFaq);
    customerStore.resetApplyForm();
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const {
          ok,
          message,
          careInfo,
          feeInfo,
        } = await customerStore.getLastCare();

        if (ok) {
          setCareInfo(careInfo);
          setFeeInfo(feeInfo);
          if (careInfo) {
            setCouponCode(careInfo['couponInfo']);
          }
        } else {
          alert(`신청정보 조회에 실패하였습니다. ${message}`);
        }
      } catch (e) {
        alert(`신청정보 조회에 실패하였습니다. ${e.message}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [customerStore]);

  if (isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <Title>
        돌봄을 이렇게
        <br />
        신청해주셨어요!
      </Title>
      <Space height={50} />

      <InfoBox>
        <InfoCategory>아이 정보</InfoCategory>
        {careInfo &&
          careInfo.childInfo.map(x => (
            <div>
              {x.name} / {x.sex === 0 ? '남아' : '여아'} / {x.birthdate}
              <br />
            </div>
          ))}
        <br />
        <InfoCategory>돌봄 유형</InfoCategory>
        <div>{careInfo ? careInfo.isRegular : ''}</div>
        <br />
        <InfoCategory>돌봄 종류</InfoCategory>
        {careInfo && careInfo.careType.map(type => <div>{type}</div>)}
        <br />
        <InfoCategory>돌봄 일정</InfoCategory>
        {careInfo &&
          !careInfo.isRegular.includes('비정기') &&
          careInfo.carePlan.map(plan => (
            <>
              <div>매주 {plan && plan.dayofweek.join(', ')}</div>
              <div>{`- ${plan.serviceType} / ${plan.timeInfo}`}</div>
              <br />
            </>
          ))}
        {careInfo &&
          careInfo.isRegular.includes('비정기') &&
          careInfo.carePlan.map(plan => (
            <>
              <div>{plan.date}</div>
              <div>{`- ${plan.serviceType} / ${plan.time}`}</div>
              <br />
            </>
          ))}
        {careInfo && (
          <>
            <InfoCategory>돌봄 장소</InfoCategory>
            <div>{careInfo.careAddress}</div>
            <br />
          </>
        )}
      </InfoBox>
      <Space height={30} />

      <CareCard feeInfo={feeInfo} careInfo={careInfo} couponCode={couponCode} />
      <Space height={50} />

      <Bottom
        progress={null}
        nextLabel="확인"
        onClickPrev={null}
        onClickNext={onSubmit}
      />
    </>
  );
});

const CareCard = ({ feeInfo, careInfo, couponCode }) => {
  return feeInfo ? (
    <CardContainer>
      <HorizontalLayout>
        <CardTitle>예상 돌봄 비용</CardTitle>
        <Push />
        <Tag>
          {careInfo && careInfo.isRegular.includes('비정기')
            ? '비정기'
            : '4주 기준'}
        </Tag>
      </HorizontalLayout>
      <Space height={10} />
      <LightGray12>
        *예상 돌봄 비용은{' '}
        {<span style={{ fontWeight: 'bold' }}>일반 선생님</span>}으로 책정된
        것으로 <br />
        선생님 경력에 따라 달라질 수 있습니다.
      </LightGray12>
      <Space height={10} />
      <VerticalLayout style={{ gap: '13px' }}>
        <CardText style={{ color: Color.Primary }}>
          총 예상금액 {feeInfo.totalFee.toLocaleString()}원
        </CardText>
        {
          <>
            <HorizontalLayout center>
              <CardTextContents>기본돌봄</CardTextContents>
              <div
                style={{
                  color: '#999',
                  fontSize: '12px',
                }}
              >
                &nbsp;|&nbsp;
              </div>
              <CardTextContents>
                {careInfo && careInfo.isRegular.includes('비정기')
                  ? `총 ${careInfo && careInfo.carePlan.length}회`
                  : `주 ${feeInfo && feeInfo.cntOfWeek}회`}
              </CardTextContents>
              <CardTextContents>
                &nbsp;/ {`총 ${feeInfo.totalHour}시간`}
              </CardTextContents>
              <Space width={4} />
              <div style={{ color: '#999', fontSize: '12px' }}>|</div>
              <Space width={4} />
              <CardText style={{ color: Color.Primary }}>
                {feeInfo.totalFee ? feeInfo.totalFee.toLocaleString() : ''}원
              </CardText>
            </HorizontalLayout>
            {feeInfo && feeInfo.serviceFee > 0 && (
              <HorizontalLayout center>
                <CardTextContents>창의놀이 패키지</CardTextContents>
                <div
                  style={{
                    color: '#999',
                    fontSize: '12px',
                  }}
                >
                  &nbsp;|&nbsp;
                </div>
                <CardTextContents>{feeInfo.serviceType}</CardTextContents>
                <Space width={4} />
                <div style={{ color: '#999', fontSize: '12px' }}>|</div>
                <Space width={4} />
                <CardText style={{ color: Color.Primary }}>
                  {feeInfo.serviceFee.toLocaleString()}원
                </CardText>
              </HorizontalLayout>
            )}
          </>
        }

        {couponCode === 'playkitsd' && (
          <>
            <HorizontalLayout center>
              <CardTextContents>* 쿠폰적용 | </CardTextContents>
              <CardTextContents style={{ color: Color.Primary }}>
                &nbsp;
                {`[성동구 놀이키트 쿠폰]`}
              </CardTextContents>
            </HorizontalLayout>
          </>
        )}
      </VerticalLayout>
    </CardContainer>
  ) : (
    <></>
  );
};

const CardContainer = styled.div`
  width: 100%;

  box-shadow: 3px 4px 12px 0 #dedede;
  border-radius: 10px;
  background-color: #fff;

  padding: 18px 18px;
`;
const CardTitle = styled.div`
  color: #707070;

  font-size: 20px;
  font-weight: bold;
`;
const CardText = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.8px;
  color: #969696;
`;
const CardTextContents = styled.div`
  font-size: 16px;
  font-weight: no;
  letter-spacing: -0.8px;
  color: #969696;
`;

const InfoBox = styled.div`
  width: 100%;

  color: #484848;

  border-radius: 10px;
  background-color: #f7f7f7;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.8px;

  padding: 19px 17px;
`;
const InfoCategory = styled.div`
  font-weight: bold;
`;
