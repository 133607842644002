import React, { useState } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';

import { DateRangePicker } from 'atom/input';
import { Color } from 'constant';
import { Backdrop } from './Backdrop';

interface DateRangePickerPopupProps {
  startDate: DateTime;
  endDate: DateTime;
  onChange: (startDate: DateTime, endDate: DateTime) => void;
  onClose: () => void;
}
export const DateRangePickerPopup = ({
  startDate,
  endDate,
  onChange,
  onClose,
}: DateRangePickerPopupProps) => {
  const [date, setDate] = useState(endDate);

  const onSubmit = () => {
    onChange(startDate, date);
    onClose();
  };

  return (
    <Backdrop onClose={onClose}>
      <Container>
        <CalendarContainer>
          <SCalendar
            key={date?.toISO()}
            selectRange
            minDate={startDate?.toJSDate() || DateTime.local().toJSDate()}
            className={!!date ? 'selected' : 'selecting'}
            formatDay={(locale, date) => `${date.getDate()}`}
            value={[startDate?.toJSDate(), date?.toJSDate()]}
            calendarType="US"
            onClickDay={(v, e) => {
              setDate(DateTime.fromJSDate(v));
              console.log(e);
              //onChange(startDate, DateTime.fromJSDate(v));
            }}
            onChange={v => {
              //setDate(DateTime.fromJSDate(v));
              //onChange(DateTime.fromJSDate(v[0]), DateTime.fromJSDate(v[1]));
            }}
          />
        </CalendarContainer>
        <BottomButton onClick={onSubmit}>적용</BottomButton>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  position: relative;
  width: 400px;
  height: 390px;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const CalendarContainer = styled.div`
  padding: 20px 20px;
`;
const SCalendar = styled(Calendar)`
  button.react-calendar__tile--rangeEnd {
    background: linear-gradient(to right, #ffedd5 50%, white 50%) !important;
  }
`;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  position: absolute;
  bottom: 0px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
