import React, { useState, useEffect } from 'react';
import { INoticeContent } from 'src/content/Notices';
import { useStores } from '..';

export const useNotices = (query: object) => {
  const { valueStore } = useStores();
  const [data, setData] = useState<INoticeContent[]>();

  useEffect(() => {
    (async () => {
      setData(await valueStore.getNotices(query));
    })();
  }, []);
  return data;
};

export const useNotice = (id: string) => {
  const { valueStore } = useStores();
  const [data, setData] = useState<INoticeContent>();
  useEffect(() => {
    (async () => {
      setData(await valueStore.getNotice(id));
    })();
  }, []);
  return data;
};
