import { RoundButton } from 'atom/button';
import { ImageBox } from 'atom/image';
import { Space, VerticalLayout } from 'atom/layout';
import {
  DarkGray10,
  DarkGray16,
  DarkGray18,
  LightGray12,
  LightGray8,
  Primary16,
} from 'atom/text';
import HowToDo from 'component-mobile/event/play_kit/HowToDo';
import LimitedCount from 'component-mobile/event/play_kit/LimitedCount';
import Notice from 'component-mobile/event/play_kit/Notice';
import Reviews from 'component-mobile/event/play_kit/Reviews';
import ServiceInfo from 'component-mobile/event/play_kit/ServiceInfo';
import { Footer } from 'component-mobile/footer';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const EventPlayKit = props => {
  const history = useHistory();
  return (
    <>
      <Container center>
        <ImageBox
          src={
            require('asset/mobile/event/event_playkit/mobile_main_top_3x_2.png')
              .default
          }
          minHeight={611}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              marginTop: '16%',
              position: 'absolute',
              left: '50%',
              transform: 'translate(-90%, -16%)',
            }}
          >
            <DarkGray10 style={{ fontSize: '11px' }}>
              2022 시-구 상향적 협력적 일자리창출 사업
            </DarkGray10>
            <DarkGray16 bold style={{ fontSize: '17px' }}>
              정기 돌봄 신청시
            </DarkGray16>
            <DarkGray16 bold style={{ fontSize: '17px' }}>
              10만원 상당의
            </DarkGray16>
            <Primary16 bold style={{ fontSize: '17px' }}>
              창의 놀이
            </Primary16>
            <Primary16 bold style={{ fontSize: '17px' }}>
              서비스가 무료!
            </Primary16>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              position: 'absolute',
              left: '50%',
              bottom: '8%',
              width: '325px',
              transform: 'translate(-50%, -8%)',
            }}
          >
            <LightGray8>
              ※ 본 이벤트는 3개월 이상 정기 돌봄을 신청한 부모님 대상으로
              진행됩니다
            </LightGray8>
            <LightGray8>
              ※ 본 이벤트는 계약금 10만원 선납 후 참여 가능하며, 3개월 정기 돌봄
              이후 환급해드립니다.
            </LightGray8>
            <LightGray8>
              ※ 본 이벤트는 1:1 돌봄일 경우에만 참여하실 수 있습니다.
            </LightGray8>
            <LightGray8>
              ※ 본 이벤트는 4-8세 연령 대상으로만 진행됩니다.
            </LightGray8>
          </div>
        </ImageBox>
        <LimitedCount />
        <Space height={22} />
        <RoundButton
          style={{
            width: '204px',
            height: '40px',
            fontSize: '16px',
            padding: 0,
          }}
          onClick={() => history.push('/service/apply/q1')}
        >
          지금 바로 신청하기
        </RoundButton>
        <Space height={64} />
        <div
          style={{
            width: '100%',
            height: '608px',
            background: '#fffbf7',
            textAlign: 'center',
          }}
        >
          <Space height={64} />
          <DarkGray18 bold>이용 중인 부모님의</DarkGray18>
          <DarkGray18 bold>
            만족도 <span style={{ color: '#D4594B' }}>9.8점</span> 히어로 돌봄
            서비스
          </DarkGray18>
          <Space height={6} />
          <LightGray12>아이를 키워본 30~67세 여성이라면 누구나</LightGray12>
          <Reviews />
        </div>

        <Space height={64} />
        <ServiceInfo />
        <div
          style={{
            width: '100%',
            backgroundColor: '#F9F9F9',
            height: '391px',
            textAlign: 'center',
          }}
        >
          <Space height={64} />

          <DarkGray18 bold>창의 놀이시간에 아이들이</DarkGray18>
          <DarkGray18 bold>만든 작품이에요!</DarkGray18>
          <img
            src={
              require('asset/mobile/event/event_playkit/m-serviceInfo4.png')
                .default
            }
          />
        </div>
        <HowToDo />
        <Notice />
      </Container>
      <Footer />
    </>
  );
};
const Container = styled(VerticalLayout)`
  font-family: 'Noto Sans KR';
`;
export default EventPlayKit;
