import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import {
  Gray14,
  Gray20,
  LightGray10,
  LightGray14,
  Primary24,
  Primary18,
  Gray18,
  Primary34,
  Primary16,
  Gray12,
} from 'atom/text';
import { HorizontalLayout, Push, Space, VerticalLayout } from 'atom/layout';
import { useIsMobile } from 'util/index';

interface ExtraServiceCardWideProps {
  image: string;
  title: string;
  content: string;
  onClick: () => void;
}
export const ExtraServiceCardWide = ({
  image,
  title,
  content,
  onClick,
}: ExtraServiceCardWideProps) => {
  const isMobile = useIsMobile();

  const Title = isMobile ? Primary16 : Primary34;
  const Subtitle = isMobile ? Gray12 : Gray18;
  const Detail = isMobile ? LightGray10 : LightGray14;

  const getPcContents = () => (
    <VerticalLayout style={{ width: '100%', padding: '20px' }}>
      <Space height={64} />
      <Subtitle style={{ whiteSpace: 'pre' }}>
        {content.replace('\\n', '\n')}
      </Subtitle>
      <Space height={12} />

      <Title bold>{title}</Title>
      <Push />

      <HorizontalLayout center>
        <Push />
        <Detail className="detail" semiBold>
          자세히 보기
        </Detail>
        <SVG
          style={{
            transform: 'translateY(1px)',
            width: isMobile ? '12px' : 'auto',
          }}
          src={require('asset/icon/arrow_right.svg').default}
        />
      </HorizontalLayout>
      <Space width={isMobile ? 12 : 24} />
    </VerticalLayout>
  );
  const getMobileContents = () => (
    <VerticalLayout
      style={{ width: '100%', justifyContent: 'center', padding: '20px' }}
    >
      <HorizontalLayout center>
        <VerticalLayout style={{ width: '100%', height: '100%' }}>
          <Title bold>{title}</Title>

          <Space height={8} />
          <Subtitle> {content.replace('\\n', '\n')}</Subtitle>
        </VerticalLayout>
        <Push />
        <SVG
          style={{
            transform: 'translateY(1px)',
            width: isMobile ? '12px' : 'auto',
          }}
          src={require('asset/icon/arrow_right_orange.svg').default}
        />
      </HorizontalLayout>
    </VerticalLayout>
  );
  return (
    <Container isMobile={isMobile} onClick={onClick}>
      <Image isMobile={isMobile} src={image} />
      {isMobile ? getMobileContents() : getPcContents()}
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  width: 640px;
  height: 311px;

  background: #fff;
  box-shadow: 3px 4px 16px #dedede;
  border-radius: 24px;
  box-sizing: border-box;

  overflow: hidden;
  cursor: pointer;

  transition: all 0.25s ease;

  svg {
    color: #969696;
  }
  &:hover {
    transform: scale(1.015);
    border: 2px solid #e9901e;

    img {
      filter: saturate(1.35);
    }
    .detail,
    svg {
      color: #e9901e;
    }
  }

  ${({ isMobile }) =>
    isMobile
      ? `
    width: 320px;
    height: 120px;
    border-radius: 12px;

    margin-left: 16px;
    margin-right: 16px;
  `
      : `
  `}
`;
const Image = styled.img<any>`
  width: 50%;
  height: 100%;

  object-fit: fill;

  ${({ isMobile }) =>
    isMobile
      ? `
      width: 40%;
  height: 100%;
  `
      : `
  `}
`;
