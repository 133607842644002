import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Color } from 'constant';
import { useIsMobile } from 'util/index';

interface SegmentTabProps {
  items: string[];
  value: number;
  isMobile: boolean;
  onChange: (x: number) => void;
}
export const SegmentTab = ({
  items,
  value,
  isMobile,
  onChange,
  ...props
}: SegmentTabProps) => {
  //const isMobile = useIsMobile();

  return (
    <Container isMobile={isMobile}>
      {items.map((x, index) => (
        <Chip
          key={x}
          isMobile={isMobile}
          active={index === value}
          onClick={() => onChange(index)}
        >
          {x}
        </Chip>
      ))}
    </Container>
  );
};

const Container = styled.div<Partial<SegmentTabProps> | any>`
  width: 320px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  background: #f6f6f6;
  border-radius: 12px;

  gap: 20px;

  padding: 8px 15px;

  ${({ isMobile }) =>
    isMobile
      ? `
      width:250px;
      padding: 8px 8px;
    `
      : `
    `}
`;

const Chip = styled.div<any>`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  padding: 3px 10px;

  cursor: pointer;

  ${({ isMobile }) =>
    isMobile
      ? `
  font-size: 14px;
  padding: 0px 15px;
    `
      : `
      padding: 2px 15px;
    `}

  ${({ active }) =>
    active
      ? `
      background: #F69F30;
      border-radius: 5px;
      color: white;
      `
      : `
      color: #a4a4a4;
`}
`;
