import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray18, Primary18 } from 'atom/text';
import { Footer } from 'component-mobile/footer';
import { Header } from 'component-mobile/header';

import React from 'react';
import { Link } from 'react-router-dom';

const SignUpMainPage = props => {
  return (
    <>
      <Header />
      <VerticalLayout center>
        <Space height={107} />
        <Link to={'/sign-up/parents/phone'}>
          <div
            style={{
              width: '308px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={require('asset/sign-up/userImg.svg').default} />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DarkGray18 bold>우리 아이 맡기기</DarkGray18>
              <Primary18 bold>부모님이에요.</Primary18>
            </div>
            <img
              style={{ width: '20px' }}
              src={require('asset/icon/arrow_right.svg').default}
            />
          </div>
        </Link>
        <Space height={42} />
        <hr style={{ width: '297px' }} />
        <Space height={42} />
        <Link to={'/sign-up/hero/phone'}>
          <div
            style={{
              width: '308px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={require('asset/sign-up/heroImg.svg').default} />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DarkGray18 bold>히어로 활동하기</DarkGray18>
              <Primary18 bold>선생님이에요.</Primary18>
            </div>
            <img
              style={{ width: '20px' }}
              src={require('asset/icon/arrow_right.svg').default}
            />
          </div>
        </Link>
        <Space height={200} />
      </VerticalLayout>
      <Footer />
    </>
  );
};

export default SignUpMainPage;
