import React, { useState, CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';

import { Space } from 'atom/layout';
import { Color } from 'constant';

interface DatePickerProps {
  style?: CSSProperties;
  value: DateTime;
  onChange: (value: DateTime) => void;
}
export const DatePicker = ({ value, onChange, ...props }: DatePickerProps) => {
  return (
    <Holder>
      <Container {...props}>
        <Calendar
          formatDay={(locale, date) => `${date.getDate()}`}
          minDate={DateTime.local().toJSDate()}
          value={value?.toJSDate()}
          calendarType="US"
          onChange={v => {
            onChange(DateTime.fromJSDate(v));
          }}
        />
      </Container>
    </Holder>
  );
};

interface MultiDatePickerProps {
  style?: CSSProperties;
  value: DateTime[];
  onChange: (value: DateTime[]) => void;
  onClose: () => void;
}
export const MultiDatePicker = ({
  value,
  onChange,
  onClose,
  ...props
}: MultiDatePickerProps) => {
  console.log(value);

  return (
    <Holder>
      <Container {...props}>
        <MultiPicker
          formatDay={(locale, date) => `${date.getDate()}`}
          minDate={DateTime.local().toJSDate()}
          initialDates={value?.map(x => x.toJSDate())}
          value={null}
          onChange={v => {
            onChange(v.map(x => DateTime.fromJSDate(x)));
          }}
        />
        <Space height={12} />

        <BottomButton
          onClick={() => {
            onChange(value);
            onClose();
          }}
        >
          적용
        </BottomButton>
      </Container>
    </Holder>
  );
};

export const MultiPicker = ({ initialDates = [], onChange, ...props }) => {
  const dateAlreadyClicked = (dates, date) =>
    dates.some(
      d =>
        DateTime.fromJSDate(date)
          .startOf('day')
          .toISO() ===
        DateTime.fromJSDate(d)
          .startOf('day')
          .toISO(),
    );
  const datesExcept = (dates, date) =>
    dates.filter(
      d =>
        DateTime.fromJSDate(date)
          .startOf('day')
          .toISO() !==
        DateTime.fromJSDate(d)
          .startOf('day')
          .toISO(),
    );
  const [dates, setDates] = useState(initialDates || []);

  const onClickDay = date => {
    // if day is already clicked, remove it from state
    if (dateAlreadyClicked(dates, date)) {
      setDates(dates => datesExcept(dates, date));
    } else setDates(dates => [...dates, date]);
  };

  const tileClassName = ({ date }) => {
    const classNames = [];
    // give active days a special class
    if (dateAlreadyClicked(dates, date))
      return ['react-calendar__tile--selected', ...classNames];
    return classNames;
  };

  useEffect(() => {
    onChange(dates);
  }, [dates]);

  return (
    <Calendar
      tileClassName={tileClassName as any}
      defaultActiveStartDate={initialDates[initialDates.length - 1]}
      onClickDay={onClickDay}
      calendarType="US"
      {...props}
    />
  );
};

const Holder = styled.div``;
const Container = styled.div<any>`
  position: absolute;

  padding: 24px 24px;

  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  overflow: hidden;

  z-index: 100;
`;
const BottomButton = styled.div`
  display: flex;
  width: calc(100% + 48px);
  height: 52px;

  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
