import jwtDecode from 'jwt-decode';

const _accessTokenKey = 'token';
const _refreshTokenKey = 'refreshToken';
const _userId = 'id';
const _userName = 'name';
const _userType = 'type';

export function getAccessToken() {
  return sessionStorage.getItem(_accessTokenKey);
}
function setAccessToken(jwt: string) {
  sessionStorage.setItem(_accessTokenKey, jwt);
}
export function getRefreshToken() {
  return sessionStorage.getItem(_refreshTokenKey);
}
function setRefreshToken(jwt: string) {
  sessionStorage.setItem(_refreshTokenKey, jwt);
}
export function getUserType() {
  return sessionStorage.getItem(_userType);
}
function setUserType(type: string) {
  sessionStorage.setItem(_userType, type);
}
export function getUserName() {
  return sessionStorage.getItem(_userName);
}
function setUserName(name: string) {
  sessionStorage.setItem(_userName, name);
}
export function getUserId() {
  return sessionStorage.getItem(_userId);
}
function setUserId(id: number) {
  sessionStorage.setItem(_userId, String(id));
}
function getTokenInfo(jwt: string) {
  return jwtDecode(jwt);
}
function getIsExpired(tokenInfo) {
  const expiration = new Date(tokenInfo.exp * 1000);

  const now = new Date();
  if (expiration.getTime() - now.getTime() <= 0) {
    return true;
  } else {
    return false;
  }
}
export async function checkAccessToken() {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return false;
  }
  const tokenInfo = getTokenInfo(accessToken);
  console.log(tokenInfo);

  if (getIsExpired(tokenInfo)) {
    //console.log('checkAccessToken() tokenRefresh()');
    return tokenRefresh();
  } else {
    return true;
  }
}

async function tokenRefresh() {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return false;
  }
  const tokenInfo = getTokenInfo(refreshToken);
  if (getIsExpired(tokenInfo)) {
    return false;
  } else {
    //리프레시 요청

    /**
     * ok: true,
      data: {
     accessToken: newAccessToken,
     refreshToken,
 },
     * 
     */
    try {
      const { ok, data } = await apiNew.get('/home/user/refresh');
      if (ok) {
        setAccessToken(data['accessToken']);
        setRefreshToken(data['refreshToken']);
        return true;
      }
      return false;
    } catch (error) {
      console.log('token refresh failed');
      return false;
    }
  }
}
export function clearSesstionStorage() {
  sessionStorage.clear();
  apiNew.token = '';
  apiNew.refreshToken = '';
}

export function okLogin({ id, name, type, token, refreshToken }) {
  apiNew.token = token;
  apiNew.refreshToken = refreshToken;
  setAccessToken(token);
  setRefreshToken(refreshToken);
  setUserId(id);
  setUserName(name);
  setUserType(type);
}

export function okTmpLogin({ id }) {
  setUserId(id);
}
