import React from 'react';
import styled from 'styled-components';

import { Space } from 'atom/layout';
import { SitterInterviewContent } from 'content';
import { Color } from 'constant';

interface SitterCardProps {
  data: SitterInterviewContent;
  onClick?: () => void;
};
export const SitterCard = ({
  data,
  onClick,
}: SitterCardProps) => {
  return (
    <Container
      onClick={onClick}
    >
      <Image
        src={data.portrait}
      />
      <TextWrap>
        <div>
          <IntroText>
            {data.content}
          </IntroText>
          <NameText>
            <b>{data.sitterName}</b>
            &nbsp;
            ({data.sitterInfo})
          </NameText>
        </div>
        <ShowMoreText
          onClick={() => window.open(data.url, '_blank')}
        >
          인터뷰 더보기
        </ShowMoreText>
      </TextWrap>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  display: flex;

  width: 272px;
  height: 177px;
  
  padding: 41px 28px 20px;

  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  margin: 5px;
`;
const Image = styled.img`
  position: absolute;
  right: 9px;
  bottom: 0px;

  width: 123px;
  height: 164px;

  object-fit: cover;
  z-index: 0;
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  z-index: 100;
`
const IntroText = styled.div`
  font-size: 10px;

  line-height: 1.75;
  white-space: pre-line;
  transform: scale(0.82);
  transform-origin: top left;
`;
const NameText = styled.div`
  font-size: 10px;
  font-weight: 300;

  line-height: 1;
  letter-spacing: -0.21px;
  
  transform: scale(0.7);
  transform-origin: top left;
`;
const ShowMoreText = styled.div`
  color: ${Color.Primary};

  font-size: 10px;
  font-weight: bold;
  text-decoration: underline;

  line-height: 1;
  letter-spacing: -0.4px;
  
  transform: scale(0.8);
  transform-origin: bottom left;
`;
