import React, { useEffect } from 'react';
import { Checkbox } from 'atom/input';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray15, DarkGray24, Primary15 } from 'atom/text';
import { observer } from 'mobx-react';
import { Color } from 'constant';
import { useStores } from 'state';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { HeaderCustomerSignUp } from 'component-mobile/header';
import { RoundButton } from 'atom/button';
import { okLogin } from 'util/token';
import { useAuthDispatch } from '../../context/AuthContext';
import customerStore from 'state/customer';

const MoreBtn = ({ url }) => {
  return (
    <Link to={url} target={'_blank'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '63px',
        }}
      >
        <Primary15 bold style={{ textDecoration: 'underline' }}>
          자세히
        </Primary15>

        <img
          style={{ width: '10px' }}
          src={require('asset/icon/arrow_right_orange.svg').default}
        ></img>
      </div>
    </Link>
  );
};
const SitterSignUpPageStep7 = observer(() => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { sitterStore } = useStores();
  const hadAllAgree = () =>
    sitterStore.signUpForm.isServiceAgree &&
    sitterStore.signUpForm.isPrivacyAgree &&
    sitterStore.signUpForm.isMarketingAgree;
  const changeAgree = (value: boolean) => {
    sitterStore.signUpForm.isServiceAgree = value;
    sitterStore.signUpForm.isPrivacyAgree = value;
    sitterStore.signUpForm.isMarketingAgree = value;
  };

  const checkForm = () => {
    if (
      sitterStore.signUpForm.isServiceAgree === false ||
      sitterStore.signUpForm.isPrivacyAgree === false
    ) {
      showConfirm('필수 약관을 동의해주세요.');
      return false;
    }

    return true;
  };

  const registerCostomer = async () => {
    if (checkForm()) {
      try {
        const {
          ok,
          result,
          token,
          message,
          refreshToken,
        } = await sitterStore.signUpHero(sitterStore.signUpForm);
        if (ok) {
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          history.push('/hero/wellcome');
          customerStore.resetSignUpForm();
          sitterStore.resetSignUpForm();
        } else {
          alert(message || '회원가입에 실패하였습니다.');
        }
      } catch (e) {
        alert(e.message || '회원가입에 실패하였습니다.');
      }
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.push('/sign-up/hero/phone');
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <HeaderCustomerSignUp progress={100} />

      <VerticalLayout style={{ padding: '25px' }}>
        <Space height={32} />
        <DarkGray24 bold>
          약관 및 정책에 <br />
          동의해주세요.
        </DarkGray24>
        <Space height={42} />
        <TermsContainer>
          <Checkbox
            variant="square-line"
            value={hadAllAgree()}
            onChange={value => changeAgree(value)}
          />
          <Space width={15} />
          <DarkGray15 bold>전체 동의하기</DarkGray15>
        </TermsContainer>
        <Space height={26} />
        <hr style={{ width: '100%', alignSelf: 'center' }} />
        <Space height={26} />
        <TermsInerBox>
          <TermsContainer>
            <Checkbox
              variant="square-line"
              value={sitterStore.signUpForm.isServiceAgree}
              onChange={value =>
                (sitterStore.signUpForm.isServiceAgree = value)
              }
            />
            <Space width={15} />
            <DarkGray15 bold>
              <span style={{ color: Color.Primary }}>[필수]</span> 서비스
              이용약관
            </DarkGray15>
          </TermsContainer>
          <MoreBtn url={'/terms/service-terms.html'} />
        </TermsInerBox>
        <Space height={26} />
        <TermsInerBox>
          <TermsContainer>
            <Checkbox
              variant="square-line"
              value={sitterStore.signUpForm.isPrivacyAgree}
              onChange={value =>
                (sitterStore.signUpForm.isPrivacyAgree = value)
              }
            />
            <Space width={15} />
            <DarkGray15 bold>
              <span style={{ color: Color.Primary }}>[필수]</span> 개인정보 수집
              및 이용 동의
            </DarkGray15>
          </TermsContainer>
          <MoreBtn url={'/terms/privacy-policy.html'} />
        </TermsInerBox>
        <Space height={26} />
        <TermsInerBox>
          <TermsContainer>
            <Checkbox
              variant="square-line"
              value={sitterStore.signUpForm.isMarketingAgree}
              onChange={value =>
                (sitterStore.signUpForm.isMarketingAgree = value)
              }
            />
            <Space width={15} />
            <DarkGray15 bold>
              <span style={{ color: Color.Primary }}>[선택]</span> 혜택/정보
              수신동의
            </DarkGray15>
          </TermsContainer>
          <MoreBtn url={'https://woorihero.com'} />
        </TermsInerBox>
        <Space height={165} />
        <RoundButton
          style={{
            width: '320px',
            height: '56px',
            padding: 0,
            fontSize: '16px',
          }}
          onClick={registerCostomer}
        >
          다음
        </RoundButton>
      </VerticalLayout>
    </>
  );
});

const InnerBox = styled.div`
  width: 390px;
  display: flex;
  flex-direction: column;
`;
const TermsContainer = styled.div`
  display: flex;
`;
const TermsInerBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export default SitterSignUpPageStep7;
