import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray12, LightGray11 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Contaent2InfoBox = ({ info }) => {
  const { title, description, imgPath } = info;
  return (
    <Container>
      <Img src={imgPath} />
      <VerticalLayout center>
        <DarkGray12 bold center>
          {title}
        </DarkGray12>
        <Space height={12} />
        <LightGray11 center>{description}</LightGray11>
      </VerticalLayout>
    </Container>
  );
};
const Container = styled(HorizontalLayout)`
  width: 275px;
  height: 127px;
  background: #ffffff;
  border-radius: 11px;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 16px;
`;
const Img = styled.img`
  height: 86px;
  width: 100px;
  object-fit: contain;
`;
export default Contaent2InfoBox;
