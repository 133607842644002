import { RoundButton } from 'atom/button';
import { ImageBox } from 'atom/image';
import { Space } from 'atom/layout';
import {
  DarkGray18,
  DarkGray20,
  DarkGray24,
  DarkGray40,
  LightGray28,
} from 'atom/text';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Overlay } from 'atom/display';
import { useTopmost, useFadeClasses, MobileContextProvider } from 'util/index';
import { WorkBasicPage } from 'page-mobile/sitter/works/WorkBasicPage';
import { WorkPremiumPage } from 'page-mobile/sitter/works/WorkPremiumPage';
import { WorkExtraPage } from 'page-mobile/sitter/works/WorkExtraPage';
import { IntroPremiumPage } from 'page-mobile/customer/intro/IntroPremiumPage';

export const HeroWorks = () => {
  const history = useHistory();
  const topmost = useTopmost();

  const onClickBasicService = () => {
    const id = topmost.show(
      <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
        <WorkBasicPage sheet onClose={() => topmost.dismiss(id)} />
      </Overlay>,
    );
  };

  const onClickPremiumService = () => {
    const id = topmost.show(
      <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
        {/* <WorkPremiumPage sheet onClose={() => topmost.dismiss(id)} /> */}
        <MobileContextProvider isMobile={true}>
          <IntroPremiumPage
            injectedHistory={{
              push: (x: string) => {
                // history.push(x);
                topmost.dismiss(id);
              },
            }}
          />
        </MobileContextProvider>
      </Overlay>,
    );
  };
  const onClickExtraServices = () => {
    const id = topmost.show(
      <Overlay style={{ width: '660px' }} onClose={() => topmost.dismiss(id)}>
        <WorkExtraPage sheet onClose={() => topmost.dismiss(id)} />
      </Overlay>,
    );
  };

  return (
    <>
      <Space height={116} />
      <DarkGray40 style={{ fontWeight: 700 }}>
        아이돌봄 선생님은 어떤일을 하나요?
      </DarkGray40>
      <LightGray28>
        선생님이 원하는 업무만 선택해서 일하실 수 있어요!
      </LightGray28>
      <Space height={78} />
      <Container>
        <ImgContainer>
          <img
            src={require('asset/event/event_sd/work1_bg.jpg').default}
            style={{ width: '394px', marginRight: '29px' }}
          />
          <ContentBox>
            <Space height={102} />
            <img
              src={require('asset/event/event_sd/work1.png').default}
              style={{ width: '336px' }}
            />
            <DarkGray20
              style={{
                fontWeight: 500,
                textAlign: 'center',
                marginTop: '25px',
              }}
            >
              <span style={{ color: '#F69F30' }}>아이 일상을 돕는</span> 등하원
              및 놀이돌봄
            </DarkGray20>
            <DarkGray18 style={{ padding: '20px 48px 31px 48px' }}>
              · 실내 놀이 / 야외 놀이 / 책 읽기 / 목욕 / 숙제지도 등 전반적인
              아이돌봄을 진행해요.
              <br />· 선생님이 집으로 방문하여 아이의 등하원 및 놀이돌봄을
              진행하여 안전한 케어를 도와줘요.
            </DarkGray18>
            <RoundButton
              style={{
                width: '314px',
                height: '51px',
                fontSize: '18px',
                padding: 0,
              }}
              variant="outlined"
              onClick={onClickBasicService}
            >
              상세 정보 보기
            </RoundButton>
          </ContentBox>
        </ImgContainer>

        <img src={require('asset/event/event_sd/work_+.svg').default} />

        <ImgContainer>
          <img
            src={require('asset/event/event_sd/work2_bg.jpg').default}
            style={{ width: '394px', marginRight: '29px' }}
          />
          <ContentBox>
            <Space height={102} />
            <img
              src={require('asset/event/event_sd/work2.png').default}
              style={{ width: '336px' }}
            />
            <DarkGray20
              style={{
                fontWeight: 500,
                textAlign: 'center',
                marginTop: '25px',
              }}
            >
              <span style={{ color: '#F69F30' }}>놀이로 찾는 아이 재능</span>{' '}
              창의놀이
            </DarkGray20>
            <DarkGray18 style={{ padding: '20px 48px 58px 48px' }}>
              · 선생님이 매주 다양한 히어로 놀이를 진행하여 아이에게 다양한
              경험을 제공해요.
              <br />
              <br />· 예술가 / 발명가 / 과학자 / 자유놀이 등 100여가지의 다양한
              놀이를 진행합니다.
            </DarkGray18>
            <RoundButton
              style={{
                width: '314px',
                height: '51px',
                fontSize: '18px',
                padding: 0,
              }}
              variant="outlined"
              onClick={onClickPremiumService}
            >
              상세 정보 보기
            </RoundButton>
          </ContentBox>
        </ImgContainer>

        {/* <ImgContainer>
          <img
            src={require('asset/event/event_sd/work3_bg.jpg').default}
            style={{ width: '394px', marginRight: '29px', marginTop: '58px' }}
          />
          <ContentBox>
            <Space height={102} />
            <img
              src={require('asset/event/event_sd/work3.png').default}
              style={{ width: '336px' }}
            />
            <DarkGray20
              style={{
                fontWeight: 500,
                textAlign: 'center',
                marginTop: '25px',
              }}
            >
              <span style={{ color: '#F69F30' }}>쾌적한 육아환경</span>{' '}
              가사서비스
            </DarkGray20>
            <DarkGray18 style={{ padding: '20px 48px 31px 48px' }}>
              · 아이가 없을 때 가정으로 미리 방문해 쾌적한 돌봄이 이루어질 수
              있도록 도와줘요.
              <br />
              <br />· 선생님의 안전을 위해 아이와 관련된 가사 or 요리 중 한 가지
              서비스만 진행해요.
            </DarkGray18>
            <RoundButton
              style={{
                width: '314px',
                height: '51px',
                fontSize: '18px',
                padding: 0,
              }}
              variant="outlined"
              onClick={onClickExtraServices}
            >
              상세 정보 보기
            </RoundButton>
          </ContentBox>
        </ImgContainer> */}
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ImgContainer = styled.div`
  position: relative;
  margin-left: 29px;
`;

const ContentBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 394px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
