import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Select } from 'atom/input';
import { Space } from 'atom/layout';
import { UnderlinedText } from 'atom/text';
import { AddressItem, NoAddressData } from 'atom/address';
import { AddressPopup } from 'component/popup';
import { useStores } from 'state';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestStep7 } from './RequestStep7';

export const RequestStep6 = observer(({ Bottom, onNext }) => {
  const topmost = useTopmost();
  const { customerStore } = useStores();

  const onClickAdd = () => {
    const id = topmost.show(
      <AddressPopup
        value=""
        initialDetailAddress={customerStore.applyForm.addressDetail || ''}
        onSubmit={(x, y) => {
          customerStore.applyForm.address = x;
          customerStore.applyForm.addressDetail = y;
        }}
        onClose={() => topmost.dismiss(id)}
      />,
    );
  };
  const onChangeAddress = (newAddress: string) => {
    customerStore.applyForm.address = newAddress;
  };
  const onRemoveAddress = () => {
    customerStore.applyForm.address = null;
  };

  return (
    <>
      <Title>
        히어로 선생님이
        <br />
        어디로 가면 될까요?
      </Title>
      <Space height={8} />
      <Subtitle>*현재 서울시에서만 가능한 서비스입니다.</Subtitle>
      <Space height={52} />

      {!customerStore.applyForm.address && <NoAddressData />}
      {!!customerStore.applyForm.address && (
        <React.Fragment>
          <AddressItem
            data={
              customerStore.applyForm.address +
              ' ' +
              customerStore.applyForm.addressDetail
            }
            onChange={e => onChangeAddress(e)}
            onRemove={() => onRemoveAddress()}
          />
          <Space height={10} />
        </React.Fragment>
      )}
      <Space height={22} />

      {!customerStore.applyForm.address && (
        <UnderlinedText onClick={onClickAdd}>+ 주소 추가하기</UnderlinedText>
      )}
      <Space height={50} />
      <Bottom
        progress={(1 / 11) * 6}
        disabled={!customerStore.applyForm.address}
        onClickNext={() => onNext(RequestStep7)}
      />
    </>
  );
});
