import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import AnimatedNumbers from 'react-animated-numbers';
import _Sheet from 'react-modal-sheet';

import { VerticalLayout, HorizontalLayout, Space, Push } from 'atom/layout';
import { ImageBox } from 'atom/image';
import { RoundButton } from 'atom/button';
import {
  Black24,
  Gray14,
  White16,
  White24,
  LightGray16,
  Gray18,
  Primary34,
} from 'atom/text';
import { SliderWithDots } from 'atom/display';
import { Header } from 'component-mobile/header';
import { Footer } from 'component-mobile/footer';
import { AdditionalServicePopup, CareReviews } from 'component-mobile/care';
import { HeroPlayCard } from 'component/customer/HeroPlayCard';
import { ExtraServiceCard } from 'component/customer';
import { HeroPlayContents } from 'content';
import { Color, URL } from 'constant';
import { useReviews, useSiteValue } from 'state/hook/value';
import { useTopmost, useFadeClasses } from 'util/index';

import AnimatedNumber from './Ani';
import { ExtraServiceCardWide } from 'component/customer/ExtraServiceCardWide';

const Sheet = _Sheet as any;

export const CustomerIntroPage = ({}) => {
  const history = useHistory();
  // const careTotalTime = useSiteValue('care_total_time');
  // const careRating = useSiteValue('care_rating');
  // const careDuration = useSiteValue('care_average_time');
  const careTotalTime = useSiteValue('care_time');
  const careRating = useSiteValue('care_score');
  const careDuration = useSiteValue('average_care_months');
  const [serviceSheet, setServiceSheet] = useState<string | null>();
  useFadeClasses();

  const onClickBasicService = () => {
    setServiceSheet('basic');
  };
  const onClickPremiumService = () => {
    setServiceSheet('premium');
  };
  const onClickExtraService = () => {
    setServiceSheet('extra');
  };

  return (
    <>
      <Header />
      <VerticalLayout center>
        <ImageBox
          src={require('asset/mobile/customer/intro_background.jpg').default}
          minHeight={440}
          maxHeight={440}
        >
          <VerticalLayout style={{ height: '100%', justifyContent: 'center' }}>
            <Push />
            <HorizontalLayout>
              <Push maxWidth={260} />
              <VerticalLayout>
                <White24 bold center>
                  집근처 든든한&nbsp;
                  <span style={{ color: Color.Primary }}>아이돌봄</span> 서비스
                </White24>
                <Space height={26} />
                <White16 center>
                  집 근처 30분 거리 베테랑 육아 경력자 선생님이 <br />
                  우리 아이 등하원부터 신나는 놀이돌봄까지!
                </White16>
                <Space height={24} />
                <RoundButton
                  style={{ width: '100%' }}
                  onClick={() => history.push('/service/apply')}
                >
                  돌봄 서비스 신청하기
                </RoundButton>
              </VerticalLayout>
              <Push maxWidth={300} />
            </HorizontalLayout>
            <Space height={40} />
          </VerticalLayout>
        </ImageBox>

        <VerticalLayout
          center
          style={{
            position: 'relative',
            width: '100%',
            background: 'white',
          }}
        >
          <Space height={64} />
          <Black24 bold center>
            히어로만의 특별한
            <br />
            아이 돌봄 서비스
          </Black24>
          <Space height={12} />
          <LightGray16 center>
            아이와 부모님 모두에게 필요한 서비스를 선택해보세요!
          </LightGray16>
          <Space height={32} />
          <VerticalLayout center>
            <ExtraServiceCardWide
              image={require('asset/customer/service/1.jpg').default}
              title="등하원 및 놀이돌봄"
              content="안심하고 맡기는\n등하원 및 놀이돌봄"
              onClick={onClickBasicService}
            />
            <Space height={12} />

            <ExtraServiceCardWide
              image={require('asset/customer/service/2-2.png').default}
              title="히어로 놀이패키지"
              content="우리아이 돌봄 시간을\n더욱 특별하게 만들어주는!"
              onClick={onClickPremiumService}
            />
            {/* <ExtraServiceCard
              image={require('asset/customer/service/1.jpg').default}
              title="등하원 및 놀이돌봄"
              content="아이 등하원은 물론,\n식사지도와 놀이돌봄이 한번에!"
              onClick={onClickBasicService}
            />
            <ExtraServiceCard
              image={require('asset/customer/service/2-2.png').default}
              title="히어로 놀이패키지"
              content="우리아이 돌봄 시간을\n더욱 특별하게 만들어주는!"
              onClick={onClickPremiumService}
            /> */}
          </VerticalLayout>
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout
          style={{
            position: 'relative',
            width: '100%',
            background: '#fafafa',
          }}
        >
          <Space height={64} />
          <VerticalLayout center>
            <Gray18 semiBold>누적 돌봄 시간</Gray18>
            <Space height={12} />
            <Primary34 semiBold>
              <div style={{ display: 'inline-flex ' }}>
                <AnimatedNumber
                  includeComma
                  animateToNumber={+careTotalTime}
                  // @ts-ignore
                  configs={(number, index) => {
                    return {
                      mass: 1,
                      tension: 230 * (index + 1),
                      friction: 140,
                    };
                  }}
                />
              </div>
              시간
            </Primary34>
          </VerticalLayout>
          <Space height={32} />
          <VerticalLayout center>
            <Gray18 semiBold>부모님 만족도</Gray18>
            <Space height={12} />
            <Primary34 semiBold>
              <div style={{ display: 'inline-flex ' }}>
                <AnimatedNumber
                  animateToNumber={+careRating}
                  // @ts-ignore
                  configs={(number, index) => {
                    return {
                      mass: 1,
                      tension: 230 * (index + 1),
                      friction: 140,
                    };
                  }}
                />
              </div>
              점
            </Primary34>
          </VerticalLayout>
          <Space height={32} />
          <VerticalLayout center>
            <Gray18 semiBold>평균 돌봄 기간</Gray18>
            <Space height={12} />
            <Primary34 semiBold>
              <div style={{ display: 'inline-flex ' }}>
                <AnimatedNumber
                  animateToNumber={+careDuration}
                  // @ts-ignore
                  configs={(number, index) => {
                    return {
                      mass: 1,
                      tension: 230 * (index + 1),
                      friction: 140,
                    };
                  }}
                />
              </div>
              개월 이상
            </Primary34>
          </VerticalLayout>
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{
            position: 'relative',
            width: '100%',
            background: 'rgba(246, 159, 48, 0.04)',
          }}
        >
          <Space height={64} />
          <Black24 bold center>
            우리동네 돌봄히어로가 특별한 이유
          </Black24>
          <Space height={16} />
          <LightGray16 center>
            까다로운 7단계 검증과정을 통과한
            <br />
            선생님만 매칭해드려요!
          </LightGray16>
          <Space height={32} />
          <div style={{ position: 'relative' }}>
            <SVG
              src={require('asset/mobile/customer/features.svg').default}
              style={{ maxWidth: '90vw', overflow: 'visible' }}
            />
            <RadarEffect />
          </div>
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{
            position: 'relative',
            width: '100%',
            background: 'white',
          }}
        >
          <Space height={64} />
          <Black24 bold center>
            우리동네 돌봄히어로
            <br />
            간단하게 이용하기
          </Black24>
          <Space height={12} />
          <LightGray16 center>
            직접 찾을 필요 없이 담당 매니저가 찾아드려요!
          </LightGray16>
          <Space height={40} />
          <SVG
            src={require('asset/mobile/customer/flow.svg').default}
            style={{ maxWidth: '90vw', overflow: 'visible' }}
          />
          <Space height={64} />
        </VerticalLayout>

        <VerticalLayout
          center
          style={{
            position: 'relative',
            width: '100%',
            background: '#fafafa',
          }}
        >
          <Space height={64} />
          <Black24 bold center>
            히어로를 이용하신 부모님들의
            <br />
            이유있는 추천!
          </Black24>
          <Space height={12} />
          <LightGray16 center>
            서비스를 이용하시는 부모님의 후기를 확인해 보세요
          </LightGray16>
          <div style={{ marginTop: '-24px' }} />
          <SVG
            src={require('asset/mobile/customer/reviews.svg').default}
            style={{ maxWidth: '90vw', overflow: 'visible' }}
          />
          <div style={{ marginTop: '-24px' }} />
        </VerticalLayout>

        <VerticalLayout
          style={{
            position: 'relative',
            width: '100%',
            background: 'rgba(246, 159, 48, 0.04)',
          }}
        >
          <Space height={64} />
          <HorizontalLayout>
            <VerticalLayout>
              <Black24 bold center>
                당신에게 돌봄히어로가 찾아갑니다.
              </Black24>
              <Space height={16} />
              <LightGray16 center>
                “부모님들의 든든한 육아메이트가 되어드려요!”
              </LightGray16>
              <Space height={40} />
              <HorizontalLayout>
                <Space width={20} />
                <Gray14
                  style={{ fontSize: '13px', flex: 1, lineHeight: '22px' }}
                >
                  처음 해보는 육아, 낯설고 어려운데 그와중에 일까지 해야하는
                  맞벌이부부를 위해 우리동네 돌봄히어로가 탄생했어요.
                  <br />
                  <br />
                  20년 가까이 자신의 아이를 사랑으로 성장 시켜온 베테랑 히어로
                  선생님들이 부모님의 든든한 육아메이트가 되어 드리겠습니다.
                </Gray14>
                <Space width={175} />
              </HorizontalLayout>
            </VerticalLayout>
            <img
              src={require('asset/mobile/customer/bottom_image.png').default}
              style={{
                position: 'absolute',
                width: '168px',
                bottom: '0px',
                right: '7px',
                imageRendering: '-webkit-optimize-contrast',
              }}
            />
          </HorizontalLayout>

          <Space height={64} />
        </VerticalLayout>

        <GrayBackground>
          <Space height={64} />
          <Black24 bold>더 궁금한 점이 있나요?</Black24>
          <Space height={49} />
          <RoundButton
            style={{
              width: 'calc(100% - 40px)',
              fontSize: '16px',
              height: '56px',
            }}
            onClick={() => history.push('/faq')}
          >
            자주 묻는 질문
          </RoundButton>
          <Space height={24} />
          <RoundButton
            style={{
              width: 'calc(100% - 40px)',
              fontSize: '16px',
              height: '56px',
            }}
            variant="dark"
            onClick={() => window.open(URL.Kakao)}
          >
            히어로 상담하기
          </RoundButton>
          <Space height={64} />
        </GrayBackground>
      </VerticalLayout>
      <Footer />

      <AdditionalServicePopup
        show={!!serviceSheet}
        type={serviceSheet as any}
        onClickApply={() => history.push('/service/apply')}
        onClose={() => setServiceSheet(null)}
      />
    </>
  );
};

const SliderContainer = styled.div`
  width: 100%;
  height: 400px;

  overflow: hidden;

  padding: 20px 0px;

  .slick-dots {
    bottom: -48px !important;
  }
`;
const GrayBackground = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;

  background: #f9f9f9;
`;
const RadarEffect = styled.div`
  position: absolute;
  left: 75px;
  top: 551px;

  width: 200px;
  height: 200px;

  background: rgba(255, 153, 0, 0.2);
  border: 3px solid #f69f30;
  border-radius: 100px;

  animation-duration: 2s;
  animation-name: fadein_radar;
  animation-iteration-count: infinite;
  animation-direction: forward;

  transform: translateY(110px);

  @keyframes fadein_radar {
    from {
      width: 0px;
      height: 0px;
      left: calc(75px + 100px);
      top: calc(351px + 100px);
      opacity: 0.5;
    }
    to {
      width: 200px;
      height: 200px;
      left: 75px;
      top: 351px;
      opacity: 1;
    }
  }
`;
