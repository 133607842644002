import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem as MuiMenuItem } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import { Space, Push } from 'atom/layout';
import { Color, URL } from 'constant';
import { MenuItem, MenuItemText } from './MenuItem';
import { useAuthContext } from '../../context/AuthContext';

export const Header = ({}) => {
  const history = useHistory();
  const { isLogin } = useAuthContext();

  return (
    <SHeader>
      <Container>
        <Push maxWidth={200} />
        <Logo onClick={() => history.replace('/')} />
        <Space width={95} />

        <MenuItem label="돌봄서비스" linkTo="/service/intro">
          <Link aria-label="돌봄서비스 소개" to="/service/intro">
            <MuiMenuItem>돌봄서비스 소개</MuiMenuItem>
          </Link>

          <Link aria-label="돌봄서비스 신청" to="/service/apply">
            <MuiMenuItem>돌봄서비스 신청</MuiMenuItem>
          </Link>
        </MenuItem>

        <Space width={55} />

        <MenuItem label="히어로 선생님" linkTo="/hero/about">
          <Link aria-label="히어로선생님 혜택" to="/hero/about">
            <MuiMenuItem>히어로선생님 혜택</MuiMenuItem>
          </Link>
          <Link aria-label="히어로선생님 지원" to="/hero/apply">
            <MuiMenuItem>히어로선생님 지원</MuiMenuItem>
          </Link>
        </MenuItem>

        <Push />
        <Link
          aria-label="이벤트"
          to={{ pathname: 'https://woorihero.oopy.io/' }}
          target="_blank"
        >
          <MenuItemText>이벤트</MenuItemText>
        </Link>
        <Space width={45} />
        <Link aria-label="기업 제휴" to="/service/b2b">
          <MenuItemText>기업 제휴</MenuItemText>
        </Link>
        <Space width={45} />
        <Link aria-label="자주 묻는 질문" to="/faq">
          <MenuItemText>자주 묻는 질문</MenuItemText>
        </Link>
        <Space width={45} />
        <Link aria-label="이용요금" to="/service/pricing">
          <MenuItemText>이용요금</MenuItemText>
        </Link>
        <Space width={45} />
        <Link aria-label="로그인/회원가입" to="/login">
          <MenuItemText>
            {isLogin ? '마이페이지' : '로그인/회원가입'}
          </MenuItemText>
        </Link>
        <Push maxWidth={200} />
        <GoToTopFAB />
        {/* <FAB /> */}
      </Container>
    </SHeader>
  );
};

const SHeader = styled.header``;
const Container = styled.nav`
  position: relative;
  display: flex;

  width: 100%;
  height: 100px;

  align-items: center;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  z-index: 100;
`;
const Logo = styled.img.attrs({
  src: require('asset/logo.png').default,
})`
  width: 131px;
  height: 61px;

  cursor: pointer;

  transition: all 0.52s ease;

  &:hover {
    transform: scale(0.95);
  }
`;

const GoToTopFAB = styled.img.attrs({
  src: require('asset/mobile/home/top.svg').default,
  onClick: () => window.scrollTo(0, 0),
})`
  position: fixed;
  right: 12px;
  bottom: 90px;

  width: 80px;

  cursor: pointer;
`;
const FAB = styled.img.attrs({
  src: require('asset/mobile/home/kakao.png').default,
  onClick: () => window.open(URL.Kakao, '_blank'),
})`
  position: fixed;
  right: 40px;
  bottom: 20px;

  width: 80px;

  cursor: pointer;
`;
