import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

import { Labeled } from 'atom/input';
import { HorizontalLayout, Push, Space } from 'atom/layout';
import { useStores } from 'state';
import { RequestConfirm, RequestStep1 } from '../request';
import { Title, Subtitle } from './style';

export const RegisterStep3 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const isValidEmail =
    customerStore.signUpForm.email.length > 0 &&
    customerStore.signUpForm.email.includes('@');

  const onSubmit = async () => {
    try {
      await customerStore.update(customerStore.signUpForm);
      onNext(RequestConfirm);
    } catch (e) {
      showConfirm('가입에 실패했습니다.');
    }
  };

  return (
    <>
      <Title>
        이름과 이메일 주소를
        <br />
        작성해 주세요.
      </Title>
      <Space height={74} />

      <Labeled label="이름">
        <TextField
          variant="outlined"
          placeholder="입력란"
          style={{ width: '100%' }}
          value={customerStore.signUpForm.name}
          onChange={e => (customerStore.signUpForm.name = e.target.value)}
        />
      </Labeled>
      <Space height={20} />

      <Labeled label="이메일 주소">
        <TextField
          variant="outlined"
          placeholder="입력란"
          style={{ width: '100%' }}
          value={customerStore.signUpForm.email}
          onChange={e => (customerStore.signUpForm.email = e.target.value)}
        />
      </Labeled>
      <Space height={50} />

      <Bottom
        nextLabel="완료"
        disabled={!isValidEmail || customerStore.signUpForm.name.length === 0}
        progress={(1 / 3) * 3}
        onClickNext={onSubmit}
      />
    </>
  );
});
