import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { Carousel } from 'react-responsive-carousel';

import { RoundButton } from 'atom/button';
import { HorizontalLayout, VerticalLayout, Space, Push } from 'atom/layout';
import { ImageBox } from 'atom/image';

import {
  DarkGray32,
  DarkGray24,
  LightGray20,
  LightGray24,
  LightGray28,
  DarkGray40,
  DarkGray30,
  Primary40,
  LightGray22,
  Primary48,
  DarkGray44,
} from 'atom/text';
import { Header } from 'component/header';
import { Footer } from 'component/footer';

import { LimitedSeats } from 'component/event/LimitedSeats';
import { SupportInfos } from 'component/event/SupportInfos';
import { Benefits } from 'component/event/ Benefits';
import { HeroWorks } from 'component/event/HeroWorks';
import { FaqSD } from 'component/event/FaqSD';
import { month } from 'component/event/LimitedSeats';

export const EventSungDongPage = ({}) => {
  const history = useHistory();

  return (
    <>
      <Container center>
        <ImageBox
          src={require('asset/event/event_sd/event_sd_top_2.jpg').default}
          minHeight={810}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              marginTop: '275px',
              position: 'absolute',
              left: '20%',
              marginLeft: 'calc(200px/-2)',
            }}
          >
            {/* <DarkGray32>서울시 지원 선착순 무료교육</DarkGray32>
            <Primary48 bold>시급 최대 17,000원</Primary48>
            <DarkGray44 bold style={{ fontSize: '48px' }}>
              아이와 놀면서 돈도버는 최고의 일자리!
            </DarkGray44> */}
            {/* //old ver.  */}
            <DarkGray32 style={{ fontWeight: 400 }}>
              2022 시-구 상향적 협력적 일자리창출 사업
            </DarkGray32>
            <div
              style={{
                color: '#ED584E',
                background:
                  'linear-gradient(to top, rgba(251, 231, 6, 0.4) 50%, transparent 50%)',
                position: 'relative',
              }}
            >
              <div
                style={{
                  color: '#ED584E',
                  fontSize: '42px',
                  position: 'absolute',
                  top: -15,
                  left: 314,
                  wordSpacing: 7,
                }}
              >
                · ·
              </div>
              <p style={{ fontSize: '34px', fontWeight: 600, margin: 0 }}>
                <span style={{ fontSize: '50px', fontWeight: 700 }}>
                  {month}
                </span>
                한 달간 교육비 무료 지원
              </p>
            </div>
            <DarkGray32 style={{ fontSize: '47px', fontWeight: 700 }}>
              집 근처 30분 이내 활동 가능!
              <br />
              아이돌봄 선생님을 모집 합니다
            </DarkGray32>
            <Space height={26} />
            <RoundButton
              style={{
                width: '260px',
                height: '60px',
                fontSize: '24px',
                padding: 0,
              }}
              onClick={() => history.push('/hero/apply')}
            >
              지금 바로 신청하기
            </RoundButton>
            <Space height={133} />
          </div>
          <div
            style={{
              fontSize: '40px',
              fontWeight: 600,
              letterSpacing: -3,
              position: 'absolute',
              zIndex: 9999,
              left: '50%',
              bottom: '70px',
              transform: 'translate(-50%, 50%)',
            }}
          >
            선착순 무료교육 <span style={{ color: '#ED584E' }}>마감임박!</span>
          </div>
        </ImageBox>
        <img
          src={require('asset/event/event_sd/price.svg').default}
          style={{ alignSelf: 'center' }}
        />

        <Space height={90} />
        <LimitedSeats />
        <Space height={37} />
        <LightGray24>
          * 선착순 자리가 얼마 남지 않았어요. 신청을 서둘러 주세요!
        </LightGray24>
        <Space height={76} />
        <RoundButton
          style={{
            width: '334px',
            height: '60px',
            fontSize: '24px',
            padding: 0,
          }}
          onClick={() => history.push('/hero/apply')}
        >
          지금 바로 신청하기
        </RoundButton>
        <Space height={150} />
        <ImageBox
          src={require('asset/event/event_sd/event_sd_middle_bg.jpg').default}
          minHeight={1320}
        >
          <Space height={120} />
          <DarkGray40
            style={{
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            단 하루만 교육 수료하고,
            <br />집 근처에서 바로 활동해보세요!
          </DarkGray40>
          <Space height={20} />
          <LightGray28
            style={{
              textAlign: 'center',
            }}
          >
            아이를 키워본 30~67세 여성이라면 누구나
          </LightGray28>
          <Space height={86} />
          <HorizontalLayout style={{ justifyContent: 'center' }}>
            <img
              src={require('asset/event/event_sd/flow.svg').default}
              style={{ marginRight: '53px' }}
            />
            <VerticalLayout style={{ width: '600px' }}>
              <Space height={26} />
              <DarkGray24 style={{ fontWeight: 500 }}>
                홈페이지 히어로 선생님 지원하기 클릭 후 지원
              </DarkGray24>
              <Space height={12} />
              <LightGray20>
                * 홈페이지 신청이 힘드신 경우 02-6232-0220 으로 전화접수
                가능합니다.
              </LightGray20>
              <Space height={68} />
              <DarkGray24 style={{ fontWeight: 500 }}>
                신청한 교육날짜에 교육 진행 (단 하루, 5시간)
              </DarkGray24>
              <Space height={84} />
              <DarkGray24 style={{ fontWeight: 500 }}>
                전문 교육 수료 후 관련 서류 제출
              </DarkGray24>
              <Space height={12} />
              <LightGray20>
                *신분증 사본, 가족관계증명서 사본,
                <br />
                통장사본, 보건증(b형 간염 항체검사 포함) 제출
              </LightGray20>
              <Space height={60} />
              <DarkGray24 style={{ fontWeight: 500 }}>
                집 근처에서 돌봄 활동 시작!
              </DarkGray24>
              <Space height={12} />
              <LightGray20>
                돌봄을 원하는 가정과 매칭되면 <br />
                히어로 선생님으로 바로 활동 가능
              </LightGray20>
            </VerticalLayout>
          </HorizontalLayout>
          <Space height={74} />
          <VerticalLayout center>
            <RoundButton
              style={{
                width: '334px',
                height: '60px',
                fontSize: '24px',
                padding: 0,
              }}
              onClick={() => history.push('/hero/apply')}
            >
              교육 일정 확인하기
            </RoundButton>
            <Space height={21} />
            <a href="tel:02-6232-0220">
              <RoundButton
                style={{
                  width: '334px',
                  height: '60px',
                  fontSize: '24px',
                  padding: 0,
                }}
                variant="white"
              >
                전화 접수 : 02-6232-0220
              </RoundButton>
            </a>
          </VerticalLayout>
        </ImageBox>
        <Space height={120} />
        <VerticalLayout center>
          <DarkGray40 style={{ fontWeight: 700 }}>
            히어로 선생님으로 활동해야하는 이유{' '}
            <span style={{ color: '#F69F30' }}>세가지!</span>
          </DarkGray40>
          <LightGray28>
            히어로 센터가 선생님의 든든한 지원군이 되어드려요.
          </LightGray28>
          <Space height={115} />
          <SupportInfos />
          <Space height={120} />
        </VerticalLayout>
        <Benefits />
        <Space height={120} />
        <HeroWorks />
        <Space height={120} />
        <ImageBox
          src={require('asset/event/event_sd/event_sd_middle_bg.jpg').default}
          minHeight={1068}
        >
          <VerticalLayout center>
            <Space height={120} />
            <DarkGray40 style={{ fontWeight: 700 }}>
              서울 전지역 아이돌봄 선생님 대모집 중
            </DarkGray40>
            <LightGray28>
              홈페이지 간편지원 or 전화 한번이면 지원 완료!
            </LightGray28>
            <CallBox>
              <DarkGray30 style={{ fontWeight: 500, marginTop: '30px' }}>
                히어로 교육 문의/신청
              </DarkGray30>
              <a href="tel:02-6232-0220">
                <Primary40 style={{ fontWeight: 500, marginTop: '5px' }}>
                  02 -6232-0220
                </Primary40>
              </a>
              <LightGray22 style={{ marginTop: '10px' }}>
                오전 10시 ~ 오후 6시 주말 / 공휴일 제외
              </LightGray22>
              <CallIcon
                src={require('asset/event/event_sd/call.png').default}
              />
            </CallBox>
            <ApplyBox onClick={() => history.push('/hero/apply')}>
              <DarkGray30 style={{ fontWeight: 500, marginTop: '40px' }}>
                히어로 교육 신청
              </DarkGray30>

              <Primary40 style={{ fontWeight: 500, marginTop: '5px' }}>
                지금 바로 간편 신청하기
              </Primary40>
            </ApplyBox>
            <Space height={42} />
            <LightGray24>
              * 서울시에서 활동 가능하신 분만 지원해주세요.
            </LightGray24>
            <Space height={62} />
            <RoundButton
              style={{
                width: '334px',
                height: '60px',
                fontSize: '24px',
                padding: 0,
              }}
              onClick={() => history.push('/')}
            >
              홈페이지 바로가기
            </RoundButton>
          </VerticalLayout>
        </ImageBox>
        <FaqSD />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled(VerticalLayout)`
  font-family: 'Noto Sans KR';
`;
const CallIcon = styled.img`
  position: absolute;
  bottom: 90px;
  left: 118px;
`;
const CallBox = styled.div`
  width: 641px;
  height: 216px;
  margin-top: 50px;

  background: #ffffff;
  /* Card */
  position: relative;
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  text-align: center;
  &:hover {
    border: 1px solid #ff9030;
  }
`;
const ApplyBox = styled.div`
  width: 641px;
  height: 192px;
  margin-top: 50px;
  /* Containers / White */

  background: #ffffff;

  box-sizing: border-box;
  /* Card */
  text-align: center;
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 1px solid #ff9030;
  }
`;
