import React from 'react';
import { RoundButton } from 'atom/button';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray44, LightGray24, Primary48 } from 'atom/text';
import { Color } from 'constant';
import styled from 'styled-components';
const TopMain = ({ moveToApply }) => {
  return (
    <TopContainer>
      <TopLeftTexts>
        <img
          style={{ width: '60px' }}
          src={require('asset/b2b/building.svg').default}
        />
        <DarkGray44 style={{ fontSize: '48px' }} bold>
          아이를 믿고 맡길 수 있는
        </DarkGray44>
        <Primary48 bold>
          우리동네 돌봄히어로
          <span style={{ color: Color.darkGray }}>를 통해</span>
        </Primary48>
        <Primary48 bold>
          만족도 높은 직원경험
          <span style={{ color: Color.darkGray }}>을 선물하세요.</span>
        </Primary48>
        <Space height={24} />
        <LightGray24 semiBold>
          집 근처 30분 거리 이내에 있는 아이돌봄 전문 선생님을 매칭해보세요.
        </LightGray24>
        <Space height={64} />
        <RoundButton
          onClick={moveToApply}
          style={{ fontSize: '24px', fontWeight: 700 }}
        >
          기업 제휴 신청하기
        </RoundButton>
      </TopLeftTexts>
      <TopRightImage>
        <img
          style={{ width: '503px', height: '782px' }}
          src={require('asset/b2b/topRightTeacher.png').default}
        />
      </TopRightImage>
    </TopContainer>
  );
};

const TopRightImage = styled(VerticalLayout)`
  position: absolute;
  top: 15px;
  right: 20%;
  transform: translateX(10%);
`;
const TopLeftTexts = styled(VerticalLayout)`
  position: absolute;
  top: 120px;
  left: 15%;
  transform: translateX(-10%);
`;
const TopContainer = styled.div`
  width: 100%;
  height: 797px;
  position: relative;
`;

export default TopMain;
