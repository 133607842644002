import React from 'react';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
const Reviews = () => {
  SwiperCore.use([Pagination]);
  return (
    <StyledSwiper
      slidesPerView={1}
      spaceBetween={50}
      loop={true}
      initialSlide={0}
      centeredSlides={true}
      className="mySwiper"
      pagination={{ clickable: true }}
    >
      <SwiperSlide>
        <img
          style={{ width: '269px' }}
          src={
            require('asset/mobile/event/event_playkit/m-review1.png').default
          }
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          style={{ width: '269px' }}
          src={
            require('asset/mobile/event/event_playkit/m-review2.png').default
          }
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          style={{ width: '269px' }}
          src={
            require('asset/mobile/event/event_playkit/m-review3.png').default
          }
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          style={{ width: '269px' }}
          src={
            require('asset/mobile/event/event_playkit/m-review4.png').default
          }
        />
      </SwiperSlide>
    </StyledSwiper>
  );
};

const StyledSwiper = styled(Swiper)`
margin-top:40px;
  .swiper-pagination-bullet-active {
    background-color: orange;
  }
  
  .swiper-container {
    
    
  }
  .swiper-slide-active{
    position: relative !important;
    height: 400px;
  }
  .swiper-pagination-bullets {
    position: absolute !important;
    left: 0px !important;
    bottom:20px !important;
    
    z-index:999;
`;

export default Reviews;
