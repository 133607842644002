import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray12, DarkGray18, Primary12 } from 'atom/text';
import React, { useState } from 'react';
import styled from 'styled-components';
import { VerificationStep } from 'model';
import { useStores } from 'state/index';
import ApplyInputRow from './ApplyInputRow';
import { observer } from 'mobx-react';
import { showTermsPopup } from 'util/index';
import ApplyTermsRow from './ApplyTermsRow';
import { RoundButton } from 'atom/button';

const Apply = observer(props => {
  const { customerStore, loginStore } = useStores();
  const [verificationStep, setVerificationStep] = useState(
    VerificationStep.InputNumber,
  );
  const onClickSendNumber = async () => {
    const phoneNumber = customerStore.applyCompanyForm.phone;
    if (phoneNumber.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    loginStore.form.phone = phoneNumber;

    try {
      await customerStore.sendVerificationCode(phoneNumber);
      setVerificationStep(VerificationStep.Sent);
      showConfirm('인증번호를 전송하였습니다.');
    } catch (error) {
      alert(error.message || '인증번호 전송이 실패했습니다.');
    }
  };

  const onClickCheckNumber = async () => {
    const phone = loginStore.form.phone;
    const accessNumber = loginStore.form.accessNumber;
    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }

    try {
      const {
        ok,
        result,
        message,
        // token,
        // refreshToken,
      } = await customerStore.confirmVerificationCode(
        phone,
        Number(accessNumber),
      );
      if (ok) {
        showConfirm('인증되었습니다.');
        return setVerificationStep(VerificationStep.Verified);
      }
      return showConfirm(`인증번호가 일치하지 않습니다 : ${message}`);
    } catch (error) {
      showConfirm(`인증번호 검증에 오류가 있습니다 : ${error.message}`);
    }
  };
  const inputs = [
    {
      label: '기업명',
      placeholder: '기업명을 입력해주세요.',
      value: customerStore.applyCompanyForm.companyName,
      onChange: e =>
        (customerStore.applyCompanyForm.companyName = e.target.value),
      children: null,
      spaceHeight: 12,
      isImportant: true,
    },
    {
      label: '담당자명',
      placeholder: '담당자명을 입력해주세요.',
      value: customerStore.applyCompanyForm.name,
      onChange: e => (customerStore.applyCompanyForm.name = e.target.value),
      children: null,
      spaceHeight: 12,
      isImportant: true,
    },
    {
      label: '휴대폰 번호',
      placeholder: '휴대폰 번호를 입력해주세요.',
      value: customerStore.applyCompanyForm.phone,
      type: 'number',
      onChange: e =>
        (customerStore.applyCompanyForm.phone = e.target.value.replace(
          /\D/g,
          '',
        )),
      children: (
        <SendNumberBtn
          disabled={verificationStep !== VerificationStep.InputNumber}
          onClick={onClickSendNumber}
        >
          인증번호 전송
        </SendNumberBtn>
      ),
      spaceHeight: 4,
      isImportant: true,
    },
    {
      label: '',
      placeholder: '인증번호를 입력해주세요.',
      value: loginStore.form.accessNumber,
      type: 'number',
      onChange: e =>
        (loginStore.form.accessNumber = e.target.value.replace(/\D/g, '')),
      children: (
        <SendNumberBtn
          disabled={verificationStep === VerificationStep.Verified}
          onClick={onClickCheckNumber}
        >
          인증하기
        </SendNumberBtn>
      ),
      spaceHeight: 4,
    },
    {
      isImportant: true,
      label: '문의 내용',
      placeholder: '문의 내용을 입력해주세요.',
      value: customerStore.applyCompanyForm.content,
      onChange: e => (customerStore.applyCompanyForm.content = e.target.value),
      children: null,
      spaceHeight: 12,
      isTextArea: true,
    },
    {
      label: '방문경로',
      placeholder: '방문경로를 입력해주세요,',
      value: customerStore.applyCompanyForm.inflowRoute,
      onChange: e =>
        (customerStore.applyCompanyForm.inflowRoute = e.target.value),
      children: null,
      spaceHeight: 12,
    },
  ];

  const Terms = {
    privacy: {
      title: '개인정보 수집 및 이용 동의 약관',
      content: (
        <div>
          <DarkGray12 bold>1. 개인정보의 수집 및 이용 목적</DarkGray12>
          <DarkGray12>본인 확인, B2B 제휴 안내, 혜택 제공</DarkGray12>
          <Space height={24} />
          <DarkGray12 bold>2. 수집하는 개인정보의 항목</DarkGray12>
          <DarkGray12> (1) 필수사항 : 이름, 전화번호, 회사명,</DarkGray12>
          <DarkGray12> (2) 선택사항 : 없음</DarkGray12>
          <Space height={24} />
          <DarkGray12 bold>3. 보유기간 및 이용기간</DarkGray12>
          <DarkGray12>
            이벤트 종료일 이후 2개월(60일)간
            <br />
            보유 및 이용 후 파기
          </DarkGray12>
          <Space height={24} />
          <DarkGray12 bold>
            4. B2B 제휴 참여자는 개인정보 수집 및 이용에 동의하지 않을 수
            있습니다.
          </DarkGray12>
          <DarkGray12>
            다만, 미동의 시 이벤트 참여 및 상품 제공이 제한됩니다.
          </DarkGray12>
        </div>
      ),
    },
  };
  const termsInputs = [
    {
      key: 'service',
      title: '[필수] 개인 정보 수집 및 이용 동의',
      isChecked: customerStore.applyCompanyForm.isPrivacyAgree,
      children: (
        <TermsMoreBtn
          onClick={() =>
            showTermsPopup(Terms.privacy.title, Terms.privacy.content)
          }
        />
      ),
      onChange: () => {
        const prev = customerStore.applyCompanyForm.isPrivacyAgree;
        customerStore.applyCompanyForm.isPrivacyAgree = !prev;
      },
      isImportant: true,
    },
  ];

  const onVerifyBody = () => {
    const {
      phone,
      name,
      companyName,
      content,
      isPrivacyAgree,
    } = customerStore.applyCompanyForm;
    console.log(customerStore.applyCompanyForm);
    if (!companyName) {
      showConfirm('회사명을 입력해주세요.');
      return false;
    }
    if (!name) {
      showConfirm('이름을 입력해주세요.');
      return false;
    }
    if (!phone) {
      showConfirm('휴대폰번호를 입력해주세요.');
      return false;
    }
    if (verificationStep !== VerificationStep.Verified) {
      showConfirm('휴대폰번호를 인증해주세요.');
      return false;
    }
    if (!content) {
      showConfirm('문의사항을 입력해주세요');
      return false;
    }
    if (!isPrivacyAgree) {
      showConfirm('개인정보 수집 및 이용 약관을 동의해주세요');
      return false;
    }

    return true;
  };
  const onClickCompanyApply = async () => {
    if (onVerifyBody()) {
      try {
        const { result, ok, message } = await customerStore.applyCompany(
          customerStore.applyCompanyForm,
        );
        console.log(result);
        if (ok) {
          customerStore.resetApplyCompanyForms();
          loginStore.resetForm();
          setVerificationStep(VerificationStep.InputNumber);
          return showConfirm('제휴 문의가 등록되었습니다.');
        }
        return showConfirm(`등록에 실패하였습니다. ${message}`);
      } catch (error) {
        showConfirm(`오류가 발생했습니다: ${error.message}`);
      }
    }
  };
  return (
    <Container center>
      <Space height={64} />
      <DarkGray18 bold>제휴 문의하기</DarkGray18>
      <Space height={20} />
      {inputs.map(input => (
        <ApplyInputRow key={input.label} input={input} />
      ))}
      {termsInputs.map(input => (
        <ApplyTermsRow key={input.key} terms={input} />
      ))}
      <Space height={32} />
      <RoundedButton onClick={onClickCompanyApply}>
        지금바로 참여하기
      </RoundedButton>
    </Container>
  );
});

const Container = styled(VerticalLayout)`
  height: 750px;
  background: #ececec;
`;
const SendNumberBtn = styled.button`
  width: 83px;
  height: 32px;
  margin-left: 5px;
  background: #f69f30;
  border: 1px solid #f69f30;
  border-radius: 5px;
  letter-spacing: -1px;

  font-weight: 700;
  font-size: 11px;
  line-height: 20px;
  color: #ffffff;

  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    filter: contrast(1.15);
  }
  &:active {
    filter: contrast(0.8);
  }

  ${({ disabled }) =>
    disabled
      ? `
  filter: grayscale(1);
  pointer-events: none;
`
      : `
`}
`;

const TermsMoreBtn = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Primary12 bold style={{ textDecoration: 'underline', fontSize: '10px' }}>
        자세히
      </Primary12>
      <Space width={4} />
      <img
        style={{ width: '5px' }}
        src={require('asset/icon/arrow_right_orange.svg').default}
      ></img>
    </div>
  );
};
export default Apply;

const RoundedButton = styled(RoundButton)`
  width: 204px;
  height: 40px;

  padding: 10px 16px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
