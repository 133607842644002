import { Space, VerticalLayout } from 'atom/layout';
import { Company, URL } from 'constant';

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Footer } from 'component-mobile/footer';
import {
  DarkGray24,
  DarkGray16,
  LightGray14,
  Primary18,
  DarkGray18,
  DarkGray14,
} from 'atom/text';
import { HeaderMyPageApply } from 'component-mobile/header/HeaderMyPageApply';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import { RoundButton } from 'atom/button';
import { getUserId, getUserType } from 'util/token';
import { useStores } from 'state/index';
import { getDateKor, getDayOfWeek, getTime } from 'util/index';
import { CircularProgress } from '@material-ui/core';

const MyApplySitter = props => {
  const [applyInfo, setApplyInfo] = useState({
    host_start_time: '',
    host_end_time: '',
    fee: 0,
    supplies: '신원 확인을 위한 신분증, 필기구, 마스크 필수착용',
    note:
      '교육 당일에 프로필 사진촬영이 있어요.\n자기소개서에 들어갈 사진이니 모자 착용은 하지말아주세요!',
    center_name: '',
    center_address: '',
    center_guide: '',
  });
  const { sitterStore } = useStores();
  const history = useHistory();
  const [geo, setGeo] = useState({ lat: 37.5482207, lng: 127.0529857 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const type = getUserType();
    if (type !== 'HERO') {
      history.push('/login');
    }
  }, [history]);

  useEffect(() => {
    const getApplyInfo = async () => {
      setLoading(true);

      try {
        const { ok, result, message } = await sitterStore.getMyApplyInfo(
          getUserId(),
        );
        if (ok) {
          if (result) {
            setApplyInfo(result);
          }
        } else {
          alert(`조회에 실패하였습니다. ${message}`);
        }
      } catch (e) {
        alert(`조회에 실패하였습니다. ${e.message}`);
      } finally {
        setLoading(false);
      }
    };

    getApplyInfo();
  }, []);
  useEffect(() => {
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(applyInfo.center_address, (result, status) => {
      if (status === kakao.maps.services.Status.OK) {
        setGeo({
          lat: Number(result[0].y),
          lng: Number(result[0].x),
        });
      }
    });
  }, [applyInfo]);
  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (!applyInfo.host_start_time) {
    return (
      <>
        <HeaderMyPageApply title="교육 신청내역" />
        <Space height={64} />
        <VerticalLayout center>
          <img
            style={{ width: '280px', height: '186px' }}
            src={require('asset/noApplyData.png').default}
          />
          <Space height={24} />
          <DarkGray18 bold>교육 신청내역이 없어요!</DarkGray18>
          <Space height={24} />
          <DarkGray14 center>
            무료 혜택이 사라지기 전에
            <br />
            지금 바로 교육을 신청해주세요! 🙂
          </DarkGray14>
          <Space height={80} />

          <RoundButton
            onClick={() => {
              history.push('/hero/apply');
            }}
            style={{
              width: '320px',
              height: '56px',
              fontSize: '16px',
              padding: '17px 32px',
            }}
          >
            교육 신청하기
          </RoundButton>
        </VerticalLayout>
        <Space height={90} />
        <Footer />
      </>
    );
  }
  return (
    <>
      <HeaderMyPageApply title="교육 신청내역" />
      <VerticalLayout style={{ padding: '20px' }}>
        <Space height={12} />
        <DarkGray24 bold> 교육 신청이 완료되었습니다</DarkGray24>
        <Space height={32} />
        <hr />
        <Space height={32} />
        <Primary18 bold>교육 일정</Primary18>
        <Space height={8} />
        <DarkGray16>{`교육일자 : ${getDateKor(
          applyInfo.host_start_time,
        )} ${getDayOfWeek(applyInfo.host_start_time)}`}</DarkGray16>
        <DarkGray16>{`교육시간 : ${getTime(
          applyInfo.host_start_time,
        )} ~ ${getTime(applyInfo.host_end_time)}`}</DarkGray16>
        <Space height={8} />
        <LightGray14>
          *교육에 불참하게 될 시, 최소 하루 전에 미리 연락 주세요.{' '}
        </LightGray14>
        <Space height={24} />
        <hr />
        <Space height={24} />
        <Primary18 bold>교육비</Primary18>
        <Space height={8} />
        <DarkGray16>
          {applyInfo.fee === 0
            ? '교육비 : 무료'
            : `${applyInfo.fee.toLocaleString()}원\n계좌 : ${
                Company.BankAccount
              }\n\n*교육 하루 전까지는 꼭 입금을 한 뒤 참석해주세요.`}
        </DarkGray16>
        <Space height={24} />
        <hr />
        <Space height={24} />
        <Primary18 bold>준비물</Primary18>
        <Space height={8} />
        <DarkGray16>{applyInfo.supplies}</DarkGray16>
        <Space height={24} />
        <hr />
        <Space height={24} />
        <Primary18 bold>참고 사항</Primary18>
        <Space height={8} />
        <DarkGray16>{applyInfo.note}</DarkGray16>
        <Space height={24} />
        <hr />
        <Space height={24} />
        <Primary18 bold>교육장소</Primary18>
        <Space height={8} />
        <DarkGray16>{applyInfo.center_address}</DarkGray16>
        <Space height={8} />
        <LightGray14>{applyInfo.center_guide}</LightGray14>
        <Space height={24} />
        <hr />
        <Space height={24} />
        <Map center={geo} style={{ width: '100%', height: '200px' }} level={5}>
          <MapMarker position={geo} />
        </Map>
        <LightGray14>
          교육일정 변경 및 취소를 원하는 경우,
          <br />
          아래 상담버튼을 누르고 변경/취소 내역을 남겨주세요.
        </LightGray14>
        <Space height={32} />
        <RoundButton
          style={{
            width: '320px',
            height: '56px',
            padding: 0,
            fontSize: '16px',
          }}
          onClick={() => window.open(URL.Kakao)}
        >
          카카오톡으로 상담하기
        </RoundButton>
      </VerticalLayout>
      <Footer />
    </>
  );
};

export default MyApplySitter;
