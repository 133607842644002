import React from 'react';
import styled from 'styled-components';

import { HorizontalLayout, Push, Space } from 'atom/layout';
import {
  Primary16,
  Gray10,
  Gray12,
  Gray14,
  LightLightGray16,
  LightGray10,
} from 'atom/text';
import { Checkbox } from 'atom/input';

interface ServiceCardProps {
  title: string;
  price: string;
  body: string;
  comment?: string;
  value: boolean;
  anyChecked: boolean;
  onChange: (x: boolean) => void;
}
export const ServiceCard = ({
  title,
  price,
  body,
  comment,
  value,
  anyChecked,
  onChange,
}: ServiceCardProps) => {
  return (
    <Container selected={value} onClick={() => onChange(!value)}>
      <HorizontalLayout>
        <Checkbox variant="square" value={value} onChange={onChange} />
        <Space width={8} />
        {anyChecked === value ? (
          <Primary16 bold>{title}</Primary16>
        ) : (
          <LightLightGray16 bold>{title}</LightLightGray16>
        )}
        <Push />
        <Gray10>{price}</Gray10>
      </HorizontalLayout>
      <Space height={16} />
      <Gray14 semiBold style={{ lineHeight: 1.2 }}>
        {`${body}`}
      </Gray14>
      {comment && (
        <>
          <Space height={20} />
          <LightGray10>{comment}</LightGray10>
        </>
      )}
    </Container>
  );
};

interface DefaultServiceCardProps {
  title: string;
  price: string;
  body: string;
  comment?: string;
  value?: boolean;
  anyChecked?: boolean;
  onChange?: (x: boolean) => void;
}
export const DefaultServiceCard = ({
  title,
  price,
  body,
  comment,
  value,
  anyChecked,
  onChange,
}: DefaultServiceCardProps) => {
  return (
    <DefaultServiceCardContainer>
      <HorizontalLayout>
        {!!onChange && (
          <>
            <Checkbox value={value} onChange={onChange} />
            <Space width={8} />
          </>
        )}
        <Primary16 bold>{title}</Primary16>
        <Push />
        <Gray12>{price}</Gray12>
      </HorizontalLayout>
      <Space height={16} />
      <Gray14 style={{ lineHeight: 1.2 }}>{body}</Gray14>
      {comment && (
        <>
          <Space height={20} />
          <LightGray10>{comment}</LightGray10>
        </>
      )}
    </DefaultServiceCardContainer>
  );
};

const Container = styled.div<any>`
  border-radius: 16px;

  padding: 12px 19px;

  ${({ selected }) =>
    selected
      ? `
  background-color: #ffedd5;
  `
      : `
  border: solid 1px #b7b7b7;
  color: gray;
  `}
`;

const DefaultServiceCardContainer = styled.div`
  border-radius: 16px;
  background-color: #ffedd5;

  padding: 12px 19px;
`;
