import { ImageBox } from 'atom/image';
import { Space } from 'atom/layout';
import { DarkGray20 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

export const Benefits = () => {
  return (
    <ImageBox
      src={require('asset/mobile/event/event_sd/mobile_benefit_bg.jpg').default}
      minHeight={480}
      style={{
        textAlign: 'center',
      }}
    >
      <Space height={100} />
      <DarkGray20 style={{ fontSize: '18px', fontWeight: 700 }}>
        지금 바로 활동하면, 혜택이 와르르!
      </DarkGray20>
      <Space height={30} />
      <img
        src={
          require('asset/mobile/event/event_sd/mobile_benefit_card.png').default
        }
        style={{ width: '216px', height: '382px' }}
      />
    </ImageBox>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
