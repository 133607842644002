import React from 'react';
import styled, { CSSProperties } from 'styled-components';

type VerticalLayoutProps = {
  center?: boolean;
  fill?: boolean;
  style?: CSSProperties;
  className?: string;
  children: React.ReactNode;
};
export const VerticalLayout = ({
  center = false,
  fill = false,
  children,
  ...props
}: VerticalLayoutProps) => {
  return (
    <Container center={center} fill={fill} {...props}>
      {children}
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;

  ${({ fill }: VerticalLayoutProps) =>
    fill
      ? `
    flex: 1;
  `
      : `
  `}
  ${({ center }: VerticalLayoutProps) =>
    center
      ? `
    align-items: center;
  `
      : `
  `}
`;
