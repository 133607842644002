import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getDate } from '../../util';
import queryString from 'query-string';
import { useNotices } from 'state/hook/notice';
import { CircularProgress } from '@material-ui/core';

const NoticeList = props => {
  const { search } = useLocation();
  const datas = useNotices(queryString.parse(search));

  if (!datas) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Container>
      <TableHeader>
        <TableHeaderItem flex={70}>제목</TableHeaderItem>
        <TableHeaderItem>등록일</TableHeaderItem>
      </TableHeader>
      {datas.length === 0 ? (
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
          공지사항이 없습니다.
        </div>
      ) : (
        datas
          .sort((a, b) => {
            if (a.is_pinned && !b.is_pinned) return -1;
            return 1;
          })
          .map(item => (
            <Link key={item.id} to={`/notice/${item.id}`}>
              <TableRow isPinned={item.is_pinned}>
                <TableCol flex={70}>
                  {
                    <>
                      <span style={{ fontWeight: 'bold' }}>
                        {item.is_pinned ? '[필독] ' : '[공지] '}
                      </span>
                      <span>{item.title}</span>
                    </>
                  }
                </TableCol>
                <TableCol center>{getDate(item.created_at)}</TableCol>
              </TableRow>
            </Link>
          ))
      )}
    </Container>
  );
};

export default NoticeList;

const Container = styled.div`
  padding: 20px;
`;
const TableHeader = styled.div`
  display: flex;
  border-top: solid 1px #2d2d2d;
  border-bottom: solid 1px #2d2d2d;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 700;
`;
const TableHeaderItem = styled.div<any>`
  text-align: center;
  flex: ${({ flex }) => (flex ? `1 1 ${flex}%` : '1 1 30%')};
`;

const TableRow = styled.div<any>`
  display: flex;
  padding: 10px 0;
  font-size: 12px;
  border-bottom: solid 1px #ececec;
  cursor: pointer;
  ${({ isPinned }) =>
    isPinned ? 'background-color: #FFFBF7; color: #F69F30;' : ''}
`;

const TableCol = styled.div<any>`
  padding: 0 14px;
  text-align: ${({ center }) => (center ? `center` : 'left')};
  flex: ${({ flex }) => (flex ? `1 1 ${flex}%` : '1 1 30%')};
`;
