import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Space, HorizontalLayout, Push } from 'atom/layout';
import { useIsMobile } from 'util/index';

interface LabeledProps {
  Right?: React.ReactNode;
  label: string;
  labelStyle?: CSSProperties;
  gap?: number;
  children: React.ReactNode;
};
export const Labeled = ({
  Right,
  label,
  labelStyle,
  gap = 16,
  children,
  ...props
}: LabeledProps) => {
  const isMobile = useIsMobile();

  return (
    <Container
      {...props}
    >
      <HorizontalLayout>
        <Label
          style={labelStyle}
        >
          {label}
        </Label>
        <Push />
        {Right}
      </HorizontalLayout>
      <Space height={isMobile ? 10 : gap} />
      {children}
    </Container>
  )
};

const Container = styled.div`
`;
const Label = styled.div`
  font-size: 16px;
  font-weight: 500;

  line-height: 1.25;
  letter-spacing: -0.4px;

  color: #484848;
`;
