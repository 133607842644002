import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Labeled } from 'atom/input';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, Primary12 } from 'atom/text';
import { observer } from 'mobx-react';
import { useStores } from 'state';
import { useHistory } from 'react-router-dom';
import { RoundButton } from 'atom/button';
import { HeaderSignUpInFrame } from 'component-mobile/header/HeaderSignUpInFrame';
import { HeaderSignUpInFrameCustomer } from 'component-mobile/header/HeaderSignUpInFrameCustomer';
import ApplySignupStep2 from './ApplySignupStep2';

const ApplySignupStep1 = observer(({ onClickPrev, onNext, setHasHeader }) => {
  //여기서부터 다시 시작
  const history = useHistory();
  const { customerStore } = useStores();
  const [nameWarningKey, setNameWarningKey] = useState(null);
  const [phoneWarningKey, setPhoneWarningKey] = useState(null);
  const regEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  const nameWarning = { 1: '이름을 입력해주세요' };
  const phoneWarning = { 1: '이메일 형식이 올바르지 않습니다.' };

  const checkForm = () => {
    if (customerStore.signUpForm.name.length < 2) {
      setNameWarningKey(1);
      return false;
    }
    setNameWarningKey(null);
    if (customerStore.signUpForm.email.match(regEmail) === null) {
      setPhoneWarningKey(1);
      return false;
    }
    setPhoneWarningKey(null);
    return true;
  };

  const registerCostomer = () => {
    if (checkForm()) {
      onNext(ApplySignupStep2);
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    setHasHeader(false);
    if (!customerStore.signUpForm.phone) {
      history.goBack();
      //휴대폰 번호 인증이 안되었다면 현재 페이지 접근 불가.
    }
  }, [customerStore, customerStore.signUpForm.phone, history]);
  return (
    <>
      <HeaderSignUpInFrameCustomer
        title={'회원가입 하기'}
        progress={50}
        onGoback={onClickPrev}
      />

      <VerticalLayout style={{ padding: '40px 20px' }}>
        <Space height={32} />
        <DarkGray24 bold>
          서비스 신청을 위해
          <br />
          회원가입이 필요합니다.
        </DarkGray24>
        <Space height={32} />
        <Labeled label="이름">
          <TextField
            variant="outlined"
            fullWidth
            placeholder="홍길동"
            value={customerStore.signUpForm.name}
            onChange={e => (customerStore.signUpForm.name = e.target.value)}
          />
        </Labeled>
        {nameWarningKey ? (
          <Primary12 style={{ margin: '4px 0 2px 4px' }}>
            {nameWarning[nameWarningKey]}
          </Primary12>
        ) : (
          <Space height={24} />
        )}
        <Space height={24} />
        <Labeled label="이메일 주소">
          <TextField
            variant="outlined"
            fullWidth
            placeholder="woorihero@gmail.com"
            value={customerStore.signUpForm.email}
            onChange={e => (customerStore.signUpForm.email = e.target.value)}
          />
        </Labeled>
        {phoneWarningKey ? (
          <Primary12 style={{ margin: '4px 0 2px 4px' }}>
            {phoneWarning[phoneWarningKey]}
          </Primary12>
        ) : (
          <Space height={24} />
        )}
        <Space height={119} />
        <RoundButton
          style={{
            width: '320px',
            height: '56px',
            padding: 0,
            fontSize: '16px',
            alignSelf: 'center',
          }}
          onClick={registerCostomer}
        >
          다음
        </RoundButton>
        <Space height={35} />
      </VerticalLayout>
    </>
  );
});

export default ApplySignupStep1;
