import React from 'react';
import styled from 'styled-components';

import { HorizontalLayout } from 'atom/layout';
import { Color } from 'constant';

interface TabProps {
  items: LabelValue[];
  value: any;
  onChange: (x: string) => void;
}
export const Tab = ({ items, value, onChange }: TabProps) => {
  return (
    <Container>
      {items.map(x => (
        <TabItem
          key={x.value}
          active={x.value === value}
          onClick={() => onChange(x.value)}
        >
          {x.label}
        </TabItem>
      ))}
    </Container>
  );
};

const Container = styled(HorizontalLayout)`
  width: 100%;
`;
const TabItem = styled.div<any>`
  display: flex;
  flex: 1;
  height: 50px;

  font-size: 18px;
  font-weight: 500;

  letter-spacing: -0.8px;

  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 -12px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  align-items: center;
  justify-content: center;

  transition: all 0.1s linear;
  cursor: pointer;
  border-bottom: 2px solid ${Color.Primary};
  ${({ active }) =>
    active
      ? `
    color: ${Color.white};
    background-color: ${Color.Primary};
    
  `
      : `
    color: #c0c0c0;
  `}
`;
