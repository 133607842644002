import { TextField } from '@material-ui/core';
import { RoundButton } from 'atom/button';
import { Labeled } from 'atom/input';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, Primary12 } from 'atom/text';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'state';
import { HeaderCustomerSignUp } from 'component-mobile/header';
import { AddressPopup } from 'component/popup';
import { useTopmost } from 'util/index';

const SitterSignUpPageStep3 = observer(() => {
  const history = useHistory();
  const topmost = useTopmost();
  const { sitterStore } = useStores();
  const [addressWarningKey, setAddressWarningKey] = useState(null);
  const addressWarning = {
    1: '주소를 입력해주세요',
    2: '현재는 서울 거주자만 활동이 가능합니다.',
  };

  const onClickAddress = () => {
    const id = topmost.show(
      <AddressPopup
        value=""
        restrictToSeoul={false}
        initialDetailAddress={''}
        onSubmit={(x, y) => {
          sitterStore.signUpForm.address = x;
          sitterStore.signUpForm.detailAddress = y;
        }}
        onClose={() => topmost.dismiss(id)}
      />,
    );
  };

  const checkForm = () => {
    const address = sitterStore.signUpForm.address;

    if (address.length <= 0) {
      setAddressWarningKey(1);
      return false;
    }
    if (
      !address.startsWith('서울') // && !address.startsWith('경기')
    ) {
      setAddressWarningKey(2);
      showConfirm(
        '죄송합니다.\n히어로 선생님은 서울 거주자만 지원 가능합니다.',
      );
      return false;
    }

    setAddressWarningKey(null);
    return true;
  };
  const registerHero = () => {
    if (checkForm()) {
      history.push('/sign-up/hero/info4');
    } else {
      console.log('회원가입 불가');
    }
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.push('/sign-up/hero/phone');
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <HeaderCustomerSignUp progress={(3 / 7) * 100} />
      <VerticalLayout style={{ padding: '25px' }}>
        <DarkGray24 bold>
          주소를 <br />
          입력해주세요.
        </DarkGray24>
        <Space height={64} />
        <Labeled label="주소" labelStyle={{ fontSize: '18px' }} gap={10}>
          <TextField
            placeholder="주소를 입력해주세요"
            variant="outlined"
            fullWidth
            value={`${sitterStore.signUpForm.address}`}
            onClick={onClickAddress}
            InputProps={
              sitterStore.signUpForm.address
                ? {
                    startAdornment: (
                      <img
                        style={{ width: '16px', marginRight: '12px' }}
                        src={require('asset/icon/flag_gray.svg').default}
                      />
                    ),
                  }
                : {}
            }
          />
          <Space height={10} />
          {sitterStore.signUpForm.detailAddress && (
            <TextField
              variant="outlined"
              fullWidth
              value={`${sitterStore.signUpForm.detailAddress}`}
              onClick={onClickAddress}
            />
          )}
        </Labeled>
        {addressWarningKey ? (
          <Primary12 style={{ margin: '4px 0 2px 4px' }}>
            {addressWarning[addressWarningKey]}
          </Primary12>
        ) : (
          <Space height={24} />
        )}
        <Space height={220} />
      </VerticalLayout>
      <RoundButton
        style={{
          width: '320px',
          height: '56px',
          padding: 0,
          fontSize: '16px',
          margin: 'auto',
        }}
        onClick={registerHero}
      >
        다음
      </RoundButton>
    </>
  );
});

export default SitterSignUpPageStep3;
