import React, { useState, useEffect } from 'react';

import { IChild } from 'model';
import { useStores } from 'state';

export const useChildren = () => {
  const { customerStore, versionStore } = useStores();
  const [data, setData] = useState<IChild[]>();

  useEffect(() => {
    (async () => {
      setData(await customerStore.getChildren());
    })();
  }, [customerStore, versionStore.getVersion('child')]);

  return data;
};
