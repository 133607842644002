import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray32, DarkGray44, LightGray28 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

const Content3 = props => {
  return (
    <Container center>
      <Space height={160} />
      <HorizontalLayout center>
        <TextGroup>
          <LightGray28 style={{ fontSize: '34px' }} semiBold>
            훌륭한 인재 확보와 유지를 위한 필수조건
          </LightGray28>
          <Space height={12} />
          <DarkGray44 style={{ fontSize: '48px' }} bold>
            육아 복지
          </DarkGray44>
          <Space height={32} />
          <DarkGray32 style={{ fontSize: '34px' }}>
            업무 중 자녀 걱정, 돌봄 스트레스{' '}
            <span style={{ fontWeight: 'bold' }}>DOWN!</span>
          </DarkGray32>
          <DarkGray32 style={{ fontSize: '34px' }}>
            회사 만족도, 업무 집중도, 성과는{' '}
            <span style={{ fontWeight: 'bold' }}>UP!</span>
          </DarkGray32>
        </TextGroup>
        <ImgBox src={require('asset/b2b/content3.png').default} />
      </HorizontalLayout>
    </Container>
  );
};

const Container = styled(VerticalLayout)`
  background: #f6f6f6;
  height: 550px;
  width: 100%;
`;
const TextGroup = styled.div``;
const ImgBox = styled.img`
  width: 607px;
  hight: 230px;
`;
export default Content3;
