import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray32, LightGray20, Primary48 } from 'atom/text';
import React from 'react';

const Content1InfoBox = ({ info }) => {
  const { percent, title, description } = info;
  return (
    <VerticalLayout center style={{ margin: '0 58px' }}>
      <Primary48 style={{ fontSize: '60px' }}>{percent}</Primary48>
      <Space height={64} />
      <DarkGray32 center style={{ fontSize: '34px' }}>
        {title}
      </DarkGray32>
      <Space height={20} />
      <LightGray20 center>{description}</LightGray20>
    </VerticalLayout>
  );
};

export default Content1InfoBox;
