import { VerticalLayout } from 'atom/layout';
import { DarkGray20 } from 'atom/text';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Backdrop } from './Backdrop';

interface TermsPopupProps {
  title: string;
  content: React.ReactNode;
  onClose?: () => void;
}
const TermsPopup = ({ onClose, title, content }: TermsPopupProps) => {
  return (
    <Backdrop onClick={onClose}>
      <Container
        style={{ backgroundColor: 'white' }}
        onClick={e => e.stopPropagation()}
      >
        <VerticalLayout
          center
          style={{ backgroundColor: 'white', minHeight: '60vh' }}
        >
          <Header>
            <DarkGray20 bold>{title}</DarkGray20>
            <CloseBtn onClick={onClose}>X</CloseBtn>
          </Header>
          <Line />
          <Body>{content}</Body>
        </VerticalLayout>
      </Container>
    </Backdrop>
  );
};

const CloseBtn = styled.button`
  background-color: white;
  font-size: 24px;
  font-weight: bold;
  border: 0;
`;

const Line = styled.div`
  width: 90%;
  height: 2px;
  background-color: black;
`;
const Container = styled.div`
  width: 700px;
  background-color: white;
`;

const Header = styled.div`
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  padding: 1.5rem 2rem;
  width: 100%;
`;
export default TermsPopup;
