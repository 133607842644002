import { RoundButton } from 'atom/button';
import { Checkbox, Labeled } from 'atom/input';
import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { Black16, DarkGray24 } from 'atom/text';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'state';
import { HeaderSignUpInFrame } from 'component-mobile/header/HeaderSignUpInFrame';
import ApplySignUpPageStep2 from './ApplySignUpPageStep2';
import ApplySignUpPageStep4 from './ApplySignUpPageStep4';

const ApplySignUpPageStep3 = observer(({ onNext }) => {
  const history = useHistory();
  const { sitterStore } = useStores();

  const registerHero = () => {
    onNext(ApplySignUpPageStep4);
  };

  useEffect(() => {
    if (!sitterStore.signUpForm.phone) {
      history.push('/');
    }
  }, [sitterStore.signUpForm.phone, history, sitterStore]);
  return (
    <>
      <HeaderSignUpInFrame
        progress={(3 / 6) * 100}
        onGoback={() => {
          onNext(ApplySignUpPageStep2);
        }}
      />
      <VerticalLayout>
        <DarkGray24 bold>
          자녀 양육 경험이
          <br />
          있나요?
        </DarkGray24>
        <Space height={64} />
        <Labeled
          label="자녀 양육 경험"
          labelStyle={{ fontSize: '18px' }}
          gap={10}
        >
          <HorizontalLayout style={{ justifyContent: 'space-around' }}>
            <HorizontalLayout>
              <Checkbox
                variant="circle"
                value={sitterStore.signUpForm.hasNurture}
                onChange={e => {
                  if (e) sitterStore.signUpForm.hasNurture = true;
                }}
              />

              <Space width={10} />
              <Black16>경험 있음</Black16>
            </HorizontalLayout>
            <Space width={100} />

            <HorizontalLayout>
              <Checkbox
                variant="circle"
                value={!sitterStore.signUpForm.hasNurture}
                onChange={e => {
                  if (e) {
                    showConfirm(
                      '자녀 양육경험이 없으실 경우,\n필수로 돌봄경험이 있으셔야 합니다.',
                    );
                    sitterStore.signUpForm.hasNurture = false;
                  }
                }}
              />
              <Space width={10} />
              <Black16>경험 없음</Black16>
            </HorizontalLayout>
          </HorizontalLayout>
        </Labeled>
        <Space height={220} />
      </VerticalLayout>
      <RoundButton
        style={{
          width: '320px',
          height: '56px',
          padding: 0,
          fontSize: '16px',
          margin: 'auto',
        }}
        onClick={registerHero}
      >
        다음
      </RoundButton>
    </>
  );
});

export default ApplySignUpPageStep3;
