import { Tag } from 'atom/deco';
import { HorizontalLayout, Push, Space, VerticalLayout } from 'atom/layout';
import { DarkGray14, DarkGray18, DarkGray24, LightGray12 } from 'atom/text';
import { HeaderMyPageApply } from 'component-mobile/header/HeaderMyPageApply';
import React, { useEffect, useState } from 'react';
import { Color, URL } from 'constant';
import styled from 'styled-components';
import { Footer } from 'component-mobile/footer';
import { RoundButton } from 'atom/button';
import { useStores } from 'state/index';
import { ICareInfo, IFeeInfo } from 'model';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const MyApplyCustomer = props => {
  const { customerStore } = useStores();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [careInfo, setCareInfo] = useState<ICareInfo>();
  const [feeInfo, setFeeInfo] = useState<IFeeInfo>();
  const [couponCode, setCouponCode] = useState(null);

  const handleCancel = async () => {
    try {
      const { ok, message } = await customerStore.cancelAppy(careInfo.orderId);
      if (ok) {
        await getInfos();
        showConfirm('취소하였습니다.');
      } else {
        alert(`취소에 실패하였습니다 ${message}`);
      }
    } catch (e) {
      alert(`취소에 실패하였습니다 ${e.message}`);
    }
  };
  const getInfos = async () => {
    setIsLoading(true);
    try {
      const {
        ok,
        message,
        careInfo,
        feeInfo,
      } = await customerStore.getLastCare();
      if (ok) {
        setCareInfo(careInfo);
        setFeeInfo(feeInfo);
        if (careInfo) {
          setCouponCode(careInfo['couponInfo']);
        }
      } else {
        alert(`신청정보 조회에 실패하였습니다. ${message}`);
      }
    } catch (e) {
      alert(`신청정보 조회에 오류가 발생하였습니다. ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getInfos();
  }, []);
  if (isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (!careInfo) {
    return (
      <>
        <HeaderMyPageApply title="서비스 신청내역" />
        <Space height={64} />

        <VerticalLayout center>
          <img
            style={{ width: '280px', height: '186px' }}
            src={require('asset/noApplyData.png').default}
          />
          <Space height={24} />
          <DarkGray18 bold>서비스 신청내역이 없어요!</DarkGray18>
          <Space height={24} />
          <DarkGray14 center>
            빠른 매칭을 위해
            <br />
            지금 바로 서비스를 신청해주세요! 🙂
          </DarkGray14>
          <Space height={90} />
          <RoundButton
            onClick={() => {
              history.push('/service/apply');
            }}
            style={{
              width: '320px',
              height: '56px',
              fontSize: '16px',
              padding: '17px 32px',
            }}
          >
            서비스 신청하기
          </RoundButton>
        </VerticalLayout>
        <Space height={60} />
        <Footer />
      </>
    );
  }
  return (
    <>
      <HeaderMyPageApply title="서비스 신청내역" />
      <Space height={24} />
      <VerticalLayout style={{ padding: '20px' }}>
        <DarkGray24 bold>돌봄을 이렇게 신청해주셨어요!</DarkGray24>
        <Space height={24} />
        <ApplyInfoCard>
          <InfoCategory>아이 정보</InfoCategory>
          {careInfo &&
            careInfo.childInfo.map(child => (
              <div>{`${child.name} / ${child.sex === 1 ? '여아' : '남아'} / ${
                child.birthdate
              }`}</div>
            ))}
          <br />
          <InfoCategory>돌봄 유형</InfoCategory>
          <div>{careInfo ? careInfo.isRegular : ''}</div>
          <br />
          <InfoCategory>돌봄 종류</InfoCategory>
          {careInfo && careInfo.careType.map(type => <div>{type}</div>)}
          <br />
          <InfoCategory>돌봄 일정</InfoCategory>
          {careInfo &&
            !careInfo.isRegular.includes('비정기') &&
            careInfo.carePlan.map(plan => (
              <>
                <div>매주 {plan && plan.dayofweek.join(', ')}</div>
                <div>{`- ${plan.serviceType} / ${plan.timeInfo}`}</div>
                <br />
              </>
            ))}
          {careInfo &&
            careInfo.isRegular.includes('비정기') &&
            careInfo.carePlan.map(plan => (
              <>
                <div>{plan.date}</div>
                <div>{`- ${plan.serviceType} / ${plan.time}`}</div>
                <br />
              </>
            ))}

          <InfoCategory>돌봄 장소</InfoCategory>
          <div>{careInfo && careInfo.careAddress}</div>
        </ApplyInfoCard>
        <Space height={24} />
        <CardContainer>
          <HorizontalLayout>
            <CardTitle>예상 돌봄 비용</CardTitle>
            <Push />
            <Tag>
              {careInfo && careInfo.isRegular.includes('비정기')
                ? '비정기'
                : '4주 기준'}
            </Tag>
          </HorizontalLayout>
          <Space height={10} />
          <LightGray12>
            선생님 경력에 따라 예상 돌봄비용이 달라질 수 있습니다.
          </LightGray12>
          <Space height={10} />
          <VerticalLayout style={{ gap: '13px' }}>
            <CardText style={{ color: Color.Primary }}>
              총 예상금액 {feeInfo && feeInfo.totalFee.toLocaleString()}원
            </CardText>

            <HorizontalLayout center>
              <CardTextContents>기본돌봄</CardTextContents>
              <div
                style={{
                  color: '#999',
                  fontSize: '12px',
                }}
              >
                &nbsp;|&nbsp;
              </div>
              <CardTextContents>
                {feeInfo && careInfo.isRegular.includes('비정기')
                  ? `총 ${careInfo && careInfo.carePlan.length}회`
                  : `주 ${feeInfo && feeInfo.cntOfWeek}회`}
              </CardTextContents>
              <CardTextContents>
                &nbsp;/ {feeInfo && `총 ${feeInfo.totalHour}시간`}
              </CardTextContents>
              <Space width={4} />
              <div style={{ color: '#999', fontSize: '12px' }}>|</div>
              <Space width={4} />
              <CardText style={{ color: Color.Primary }}>
                {feeInfo && feeInfo.careFee.toLocaleString()}원
              </CardText>
            </HorizontalLayout>
            {feeInfo && feeInfo.serviceFee > 0 && (
              <HorizontalLayout center>
                <CardTextContents>창의놀이 패키지</CardTextContents>
                <div
                  style={{
                    color: '#999',
                    fontSize: '12px',
                  }}
                >
                  &nbsp;|&nbsp;
                </div>
                <CardTextContents>{feeInfo.serviceType}</CardTextContents>
                <Space width={4} />
                <div style={{ color: '#999', fontSize: '12px' }}>|</div>
                <Space width={4} />
                <CardText style={{ color: Color.Primary }}>
                  {feeInfo.serviceFee.toLocaleString()}원
                </CardText>
              </HorizontalLayout>
            )}
            {couponCode === 'playkitsd' && (
              <>
                <HorizontalLayout center>
                  <CardTextContents>* 쿠폰적용 | </CardTextContents>
                  <CardTextContents style={{ color: Color.Primary }}>
                    &nbsp;
                    {`[성동구 놀이키트 쿠폰]`}
                  </CardTextContents>
                </HorizontalLayout>
              </>
            )}
          </VerticalLayout>
        </CardContainer>
        <Space height={34} />
        <NoticeBox>
          <LightGray12>
            신청서 접수 후 담당매니저가 1-2일(영업일 기준) 이내에 연락드립니다.
          </LightGray12>
          <LightGray12>
            담당매니저와 상담을 원하시나요?{' '}
            <span
              style={{
                color: Color.Primary,
                fontWeight: 'bold',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => window.open(URL.Kakao)}
            >
              카카오톡 상담하기
            </span>
          </LightGray12>
        </NoticeBox>
        <Space height={16} />
        <RoundButton
          variant="white"
          style={{
            width: '320px',
            height: '56px',
            padding: 0,
            fontSize: '16px',
            alignSelf: 'center',
          }}
          onClick={handleCancel}
        >
          서비스 취소하기
        </RoundButton>
      </VerticalLayout>
      <Footer />
    </>
  );
};
const NoticeBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const CardTitle = styled.div`
  color: #707070;

  font-size: 20px;
  font-weight: bold;
`;
const CardText = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.8px;
  color: #969696;
`;
const CardTextContents = styled.div`
  font-size: 16px;
  font-weight: no;
  letter-spacing: -0.8px;
  color: #969696;
`;
const CardContainer = styled.div`
  width: 100%;

  box-shadow: 3px 4px 12px 0 #dedede;
  border-radius: 10px;
  background-color: #fff;

  padding: 16px;
`;
const ApplyInfoCard = styled.div<any>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;

  background: #f6f6f6;
  border-radius: 16px;

  font-size: 16px;
  line-height: 1.3;
  letter-spacing: -0.8px;
`;
const InfoCategory = styled.div`
  font-weight: bold;
`;

export default MyApplyCustomer;
