import React from 'react';

import customerStore, { ICustomerStore } from './customer';
import drawerStore, { IDrawerStore } from './drawer';
import sitterStore, { ISitterStore } from './sitter';
import versionStore, { IVersionStore } from './version';
import bannerStore, { IBannerStore } from './banner';
import valueStore, { IValueStore } from './value';
import loginStore, { ILoginStore } from './login';

interface IStores {
  loginStore: ILoginStore;
  customerStore: ICustomerStore;
  drawerStore: IDrawerStore;
  sitterStore: ISitterStore;
  versionStore: IVersionStore;
  bannerStore: IBannerStore;
  valueStore: IValueStore;
}
export const useStores = (): IStores => {
  return {
    loginStore,
    customerStore,
    drawerStore,
    sitterStore,
    versionStore,
    bannerStore,
    valueStore,
  };
};
