import { ImageBox } from 'atom/image';
import { Space, VerticalLayout } from 'atom/layout';
import { DarkGray20, DarkGray28, DarkGray40, LightGray28 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

export const FaqSD = () => {
  const faqs = [
    {
      title: 'Q. 교육은 어떻게 신청하나요?',
      contents:
        '[교육 신청하기] 버튼을 눌러 원하는 날에 교육을 신청해주시거나 대표번호로 전화주세요.\n교육은 매주 서울 성동안심상가 8층 대강당에서 이루어집니다.\n교육 수료 후 관련 서류를 제출하시면 돌봄 선생님으로 활동이 바로 가능합니다.',
    },
    {
      title: 'Q. 교육을 들어야만 돌봄 활동이 가능한가요?',
      contents:
        '우리동네 돌봄히어로 소속 돌봄 선생님으로 활동하기 위해서는 교육을 이수하셔야 합니다.\n교육은 가능한 일정에 맞춰 하루만 교육을 받으시면 됩니다.\n5시간 동안 아이돌봄에 대한 전반적인 교육이 이루어집니다. ',
    },
    {
      title: 'Q. 정부 돌봄인가요?',
      contents:
        '우리동네 돌봄히어로는 사설 돌봄업체로 정부에서 운영하고 있는 아이돌봄사업이 아닙니다.\n다만, 서울시와 각 구 여성인력개발센터 등과 선생님 양성교육을 협력해서 진행하고 있습니다.',
    },
    {
      title: 'Q. 4대 보험 가입이 되나요?',
      contents:
        '우리동네 돌봄히어로는 직업소개업 및 교육업으로 4대 보험 적용은 해당되지 않습니다.\n같은 이유로 퇴직금 또한 지급이 불가합니다',
    },
  ];
  return (
    <Container>
      <VerticalLayout center>
        <Space height={120} />
        <DarkGray40 style={{ fontWeight: 700 }}>자주 묻는 질문</DarkGray40>
        <Space height={84} />

        {faqs.map(item => (
          <>
            <Divider />
            <FaqBox>
              <Space height={31} />
              <DarkGray28 style={{ fontWeight: 500 }}>{item.title}</DarkGray28>
              <Space height={10} />
              <DarkGray20 style={{ fontWeight: 300, marginLeft: '30px' }}>
                {item.contents}
              </DarkGray20>
              <Space height={31} />
            </FaqBox>
          </>
        ))}
      </VerticalLayout>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 1140px;
  background: #fafafa;
`;
const Divider = styled.hr`
  width: 960px;
  height: 2px;
  /* Containers/200 */
  background: #d9d9d9;
`;

const FaqBox = styled.div`
  text-align: start;
  width: 900px;
`;
