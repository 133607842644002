import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'atom/input';
import { Space, Push, VerticalLayout } from 'atom/layout';
import { Gray16 } from 'atom/text';
import { ChildPopup } from 'component/popup';
import { useTopmost } from 'util/index';
import { IChild } from 'model';

interface ChildItemProps {
  data: IChild;
  onEdit?: () => void;
  onRemove?: () => void;
}
export const ChildItem = ({ data, onEdit, onRemove }: ChildItemProps) => {
  const topmost = useTopmost();

  return (
    <Container>
      <Thumbnail
        src={
          data.sex === 0
            ? require('asset/customer/male.png').default
            : require('asset/customer/female.png').default
        }
      />
      <Space width={20} />
      <VerticalLayout style={{ transform: 'translateY(-5px)' }}>
        <InnerText>{data.name}</InnerText>
        <Space height={4} />
        <Gray16>
          {data.birthdate} / {data.sex === 0 ? '남자' : '여자'}
        </Gray16>
      </VerticalLayout>

      <Push />
      <Space width={32} />
      <VerticalLayout>
        <Icon
          src={require('asset/icon/remove.png').default}
          onClick={onRemove}
        />
        <Space height={10} />

        <Icon src={require('asset/icon/edit.png').default} onClick={onEdit} />
      </VerticalLayout>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 75px;

  background: white;
  border-radius: 10px;
  box-shadow: 3px 4px 12px 0 #dedede;

  padding: 16px 18px;
`;
const Thumbnail = styled.img`
  width: 43px;
  height: 43px;
`;
const InnerText = styled.div`
  color: #f69f30;

  font-size: 16px;
  font-weight: bold;

  line-height: 1.63;
  letter-spacing: -0.4px;
`;
const Icon = styled.img`
  width: 17px;

  cursor: pointer;
`;
