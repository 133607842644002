import { TextField } from '@material-ui/core';
import { RoundButton } from 'atom/button';
import { Labeled } from 'atom/input';
import { HorizontalLayout, Space, VerticalLayout } from 'atom/layout';
import { DarkGray24, LightGray12 } from 'atom/text';
import { Footer } from 'component-mobile/footer';
import { Header } from 'component-mobile/header';
import CountDown from 'react-countdown';
import { Color } from 'constant';
import { observer } from 'mobx-react';
import { VerificationStep } from 'model';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStores } from 'state';
import Timer, { threeMinutes } from 'component-mobile/login/Timer';
import { useAuthDispatch } from '../../context/AuthContext';
import { okLogin } from 'util/token';

const LoginPage = observer(() => {
  const { loginStore, customerStore, sitterStore } = useStores();
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const [step, setStep] = useState(VerificationStep.InputNumber);
  const [timerDate, setTimerDate] = useState(Date.now());

  const onSendCode = async () => {
    const phone = loginStore.form.phone;
    if (phone.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    try {
      await loginStore.sendVerificationCode(phone);
      setStep(VerificationStep.Sent);
      setTimerDate(Date.now());
    } catch (e) {
      alert(e.message || '인증번호 전송이 실패했습니다.');
    }
  };
  const onVerifyCode = async () => {
    const phone = loginStore.form.phone;
    const accessNumber = loginStore.form.accessNumber;
    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }
    setStep(VerificationStep.Sent);
    try {
      const {
        ok,
        result,
        token,
        refreshToken,
        message,
        code,
      } = await loginStore.confirmVerificationCode(phone, Number(accessNumber));
      if (ok) {
        const { id, name, type } = result;
        okLogin({ id, name, type, token, refreshToken });
        dispatch({
          type: 'updateLoginState',
          isLogin: true,
        });

        history.push('/');
      } else {
        if (
          message.includes('acess check ok') ||
          message.includes('temp_user')
        ) {
          //인증번호 체크 하였으나 회원이 아니거나 //인증번호 조회 결과 임시 유저일때.
          alert(`회원가입이 필요합니다`);
          customerStore.signUpForm.phone = loginStore.form.phone;
          sitterStore.signUpForm.phone = loginStore.form.phone;
          alert(`회원가입이 필요합니다`);
          history.push('/sign-up');
        }
      }
    } catch (e) {
      if (
        e.message.includes('access check ok') ||
        e.message.includes('temp_user')
      ) {
        //인증번호 체크 하였으나 회원이 아니거나 //인증번호 조회 결과 임시 유저일때.
        customerStore.signUpForm.phone = loginStore.form.phone;
        sitterStore.signUpForm.phone = loginStore.form.phone;
        alert(`회원가입이 필요합니다`);
        history.push('/sign-up');
      } else {
        alert(e.message || '인증번호 검증이 실패했습니다.');
      }
    } finally {
      loginStore.resetForm();
    }
  };

  useEffect(() => {
    setStep(VerificationStep.InputNumber);
  }, [loginStore.form.phone]);
  return (
    <>
      <Header />
      <Space height={16} />
      <VerticalLayout style={{ padding: '20px' }}>
        <DarkGray24 bold>
          로그인하기 위해
          <br />
          휴대폰 번호를 인증해 주세요.
        </DarkGray24>
        <Space height={32} />
        <Labeled label="휴대폰 번호">
          <HorizontalLayout style={{ alignItems: 'end' }}>
            <TextField
              style={{ flex: 1 }}
              disabled={step !== VerificationStep.InputNumber}
              value={loginStore.form.phone}
              onChange={e =>
                (loginStore.form.phone = e.target.value.replace(/\D/g, ''))
              }
            />
            <Space width={12} />
            <RoundButton
              variant="primary"
              disabled={step !== VerificationStep.InputNumber}
              style={{
                width: '112px',
                height: '42px',
                padding: 0,
                fontSize: '15px',
              }}
              onClick={onSendCode}
            >
              인증번호 전송
            </RoundButton>
          </HorizontalLayout>
        </Labeled>
        <Space height={40} />
        {step === VerificationStep.InputNumber && <Space height={77} />}
        {step !== VerificationStep.InputNumber && (
          <Labeled label="인증번호를 입력해주세요">
            <HorizontalLayout
              style={{ alignItems: 'end', position: 'relative' }}
            >
              <TextField
                style={{ flex: 1 }}
                value={loginStore.form.accessNumber}
                onChange={e =>
                  (loginStore.form.accessNumber = e.target.value.replace(
                    /\D/g,
                    '',
                  ))
                }
              />
              <Space width={12} />

              <div
                style={{ position: 'absolute', right: '130px', top: '10px' }}
              >
                <CountDown date={timerDate + threeMinutes} renderer={Timer} />
              </div>

              <RoundButton
                variant="secondary"
                style={{
                  width: '112px',
                  height: '42px',
                  padding: 0,
                  fontSize: '15px',
                }}
                onClick={onVerifyCode}
              >
                인증하기
              </RoundButton>
            </HorizontalLayout>
          </Labeled>
        )}
      </VerticalLayout>
      <Space height={150} />
      <div style={{ padding: '20px', textAlign: 'right' }}>
        <LightGray12>
          혹시, 돌봄히어로가 처음이신가요?{' '}
          <Link to={'/sign-up'}>
            <span
              style={{
                color: Color.Primary,
                fontWeight: 'bold',
                fontSize: '13px',
                textDecoration: 'underline',
              }}
            >
              회원가입
            </span>
          </Link>
        </LightGray12>
        <Space width={24} />
        <LightGray12>
          휴대폰 번호 변경시에는 02-6232-0220 센터로 연락해주세요.
        </LightGray12>
      </div>
      <Footer />
    </>
  );
});

export default LoginPage;
