import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

import { RoundButton } from 'atom/button';
import { Labeled } from 'atom/input';
import { HorizontalLayout, Space } from 'atom/layout';
import { useStores } from 'state';
import { VerificationStep } from 'model';
import { Title, Subtitle } from './style';
import { okLogin, okTmpLogin } from 'util/token';
import { useAuthDispatch } from '../../../context/AuthContext';
import { ApplySignupStep1 } from './ApplySignupStep1';
import { useHistory } from 'react-router-dom';
import { RequestStep1 } from '../request';

export const RegisterStep1 = observer(({ Bottom, onNext, onLogin }) => {
  const { customerStore } = useStores();
  const [step, setStep] = useState(VerificationStep.InputNumber);
  const dispatch = useAuthDispatch();
  const history = useHistory();

  const onSendCode = async () => {
    const phone = customerStore.signUpForm.phone;
    if (phone.length < 10) {
      return showConfirm('정확한 휴대폰 번호를 입력해주세요');
    }
    try {
      await customerStore.sendVerificationCode(phone);
      setStep(VerificationStep.Sent);
    } catch (e) {
      alert(e.message || '인증번호 전송이 실패했습니다.');
    }
  };
  const onVerifyCode = async () => {
    const phone = customerStore.signUpForm.phone;
    const accessNumber = customerStore.signUpForm.accessNumber;
    if (accessNumber.length < 4) {
      return showConfirm('인증번호 4자리를 입력해주세요');
    }

    setStep(VerificationStep.Sent);
    try {
      const {
        ok,
        result,
        message,
        token,
        refreshToken,
      } = await customerStore.confirmVerificationCode(
        phone,
        Number(accessNumber),
      );

      if (ok) {
        setStep(VerificationStep.Verified);
        if (token) {
          //로그인 처리
          const { id, name, type } = result;
          okLogin({ id, name, type, token, refreshToken });
          dispatch({
            type: 'updateLoginState',
            isLogin: true,
          });
          if (type === 'HERO') {
            showConfirm('히어로 선생님은 돌봄신청을 할 수 없습니다.');
            history.push('/');
          } else {
            onLogin(); //이미 신청한 돌봄내역이 있나 검사 후 다음스탭 또는 신청안내
          }
        } else {
          //번호인증은 했지만 회원가입이 안되있다면
          //임시 회원가입 후 결과 리턴
          okTmpLogin(result);
          onNext(RequestStep1); // 정기/비정기 선택으로
          //onNext(ApplySignupStep1); // 회원가입 화면으로
          ///home/user/accessNumber/check
          //{"result":{"id":10156,"name":"temp_user","phone":"01096833313","email":"","password":"","type":null,"status":0,"inflow_route":null,"is_marketing_agree":0,"marketing_agree_at":"2022-08-31T04:50:52.000Z","is_service_agree":0,"service_agree_at":"2022-08-31T04:50:52.000Z","is_privacy_agree":null,"privacy_agree_at":null,"ex_phone":null,"created_at":"2022-08-31T04:50:52.000Z","updated_at":"2022-08-31T04:50:52.000Z"},"ok":true,"message":"success for check(this is temp_user)"}
        }
      } else {
        alert(message || '번호인증에 오류가 발생했습니다.');
      }
    } catch (e) {
      alert(e.message || '번호인증에 실패했습니다.');
    }
  };

  useEffect(() => {
    setStep(VerificationStep.InputNumber);
  }, [customerStore.signUpForm.phone]);

  return (
    <>
      <Title>
        언제나 가까이,
        <br />
        우리동네 돌봄히어로
      </Title>
      <Space height={18} />
      <Subtitle>현재 서울시에서만 가능한 서비스입니다.</Subtitle>
      <Space height={32} />

      <Labeled label="휴대폰 번호">
        <HorizontalLayout>
          <TextField
            style={{ flex: 1 }}
            value={customerStore.signUpForm.phone}
            disabled={step !== VerificationStep.InputNumber}
            // inputProps={{
            //   maxLength: 11,
            // }}
            onChange={e =>
              (customerStore.signUpForm.phone = e.target.value.replace(
                /\D/g,
                '',
              ))
            }
          />
          <Space width={32} />
          <RoundButton
            variant="secondary"
            disabled={step !== VerificationStep.InputNumber}
            style={{
              width: '112px',
              height: '42px',
              padding: 0,
              fontSize: '15px',
            }}
            onClick={onSendCode}
          >
            인증번호 전송
          </RoundButton>
        </HorizontalLayout>
      </Labeled>
      <Space height={40} />

      {step === VerificationStep.InputNumber && <Space height={77} />}
      {step !== VerificationStep.InputNumber && (
        <Labeled label="인증번호를 입력해주세요">
          <HorizontalLayout>
            <TextField
              style={{ flex: 1 }}
              value={customerStore.signUpForm.accessNumber}
              onChange={e =>
                (customerStore.signUpForm.accessNumber = e.target.value)
              }
            />
            <Space width={32} />
            <RoundButton
              variant="secondary"
              style={{
                width: '112px',
                height: '42px',
                padding: 0,
                fontSize: '15px',
              }}
              onClick={onVerifyCode}
            >
              인증하기
            </RoundButton>
          </HorizontalLayout>
        </Labeled>
      )}
      <Space height={50} />
    </>
  );
});
